export const GenderEnum = {
  MALE: 'Maschio',
  FEMALE: 'Femmina',
};

export const StanceEnum = {
  REGULAR: 'Regular',
  GOOFY: 'Goofy',
};

export const LevelEnum = {
  NOVICE: '1',
  AVERAGE: '3',
  EXPERT: '5',
};

export const LevelTranslateEnum = {
  NOVICE: 'Principiante',
  AVERAGE: 'Intermedio',
  EXPERT: 'Esperto',
};

export const enumToOptions = (enumObj: {
  [key: string]: string;
}): { label: string; value: string }[] =>
  Object.entries(enumObj).map(([value, label]) => ({ label, value }));

export const measureConvert = (
  measure: string | undefined,
  additionalInfo: string,
  prefix?: boolean
) => {
  if (!measure || isNaN(parseInt(measure)) || parseInt(measure) === 0) {
    return '';
  }
  return !prefix
    ? measure + ' ' + additionalInfo
    : additionalInfo + ' ' + measure;
};

export const getAvailabilityMinMaxRange = (
  size: string
): { minSize: number; maxSize: number } => {
  const [minSize, maxSize] = size.split('-');
  return {
    minSize: parseFloat(minSize.trim()),
    maxSize: parseFloat(maxSize.trim()),
  };
};

export const isValidPhonenumber = (value: string) => {
  return /^\d{7,}$/.test(value.replace(/[\s()+\-\.]|ext/gi, ''));
};
