import { Flex, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import shallow from 'zustand/shallow';
import {
  addToken,
  getUserApi,
  notifyServerError,
} from '../../services/api/open-api';
import { useNotification, useUserStore } from '../../store';
import { BookingInfo } from '../../types';
import { ProfileForm } from '../forms/profile-form';
import StyledButton from '../inputs/styled-button';
import { SectionTitle } from '../section-title';
import AccountWhiteWrapper from './white.wrapper';

type ProfilesForm = Pick<BookingInfo, 'profiles'>;

const AccountProfiles: FC = () => {
  const { user, setUser, token } = useUserStore(
    ({ user, setUser, token }) => ({ user, setUser, token }),
    shallow
  );
  const profilesForm = useForm<ProfilesForm>({
    defaultValues: {
      profiles: user?.profiles?.filter((profile) => !profile.main),
    },
  });
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);
  const onSubmit = async (data: ProfilesForm) => {
    const api = getUserApi();
    if (!user?.id) {
      return;
    }

    const userProfile = user.profiles?.find((profile) => profile.main);
    const newProfiles = [];
    for (const [key, value] of Object.entries(data.profiles ?? [])) {
      const { ownedBoots, ...tempProfile } = value;
      newProfiles.push({
        bootsMM: ownedBoots?.bootsMM,
        bootsBrand: ownedBoots?.brand,
        bootsSize: ownedBoots?.size,
        ...tempProfile,
      });
    }
    try {
      const {
        data: { element },
      } = await api.userEdit(
        user.id,
        { profiles: userProfile ? [userProfile, ...newProfiles] : newProfiles },
        addToken(token)
      );

      if (!element) {
        return;
      }
      setUser(element);
      notify('success', {
        text: 'I tuoi profili sono stati modificati con successo.',
        title: 'Profili modificati.',
      });
    } catch (e) {
      notifyServerError(e, notify);
    }
  };

  const deleteProfile = async (index: number) => {
    const userProfile = user?.profiles?.find((profile) => profile.main);
    const newProfiles = user?.profiles
      ?.filter((profile) => !profile.main)
      .filter((profile, i) => index !== i);
    try {
      await getUserApi().userEdit(
        user?.id!,
        {
          profiles: userProfile
            ? [userProfile, ...(newProfiles ?? [])]
            : newProfiles,
        }!,
        addToken(token)
      );
      setUser({
        ...user,
        profiles: userProfile
          ? [userProfile, ...(newProfiles ?? [])]
          : newProfiles,
      });
      profilesForm.setValue('profiles', newProfiles);
    } catch (e) {
      notifyServerError(e, notify);
    }
  };

  return (
    <FormProvider {...profilesForm}>
      <form onSubmit={profilesForm.handleSubmit(onSubmit)}>
        <AccountWhiteWrapper>
          <SectionTitle textAlign='left' text='Profili' />
          <Flex flexDirection='column' h='100%' minH='200px' mt='10px'>
            {user?.profiles?.filter((profile) => !profile.main).length ===
              0 && (
              <Text w='100%' textAlign='center'>
                Non sono presenti altri profili oltre quello principale.
              </Text>
            )}
            {user?.profiles
              ?.filter((profile) => !profile.main)
              ?.map((profile, index) => {
                return (
                  <div key={'accountprofile' + profile.id}>
                    <HStack justifyContent='space-between'>
                      <Text color='brand.500' my='15px'>
                        Profilo {index + 1}
                      </Text>
                      <Text
                        color='warning'
                        my='15px'
                        cursor='pointer'
                        onClick={() => {
                          notify('warning', {
                            onConfirm: () => deleteProfile(index),
                            title: 'Eliminazione definitiva',
                            text: 'Sei sicuro di voler eliminare il profilo?',
                          });
                        }}
                      >
                        Elimina
                      </Text>
                    </HStack>

                    <ProfileForm
                      profileId={profile.id}
                      index={index}
                      page='account'
                    />
                  </div>
                );
              })}
            <HStack mt='30px !important' h='50px'>
              {profilesForm.getValues('profiles')?.length !== 0 && (
                <StyledButton type='submit'>Salva modifiche</StyledButton>
              )}
            </HStack>
          </Flex>
        </AccountWhiteWrapper>
      </form>
    </FormProvider>
  );
};

export default AccountProfiles;
