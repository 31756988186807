export class PrintServices {
  static number = (inputValue: number | string | undefined, mark: '€' | '%' | string = '€', decimals = 2): string => {
    let value = (typeof inputValue === 'string' ? parseFloat(inputValue) : inputValue) ?? 0
    if (isNaN(value)) value = 0

    return (
      value
        .toFixed(decimals)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ` ${mark}`
    )
  }
}
