import { TModalOpen } from '../../types';
import {
  AreaApi,
  AuthApi,
  AvailabilityApi,
  BookingApi,
  PointApi,
  PriceApi,
  TrackApi,
  UserApi,
} from '../../typings';
export interface Options {
  params?: any;
  headers?: any;
  data?: any;
}

const ApiInstance: {
  UserApi: null | UserApi;
  AuthApi: null | AuthApi;
  AvailabilityApi: null | AvailabilityApi;
  BookingApi: null | BookingApi;
  PointApi: null | PointApi;
  TrackApi: null | TrackApi;
  AreaApi: null | AreaApi;
  PriceApi: null | PriceApi;
} = {
  UserApi: null,
  AuthApi: null,
  AvailabilityApi: null,
  BookingApi: null,
  PointApi: null,
  TrackApi: null,
  AreaApi: null,
  PriceApi: null,
};

export const getUserApi = () => {
  if (ApiInstance.UserApi) {
    return ApiInstance.UserApi;
  }
  ApiInstance.UserApi = new UserApi();
  return ApiInstance.UserApi;
};

export const getAuthApi = (): AuthApi => {
  if (ApiInstance.AuthApi) {
    return ApiInstance.AuthApi;
  }
  ApiInstance.AuthApi = new AuthApi();
  return ApiInstance.AuthApi;
};

export const getAvailabilityApi = (): AvailabilityApi => {
  if (ApiInstance.AvailabilityApi) {
    return ApiInstance.AvailabilityApi;
  }
  ApiInstance.AvailabilityApi = new AvailabilityApi();
  return ApiInstance.AvailabilityApi;
};

export const getBookingApi = (): BookingApi => {
  if (ApiInstance.BookingApi) {
    return ApiInstance.BookingApi;
  }
  ApiInstance.BookingApi = new BookingApi();
  return ApiInstance.BookingApi;
};

export const getPointApi = (): PointApi => {
  return ApiInstance.PointApi ? ApiInstance.PointApi : new PointApi();
};

export const getTrackApi = (): TrackApi => {
  return ApiInstance.TrackApi ? ApiInstance.TrackApi : new TrackApi();
};

export const getAreaApi = (): AreaApi => {
  return ApiInstance.AreaApi ? ApiInstance.AreaApi : new AreaApi();
};

export const getPriceApi = (): PriceApi => {
  return ApiInstance.PriceApi ? ApiInstance.PriceApi : new PriceApi();
};

export const addToken = (
  token?: string
): { headers: { authorization?: string } } => ({
  headers: token ? { authorization: `Bearer ${token}` } : {},
});

export const notifyServerError = (
  e: any,
  notify: TModalOpen,
  onClose?: () => void
) => {
  const message =
    typeof e === 'string' ? e : e.response?.data?.message ?? e.message;

  notify('error', {
    title: 'Si è verificato un errore',
    text: `Errore del server. Si prega di riprovare più tardi.`,
    onClose,
  });
};
