import { Flex, Grid, GridItem, useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import { FC, ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import shallow from 'zustand/shallow';
import {
  addToken,
  getAuthApi,
  getUserApi,
  notifyServerError,
} from '../../services/api/open-api';
import { useNotification, useUserStore } from '../../store';
import {
  UserProfile,
  UserProfileGenderEnum,
  UserProfileLevelEnum,
  UserProfileStanceEnum,
} from '../../typings';
import {
  enumToOptions,
  GenderEnum,
  LevelTranslateEnum,
  StanceEnum,
} from '../../utils/profile-utils';
import { BirthDateButton, BirthDateCalendar } from '../forms/date.selector';
import { DetailsTabButton, TitleIndexedTabs } from '../forms/tabs';
import SimpleInputText from '../inputs/input-text';
import { CustomSelect } from '../inputs/select-input';
import StyledButton from '../inputs/styled-button';
import { MeasuresModal } from '../modals/modals';
import { SectionTitle } from '../section-title';
import AccountWhiteWrapper from './white.wrapper';

interface AccountForm extends UserProfile {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const GridForm = ({ children }: { children: ReactNode }) => (
  <Flex direction='column'>
    <Grid
      templateColumns={{
        base: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)',
      }}
      gap='16px'
      w='100%'
      justifyItems='center'
    >
      {children}
    </Grid>
  </Flex>
);

const AccountData: FC = () => {
  const { user, setUser, token } = useUserStore(
    ({ user, setUser, token }) => ({ user, setUser, token }),
    shallow
  );
  const userProfile = user?.profiles?.find((profile) => profile.main);
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm<AccountForm>({
    defaultValues: userProfile,

    mode: 'onChange',
  });

  const [open, setIsOpen] = useState<
    'head' | 'boots' | 'foot' | 'ankle' | false
  >(false);

  const onSubmit = async ({
    confirmPassword,
    newPassword,
    oldPassword,
    ...data
  }: AccountForm) => {
    if (!user?.id) {
      return;
    }

    const api = getUserApi();

    try {
      const {
        data: { element },
      } = await api.userEdit(
        user.id,
        {
          profiles: [
            {
              ...data,
              birthDate: !!data.birthDate ? data.birthDate : undefined,
            },

            ...(user.profiles ?? []).filter((profile) => !profile.main),
          ],
        },
        addToken(token)
      );

      if (!element) {
        return;
      }
      setUser(element);
    } catch (e) {
      notifyServerError(e, notify);
    }
    try {
      if (confirmPassword && newPassword && (oldPassword || user.isSocial)) {
        const authApi = getAuthApi();
        await authApi.authChangePassword(
          { oldPassword: oldPassword ?? '', newPassword },
          addToken(token)
        );
      }
    } catch (e) {
      notifyServerError(e, notify);
    }
    notify('success', {
      text: "L'utente è stato aggiornato con successo.",
      title: 'Modifiche effettuate',
    });
  };

  const birthDayChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    setValue('birthDate', moment(date).format('YYYY-MM-DD'), {
      shouldValidate: true,
    });
  };

  const birthDateDisclosure = useDisclosure();

  const [birthDate, setBirthDate] = useState<Date | null>(
    moment(getValues('birthDate')).toDate()
  );

  return (
    <AccountWhiteWrapper>
      <MeasuresModal
        defaultOpen={open}
        isOpen={!!open}
        onClose={() => setIsOpen(false)}
      />

      <SectionTitle mb='23px' textAlign='left' text='Account' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TitleIndexedTabs
          childrens={[
            {
              button: <DetailsTabButton w='100%' title='Dati' />,
              panel: () => (
                <GridForm>
                  <SimpleInputText
                    {...register('firstName', {
                      required: true,
                    })}
                    label='Nome'
                    secondaryLabelType='required'
                    errors={errors.firstName}
                  />
                  <SimpleInputText
                    {...register('lastName', {
                      required: true,
                    })}
                    label='Cognome'
                    secondaryLabelType='required'
                    errors={errors.lastName}
                  />
                  <CustomSelect
                    label='Sesso'
                    {...register('gender')}
                    customOnChange={({ value }) =>
                      setValue('gender', value as UserProfileGenderEnum, {
                        shouldValidate: true,
                      })
                    }
                    customDefaultValue={enumToOptions(GenderEnum).find(
                      ({ value }) => value === getValues('gender')
                    )}
                    options={enumToOptions(GenderEnum)}
                    errors={errors.gender}
                    w='100%'
                  />
                  <BirthDateButton
                    {...register('birthDate', {})}
                    errors={errors.birthDate}
                    birthDateValue={birthDate}
                    onClick={birthDateDisclosure.onToggle}
                  />
                  {birthDateDisclosure.isOpen && (
                    <GridItem colSpan={{ base: 1, md: 2 }} w='100%'>
                      <BirthDateCalendar
                        startDate={birthDate}
                        setStartDate={setBirthDate}
                        birthDayChange={birthDayChange}
                        onClose={birthDateDisclosure.onClose}
                      />
                    </GridItem>
                  )}
                  <CustomSelect
                    label='Livello'
                    {...register('level')}
                    customOnChange={({ value }) =>
                      setValue('level', value as UserProfileLevelEnum, {
                        shouldValidate: true,
                      })
                    }
                    customDefaultValue={enumToOptions(LevelTranslateEnum).find(
                      ({ value }) => value === getValues('level')
                    )}
                    options={enumToOptions(LevelTranslateEnum)}
                    isRequired
                    errors={errors.level}
                    w='100%'
                  />
                  <CustomSelect
                    label='Stance'
                    {...register('stance')}
                    customOnChange={({ value }) =>
                      setValue('stance', value as UserProfileStanceEnum, {
                        shouldValidate: true,
                      })
                    }
                    customDefaultValue={enumToOptions(StanceEnum).find(
                      ({ value }) => value === getValues('stance')
                    )}
                    options={enumToOptions(StanceEnum)}
                    errors={errors.stance}
                    w='100%'
                  />
                </GridForm>
              ),
            },
            {
              button: <DetailsTabButton w='100%' title='Misure' />,
              panel: () => (
                <GridForm>
                  <SimpleInputText
                    label='Altezza'
                    additionalInfo='Cm'
                    {...register('heightCM', { valueAsNumber: true })}
                    errors={errors.heightCM}
                  />
                  <SimpleInputText
                    label='Peso'
                    additionalInfo='Kg'
                    {...register('weightKG', { valueAsNumber: true })}
                    errors={errors.weightKG}
                    type='number'
                  />
                  <SimpleInputText
                    label='Circ. Testa'
                    additionalInfo='Cm'
                    secondaryLabelType='size'
                    setGuideToggled={(value) =>
                      setIsOpen(value ? 'head' : false)
                    }
                    {...register('headCircCM', { valueAsNumber: true })}
                    errors={errors.headCircCM}
                  />
                  <SimpleInputText
                    label='Numero Scarpa'
                    additionalInfo='EU'
                    {...register('shoeNumber', { valueAsNumber: true })}
                    errors={errors.ankleCM}
                  />
                  <SimpleInputText
                    label='Circ. Caviglia'
                    additionalInfo='Cm'
                    secondaryLabelType='size'
                    setGuideToggled={(value) =>
                      setIsOpen(value ? 'ankle' : false)
                    }
                    {...register('ankleCM', { valueAsNumber: true })}
                    errors={errors.ankleCM}
                  />
                  <SimpleInputText
                    label='Lung. Piede'
                    additionalInfo='Cm'
                    secondaryLabelType='size'
                    setGuideToggled={(value) =>
                      setIsOpen(value ? 'foot' : false)
                    }
                    {...register('footLengthCM', { valueAsNumber: true })}
                    errors={errors.footLengthCM}
                  />
                  <SimpleInputText
                    label='Larg. Piede'
                    additionalInfo='Cm'
                    secondaryLabelType='size'
                    setGuideToggled={(value) =>
                      setIsOpen(value ? 'foot' : false)
                    }
                    {...register('footWidthCM', { valueAsNumber: true })}
                    errors={errors.footWidthCM}
                  />
                  <SimpleInputText
                    label='Taglia scarponi'
                    additionalInfo='EU'
                    {...register('bootsSize', { valueAsNumber: true })}
                    errors={errors.bootsSize}
                  />
                  <SimpleInputText
                    label='Marca scarponi'
                    {...register('bootsBrand')}
                    errors={errors.bootsBrand}
                  />
                  <SimpleInputText
                    label='Mm scarpone'
                    secondaryLabelType='size'
                    setGuideToggled={(value) =>
                      setIsOpen(value ? 'boots' : false)
                    }
                    additionalInfo='Mm'
                    {...register('bootsMM', { valueAsNumber: true })}
                    errors={errors.bootsMM}
                  />
                </GridForm>
              ),
            },
            {
              button: <DetailsTabButton w='100%' title='Password' />,
              panel: () => (
                <GridForm>
                  {!user?.isSocial && (
                    <SimpleInputText
                      {...register('oldPassword')}
                      label='Password attuale'
                      isPassword
                      errors={errors.oldPassword}
                    />
                  )}

                  <SimpleInputText
                    {...register('newPassword')}
                    label='Nuova password'
                    isPassword
                    errors={errors.newPassword}
                  />
                  <SimpleInputText
                    label='Conferma password'
                    {...register('confirmPassword', {
                      validate: {
                        isEqual: (value) =>
                          value === getValues('newPassword') || '',
                      },
                    })}
                    isPassword
                    errors={errors.confirmPassword}
                  />
                </GridForm>
              ),
            },
          ]}
        />

        <Flex w='100%' justify='center'>
          <StyledButton mt='30px !important' type='submit'>
            Salva modifiche
          </StyledButton>
        </Flex>
      </form>
    </AccountWhiteWrapper>
  );
};

export default AccountData;
