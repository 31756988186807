import {
  Grid,
  GridItem,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ProfileForm } from '../components/forms/profile-form';
import { ProfilesWithDatesInput } from '../components/inputs/person-count';
import StyledButton from '../components/inputs/styled-button';

import React, { createRef, Fragment, useEffect, useState } from 'react';
import { PageContainer } from '../components/container';
import { IncrementPersonIcon } from '../components/icons/inputs';
import { MeasuresModal } from '../components/modals/modals';
import { useBookingStore, useNotification } from '../store';
import { BookingInfo } from '../types';

import moment from 'moment';

import { useQuery } from '@tanstack/react-query';
import { DateRangeCalendar } from '../components/forms/date.selector';
import { getAvailabilityApi } from '../services/api/open-api';
import shallow from 'zustand/shallow';

const Profiles = () => {
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);
  const calendarDisclosure = useDisclosure();

  const { bookingInfo, setBookingInfo, profilesCount, setProfilesCount } =
    useBookingStore(
      ({ bookingInfo, setBookingInfo, profilesCount, setProfilesCount }) => ({
        bookingInfo,
        setBookingInfo,
        profilesCount,
        setProfilesCount,
      }),
      shallow
    );
  const navigate = useNavigate();
  const [open, setIsOpen] = useState<
    'head' | 'boots' | 'foot' | 'ankle' | false
  >(false);

  const [startDate, setStartDate] = useState<Date | null>(
    moment(bookingInfo?.startDate).toDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    moment(bookingInfo?.endDate).toDate()
  );

  const profilesRef = createRef<HTMLDivElement>();

  const refArray = Array.from({ length: profilesCount }, (a) =>
    React.createRef<HTMLDivElement>()
  );

  const methods = useForm<Partial<BookingInfo>>({
    defaultValues: {
      profiles: bookingInfo?.profiles,
      startDate: bookingInfo?.startDate,
      endDate: bookingInfo?.endDate,
    },
    shouldUnregister: true,
  });

  useEffect(() => {
    if (
      !bookingInfo?.startDate ||
      !bookingInfo?.endDate ||
      !bookingInfo?.areaId
    ) {
      navigate('/');
    }
    methods.register('startDate');
    methods.register('endDate');
  }, []);

  const formStartDate = methods.watch('startDate');
  const formEndDate = methods.watch('endDate');

  const setProfilesFunc = (value: number) => {
    for (let i = value; i < profilesCount; i++) {
      methods.unregister(`profiles.${i}`);
    }
    setProfilesCount(value);
  };

  const deliveryTime = bookingInfo?.startDate?.split('T')[1] ?? '00:00:00';
  const pickupTime = bookingInfo?.endDate?.split('T')[1] ?? '00:00:00';

  const { data: equipData, refetch: availabilityRefetch } = useQuery(
    ['getEquipmentData'],
    async () => {
      const res = await getAvailabilityApi().availabilityMinimum({
        areaId: bookingInfo?.areaId!,
        startDate: moment(formStartDate ?? new Date()).format('YYYY-MM-DD'),
        endDate: moment(formEndDate ?? new Date()).format('YYYY-MM-DD'),
      });
      return res.data;
    }
  );

  useEffect(() => {
    availabilityRefetch();
  }, [formStartDate, formEndDate]);

  const handleRangeChange = (startDate: Date | null, endDate: Date | null) => {
    methods.setValue('startDate', moment(startDate).format());
    if (endDate === null) {
      methods.setValue('endDate', moment(startDate).format());
      return;
    }
    methods.setValue('endDate', moment(endDate).format());
  };

  const onSubmit: SubmitHandler<Partial<BookingInfo>> = (data) => {
    for (const [key, value] of Object.entries(data?.profiles ?? [])) {
      methods.clearErrors(`profiles.${parseInt(key)}.bookedBoard`);
      if (
        !value.bookedBoard &&
        !value.bookedSki &&
        !value.bookedSkiBoots &&
        !value.bookedBoardBoots &&
        !value.bookedHelmet
      ) {
        methods.setError(`profiles.${parseInt(key)}.bookedBoard`, {
          type: 'required',
        });
        return notify('error', {
          title: 'Errore',
          text: 'Nessuna attrezzatura selezionata per il profilo ' + (+key + 1),
        });
      }
    }
    setBookingInfo({
      ...bookingInfo,
      ...data,
      startDate: moment(startDate).format('YYYY-MM-DDT' + deliveryTime),
      endDate: endDate
        ? moment(endDate).format('YYYY-MM-DDT' + pickupTime)
        : moment(startDate).format('YYYY-MM-DDT' + deliveryTime),
    });
    navigate('/shipment');
  };

  const onError: SubmitErrorHandler<Partial<BookingInfo>> = (data) => {
    notify('error', {
      title: 'Errore',
      text: 'Sono presenti errori nella compilazione dei profili.',
    });
  };

  return (
    <PageContainer>
      <MeasuresModal
        defaultOpen={open}
        isOpen={!!open}
        onClose={() => setIsOpen(false)}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <Text fontSize='15px' lineHeight={1.2} mb='14px' color='brand.500'>
            Periodo e persone
          </Text>
          <Grid
            borderColor='brandTransparent.500'
            borderWidth='1px'
            w='100%'
            justifyContent='space-between'
            borderRadius='10px'
            bg='white'
            p='10px 7px 10px 7px'
            templateColumns='repeat(2,1fr)'
          >
            <ProfilesWithDatesInput
              startDate={startDate!}
              endDate={endDate!}
              setProfiles={setProfilesFunc}
              cursor='pointer'
              pl='13px'
              onClick={() => {
                calendarDisclosure.onToggle();
              }}
              addRef={profilesRef}
            />
            {calendarDisclosure.isOpen && (
              <GridItem colSpan={2} mt='16px' w='100%'>
                <DateRangeCalendar
                  startDate={startDate}
                  endDate={endDate}
                  selectedArea={bookingInfo?.areaId}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  handleRangeChange={handleRangeChange}
                  isRange
                />
              </GridItem>
            )}
          </Grid>
          {refArray.map((x, i) => {
            return (
              <Fragment key={'profile' + i}>
                <Text textStyle='book' color='brand.500' my='15px'>
                  Persona {i + 1}
                </Text>
                <ProfileForm
                  index={i}
                  setGuideToggled={setIsOpen}
                  ref={x}
                  proceed={() => {
                    // if (refArray[i + 1]) {
                    refArray[i + 1]?.current?.click();
                    // }
                  }}
                  page='profiles'
                  equipData={equipData}
                />
              </Fragment>
            );
          })}

          <VStack w='100%' mb='100px'>
            <HStack
              h='20px'
              w='100%'
              maxW='325px'
              spacing='8px'
              mt='25px'
              justifyContent='space-between'
              cursor='pointer'
              onClick={() => {
                profilesRef.current?.click();
              }}
            >
              <IncrementPersonIcon w='24px' h='19px' />
              <Text fontSize='15px' color='brand.500'>
                Vuoi aggiungere una nuova persona?
              </Text>
            </HStack>

            <StyledButton mt='30px !important' type='submit'>
              Prenota
            </StyledButton>
          </VStack>
        </form>
      </FormProvider>
    </PageContainer>
  );
};

export default Profiles;
