import { IconProps, Icon } from "@chakra-ui/react";

export const LogoIcon = (props: IconProps) => (
  <Icon viewBox="0 0 64.113 16" {...props}>
    <g
      id="Raggruppa_1807"
      data-name="Raggruppa 1807"
      transform="translate(0 0)"
    >
      <path
        id="Tracciato_1289"
        data-name="Tracciato 1289"
        d="M10.4,0,8.518,4.588,7.882,6.565,7.247,4.588,5.365,0H0L5.341,10.682V16h4.8V10.682L15.482,0Z"
        transform="translate(0 0)"
      />
      <path
        id="Tracciato_1290"
        data-name="Tracciato 1290"
        d="M32.805,0V16H44.687V12.236H37.605V9.694h6.141V6.047H37.605V3.765h7.082V0Z"
        transform="translate(-12.148 0)"
      />
      <path
        id="Tracciato_1291"
        data-name="Tracciato 1291"
        d="M70.966,0,66,7.295V0H61.2V16H66V9.106L70.966,16H76.8L70.754,7.836,76.307,0Z"
        transform="translate(-22.663 0)"
      />
      <rect
        id="Rettangolo_727"
        data-name="Rettangolo 727"
        width="4.8"
        height="16"
        transform="translate(59.313 0)"
      />
    </g>
  </Icon>
);
