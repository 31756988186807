import {
  Box,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  HStack,
  Select,
  Text,
  forwardRef,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getYear } from 'date-fns';
import { it } from 'date-fns/locale';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { FieldError, UseFormSetValue } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { getAvailabilityApi, getPriceApi } from '../../services/api/open-api';
import { useBookingStore } from '../../store';
import { BookingInfo } from '../../types';
import { CalendarIcon } from '../icons/inputs';

export const DateRangeButton = forwardRef<
  {
    selectedArea: string;
    startDate: Date | null;
    endDate: Date | null;
    scrollRef?: React.MutableRefObject<HTMLDivElement | null>;
  } & ButtonProps,
  'button'
>(({ selectedArea, startDate, endDate, scrollRef, ...props }, ref) => {
  return (
    <Flex
      flexDirection='column'
      onClick={() => {
        setTimeout(() => {
          scrollRef?.current?.scrollIntoView?.({
            behavior: 'smooth',
            block: 'center',
          });
        }, 50);
      }}
    >
      <HStack justify='space-between'>
        {/* label */}
        <Text
          fontSize='15px'
          lineHeight={1.2}
          mb='10px'
          mt={{ base: '15px', md: '0px' }}
        >
          Periodo
        </Text>
      </HStack>
      <Button
        maxW={{ base: '100%', md: '315px' }}
        w='100%'
        h='48px'
        p='0px 8px 0px 8px'
        borderWidth='1px'
        borderColor={' brandTransparent.500'}
        bgColor='white'
        borderRadius='10px'
        fontWeight='200'
        disabled={!selectedArea}
        justifyItems='center'
        leftIcon={
          <CalendarIcon h='21px' w='21px' ml='9.5px' color='brand.500' />
        }
        {...props}
        ref={ref}
      >
        {!selectedArea ? (
          <Text lineHeight='12px' w='100%'>
            Selezionare località
          </Text>
        ) : !startDate || !endDate ? (
          <Text color='#808080' lineHeight='12px' w='100%'>
            {!startDate ? 'Seleziona data inizio' : 'Seleziona data fine'}
          </Text>
        ) : (
          <HStack mr='19px' w='100%' justifyContent='space-between'>
            <Text fontSize='15px' lineHeight='12px'>
              Dal
            </Text>
            <Text color='#1D1E1C80' textStyle='book' lineHeight='12px'>
              {moment(startDate).format('DD/MM/YY')}
            </Text>
            <Text fontSize='15px' lineHeight='12px'>
              Al
            </Text>
            <Text color='#1D1E1C80' lineHeight='12px'>
              {moment(endDate).format('DD/MM/YY')}
            </Text>
          </HStack>
        )}
      </Button>
    </Flex>
  );
});

export const BirthDateButton = forwardRef<
  {
    birthDateValue: Date | null;
    errors?: FieldError | undefined;
    secondaryLabelType?: 'required' | 'size' | 'time' | undefined;
    scrollRef?: React.MutableRefObject<HTMLDivElement | null>;
  } & ButtonProps,
  'button'
>(
  (
    { birthDateValue, secondaryLabelType, errors, scrollRef, ...props },
    ref
  ) => {
    return (
      <Flex
        flexDirection='column'
        w='100%'
        onClick={() => {
          setTimeout(() => {
            scrollRef?.current?.scrollIntoView?.({
              behavior: 'smooth',
              block: 'center',
            });
          }, 50);
        }}
      >
        <HStack justify='space-between'>
          {/* label */}
          <Text fontSize='15px' lineHeight={1.2} mb='10px'>
            Data di nascita
          </Text>
          {/* required/error */}
          <Text
            fontSize={secondaryLabelType === 'size' ? '13px' : '10px'}
            lineHeight={1}
            color={errors ? 'rgb(248, 36, 133)' : 'brand.500'}
            pb='10px'
          >
            {secondaryLabelType === 'required' &&
              (errors?.type === 'required' || !errors
                ? '*obbligatorio'
                : '*errore')}
          </Text>
        </HStack>

        <Button
          maxW={{ base: '100%', md: '315px' }}
          w='100%'
          h='48px'
          p='0px 8px 0px 20px'
          borderWidth='1px'
          borderColor={errors ? ' warning' : ' brandTransparent.500'}
          bgColor='white'
          borderRadius='10px'
          fontWeight='200'
          color={birthDateValue ? 'black' : '#1D1E1C80'}
          rightIcon={
            <CalendarIcon ml='20px' h='21px' w='21px' color='brand.500' />
          }
          justifyContent='space-between'
          {...props}
          ref={ref}
        >
          {birthDateValue
            ? moment(birthDateValue).format('DD/MM/YYYY')
            : 'Seleziona'}
        </Button>
      </Flex>
    );
  }
);

const CalendarHeader = ({
  date,
  incMonth,
  decMonth,
  changeMonth,
  changeYear,
  birthDate,
}: {
  date: Date;
  decMonth: () => void;
  incMonth: () => void;
  changeMonth: (month: number) => void;
  changeYear: (year: number) => void;
  birthDate?: boolean;
}) => {
  const years = Array.from(
    { length: 100 },
    (v, k) => getYear(new Date()) - (k + 3)
  );
  const months = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ];
  useEffect(() => {
    if (birthDate) {
      changeMonth(date.getMonth() ?? new Date().getMonth());
      changeYear(getYear(date) ?? getYear(new Date()) - 3);
    }
  }, []);
  return (
    <Flex flexDirection='column' w='100%'>
      <HStack
        w='100%'
        justifyContent='space-between'
        pt='13px'
        pb='11px'
        spacing={0}
        align='center'
      >
        {!birthDate && (
          <Text
            onClick={decMonth}
            cursor='pointer'
            lineHeight='14px'
            pl={{ base: '16px', md: '36px' }}
            color='brandTransparent.500'
          >
            {'<'}
          </Text>
        )}
        {birthDate ? (
          <HStack w='100%' justifyContent='space-around' spacing={0}>
            <Select
              minW='100px'
              maxW='200px'
              color='brand.500'
              fontSize='12px'
              textAlign='center'
              fontWeight='200'
              cursor='pointer'
              border='none'
              w='100%'
              defaultValue={months[date.getMonth() ?? new Date().getMonth()]}
              onChange={(e) => {
                changeMonth(months.indexOf(e.target.value));
              }}
            >
              {months.map((month) => (
                <option
                  style={{ width: '200px' }}
                  key={month}
                  label={month}
                  value={month}
                ></option>
              ))}
            </Select>
            <Select
              minW='90px'
              maxW='200px'
              textAlign='center'
              color='brand.500'
              fontSize='12px'
              fontWeight='200'
              cursor='pointer'
              border='none'
              defaultValue={'' + date.getFullYear()}
              onChange={(e) => {
                changeYear(+e.target.value);
              }}
            >
              {years.map((year) => (
                <option key={year} label={'' + year} value={year}></option>
              ))}
            </Select>
          </HStack>
        ) : (
          <Text color='brand.500' fontSize='12px' lineHeight='14px'>
            {months[date.getMonth()] + ' ' + date.getFullYear()}
          </Text>
        )}
        {!birthDate && (
          <Text
            onClick={incMonth}
            cursor='pointer'
            lineHeight='14px'
            pr={{ base: '16px', md: '36px' }}
            color='brandTransparent.500'
          >
            {'>'}
          </Text>
        )}
      </HStack>
      <HStack
        justifyContent='space-between'
        w='100%'
        bgColor='brand.500'
        h='36px'
      >
        <Text
          fontSize='10px'
          variant='bold'
          color='white'
          lineHeight='13px'
          pl={{ base: '6%', md: '36px' }}
        >
          L
        </Text>
        <Text fontSize='10px' variant='bold' color='white' lineHeight='13px'>
          M
        </Text>
        <Text fontSize='10px' variant='bold' color='white' lineHeight='13px'>
          M
        </Text>
        <Text fontSize='10px' variant='bold' color='white' lineHeight='13px'>
          G
        </Text>
        <Text fontSize='10px' variant='bold' color='white' lineHeight='13px'>
          V
        </Text>
        <Text fontSize='10px' variant='bold' color='white' lineHeight='13px'>
          S
        </Text>
        <Text
          fontSize='10px'
          variant='bold'
          color='white'
          lineHeight='13px'
          pr={{ base: '6%', md: '36px' }}
        >
          D
        </Text>
      </HStack>
    </Flex>
  );
};

export const DateRangeCalendar = forwardRef<
  {
    startDate: Date | null;
    endDate: Date | null;
    setStartDate: (date: Date | null) => void;
    setEndDate: (date: Date | null) => void;
    selectedArea?: string;
    isRange?: boolean;
    setValue?: UseFormSetValue<BookingInfo>;
    index?: number;
    handleRangeChange?: (startDate: Date | null, endDate: Date | null) => void;
    scrollRef?: React.MutableRefObject<HTMLDivElement | null>;
  } & FlexProps,
  'div'
>(
  (
    {
      startDate,
      endDate,
      setStartDate,
      setEndDate,
      selectedArea,
      handleRangeChange,
      setValue,
      index,
      scrollRef,
      ...props
    },
    ref
  ) => {
    const { setPricesData } = useBookingStore(
      ({ setPricesData }) => ({ setPricesData }),
      shallow
    );
    const [unavailableDates, setUnavailableDates] = useState<Date[]>([]);
    const [offerDates, setOfferDates] = useState<Date[]>([]);
    const [currentMonth, setCurrentMonth] = useState<Date>(
      startDate ?? new Date()
    );

    const DISABLED_COLOR = '#AAAAAA';
    const OFFER_COLOR = '#00B1DC';
    const AVAILABLE_COLOR = '#000000';

    const { data: availabilityData, refetch: availabilityRefetch } = useQuery(
      ['getAvailability'],
      async () => {
        const res = await getAvailabilityApi().availabilityCalendar({
          startDate: moment(currentMonth).startOf('month').format('YYYY-MM-DD'),
          endDate: moment(currentMonth).endOf('month').format('YYYY-MM-DD'),
          areaId: selectedArea ?? '',
        });
        return res.data;
      },
      { enabled: !!selectedArea }
    );

    const getPrices = async () => {
      const res = await getPriceApi().priceBrowse({
        filter: { areaId: selectedArea! },
      });
      const prices = res.data.elements.reduce((acc, next) => {
        return {
          ...acc,
          [next.name]: { fullValue: next.fullValue, value: next.value },
        };
      }, {});
      setPricesData(prices);
    };

    useEffect(() => {
      if (selectedArea) {
        availabilityRefetch();
      }
    }, [currentMonth, selectedArea]);

    useEffect(() => {
      if (selectedArea) {
        getPrices();
      }
    }, [selectedArea]);

    useEffect(() => {
      const unavailables: Date[] = [];
      const offers: Date[] = [];
      for (const [key, { available, offer }] of Object.entries(
        availabilityData?.availability ?? {}
      )) {
        const current = new Date(key);
        if (!available) {
          unavailables.push(current);
        } else if (offer) {
          offers.push(current);
        }
      }

      setOfferDates(offers);
      setUnavailableDates(unavailables);
    }, [availabilityData]);

    const onRangeChange = (dates: [Date | null, Date | null]) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      handleRangeChange?.(start, end);
    };

    return (
      <Flex
        flexDirection='column'
        mt={{ base: '15px', md: '0px' }}
        w='100%'
        justifyContent='center'
        ref={scrollRef}
      >
        <ReactDatePicker
          locale={it}
          renderDayContents={(date, day) => {
            const isUnavailable =
              moment(day).isBefore(
                moment(currentMonth).startOf('month'),
                'day'
              ) ||
              moment(day).isBefore(new Date(), 'day') ||
              moment(day).isAfter(currentMonth, 'month') ||
              unavailableDates.some((d) => moment(day).isSame(d, 'day'));
            const isOffer =
              !isUnavailable &&
              offerDates.some((d) => moment(day).isSame(d, 'day'));
            return (
              <Text
                color={
                  isUnavailable
                    ? DISABLED_COLOR
                    : isOffer
                    ? OFFER_COLOR
                    : AVAILABLE_COLOR
                }
              >
                {date}
              </Text>
            );
          }}
          renderCustomHeader={({
            date,
            increaseMonth,
            decreaseMonth,
            changeMonth,
            changeYear,
          }) => (
            <CalendarHeader
              date={date}
              incMonth={increaseMonth}
              decMonth={decreaseMonth}
              changeMonth={changeMonth}
              changeYear={changeYear}
            />
          )}
          disabledKeyboardNavigation
          selected={startDate}
          excludeDates={unavailableDates}
          startDate={startDate}
          endDate={endDate}
          onChange={onRangeChange}
          onMonthChange={(date) => {
            setCurrentMonth(date);
          }}
          minDate={
            currentMonth?.getMonth() === new Date().getMonth()
              ? new Date()
              : moment(currentMonth).startOf('month').toDate()
          }
          maxDate={moment(currentMonth).endOf('month').toDate()}
          inline
          selectsRange
          fixedHeight
        />
        <Flex mt='20px' align='center' px='9px' mb='10px'>
          <Box
            w='13px'
            h='13px'
            borderRadius='50%'
            bg={DISABLED_COLOR}
            mr='10px'
          />
          <Text fontSize='12px' lineHeight='1'>
            Non disponibile
          </Text>
          <Box
            w='13px'
            h='13px'
            borderRadius='50%'
            bg={OFFER_COLOR}
            ml='20px'
            mr='10px'
          />
          <Text fontSize='12px' lineHeight='1'>
            Offerta
          </Text>
        </Flex>
      </Flex>
    );
  }
);

export const BirthDateCalendar = ({
  startDate,
  setStartDate,
  birthDayChange,
  onClose,
  scrollRef,
}: {
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  birthDayChange: (date: Date | null) => void;
  onClose: () => void;
  scrollRef?: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  const onDateChange = (date: Date | null) => {
    setStartDate(date);
    birthDayChange(date);
    onClose();
  };

  const [currentMonth, setCurrentMonth] = useState<Date | null>(new Date());

  return (
    <Flex
      flexDirection='column'
      mt={{ base: '15px', md: '0px' }}
      w='100%'
      justifyContent='center'
      ref={scrollRef}
    >
      <ReactDatePicker
        locale={it}
        renderDayContents={(date, day) => {
          return <Text>{date}</Text>;
        }}
        renderCustomHeader={({
          date,
          increaseMonth,
          decreaseMonth,
          changeMonth,
          changeYear,
        }) => (
          <CalendarHeader
            date={date}
            incMonth={increaseMonth}
            decMonth={decreaseMonth}
            changeMonth={changeMonth}
            changeYear={changeYear}
            birthDate
          />
        )}
        showMonthYearDropdown
        disabledKeyboardNavigation
        selected={startDate}
        openToDate={startDate ?? moment().subtract(3, 'years').toDate()}
        startDate={startDate}
        onChange={onDateChange}
        onMonthChange={(date) => {
          setCurrentMonth(date);
        }}
        onYearChange={(date) => {
          setCurrentMonth(date);
        }}
        minDate={moment(currentMonth).startOf('month').toDate()}
        maxDate={moment(currentMonth).endOf('month').toDate()}
        inline
        fixedHeight
      />
      <Flex align='center' px='9px'></Flex>
    </Flex>
  );
};
