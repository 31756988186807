import { Flex, Text, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { PageContainer } from '../components/container';

import SimpleInputText from '../components/inputs/input-text'; /*  */
import StyledButton from '../components/inputs/styled-button';

import { SectionTitle } from '../components/section-title';
import { Wrapper } from '../components/wrapper';
import { addToken, getUserApi } from '../services/api/open-api';
import { useBookingStore, useNotification, useUserStore } from '../store';
import { UnregisteredFormType } from '../types';
import { isValidPhonenumber } from '../utils/profile-utils';

export const ContactInfo = () => {
  const { user, setUser, token, setRegisterData } = useUserStore(
    ({ user, setUser, token, setRegisterData }) => ({
      user,
      setUser,
      token,
      setRegisterData,
    }),
    shallow
  );
  const { bookingInfo, setBookingInfo } = useBookingStore(
    ({ bookingInfo, setBookingInfo }) => ({ bookingInfo, setBookingInfo }),
    shallow
  );
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);
  const { register, handleSubmit, control, formState, watch, getValues } =
    useForm<UnregisteredFormType>({
      defaultValues: {
        email: bookingInfo?.userEmail ?? user?.email,
        phone: bookingInfo?.userPhone,
      },
    });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<UnregisteredFormType> = async (data) => {
    const api = getUserApi();
    try {
      /* unregistered user */
      if (!user?.id) {
        setBookingInfo({
          ...bookingInfo!,
          userEmail: data.email,
          userPhone: data.phone,
        });
        navigate('/document');
        return;
      }

      /*registered user with no phone number saved*/
      if (!user.phone) {
        const {
          data: { element },
        } = await api.userEdit(
          user.id,
          { phone: data.phone, profiles: user.profiles },
          addToken(token)
        );
        if (!element) {
          return;
        }
        setUser(element);
        setBookingInfo({
          ...bookingInfo!,
          userEmail: data.email,
          userPhone: data.phone,
        });
        navigate('/document');
        return;
      }
    } catch (e: any) {
      notify('error', {
        title: 'Errore',
        text: 'Errore durante il salvataggio dei dati',
      });
    }
  };

  useEffect(() => {
    if (!user?.phone) {
      return;
    }
    setBookingInfo({
      ...bookingInfo!,
      userEmail: user.email,
      userPhone: user.phone,
    });
    navigate('/document');
  }, [user]);

  useEffect(() => {
    if (
      !bookingInfo?.delivery ||
      (!bookingInfo?.accomodationAddress &&
        !bookingInfo?.pointAddress &&
        !bookingInfo?.trackAddress)
    ) {
      navigate('/');
    }
  }, []);

  if (!bookingInfo) return <Wrapper />;
  return (
    <PageContainer>
      <SectionTitle
        hasBack
        toPage='/shipment'
        text={!!user ? 'Inserisci telefono' : 'Senza registrarmi'}
        mt='16px'
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack mt={{ base: '35px', md: '70px' }}>
          <SimpleInputText
            {...register('email', { required: true })}
            label='Email'
            errors={formState.errors.email}
            maxW='315px'
            mb='20px'
            disabled={!!user}
            secondaryLabelType='required'
          />
          <SimpleInputText
            {...register('phone', {
              required: true,
              /* pattern: /^[0-9]{10}$/g, */ validate: (value) =>
                isValidPhonenumber(value),
            })}
            label='Telefono'
            errors={formState.errors.phone}
            maxW='315px'
            secondaryLabelType='required'
          />

          <StyledButton mt='20px !important' type='submit'>
            Procedi
          </StyledButton>
        </VStack>

        <Flex
          fontSize='15px'
          mb='20px'
          mt='50px'
          w='100%'
          align='center'
          justify='center'
          gap='4px'
        >
          <Text>Hai già un account? </Text>
          <Text
            decoration='underline'
            color='brand.500'
            textStyle='bold'
            onClick={() => navigate('/login')}
          >
            Accedi
          </Text>
        </Flex>

        <Flex
          fontSize='15px'
          mb='20px'
          mt='15px'
          w='100%'
          align='center'
          justify='center'
          gap='4px'
        >
          <Text>Non hai un account? </Text>
          <Text
            decoration='underline'
            color='brand.500'
            textStyle='bold'
            cursor='pointer'
            onClick={() => {
              setRegisterData({});

              navigate('/register');
            }}
          >
            Registrati
          </Text>
        </Flex>
      </form>
    </PageContainer>
  );
};
