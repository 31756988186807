import { BoxProps, forwardRef, Text, VStack } from '@chakra-ui/react';
import { SciIcon, SnowboardIcon } from '../icons/sports';

export const EquipmentTabButton = forwardRef<
  { selectedKit: string; kitType: string } & BoxProps,
  'div'
>(({ selectedKit, kitType, ...props }, ref) => {
  return (
    <VStack
      boxShadow={
        !(selectedKit === kitType)
          ? '0 18px 40px var(--chakra-colors-brandTransparent-350)'
          : 'none'
      }
      w='100%'
      h='99px'
      bgColor={selectedKit === kitType ? 'rgba(0,177,220,0.2)' : 'white'}
      borderWidth='1px'
      borderColor='brand.500'
      borderRadius='10px'
      cursor='pointer'
      {...props}
      {...ref}
    >
      {kitType === 'BOARD' ? (
        <>
          <SnowboardIcon
            w='36px'
            h='36px'
            color='brand.500'
            mt='20.5px'
          ></SnowboardIcon>
          <Text textStyle='book' pt='1px'>
            Snowboard
          </Text>
        </>
      ) : (
        <>
          <SciIcon w='36px' h='36px' color='brand.500' mt='20.5px'></SciIcon>
          <Text textStyle='book' pt='1px'>
            Sci
          </Text>
        </>
      )}
    </VStack>
  );
});
