import {
  forwardRef,
  Input,
  Flex,
  InputProps,
  Text,
  HStack,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FieldError } from 'react-hook-form';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const SimpleInputText = forwardRef<
  {
    label: string;
    errors?: FieldError | undefined;
    secondaryLabelType?: 'required' | 'size' | 'time' | undefined;
    isPassword?: boolean;
    additionalInfo?: string;
    textArea?: boolean;
    setGuideToggled?: (bool: boolean) => void;
    timeRange?: string;
  } & InputProps,
  'input'
>(
  (
    {
      label,
      errors,
      secondaryLabelType,
      isPassword,
      additionalInfo,
      textArea,

      setGuideToggled,
      timeRange,
      ...props
    },
    ref
  ) => {
    const [show, setShowing] = useState(false);
    return (
      <Flex
        flexDirection='column'
        maxW={props.maxW ?? '100%'}
        w='100%'
        pt={props.pt ?? '0px'}
      >
        <HStack justify='space-between'>
          {/* label */}
          <Text fontSize='15px' lineHeight={1.2} mb='10px' whiteSpace='nowrap'>
            {label}
          </Text>
          {/* required/error */}
          <Text
            fontSize={secondaryLabelType === 'size' ? '12px' : '10px'}
            lineHeight={1}
            color={errors ? 'rgb(248, 36, 133)' : 'brand.500'}
            pb='10px'
            cursor={secondaryLabelType === 'size' ? 'pointer' : ''}
            onClick={() => {
              if (secondaryLabelType === 'size' && setGuideToggled) {
                setGuideToggled(true);
              }
            }}
            whiteSpace='nowrap'
          >
            {secondaryLabelType === 'required' &&
              (errors?.type === 'required' || !errors
                ? '*obbligatorio'
                : '*errore')}
            {secondaryLabelType === 'size' && 'Guida Taglie'}
            {secondaryLabelType === 'time' &&
              (errors?.type === 'required' || !errors
                ? '*obbligatorio'
                : timeRange)}
          </Text>
        </HStack>
        {/* Input */}
        <InputGroup>
          <Input
            fontSize='15px'
            h='48px'
            textStyle='book'
            placeholder={props.placeholder ?? 'Inserisci'}
            type={
              isPassword && !show
                ? 'password'
                : props.type
                ? props.type
                : 'text'
            }
            alignItems='center'
            borderRadius='10px'
            overflow='hidden'
            bg='white'
            borderWidth='1px'
            borderColor={errors ? ' warning' : ' brandTransparent.500'}
            _placeholder={{ color: '#1D1E1C80' }}
            {...props}
            ref={ref}
            m='0px'
            p={textArea ? '15px 20px 78px 20px' : '15px 20px 15px 20px'}
          />
          {isPassword && (
            <InputRightElement
              children={
                show ? (
                  <ViewIcon
                    h='20px'
                    w='20px'
                    mr='13px'
                    mt='10px'
                    color='#00B1DC'
                    onClick={() => setShowing(!show)}
                    cursor={'pointer'}
                  />
                ) : (
                  <ViewOffIcon
                    h='20px'
                    w='20px'
                    mr='13px'
                    mt='10px'
                    color='#00B1DC'
                    onClick={() => setShowing(!show)}
                    cursor={'pointer'}
                  />
                )
              }
            />
          )}
          {additionalInfo && (
            <InputRightElement
              h='48px'
              mr='10px'
              children={
                <Text align='center' color='rgba(29,30,28,0.41)'>
                  {additionalInfo}
                </Text>
              }
            />
          )}
        </InputGroup>
      </Flex>
    );
  }
);

export default SimpleInputText;
