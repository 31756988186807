import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { BookingSummary } from '../components/finalize/booking-summary';
import { APP_URL } from '../config';
import {
  addToken,
  getBookingApi,
  notifyServerError,
} from '../services/api/open-api';
import { useBookingStore, useNotification, useUserStore } from '../store';
import { BookingInfo, CreateBookingRequest } from '../types';

export const OrderFinalize = () => {
  const { token } = useUserStore(({ token }) => ({ token }), shallow);
  const { bookingInfo } = useBookingStore(
    ({ bookingInfo }) => ({ bookingInfo }),
    shallow
  );
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);
  const navigate = useNavigate();

  const createBooking = async (newBooking: CreateBookingRequest) => {
    const api = getBookingApi();

    const res = await api.bookingNew(newBooking, addToken(token));
    return res;
  };

  const onSubmit = async (booking: BookingInfo) => {
    const newBooking = {
      redirectUrl: `${APP_URL}/order-summary`,
      booking: {
        ...booking,
        profiles: (booking.profiles ?? []).map(({ id, ...profile }) => profile),
        accomodationAddress: !!booking.accomodationAddress
          ? JSON.parse(booking.accomodationAddress).address +
            ', ' +
            JSON.parse(booking.accomodationAddress).city
          : undefined,
      },
    };

    try {
      const res = await createBooking(newBooking);

      const checkoutURL = res?.data?.checkoutSession;
      if (checkoutURL) {
        window.location.assign(checkoutURL);
      }
    } catch (e: any) {
      if (e.response.data.message.toLowerCase().includes('coupon')) {
        return notify('error', {
          title: 'Coupon non valido',
          text: 'Il coupon inserito è scaduto o non è valido per questa zona',
        });
      }
      if (e.response.status === 422) {
        return notify('availability', {
          title: 'Non disponibile',
          text: `Non sono disponibili tutte le attrezzature richieste. Contattaci per controllare la disponibilità del magazzino.`,
        });
      }
      notifyServerError(e, notify);
    }
  };

  useEffect(() => {
    if (!bookingInfo?.documents) {
      navigate('/');
    }
  }, []);

  if (!bookingInfo) {
    return <></>;
  }

  return (
    <BookingSummary
      onSubmit={onSubmit}
      booking={bookingInfo as BookingInfo}
      isFinalize
    />
  );
};
