import { Flex, Grid, HStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { PageContainer } from '../components/container';
import { TOSCheckboxItem } from '../components/inputs/checkbox';
import SimpleInputText from '../components/inputs/input-text';
import StyledButton from '../components/inputs/styled-button';
import { SectionTitle } from '../components/section-title';

import { getAuthApi, notifyServerError } from '../services/api/open-api';
import { useNotification, useUserStore } from '../store';
import { RegisterFormType } from '../types';
import { isValidPhonenumber } from '../utils/profile-utils';

export const Register = () => {
  const { registerData } = useUserStore(
    ({ registerData }) => ({ registerData }),
    shallow
  );
  const navigate = useNavigate();
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);
  const [isChecked, setIsChecked] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
  } = useForm<RegisterFormType>({ defaultValues: registerData });

  useEffect(() => {
    setValue('TOS', false);
  }, []);

  const onSubmit: SubmitHandler<RegisterFormType> = async (formData) => {
    if (!formData.TOS) {
      return notify('error', {
        title: 'Termini e Condizioni.',
        text: 'I termini e condizioni sono obbligatori.',
      });
    }
    delete formData.TOS;
    try {
      await getAuthApi().authSignup({
        data: {
          email: formData.email,
          phone: formData.phone,
          password: formData.password,
          profiles: [{ firstName: formData.name, lastName: formData.surname }],
        },
      });
      notify('email', {
        title: 'Registrazione completata',
        text: "Controlla la tua casella di posta per confermare l'e-mail",
        buttonText: 'Home',
        onConfirm: () => navigate('/'),
      });
    } catch (e: any) {
      if (e.response.status === 422) {
        return notify('error', {
          title: 'Errore',
          text: "L'email inserita è già in uso.",
        });
      }
      notifyServerError(e, notify);
    }
  };

  return (
    <PageContainer>
      <SectionTitle
        text='Registrati'
        hasBack
        mt={{ base: '15px', md: '10px' }}
      />
      <Flex
        pb=' 70px '
        pt={{ base: '30px', md: '70px' }}
        w='100%'
        flexDirection='column'
        mx='auto'
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
            columnGap='20px'
            rowGap='14px'
            mb='15px'
            justifyItems='center'
          >
            <SimpleInputText
              {...register('name', {
                required: true,
                pattern: /^[a-z ,.'-]+$/i,
              })}
              label='Nome'
              placeholder='Es. Mario'
              errors={errors.name}
              secondaryLabelType='required'
            />
            <SimpleInputText
              {...register('surname', {
                required: true,
                pattern: /^[a-z ,.'-]+$/i,
              })}
              label='Cognome'
              placeholder='Es. Rossomando'
              errors={errors.surname}
              secondaryLabelType='required'
            />
            <SimpleInputText
              {...register('email', { required: true })}
              type='email'
              label='Email'
              placeholder='Es. Rossomando@gmail.it'
              errors={errors.email}
              secondaryLabelType='required'
            />
            <SimpleInputText
              {...register('phone', {
                required: true,
                /* pattern: /^[0-9]{10}$/g, */
                validate: (value) => isValidPhonenumber(value),
              })}
              label='Telefono'
              type='tel'
              placeholder='Es. 333 45 67 890'
              errors={errors.phone}
              secondaryLabelType='required'
            />

            <SimpleInputText
              {...register('password', {
                required: true,
              })}
              isPassword
              label='Password'
              placeholder='*********'
              errors={errors.password}
              secondaryLabelType='required'
            />
            <SimpleInputText
              {...register('password_confirm', {
                required: true,
                validate: {
                  isEqual: (value) => value === getValues('password') || '',
                },
              })}
              isPassword
              label='Conferma password'
              placeholder='*********'
              errors={errors.password_confirm}
              secondaryLabelType='required'
            />
          </Grid>

          <TOSCheckboxItem
            onClick={() => {
              setValue('TOS', !isChecked);
              setIsChecked(!isChecked);
            }}
            checked={isChecked}
            errors={errors.TOS}
            option={{
              title: 'Accetto termini & condizioni',
            }}
            formInfo={getValues}
          />

          <HStack>
            <StyledButton mt='53px !important' type='submit'>
              Registrati
            </StyledButton>
          </HStack>
        </form>
      </Flex>
    </PageContainer>
  );
};
