import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { PageContainer } from '../components/container';
import { getAuthApi, notifyServerError } from '../services/api/open-api';
import { useNotification } from '../store';

const EmailConfirm = () => {
  const code = new URLSearchParams(useLocation().search).get('code');
  const navigate = useNavigate();
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);

  useEffect(() => {
    (async () => {
      if (!code) {
        return navigate('/');
      }
      try {
        const api = getAuthApi();
        const {
          data: { sessionToken },
        } = await api.authConfirmEmail({ code });

        navigate(`/login?sessionToken=${sessionToken}`);
      } catch (e: any) {
        notifyServerError(e, notify, () => navigate('/login'));
      }
    })();
  }, [code]);

  return <PageContainer>Confermando l'e-mail...</PageContainer>;
};

export default EmailConfirm;
