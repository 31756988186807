import { Flex, forwardRef, HStack, SelectProps, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { FieldError } from 'react-hook-form';
import Select from 'react-select';
import '../../styles/style.css';
import { PreviewDataType } from '../../types';
//import "react-select/dist/react-select.css";

export const CustomSelect = forwardRef<
  {
    label: string;
    secondaryLabelType?: 'required' | 'size' | undefined;
    errors?: FieldError | undefined;
    customDefaultValue?: { label: string; value: string };
    options: { label: string; value: string }[];
    previewData?: PreviewDataType;
    customOnChange: ({
      label,
      value,
    }: {
      label: string;
      value: string;
    }) => void;
  } & SelectProps,
  'select'
>(
  (
    {
      customDefaultValue,
      label,
      errors,
      options,
      secondaryLabelType,
      previewData,
      customOnChange,
      ...props
    },
    ref
  ) => {
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    return (
      <Flex flexDirection='column' maxW={props.maxW ?? '100%'} w='100%'>
        <HStack justify='space-between'>
          {/* label */}
          <Text fontSize='15px' lineHeight={1.2} mb='10px' textStyle='book'>
            {label}
          </Text>
          {/* obbligatorio/errore */}
          <Text
            fontSize={secondaryLabelType === 'size' ? '13px' : '10px'}
            cursor={secondaryLabelType === 'size' ? 'pointer' : ''}
            lineHeight={1}
            color={errors ? 'rgb(248, 36, 133)' : 'brand.500'}
            pb='10px'
          >
            {secondaryLabelType === 'required' &&
              (errors?.type === 'required' || !errors
                ? '*obbligatorio'
                : '*errore')}
            {secondaryLabelType === 'size' && 'Guida Taglie'}
          </Text>
        </HStack>
        {/* Select */}

        <Select
          menuPortalTarget={document.body}
          placeholder={props.placeholder ?? 'Seleziona'}
          options={options}
          value={customDefaultValue ?? undefined}
          isSearchable={false}
          isDisabled={props.isDisabled}
          menuPlacement='auto'
          onChange={(e) => {
            customOnChange({ label: e!.label, value: e!.value });
          }}
          onMenuOpen={() => {
            setMenuIsOpen(true);
          }}
          onMenuClose={() => {
            setMenuIsOpen(false);
          }}
          styles={{
            control: (base, state) => ({
              ...base,
              width: '100%',
              borderColor: errors ? 'red' : '#00B1DC50',
              fontSize: '15px',
              lineHeight: '18px',
              height: '48px',
              borderRadius: state.menuIsOpen ? '10px 10px 0px 0px' : '10px',
              outline: 'none',
              boxShadow: 'none',
              fontWeight: '300',
              '&:hover': {
                borderColor: '#00B1DC50',
              },
              padding: '0px 0px 0px 20px',
              paddingBottom: state.menuIsOpen ? '1px' : '0px',
              '&:focus': {
                padding: '0px 0px 0px 20px',
              },
              cursor: 'pointer',
              backgroundColor: state.isDisabled ? 'white' : 'white',
            }),
            input: (base, state) => ({
              ...base,
              outline: 'none',
              cursor: 'pointer',
              padding: '0px 0px 1px 0px',
              margin: '0px',
            }),
            option: (base, state) => ({
              ...base,
              cursor: 'pointer',
              margin: '0px',
              fontSize: '15px',
              lineHeight: '40px',
              fontWeight: '300',
              padding: '0px 0px 0px 20px',
              backgroundColor: state.isFocused ? '#00B1DC50' : 'white',
              color: 'black',
            }),
            menu: (base, state) => ({
              ...base,
              width: 'calc(100% - 2px)',
              left: '1px',
              marginTop: state.menuPlacement === 'top' ? '-10px' : '0px',
              borderWidth: '1px',
              borderColor: '#00B1DC50',
              borderRadius:
                state.menuPlacement === 'top'
                  ? '10px 10px 0px 0px'
                  : '0px 0px 10px 10px',
              borderTopWidth: '0px',
              overflow: 'hidden',
            }),
            valueContainer: (base, state) => ({
              ...base,
              padding: '0px',
            }),
            dropdownIndicator: (base, state) => ({
              ...base,
              display: state.isDisabled ? 'none' : 'inline',
              color: '#00B1DC',
              '&:hover': {
                color: '#00B1DC',
                cursor: 'pointer',
              },
              transform: menuIsOpen ? 'rotate(180deg)' : 'none',
            }),
            indicatorSeparator: (base, state) => ({
              ...base,
              display: 'none',
            }),
            singleValue: (base, state) => ({
              ...base,
              color: state.hasValue ? 'black' : '#1D1E1C50',
              marginLeft: '0px',
            }),
            menuList: (base, state) => ({
              ...base,
              padding: '0px',
              '&:hover': {
                backgroundColor: 'white',
              },
            }),
            menuPortal: (base, state) => ({
              ...base,
              zIndex: 9999,
            }),
          }}
        ></Select>
      </Flex>
    );
  }
);
