import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import moment from 'moment';
import 'moment/locale/it';
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { AppContainer } from './components/container';
import { YetiModal } from './components/modals/modals';
import { Account } from './pages/account';
import { ContactInfo } from './pages/contact-info';
import { Document } from './pages/document';
import EmailConfirm from './pages/email-confirm';
import { OrderFinalize } from './pages/finalize';
import { ForgotPassword } from './pages/forgot-password';
import Homepage from './pages/homepage';
import { Login } from './pages/login';
import { OrderSummary } from './pages/order-summary';
import Profiles from './pages/profiles';
import { Register } from './pages/register';
import { ResetPassword } from './pages/reset-password';
import { Shipment } from './pages/shipment';
import { TermsOfService } from './pages/terms-of-service';
import { addToken, getAuthApi } from './services/api/open-api';
import { useBookingStore, useNotification, useUserStore } from './store';
import { theme } from './theme';
import { on401 } from './types';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const { token, setUser, logout, isLoggedIn } = useUserStore(
    ({ token, setUser, logout }) => ({
      setUser,
      isLoggedIn: !!token,
      token,
      logout,
    }),
    shallow
  );

  const { bookingCreationDate, reset } = useBookingStore(
    ({ bookingCreationDate, reset }) => ({ bookingCreationDate, reset }),
    shallow
  );

  const { notificationProps } = useNotification(
    ({ props }) => ({ notificationProps: props }),
    shallow
  );

  useEffect(() => {
    on401(logout);

    if (
      bookingCreationDate &&
      moment(bookingCreationDate).isBefore(moment().subtract(1, 'day'), 'date')
    ) {
      reset();
    }

    function appHeight() {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
    }

    window.addEventListener('resize', appHeight);
    appHeight();
  }, []);

  useEffect(() => {
    if (token) {
      (async () => {
        const api = getAuthApi();
        const {
          data: { user },
        } = await api.authUser(addToken(token));
        if (!user) {
          return;
        }
        setUser(user);
      })();
    }
  }, []);

  moment.locale('it');

  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AppContainer>
            {notificationProps && <YetiModal {...notificationProps} />}
            <Routes>
              {isLoggedIn ? (
                <>
                  <Route path='/account' element={<Account />} />
                </>
              ) : (
                <>
                  <Route path='/confirm-email' element={<EmailConfirm />} />
                  <Route path='/register' element={<Register />} />
                  <Route path='/forgot-password' element={<ForgotPassword />} />
                  <Route path='/reset-password' element={<ResetPassword />} />
                </>
              )}
              <Route path='/' element={<Homepage />} />
              <Route path='/login' element={<Login />} />
              <Route path='/profiles' element={<Profiles />} />
              <Route path='/shipment' element={<Shipment />} />
              <Route path='/document' element={<Document />} />
              <Route path='/finalize' element={<OrderFinalize />} />
              <Route path='/contact-info' element={<ContactInfo />} />
              <Route
                path='/order-summary/:orderId'
                element={<OrderSummary />}
              />
              <Route path='/terms-of-service' element={<TermsOfService />} />
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          </AppContainer>
        </QueryClientProvider>
      </ChakraProvider>
    </BrowserRouter>
  );
};

export { App };
