import { IconProps, Icon, Flex } from '@chakra-ui/react';

export const HomeIcon = (props: IconProps) => (
  <Icon {...props} viewBox='0 0 30 33.4'>
    <g
      id='Icon_feather-home'
      data-name='Icon feather-home'
      transform='translate(-3 -1.1)'
    >
      <path
        id='Tracciato_1292'
        data-name='Tracciato 1292'
        d='M4.5,13.5,18,3,31.5,13.5V30a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3Z'
        fill='none'
        stroke='#00b1dc'
        strokeWidth='3'
      />
      <path
        id='Tracciato_1293'
        data-name='Tracciato 1293'
        d='M13.5,33V18h9V33'
        fill='none'
        stroke='#00b1dc'
        strokeWidth='3'
      />
    </g>
  </Icon>
);

export const TrackIcon = (props: IconProps) => (
  <Icon viewBox='0 0 44.542 37.164' {...props}>
    <g
      id='Raggruppa_1896'
      data-name='Raggruppa 1896'
      transform='translate(-165.229 -255.418)'
    >
      <path
        id='Tracciato_1318'
        data-name='Tracciato 1318'
        d='M2131.542,10419.771l-20.788-33.994L2090,10419.941Z'
        transform='translate(-1923.271 -10128.859)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='3'
      />
      <path
        id='Tracciato_1319'
        data-name='Tracciato 1319'
        d='M2169.555,10394.184s16.062,3.411,13.1,8.688-4.251,6.626,3.431,9.937,8.559,3.462,8.559,3.462'
        transform='translate(-1988.719 -10126)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='3'
      />
    </g>
  </Icon>
);

export const PointIcon = (props: IconProps) => (
  <Icon viewBox='0 0 36.75 33' {...props}>
    <path
      id='Icon_metro-shop'
      data-name='Icon metro-shop'
      d='M13.719,14.471,15.2,4.627H7.488L4.265,13.065A2.958,2.958,0,0,0,4.113,14c0,2.07,2.156,3.75,4.821,3.75,2.456,0,4.487-1.432,4.785-3.281Zm7.269,3.281c2.662,0,4.821-1.68,4.821-3.75,0-.077-.006-.154-.009-.227l-.954-9.148H17.131c-.96,9.217-.964,9.294-.964,9.375,0,2.07,2.158,3.75,4.821,3.75ZM7.863,32a2.631,2.631,0,0,0,2.621,2.625h21A2.634,2.634,0,0,0,34.113,32V20.1c-.353.047-13.17,5.857-26.25-.944Zm29.85-18.937L34.486,4.627H26.774l1.481,9.829c.289,1.856,2.319,3.3,4.787,3.3,2.662,0,4.821-1.68,4.821-3.75A3.011,3.011,0,0,0,37.713,13.065Z'
      transform='translate(-2.613 -3.127)'
      fill='none'
      stroke='#00b1dc'
      strokeWidth='3'
    />
  </Icon>
);

export const OrdersIcon = (props: IconProps) => (
  <Icon viewBox='0 0 36 34.5' {...props}>
    <path
      id='Tracciato_1329'
      data-name='Tracciato 1329'
      d='M15,31.5A1.5,1.5,0,1,1,13.5,30,1.5,1.5,0,0,1,15,31.5Z'
      fill='none'
      stroke='#00b1dc'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='3'
    />
    <path
      id='Tracciato_1330'
      data-name='Tracciato 1330'
      d='M31.5,31.5A1.5,1.5,0,1,1,30,30,1.5,1.5,0,0,1,31.5,31.5Z'
      fill='none'
      stroke='#00b1dc'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='3'
    />
    <path
      id='Tracciato_1331'
      data-name='Tracciato 1331'
      d='M1.5,1.5h6l4.02,20.085a3,3,0,0,0,3,2.415H29.1a3,3,0,0,0,3-2.415L34.5,9H9'
      fill='none'
      stroke='#00b1dc'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='3'
    />
  </Icon>
);

export const EditAccountIcon = (props: IconProps) => (
  <Icon viewBox='0 0 30 28.682' {...props}>
    <g
      id='Icon_feather-edit-3'
      data-name='Icon feather-edit-3'
      transform='translate(-3 -2.818)'
    >
      <path
        id='Tracciato_1323'
        data-name='Tracciato 1323'
        d='M18,30H31.5'
        fill='none'
        stroke='#00b1dc'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
      />
      <path
        id='Tracciato_1324'
        data-name='Tracciato 1324'
        d='M24.75,5.25a3.182,3.182,0,0,1,4.5,4.5L10.5,28.5,4.5,30,6,24Z'
        fill='none'
        stroke='#00b1dc'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
      />
    </g>
  </Icon>
);

export const ProfilesIcon = (props: IconProps) => (
  <Icon viewBox='0 0 36 30.13' {...props}>
    <g
      id='Icon_feather-users'
      data-name='Icon feather-users'
      transform='translate(0 -2.87)'
    >
      <path
        id='Tracciato_1325'
        data-name='Tracciato 1325'
        d='M25.5,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3'
        fill='none'
        stroke='#00b1dc'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
      />
      <path
        id='Tracciato_1326'
        data-name='Tracciato 1326'
        d='M19.5,10.5a6,6,0,1,1-6-6,6,6,0,0,1,6,6Z'
        fill='none'
        stroke='#00b1dc'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
      />
      <path
        id='Tracciato_1327'
        data-name='Tracciato 1327'
        d='M34.5,31.5v-3A6,6,0,0,0,30,22.7'
        fill='none'
        stroke='#00b1dc'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
      />
      <path
        id='Tracciato_1328'
        data-name='Tracciato 1328'
        d='M24,4.7A6,6,0,0,1,24,16.32'
        fill='none'
        stroke='#00b1dc'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='3'
      />
    </g>
  </Icon>
);

export const LoaderIcon = () => (
  <Flex align='center' justify='center'>
    <div className='loader' />
  </Flex>
);
