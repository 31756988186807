import { Accordion, FlexProps, forwardRef } from '@chakra-ui/react';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { useBookingStore, useNotification, useUserStore } from '../../store';
import {
  BookedBoardBootsType,
  BookedBoardType,
  BookedHelmetType,
  BookedSkiBootsType,
  BookedSkiType,
  BookingInfo,
  ItemPrices,
  MinimumAvailability,
  MinimumAvailabilitySKI,
  OwnedBootsType,
  PreviewDataType,
  Profile,
} from '../../types';

import {
  GenderEnum,
  getAvailabilityMinMaxRange,
  LevelEnum,
  LevelTranslateEnum,
  measureConvert,
  StanceEnum,
} from '../../utils/profile-utils';
import { PersonalData } from './profile.form.accordion.data';
import { EquipmentSelection } from './profile.form.accordion.equipment';
import { MeasuresForm } from './profile.form.accordion.measures';
import { PreferencesForm } from './profile.form.accordion.preferences';

export const ProfileForm = forwardRef<
  {
    index: number;
    setGuideToggled?: (
      bool: false | 'ankle' | 'head' | 'foot' | 'boots'
    ) => void;
    page: 'finalize' | 'account' | 'profiles' | 'summary';
    proceed?: () => void;
    profileId?: string;
    priceRefetch?: (
      options?: (RefetchOptions & RefetchQueryFilters) | undefined
    ) => Promise<QueryObserverResult<ItemPrices, unknown>>;
    equipData?: MinimumAvailability;
  } & FlexProps,
  'div'
>(
  (
    {
      index,
      setGuideToggled,
      page,
      proceed,
      profileId,
      priceRefetch,
      equipData,
      ...props
    },
    ref
  ) => {
    const { user } = useUserStore(({ user }) => ({ user }), shallow);
    const { bookingInfo, setBookingInfo, profilesToSave } = useBookingStore(
      ({ bookingInfo, setBookingInfo, profilesToSave }) => ({
        bookingInfo,
        setBookingInfo,
        profilesToSave,
      }),
      shallow
    );
    const { notify } = useNotification(({ notify }) => ({ notify }), shallow);
    const [savedProfileData, setSavedProfileData] = useState<Profile>();
    const equipmentRef = useRef<HTMLButtonElement | null>(null);

    const generateName = (
      name:
        | keyof Profile
        | `bookedHelmet.${keyof BookedHelmetType}`
        | `bookedSkiBoots.${keyof BookedSkiBootsType}`
        | `bookedBoardBoots.${keyof BookedBoardBootsType}`
        | `ownedBoots.${keyof OwnedBootsType}`
        | `bookedBoard.${keyof BookedBoardType}`
        | `bookedSki.${keyof BookedSkiType}`
    ) => {
      return `profiles.${index}.${name}` as const;
    };

    const methods = useFormContext<BookingInfo>();

    useEffect(() => {
      if (!bookingInfo?.profiles || page === 'account') {
        return;
      }
      methods.setValue(`profiles.${index}`, bookingInfo?.profiles[index]);
    }, []);

    useEffect(() => {
      if (!user) {
        return;
      }

      if (user.profiles && page === 'account') {
        const { bootsMM, bootsBrand, bootsSize, stance, ...rest } =
          user.profiles.filter((profile) => !profile.main)[index];
        methods.setValue(
          `profiles.${index}`,
          {
            ownedBoots: {
              bootsMM: bootsMM ?? 0,
              brand: bootsBrand ?? '',
              size: bootsSize ?? '',
            },
            stance: stance ?? undefined,
            ...rest,
          },
          { shouldValidate: true }
        );
        return;
      }
    }, [user?.profiles]);

    const handleBlur = (
      fieldValue?: string,
      fieldName?:
        | keyof Profile
        | `bookedHelmet.${keyof BookedHelmetType}`
        | `bookedSkiBoots.${keyof BookedSkiBootsType}`
        | `bookedBoardBoots.${keyof BookedBoardBootsType}`
        | `ownedBoots.${keyof OwnedBootsType}`
        | `bookedBoard.${keyof BookedBoardType}`
        | `bookedSki.${keyof BookedSkiType}`
    ) => {
      if (fieldName) {
        methods.setValue(`profiles.${index}.${fieldName}`, fieldValue, {
          shouldValidate: true,
        });
      }
      if (
        Object.entries(methods.formState.errors).length === 0 &&
        page === 'finalize'
      ) {
        setBookingInfo({
          ...bookingInfo,
          profiles: methods.getValues('profiles'),
        });
        priceRefetch?.();
      }
    };

    const getErrors = (keys: (keyof Profile)[]) => {
      const errors: { [key in keyof Profile]?: unknown } = {};

      for (const key of keys) {
        const currentError = methods.formState.errors.profiles?.[index]?.[key];
        if (currentError) {
          errors[key] = currentError;
        }
      }
      return errors;
    };

    const [kitState, setKitState] = useState<PreviewDataType>(
      (() => {
        const profile = methods.getValues(`profiles.${index}`);

        if (!profile) {
          return {
            type: 'SKI',
            main: false,
            boots: false,
            helmet: false,
            level: 0,
            premium: false,
            saveProfile: false,
          };
        }

        const {
          bookedBoard,
          bookedSki,
          bookedSkiBoots,
          bookedBoardBoots,
          bookedHelmet,
          level,
        } = profile;

        return {
          type: bookedBoard || bookedBoardBoots ? 'BOARD' : 'SKI',
          main: !!(bookedBoard || bookedSki),
          boots: !!(bookedSkiBoots || bookedBoardBoots),
          helmet: !!bookedHelmet,
          premium: !!(bookedBoard?.premium || bookedSki?.premium),
          level: !level ? 0 : parseInt(LevelEnum[level]),
          saveProfile: profilesToSave.includes(index),
        };
      })()
    );

    const StanceValue = methods.watch(`profiles.${index}.stance`);

    const measureCondition = (
      condition: boolean,
      measureValue: string,
      name: string
    ) => {
      return condition ? { [name]: measureValue } : {};
    };

    const AvailabilityErrorText = {
      heightCM: "sci per l'altezza selezionata",
      shoeNumber: 'scarponi per il numero scarpa selezionato',
      headCircCM: 'caschetti per la circonferenza selezionata',
    };

    const availabilitySearch = (
      inputLabel: 'heightCM' | 'shoeNumber' | 'headCircCM',
      inputValue: number,
      availability: { [key: string]: MinimumAvailabilitySKI },
      shouldSearch: boolean
    ) => {
      if (!shouldSearch) {
        return;
      }

      let available = false;
      for (const [key, value] of Object.entries(availability)) {
        const { minSize, maxSize } = getAvailabilityMinMaxRange(key.toString());
        if (inputValue >= minSize && inputValue <= maxSize) {
          if (inputLabel === 'heightCM') {
            if (
              ((kitState.premium ? value?.premium : value?.regular) ?? 0) > 0
            ) {
              available = true;
              break;
            }
          } else {
            available = true;
            break;
          }
        }
      }
      if (!available) {
        methods.setValue(`profiles.${index}.${inputLabel}`, undefined, {
          shouldValidate: true,
        });
        notify('availability', {
          title: 'Non disponibile',
          text: `Non sono disponibili ${AvailabilityErrorText[inputLabel]}. Contattaci per controllare la disponibilità in magazzino.`,
        });
      }
    };

    return (
      <FormProvider {...methods}>
        <Accordion
          borderWidth='1px'
          borderRadius='11px'
          borderColor='brandTransparent.500'
          overflow='hidden'
          allowToggle
          minH='fit-content'
        >
          {/*PERSONAL DATA*/}
          <PersonalData
            details={{
              ...((methods.getValues(generateName('firstName')) ||
                methods.getValues(generateName('firstName'))) && {
                fullName: `${
                  methods.getValues(generateName('firstName')) ?? ''
                } ${
                  methods.getValues(generateName('lastName')) ?? ''
                }`.trimEnd(),
              }),
              gender:
                GenderEnum[methods.getValues(`profiles.${index}.gender`)!] ??
                '',
              birthDate: methods.getValues(generateName('birthDate'))
                ? moment(
                    new Date(
                      methods.getValues(generateName('birthDate')) as Date
                    )
                  ).format('DD/MM/YYYY')
                : '',
            }}
            page={page}
            errors={getErrors(['firstName', 'lastName', 'gender', 'birthDate'])}
            savedProfileData={savedProfileData}
            setSavedProfileData={setSavedProfileData}
            kitState={kitState}
            setKitState={setKitState}
            index={index}
            handleBlur={handleBlur}
            profileId={profileId ?? ''}
          />

          {/*ATTREZZATURA*/}
          {page !== 'account' && (
            <EquipmentSelection
              equipmentRef={equipmentRef}
              page={page}
              details={{
                mainDetails: kitState.main
                  ? kitState.type === 'SKI'
                    ? 'Sci + Bacchette'
                    : 'Snowboard'
                  : '',
                bootsDetails: kitState.boots ? 'Scarponi' : '',
                helmet: kitState.helmet ? 'Casco' : '',
                level:
                  LevelTranslateEnum[
                    methods.getValues(`profiles.${index}.level`)!
                  ] ?? '',
                premium:
                  methods.getValues(generateName('bookedSki.premium')) ||
                  methods.getValues(generateName('bookedBoard.premium'))
                    ? 'Premium'
                    : '',
              }}
              errors={{
                bookedBoard:
                  methods.formState.errors?.profiles?.[index]?.bookedBoard,
              }}
              index={index}
              handleBlur={handleBlur}
              kitState={kitState}
              profileId={profileId ?? ''}
              savedProfileData={savedProfileData}
              setKitState={setKitState}
              availabilitySearch={availabilitySearch}
              equipData={equipData}
            />
          )}

          {/*MEASURES*/}
          <MeasuresForm
            equipmentRef={
              !kitState.main && !kitState.boots && !kitState.helmet
                ? equipmentRef
                : undefined
            }
            details={{
              ...measureCondition(
                kitState.main || page === 'account',
                measureConvert(
                  methods.getValues(generateName('heightCM'))?.toString(),
                  'cm'
                ),
                'height'
              ),

              ...measureCondition(
                kitState.main || page === 'account',
                measureConvert(
                  methods.getValues(generateName('weightKG'))?.toString(),
                  'Kg'
                ),
                'weight'
              ),

              ...measureCondition(
                kitState.helmet || page === 'account',
                measureConvert(
                  methods.getValues(generateName('headCircCM'))?.toString(),
                  'cm'
                ),
                'headCircCM'
              ),

              ...measureCondition(
                (kitState.main && !kitState.boots) || page === 'account',
                measureConvert(
                  methods
                    .getValues(generateName('ownedBoots.size'))
                    ?.toString(),
                  'EU'
                ),
                'bootsSize'
              ),

              ...measureCondition(
                kitState.boots || page === 'account',
                measureConvert(
                  methods.getValues(generateName('shoeNumber'))?.toString(),
                  'EU'
                ),
                'shoeNumber'
              ),

              ...measureCondition(
                kitState.boots || page === 'account',
                measureConvert(
                  methods.getValues(generateName('ankleCM'))?.toString(),
                  'cm'
                ),
                'ankleCM'
              ),

              ...measureCondition(
                (kitState.main && !kitState.boots) || page === 'account',
                methods.getValues(`profiles.${index}.ownedBoots.brand`) ?? '',
                'bootsBrand'
              ),

              ...measureCondition(
                (kitState.main && !kitState.boots) || page === 'account',
                measureConvert(
                  methods
                    .getValues(generateName('ownedBoots.bootsMM'))
                    ?.toString(),
                  'cm'
                ),
                'bootsMM'
              ),

              ...measureCondition(
                (kitState.type === 'BOARD' && kitState.main) ||
                  page === 'account',
                StanceEnum[StanceValue!] ?? 'Non lo so',
                'stance'
              ),

              ...measureCondition(
                kitState.boots || page === 'account',
                measureConvert(
                  methods.getValues(generateName('footWidthCM'))?.toString(),
                  'cm'
                ),
                'footWidthCM'
              ),

              ...measureCondition(
                kitState.boots || page === 'account',
                measureConvert(
                  methods.getValues(generateName('footLengthCM'))?.toString(),
                  'cm'
                ),
                'footLengthCM'
              ),
            }}
            errors={getErrors([
              'ankleCM',
              'footLengthCM',
              'footWidthCM',
              'headCircCM',
              'heightCM',
              'weightKG',
              'stance',
              'shoeNumber',
              'ownedBoots',
            ])}
            index={index}
            page={page}
            handleBlur={handleBlur}
            kitState={kitState}
            setKitState={setKitState}
            savedProfileData={savedProfileData}
            profileId={profileId ?? ''}
            availabilitySearch={availabilitySearch}
            equipData={equipData}
            setGuideToggled={setGuideToggled}
          />
          {/*PREFERENZE*/}
          {page !== 'account' && (
            <PreferencesForm
              details={{
                saveProfile: kitState.saveProfile
                  ? methods.getValues(generateName('id'))
                    ? 'Aggiorna profilo'
                    : 'Salva profilo'
                  : '',
                notes:
                  methods.getValues(generateName('notes')) !== '' ? 'Note' : '',
              }}
              index={index}
              page={page}
              handleBlur={handleBlur}
              kitState={kitState}
              setKitState={setKitState}
              savedProfileData={savedProfileData}
              profileId={profileId ?? ''}
              errors={{}}
            />
          )}
        </Accordion>
      </FormProvider>
    );
  }
);
