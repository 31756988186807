import {
  AccordionItem,
  AccordionPanel,
  FlexProps,
  forwardRef,
  Grid,
  GridItem,
  HStack,
  Text,
} from '@chakra-ui/react';
import { MutableRefObject, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { useBookingStore } from '../../store';
import {
  BookedBoardBootsType,
  BookedBoardType,
  BookedHelmetType,
  BookedSkiBootsType,
  BookedSkiType,
  BookingInfo,
  MinimumAvailability,
  MinimumAvailabilitySKI,
  OwnedBootsType,
  PreviewDataType,
  Profile,
} from '../../types';
import { BookingProfile } from '../../typings';
import { VotingField } from '../inputs/star-rating';
import { EquipmentTabButton } from './equipment-tab-btn';
import { ProfileFormAccordionButton } from './profile-form-accordion-btn';
import { ProfilesEquipmentLabel } from './profile-form-labels';
import { EquipmentSelectionCheckbox } from './profile-form-selection-checkbox';

export const EquipmentSelection = forwardRef<
  {
    details: {};
    page: 'finalize' | 'account' | 'profiles' | 'summary';
    errors: { [key in keyof BookingProfile]?: unknown };
    savedProfileData: BookingProfile | undefined;
    kitState: PreviewDataType;
    setKitState: (kitState: PreviewDataType) => void;
    index: number;
    profileId: string;
    handleBlur: (
      fieldValue?: string,
      fieldName?:
        | keyof Profile
        | `bookedHelmet.${keyof BookedHelmetType}`
        | `bookedSkiBoots.${keyof BookedSkiBootsType}`
        | `bookedBoardBoots.${keyof BookedBoardBootsType}`
        | `ownedBoots.${keyof OwnedBootsType}`
        | `bookedBoard.${keyof BookedBoardType}`
        | `bookedSki.${keyof BookedSkiType}`
    ) => void;
    availabilitySearch: (
      inputLabel: 'heightCM' | 'shoeNumber' | 'headCircCM',

      inputValue: number,
      availability: { [key: string]: MinimumAvailabilitySKI },
      shouldSearch: boolean
    ) => void;
    equipData?: MinimumAvailability;
    equipmentRef: MutableRefObject<HTMLButtonElement | null>;
  } & FlexProps,
  'div'
>(
  (
    {
      details,
      page,
      kitState,
      setKitState,
      savedProfileData,
      index,
      profileId,
      handleBlur,
      errors,
      availabilitySearch,
      equipData,
      equipmentRef,
      ...props
    },
    ref
  ) => {
    const { pricesData } = useBookingStore(
      ({ pricesData }) => ({ pricesData }),
      shallow
    );
    const {
      register,
      unregister,
      setValue,
      getValues,
      watch,
      resetField,
      clearErrors,
    } = useFormContext<BookingInfo>();

    const defaultPrices = { fullValue: 0, value: 0 };
    const StanceValue = watch(`profiles.${index}.stance`);

    const generateName = (
      name:
        | keyof Profile
        | `bookedHelmet.${keyof BookedHelmetType}`
        | `bookedSkiBoots.${keyof BookedSkiBootsType}`
        | `bookedBoardBoots.${keyof BookedBoardBootsType}`
        | `ownedBoots.${keyof OwnedBootsType}`
        | `bookedBoard.${keyof BookedBoardType}`
        | `bookedSki.${keyof BookedSkiType}`
    ) => {
      return `profiles.${index}.${name}` as const;
    };

    const equipmentReset = () => {
      setKitState({ ...kitState, main: false, boots: false });
      setValue(generateName('bookedSki'), undefined);
      setValue(generateName('bookedBoard'), undefined);
      setValue(generateName('level'), undefined);
      unregister(generateName('bookedSkiBoots'));
      unregister(generateName('bookedBoardBoots'));
      handleBlur();
    };

    useEffect(() => {
      clearErrors(generateName('heightCM'));
      if (
        page === 'account' ||
        page === 'summary' ||
        !getValues(generateName('heightCM')) ||
        !equipData
      ) {
        return;
      }
      availabilitySearch(
        'heightCM',
        parseInt(getValues(generateName('heightCM')) as string),
        kitState.type === 'SKI'
          ? equipData?.availability?.SKI ?? {}
          : equipData?.availability?.BOARD ?? {},
        parseInt(getValues(generateName('heightCM')) as string) > 60 &&
          parseInt(getValues(generateName('heightCM')) as string) < 250
      );
    }, [kitState.premium, kitState.type]);

    return (
      <AccordionItem isDisabled={page === 'summary'} ref={ref}>
        <h2>
          <ProfileFormAccordionButton
            label='Attrezzatura'
            borderBottomBool={true}
            details={details}
            detailsMax={1}
            profileId={profileId}
            isSummary={page === 'summary'}
            errors={errors}
            equipmentRef={equipmentRef}
          />
        </h2>
        <AccordionPanel
          p='30px 16px 30px 16px'
          bgColor='rgba(242,252,255,1)'
          borderBottomWidth='1px'
          borderBottomColor='brandTransparent.500'
        >
          <Grid
            templateColumns='repeat(2, 1fr)'
            maxW={{ sm: '283px' }}
            w='100%'
            justifyContent='center'
            mx='auto'
            columnGap='16px'
            rowGap='15px'
          >
            <GridItem mb='13px'>
              <EquipmentTabButton
                kitType='SKI'
                selectedKit={kitState.type}
                onClick={() => {
                  if (page !== 'summary' && kitState.type !== 'SKI') {
                    setKitState({ ...kitState, type: 'SKI' });
                    setValue(
                      generateName('bookedSki.premium'),
                      kitState.premium
                    );
                    unregister(generateName('bookedBoard'));
                    setValue(generateName('bookedBoard'), undefined);
                    handleBlur();
                  }
                }}
              />
            </GridItem>
            <GridItem>
              <EquipmentTabButton
                kitType='BOARD'
                selectedKit={kitState.type}
                onClick={() => {
                  if (page !== 'summary' && kitState.type !== 'BOARD') {
                    setKitState({ ...kitState, type: 'BOARD' });
                    if (kitState.main) {
                      setValue(
                        generateName('bookedBoard.premium'),
                        kitState.premium
                      );
                    }

                    unregister(generateName('bookedSki'));
                    setValue(generateName('bookedSki'), undefined);
                    handleBlur();
                  }
                }}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <ProfilesEquipmentLabel num={1} label='Seleziona attrezzatura' />
            </GridItem>
            <GridItem colSpan={2}>
              <EquipmentSelectionCheckbox
                type={kitState.type}
                prices={{
                  board: pricesData['BOARDANDBOOTS'] ?? defaultPrices,
                  ski: pricesData['SKIANDBOOTS'] ?? defaultPrices,
                }}
                kitInfo={
                  kitState.type === 'SKI'
                    ? ['Kit Sci', 'Sci', 'Bacchette', 'Scarponi']
                    : ['Kit Snowboard', 'Snowboard', 'Scarponi']
                }
                checkedCondition={kitState.main && kitState.boots}
                onClick={() => {
                  if (page !== 'summary') {
                    if (!kitState.main || !kitState.boots) {
                      setKitState({
                        ...kitState,
                        main: true,
                        boots: true,
                      });
                      const {
                        firstName,
                        lastName,
                        gender,
                        birthDate,
                        ...profile
                      } = savedProfileData ?? getValues(`profiles.${index}`);

                      setValue(`profiles.${index}`, {
                        ...getValues(`profiles.${index}`),
                        ...profile,
                        bookedSkiBoots:
                          kitState.type === 'SKI' ? { size: '' } : undefined,
                        bookedBoardBoots:
                          kitState.type === 'BOARD' ? { size: '' } : undefined,

                        bookedBoard:
                          kitState.type === 'BOARD'
                            ? {
                                premium: kitState.premium,
                              }
                            : undefined,
                        bookedSki:
                          kitState.type === 'SKI'
                            ? {
                                premium: kitState.premium,
                              }
                            : undefined,
                      });

                      if (kitState.type === 'SKI') {
                        unregister(generateName('bookedBoard'));
                      } else {
                        unregister(generateName('bookedSki'));
                      }
                      unregister(generateName('ownedBoots'));

                      handleBlur();
                      clearErrors(generateName('bookedBoard'));
                      return;
                    }
                    equipmentReset();
                  }
                }}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <EquipmentSelectionCheckbox
                type={kitState.type}
                prices={{
                  board: pricesData['BOARD'] ?? defaultPrices,
                  ski: pricesData['SKI'] ?? defaultPrices,
                }}
                kitInfo={
                  kitState.type === 'SKI'
                    ? ['Sci + Bacchette', 'Singolo Accessorio']
                    : ['Snowboard', 'Singolo Accessorio']
                }
                checkedCondition={kitState.main && !kitState.boots}
                onClick={() => {
                  if (page !== 'summary') {
                    if (!kitState.main || kitState.boots) {
                      setKitState({
                        ...kitState,
                        main: true,
                        boots: false,
                      });

                      const {
                        firstName,
                        lastName,
                        gender,
                        birthDate,
                        ...profile
                      } = savedProfileData ?? getValues(`profiles.${index}`);
                      setValue(
                        `profiles.${index}`,
                        {
                          ...getValues(`profiles.${index}`),
                          ...profile,
                          ownedBoots: savedProfileData?.ownedBoots,

                          bookedBoard:
                            kitState.type === 'BOARD'
                              ? {
                                  premium: kitState.premium,
                                }
                              : undefined,
                          bookedSki:
                            kitState.type === 'SKI'
                              ? {
                                  premium: kitState.premium,
                                }
                              : undefined,
                        },
                        { shouldValidate: true }
                      );
                      kitState.type === 'SKI'
                        ? resetField(generateName('bookedBoard'))
                        : resetField(generateName('bookedSki'));
                      unregister(generateName('bookedSkiBoots'));
                      unregister(generateName('bookedBoardBoots'));
                      handleBlur();
                      clearErrors(generateName('bookedBoard'));

                      return;
                    }
                    equipmentReset();
                  }
                }}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <EquipmentSelectionCheckbox
                type={kitState.type}
                kitInfo={['Scarponi', 'Singolo Accessorio']}
                checkedCondition={!kitState.main && kitState.boots}
                prices={{
                  board: pricesData['BOARDBOOTS'] ?? defaultPrices,
                  ski: pricesData['SKIBOOTS'] ?? defaultPrices,
                }}
                onClick={() => {
                  if (page !== 'summary') {
                    if (kitState.main || !kitState.boots) {
                      setKitState({
                        ...kitState,
                        main: false,
                        boots: true,
                      });

                      const {
                        firstName,
                        lastName,
                        gender,
                        birthDate,
                        ...profile
                      } = savedProfileData ?? getValues(`profiles.${index}`);

                      setValue(`profiles.${index}`, {
                        ...getValues(`profiles.${index}`),
                        ...profile,
                        bookedSkiBoots:
                          kitState.type === 'SKI' ? { size: '' } : undefined,
                        bookedBoardBoots:
                          kitState.type === 'BOARD' ? { size: '' } : undefined,
                        bookedBoard: undefined,
                        bookedSki: undefined,
                      });

                      unregister(generateName('ownedBoots'));

                      unregister(generateName('bookedSki'));
                      unregister(generateName('bookedBoard'));
                      clearErrors(generateName('bookedBoard'));

                      handleBlur();
                      return;
                    }
                  }
                  equipmentReset();
                }}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ProfilesEquipmentLabel
                num={2}
                label='Vuoi aggiungere il casco?'
              />
            </GridItem>
            <GridItem colSpan={2}>
              <EquipmentSelectionCheckbox
                prices={{
                  board: pricesData['HELMET'] ?? defaultPrices,
                  ski: pricesData['HELMET'] ?? defaultPrices,
                }}
                type={kitState.type}
                kitInfo={['Casco', 'Singolo Accessorio']}
                checkedCondition={kitState.helmet}
                onClick={() => {
                  if (page !== 'summary') {
                    setKitState({ ...kitState, helmet: !kitState.helmet });
                    setValue(generateName('bookedHelmet.size'), '');
                    setValue(
                      generateName('headCircCM'),
                      savedProfileData?.headCircCM
                    );
                    clearErrors(generateName('bookedBoard'));

                    if (kitState.helmet) {
                      unregister(generateName('bookedHelmet'));
                    }
                  }
                  handleBlur();
                }}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ProfilesEquipmentLabel
                num={3}
                label='Seleziona il tuo livello'
              />
            </GridItem>
            <GridItem colSpan={2}>
              <VotingField
                previewData={kitState}
                setPreviewData={setKitState}
                setValue={setValue}
                profileIndex={index}
                isFinalize={page === 'finalize'}
                storeUpdate={handleBlur}
              />
              <HStack
                w='100%'
                maxW='283px'
                justifyContent='space-between'
                mt='23px'
              >
                <Text fontSize='11px'>Principiante</Text>
                <Text fontSize='11px' pr='6px'>
                  Intermedio
                </Text>
                <Text fontSize='11px'>Esperto</Text>
              </HStack>
            </GridItem>

            {/* PREMIUM */}
            {kitState.main && kitState.level === 5 && (
              <GridItem colSpan={2}>
                <EquipmentSelectionCheckbox
                  type={kitState.type}
                  prices={{
                    board: pricesData['PREMIUM'] ?? defaultPrices,
                    ski: pricesData['PREMIUM'] ?? defaultPrices,
                  }}
                  kitInfo={
                    kitState.type === 'SKI'
                      ? ['Vuoi gli sci Premium?']
                      : ['Vuoi lo snowboard Premium?']
                  }
                  checkedCondition={kitState.premium}
                  onClick={() => {
                    if (page !== 'summary') {
                      setKitState({
                        ...kitState,
                        premium: !kitState.premium,
                      });
                      setValue(
                        kitState.type === 'SKI'
                          ? generateName('bookedSki.premium')
                          : generateName('bookedBoard.premium'),
                        !kitState.premium
                      );

                      handleBlur();
                    }
                  }}
                />
              </GridItem>
            )}
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    );
  }
);
