import { Flex, FlexProps, Image } from '@chakra-ui/react';
import { ReactNode, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from './header';
import { LoaderIcon } from './icons/navigation';

export const AppContainer = ({ children }: { children: ReactNode }) => (
  <Flex direction='column' height='100vh' minW='100vw' position='relative'>
    {children}
  </Flex>
);

const CONTENT_PADDING = 30;
const CONTENT_SCREEN = 1366;
const CONTENT_DIFFERENCE = 360;
const CONTENT_MAX_WIDTH =
  CONTENT_SCREEN - CONTENT_DIFFERENCE * 2 + CONTENT_PADDING * 2;

export const PageContainer = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const [load, setLoad] = useState<boolean>(true);
  const [imageUrl, setImageUrl] = useState<string>();

  const background: Pick<
    FlexProps,
    | 'background'
    | 'backgroundImage'
    | 'backgroundPosition'
    | 'backgroundRepeat'
    | 'bgGradient'
  > = useMemo(() => {
    switch (location.pathname) {
      case '/': {
        const url = '/backgrounds/home-bg.jpg';
        setImageUrl(url);
        return {
          backgroundImage: `url(${url})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        };
      }
      case '/login': {
        const url = '/backgrounds/login.png';
        setImageUrl(url);
        return {
          backgroundSize: '100%',
          backgroundImage: `url(${url})`,
          backgroundPosition: {
            base: '0px calc(60% + 200px)',
            sm: '0px calc(70% + 150px)',
          },
          backgroundRepeat: 'no-repeat',
        };
      }
      case '/profiles': {
        return {
          bgGradient: 'linear(to-b, #00B1DC40, white)',
        };
      }
      case '/shipment':
      case '/document':
      case '/finalize':
      case '/account':
      case '/forgot-password':
      case '/reset-password':
      case '/terms-of-service':
      case '/contact-info': {
        return { bgGradient: 'linear(to-t, #00B1DC40, white)' };
      }
      default: {
        // order summary has id next to it
        if (location.pathname.includes('/order-summary')) {
          return { bgGradient: 'linear(to-t, #00B1DC40, white)' };
        }
        return { background: 'white' };
      }
    }
  }, [location]);

  return (
    <Flex
      justify='center'
      width='100%'
      height='100%'
      {...background}
      overflow='hidden'
    >
      {imageUrl && (
        <Image display='none' onLoad={() => setLoad(false)} src={imageUrl} />
      )}
      {load && imageUrl ? (
        <LoaderIcon />
      ) : (
        <Flex
          width='100%'
          height='100%'
          justify='center'
          overflowY='auto'
          overflowX='hidden'
        >
          <Flex
            direction='column'
            width='100%'
            height='100%'
            maxW={`${CONTENT_MAX_WIDTH}px`}
            px={`${CONTENT_PADDING}px`}
          >
            <Header />
            {children}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export const HeaderContainer = ({ children }: { children: ReactNode }) => (
  <Flex justify='center' zIndex='1' flexDirection='row' width='100%'>
    {children}
  </Flex>
);
