import { Flex, Image, Text } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  FieldErrorsImpl,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { PageContainer } from '../components/container';
import { DropzoneIcon } from '../components/icons/inputs';
import { CheckboxItem } from '../components/inputs/checkbox';
import { IdInput } from '../components/inputs/id-input';
import StyledButton from '../components/inputs/styled-button';
import { SectionTitle } from '../components/section-title';
import { Wrapper } from '../components/wrapper';
import { BASE_PATH } from '../config';
import { addToken, getUserApi } from '../services/api/open-api';
import { useBookingStore, useNotification, useUserStore } from '../store';
import { DocumentFormType } from '../types';

export const Document = () => {
  const { user, setUser, token } = useUserStore(
    ({ user, setUser, token }) => ({ user, setUser, token }),
    shallow
  );
  const { bookingInfo, setBookingInfo } = useBookingStore(
    ({ bookingInfo, setBookingInfo }) => ({ bookingInfo, setBookingInfo }),
    shallow
  );
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);

  const { register, handleSubmit, formState, getValues } =
    useForm<DocumentFormType>({
      defaultValues: {
        document: user ? user.documents : bookingInfo?.documents,
      },
    });

  const [document, setDocument] = useState<File>();

  const parseFile = async (data: any, token?: string) => {
    const fileEntry = Object.entries(data).find(
      ([key]) => data[key] && data[key] instanceof File
    );
    if (!fileEntry) {
      return data;
    }
    const [key, file] = fileEntry;

    const form = new FormData();
    form.append('file', data.document);

    const {
      data: { url: fileUrl },
    } = await axios({
      method: 'POST',
      url: `${BASE_PATH}/documents/upload`,
      data: form,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...(token && { authorization: `Bearer ${token}` }),
      },
    });
    return { ...data, [key]: fileUrl };
  };

  const onSubmit: SubmitHandler<DocumentFormType> = async (data) => {
    try {
      const res = await parseFile({ document: data.document });
      setBookingInfo({ ...bookingInfo!, documents: res.document });
      if (data.save && user?.id) {
        const res2 = await getUserApi().userEdit(
          user.id,
          {
            profiles: user.profiles,
            documents: res.document,
          },
          addToken(token)
        );
        setUser(res2.data.element!);
      }
      navigate('/finalize');
    } catch (e: any) {
      alert('errore');
    }
  };

  const onError: SubmitErrorHandler<FieldErrorsImpl> = (data) => {
    notify('error', {
      title: 'Formato non corretto',
      text: 'Il file caricato non è un immagine in formato JPG, PNG, BMP o JPEG.',
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!bookingInfo?.userEmail || !bookingInfo?.userPhone) {
      navigate('/');
    }
  }, []);

  if (!bookingInfo) return <Wrapper />;
  return (
    <PageContainer>
      <SectionTitle
        hasBack
        toPage={user ? '/shipment' : '/contact-info'}
        text='Documenti'
        mt={{ base: '15px', md: '' }}
      />
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Text
          mt='70px'
          fontSize='15px'
          lineHeight='20px'
          color='brand.500'
          w='100%'
          textAlign='center'
        >
          Caricare un documento valido <br />
          della persona che prenota
        </Text>
        <Flex direction='column'>
          <IdInput
            mt='20px'
            mb='20px'
            mx='auto'
            registerProps={register('document', { required: true })}
            isRequired
            setDocument={setDocument}
            errors={formState.errors.document}
            overflow='hidden'
          >
            {!getValues('document') ? (
              <DropzoneIcon w='36px' h='auto' mx='auto' />
            ) : (
              <Image
                src={
                  !document
                    ? `${BASE_PATH}/${getValues('document')}`
                    : URL.createObjectURL(document)
                }
                cursor='pointer'
                w='100%'
                alt={`${BASE_PATH}/${bookingInfo.documents}`}
                objectFit='cover'
              />
            )}
          </IdInput>
          {user && (
            <CheckboxItem
              justify='center'
              ml='13px'
              registerProps={register('save')}
              errors={formState.errors.save}
              option={{
                title: 'Salva i miei documenti',
                subtitle: 'per la prossima volta',
              }}
              mx='auto'
            />
          )}
          <StyledButton
            mt={user ? '30px !important' : '60px'}
            mb='100px'
            type='submit'
          >
            Paga
          </StyledButton>
        </Flex>
      </form>
    </PageContainer>
  );
};
