import { Button, ButtonProps, Flex, forwardRef } from '@chakra-ui/react';

const SocialButton = forwardRef<
  { brandColor?: string } & ButtonProps,
  'button'
>(({ children, brandColor = 'brand.500', leftIcon, ...rest }, ref) => {
  return (
    <Button
      boxShadow={
        rest.boxShadow ||
        '0 18px 40px var(--chakra-colors-brandTransparent-350)'
      }
      w='100%'
      maxW={rest.maxW ?? '315px'}
      mx='auto'
      borderRadius='10px'
      h='50px'
      alignItems='center'
      borderColor={brandColor}
      borderWidth='2px'
      borderStyle='solid'
      padding='8px 16px'
      bg='white'
      color={brandColor}
      ref={ref}
      {...rest}
    >
      <Flex width='18px' marginRight='24px' justify='center'>
        {leftIcon}
      </Flex>
      <Flex fontSize='15px' fontWeight='400' width='170px' lineHeight='12px'>
        {children}
      </Flex>
    </Button>
  );
});

export default SocialButton;
