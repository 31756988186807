import {
  AccordionItem,
  AccordionPanel,
  FlexProps,
  forwardRef,
  Grid,
  GridItem,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { useBookingStore, useUserStore } from '../../store';
import {
  BookedBoardBootsType,
  BookedBoardType,
  BookedHelmetType,
  BookedSkiBootsType,
  BookedSkiType,
  BookingInfo,
  GenderEnumType,
  OwnedBootsType,
  PreviewDataType,
  Profile,
} from '../../types';
import { BookingProfile } from '../../typings';
import { GenderEnum, LevelEnum } from '../../utils/profile-utils';
import SimpleInputText from '../inputs/input-text';
import { CustomSelect } from '../inputs/select-input';
import StyledButton from '../inputs/styled-button';
import { SelectProfilesModal } from '../modals/modals';
import { BirthDateButton, BirthDateCalendar } from './date.selector';
import { ProfileFormAccordionButton } from './profile-form-accordion-btn';

export const PersonalData = forwardRef<
  {
    details: {};
    page: 'finalize' | 'account' | 'profiles' | 'summary';
    errors: { [key in keyof BookingProfile]?: unknown };
    savedProfileData: BookingProfile | undefined;
    setSavedProfileData: (profile: BookingProfile) => void;
    kitState: PreviewDataType;
    setKitState: (kitState: PreviewDataType) => void;
    index: number;
    profileId: string;
    handleBlur: (
      fieldValue?: string,
      fieldName?:
        | keyof Profile
        | `bookedHelmet.${keyof BookedHelmetType}`
        | `bookedSkiBoots.${keyof BookedSkiBootsType}`
        | `bookedBoardBoots.${keyof BookedBoardBootsType}`
        | `ownedBoots.${keyof OwnedBootsType}`
        | `bookedBoard.${keyof BookedBoardType}`
        | `bookedSki.${keyof BookedSkiType}`
    ) => void;
  } & FlexProps,
  'div'
>(
  (
    {
      details,
      page,
      errors,
      savedProfileData,
      setSavedProfileData,
      kitState,
      setKitState,
      index,
      handleBlur,
      profileId,
      ...props
    },
    ref
  ) => {
    const { user } = useUserStore(({ user }) => ({ user }), shallow);
    const { bookingInfo } = useBookingStore(
      ({ bookingInfo }) => ({ bookingInfo }),
      shallow
    );
    const { register, setValue, getValues, watch, formState } =
      useFormContext<BookingInfo>();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const calendarRef = useRef<HTMLDivElement | null>(null);

    const generateName = (
      name:
        | keyof Profile
        | `bookedHelmet.${keyof BookedHelmetType}`
        | `bookedSkiBoots.${keyof BookedSkiBootsType}`
        | `bookedBoardBoots.${keyof BookedBoardBootsType}`
        | `ownedBoots.${keyof OwnedBootsType}`
        | `bookedBoard.${keyof BookedBoardType}`
        | `bookedSki.${keyof BookedSkiType}`
    ) => {
      return `profiles.${index}.${name}` as const;
    };

    const birthDateDisclosure = useDisclosure();

    const bookingBirthDate = bookingInfo?.profiles
      ? moment(bookingInfo?.profiles?.[index]?.birthDate).toDate()
      : null;

    const userBirthDate = user
      ? moment(
          user?.profiles?.filter((profile) => !profile.main)[index]?.birthDate
        ).toDate()
      : null;

    const [birthDate, setBirthDate] = useState<Date | null>(
      page !== 'account' ? bookingBirthDate : userBirthDate
    );

    const setUserValues = (ProfileIndex: number) => {
      if (user?.profiles) {
        const {
          gender,
          bootsMM,
          bootsBrand,
          bootsSize,
          stance,
          main,
          ankleCM,
          birthDate,
          shoeNumber,
          heightCM,
          weightKG,
          footLengthCM,
          footWidthCM,
          ...profile
        } = user?.profiles[ProfileIndex];
        const parsedProfile = {
          ...profile,
          heightCM,
          weightKG,
          footLengthCM,
          footWidthCM,
          gender:
            (gender as GenderEnumType) ?? getValues(generateName('gender')),
          bookedBoard:
            kitState.type === 'BOARD' && kitState.main
              ? { premium: kitState.premium }
              : undefined,
          bookedSki:
            kitState.type === 'SKI' && kitState.main
              ? { premium: kitState.premium }
              : undefined,
          bookedHelmet: kitState.helmet ? {} : undefined,
          bookedBoots: kitState.boots ? {} : undefined,
          shoeNumber,
          ankleCM,
          birthDate,
          ownedBoots: {
            bootsMM: bootsMM!,
            brand: bootsBrand!,
            size: bootsSize!,
          },
          stance,
          notes: '',
        };
        setValue(`profiles.${index}`, parsedProfile, { shouldValidate: true });
        setSavedProfileData(parsedProfile);
        setBirthDate(moment(birthDate).toDate());
        birthDayChange(moment(birthDate).toDate());
        setKitState({
          ...kitState,
          level: parseInt(LevelEnum[profile.level!]),
        });
      }
    };
    const GenderValue = watch(`profiles.${index}.gender`);

    const birthDayChange = (date: Date | null) => {
      if (!date) {
        return;
      }
      setValue(generateName('birthDate'), moment(date).format('YYYY-MM-DD'), {
        shouldValidate: true,
      });
      handleBlur();
    };

    return (
      <AccordionItem border='none' isDisabled={page === 'summary'}>
        <h2>
          <ProfileFormAccordionButton
            label='Dati Personali'
            details={details}
            detailsMax={4}
            proceedRef={ref}
            profileId={profileId}
            isSummary={page === 'summary'}
            errors={errors}
            borderBottomBool={page === 'account'}
          />
        </h2>
        <AccordionPanel
          p='30px 16px 30px 16px'
          bgColor='rgba(242,252,255,1)'
          borderTop={page !== 'account' ? '1px' : ''}
          borderBottom={page === 'account' ? '1px' : ''}
          borderColor='brandTransparent.500'
        >
          <Grid
            templateColumns={{ base: 'repeat(1,1fr)', md: 'repeat(2, 1fr)' }}
            columnGap='16px'
            rowGap='15px'
          >
            {user && page !== 'account' && (
              <GridItem colSpan={2}>
                <>
                  <HStack spacing={0}>
                    <StyledButton onClick={onOpen}>
                      Seleziona Profilo Esistente
                    </StyledButton>
                  </HStack>
                  <SelectProfilesModal
                    isOpen={isOpen}
                    onClose={onClose}
                    selectedProfileIds={getValues('profiles')
                      ?.filter((id) => !!id)
                      ?.map((profile) => profile.id)}
                    setUserValues={setUserValues}
                  />
                </>
              </GridItem>
            )}
            <GridItem colSpan={{ base: 2, md: 1 }}>
              {/* FIRSTNAME */}
              <SimpleInputText
                {...register(generateName('firstName'), {
                  required: true,
                  pattern: /^[a-z ,.'-]+$/i,
                })}
                label='Nome'
                placeholder='Es. Mario'
                errors={formState.errors?.profiles?.[index]?.firstName}
                secondaryLabelType='required'
                onBlur={(e) => handleBlur(e.target.value, 'firstName')}
              />
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              {/* LASTNAME */}
              <SimpleInputText
                {...register(generateName('lastName'), {
                  required: true,
                  pattern: /^[a-z ,.'-]+$/i,
                })}
                label='Cognome'
                placeholder='Es. Rossi'
                errors={formState.errors?.profiles?.[index]?.lastName}
                secondaryLabelType='required'
                onBlur={(e) => handleBlur(e.target.value, 'lastName')}
              />
            </GridItem>
            <GridItem colSpan={{ base: 2, md: 1 }}>
              {/* GENDER */}
              <CustomSelect
                {...register(generateName('gender'), {
                  required: page !== 'account',
                })}
                label='Sesso'
                options={[
                  { label: 'Maschio', value: 'MALE' },
                  { label: 'Femmina', value: 'FEMALE' },
                ]}
                customDefaultValue={
                  !GenderValue
                    ? undefined
                    : {
                        label: GenderEnum[GenderValue!].toString(),
                        value: GenderValue?.toString()!,
                      }
                }
                customOnChange={(gender: { label: string; value: string }) => {
                  setValue(generateName('gender'), gender.value, {
                    shouldValidate: true,
                  });
                  handleBlur();
                }}
                errors={formState.errors?.profiles?.[index]?.gender}
                secondaryLabelType='required'
              />
            </GridItem>

            {/* BIRTHDATE */}
            <GridItem colSpan={{ base: 2, md: 1 }}>
              <BirthDateButton
                {...register(generateName('birthDate'), {
                  required: page !== 'account',
                  validate: (value) => value! !== '',
                })}
                secondaryLabelType='required'
                birthDateValue={birthDate}
                errors={formState.errors?.profiles?.[index]?.birthDate}
                onClick={birthDateDisclosure.onToggle}
                scrollRef={calendarRef}
              />
            </GridItem>
            {birthDateDisclosure.isOpen && (
              <GridItem colSpan={2}>
                <BirthDateCalendar
                  scrollRef={calendarRef}
                  startDate={birthDate}
                  setStartDate={setBirthDate}
                  birthDayChange={birthDayChange}
                  onClose={birthDateDisclosure.onClose}
                />
              </GridItem>
            )}
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    );
  }
);
