import { Icon, IconProps } from '@chakra-ui/react';

export const AnkleIcon = (props: IconProps) => (
  <Icon
    xmlns='http://www.w3.org/2000/svg'
    width='161.196'
    height='149.483'
    viewBox='0 0 161.196 149.483'
    {...props}
  >
    <defs>
      <clipPath id='clipPath'>
        <rect
          id='Rettangolo_1220'
          data-name='Rettangolo 1220'
          width='155.483'
          height='149.483'
          fill='none'
          stroke='#00b1dc'
          strokeWidth='1'
        />
      </clipPath>
    </defs>
    <g
      id='Raggruppa_2095'
      data-name='Raggruppa 2095'
      transform='translate(-94.517 -531.517)'
    >
      <g
        id='Raggruppa_2086'
        data-name='Raggruppa 2086'
        transform='translate(94.517 531.517)'
      >
        <g
          id='Raggruppa_2085'
          data-name='Raggruppa 2085'
          clipPath='url(#clipPath)'
        >
          <path
            id='Tracciato_1377'
            data-name='Tracciato 1377'
            d='M38.108,250.525s-9.537,5.938-13.315,8.817-5.341,4.385-7.737,4.678c-4.558.27-3.868,7.488-.72,8.164,1.815.468,3.309.613,4.049,0'
            transform='translate(-7.189 -130.449)'
            fill='none'
            stroke='#00b1dc'
            strokeMiterlimit='10'
            strokeWidth='1'
          />
          <path
            id='Tracciato_1378'
            data-name='Tracciato 1378'
            d='M35.025,275.273c-5.4,1.552-9.395-.571-8.7-6.545.473-3.1,3.509-2.226,6.748-4.521,6-4.88,12.045-9.31,18.826-12.415'
            transform='translate(-13.665 -131.108)'
            fill='none'
            stroke='#00b1dc'
            strokeMiterlimit='10'
            strokeWidth='1'
          />
          <path
            id='Tracciato_1379'
            data-name='Tracciato 1379'
            d='M50.5,279.807c-6.612.607-11.763-2.97-8.367-8.1,2.5-2.969,4.651-1.215,9.312-3.913,5.668-3.374,9.561-9.242,15.385-10.8'
            transform='translate(-21.376 -133.82)'
            fill='none'
            stroke='#00b1dc'
            strokeMiterlimit='10'
            strokeWidth='1'
          />
          <path
            id='Tracciato_1380'
            data-name='Tracciato 1380'
            d='M49.983,129.873c-5.05,1.439-9.274,3.621-12.145,7.017a11.2,11.2,0,0,1-5.848,3.869c-3.756.832-4.364,6.1-.315,7.175,3.581,2.372,7.319,2.019,11.2-.81,2.383-2.043,5.719-2.427,9.582-1.889,6.07,1.428,12.929-.327,20.647-5.546a209.145,209.145,0,0,1,46.154-28.259l25.641-10.8c9.582-4.318,13.434-14.118,8.367-28.2-6.548-19.764-15.71-32.665-16.328-61.512A10.642,10.642,0,0,0,126.3.5H99.334a10.641,10.641,0,0,0-10.62,9.971c-.391,6.167-.917,13.577-1.394,17.2-.9,6.838-2.519,17.814-3.419,22.852S72.745,69.775,72.745,69.775s-6.838,10.976-9.717,16.374-16.194,20.873-28.609,27.17-10.976,5.4-17.814,9.177-7.759,3.971-10.976,3.6c-5.443-.63-8.592,8.772,1.248,12.955'
            transform='translate(-0.26 -0.26)'
            fill='none'
            stroke='#00b1dc'
            strokeMiterlimit='10'
            strokeWidth='1'
          />
          <path
            id='Tracciato_1381'
            data-name='Tracciato 1381'
            d='M264.465,47.8s1.439,19.073,3.959,25.731,5.038,8.637,0,13.855a39.162,39.162,0,0,0-7.2,10.076'
            transform='translate(-136.021 -24.891)'
            fill='none'
            stroke='#00b1dc'
            strokeMiterlimit='10'
            strokeWidth='1'
          />
        </g>
      </g>
      <path
        id='Tracciato_1382'
        data-name='Tracciato 1382'
        d='M7.495,93.129c-20.721,3.588,2.9,12.74,37.9,11.574,38.178-.395,53.366-11.644,31.709-15.366'
        transform='translate(167.151 474.124)'
        fill='none'
        stroke='#e80000'
        strokeMiterlimit='10'
        strokeWidth='3'
      />
      <path
        id='Tracciato_1383'
        data-name='Tracciato 1383'
        d='M1753.187,9506.242l4.64,4.977,4.7-4.977'
        transform='matrix(-0.259, 0.966, -0.966, -0.259, 9884.601, 1326.542)'
        fill='none'
        stroke='#e80000'
        strokeWidth='3'
      />
    </g>
  </Icon>
);

export const HeadIcon = (props: IconProps) => (
  <Icon
    xmlns='http://www.w3.org/2000/svg'
    width='147.244'
    height='147.507'
    viewBox='0 0 147.244 147.507'
    {...props}
  >
    <defs>
      <clipPath id='clipPath'>
        <rect
          id='Rettangolo_1219'
          data-name='Rettangolo 1219'
          width='144.735'
          height='145.003'
          transform='matrix(1, -0.017, 0.017, 1, -14.988, 1.394)'
          fill='none'
          stroke='#00b1dc'
          strokeWidth='1'
        />
      </clipPath>
    </defs>
    <g
      id='Raggruppa_2094'
      data-name='Raggruppa 2094'
      transform='translate(-101.012 -470.868)'
    >
      <g
        id='Raggruppa_2083'
        data-name='Raggruppa 2083'
        transform='translate(116 472)'
        clipPath='url(#clipPath)'
      >
        <path
          id='Tracciato_1373'
          data-name='Tracciato 1373'
          d='M41.1,134.108c6.48-20.933,13.185-42.071,8.422-52.739-9.425-26.67-25.467-47.927,1.61-70.386C72.379-3.856,108.674-3.254,120.3,14.794c6.933,11.6,17,23.841,14.438,33.488,0,0-2.005,7.52,2.005,11.731s12.834,18.048,6.417,19.752-4.913,3.208-4.913,3.208c.511,3.837,1.712,7.744-3.209,11.029,2.061,1.078,2.962,2.4.6,4.412,0,0-5.645,2.912-3.309,6.818,3.838,6.417-6.531,15.375-9.826,13.435l-18.549-2.707c-.337,11.435,1.133,21.478,5.515,29.277'
          transform='translate(-23.271 -0.322)'
          fill='none'
          stroke='#00b1dc'
          strokeMiterlimit='10'
          strokeWidth='1'
        />
        <path
          id='Tracciato_1374'
          data-name='Tracciato 1374'
          d='M137.734,153.336c-31.483-11.029-5.414,42.111,12.634,31.483'
          transform='translate(-79.201 -97.757)'
          fill='none'
          stroke='#00b1dc'
          strokeMiterlimit='10'
          strokeWidth='1'
        />
        <path
          id='Tracciato_1375'
          data-name='Tracciato 1375'
          d='M10.221,93.382c-28.011,4.85,3.918,17.223,51.241,15.646,51.61-.534,82-11.584,52.722-16.617'
          transform='translate(-0.322 -63.876)'
          fill='none'
          stroke='#e80000'
          strokeMiterlimit='10'
          strokeWidth='3'
        />
      </g>
      <path
        id='Tracciato_1376'
        data-name='Tracciato 1376'
        d='M1753.187,9506.242l4.64,4.977,4.7-4.977'
        transform='matrix(-0.259, 0.966, -0.966, -0.259, 9870.601, 1263.305)'
        fill='none'
        stroke='#e80000'
        strokeWidth='3'
      />
    </g>
  </Icon>
);

export const BootIcon = (props: IconProps) => (
  <Icon
    xmlns='http://www.w3.org/2000/svg'
    width='162.711'
    height='157.862'
    viewBox='0 0 162.711 157.862'
    {...props}
  >
    <defs>
      <clipPath id='clipPath'>
        <rect
          id='Rettangolo_1221'
          data-name='Rettangolo 1221'
          width='162.711'
          height='148.256'
          fill='none'
          stroke='#00b1dc'
          strokeWidth='1'
        />
      </clipPath>
    </defs>
    <g id='Raggruppa_2090' data-name='Raggruppa 2090'>
      <g
        id='Raggruppa_2089'
        data-name='Raggruppa 2089'
        clipPath='url(#clipPath)'
      >
        <path
          id='Tracciato_1384'
          data-name='Tracciato 1384'
          d='M65,147.44H11.746c-11.189-10.006-13.624-22.76-9.1-37.7C14.943,77.679,25.793,46.557,27.3,21.524c-.358-7.571,3.684-9.913,9.975-9.584l.2-10.953L54.68,13.9s45.965-14.67,46.356-.391L98.3,22.894s9.193-.2,6.064,7.433-22.1,44.4-8.215,52.615,53.2,26.405,53.2,26.405c8.29,4.482,14.667,10.181,13.105,20.929l-3.521,17.164-55.745,1.027C90.341,139.251,77.609,138.739,65,147.44Z'
          transform='translate(-0.24 -0.473)'
          fill='none'
          stroke='#00b1dc'
          strokeMiterlimit='10'
          strokeWidth='1'
        />
        <path
          id='Tracciato_1385'
          data-name='Tracciato 1385'
          d='M47,39.279c-.914,27.789-5.319,52.674-17.6,71-8.8,21.181-10.868,38.123,7.433,42.249H160.833c6.259-1.956,5.31-12.184-3.129-17.212-31.491-18.191-67.876-25.177-63.839-46.552L109,41.235,56.188,45.929Z'
          transform='translate(-10.942 -18.814)'
          fill='none'
          stroke='#00b1dc'
          strokeMiterlimit='10'
          strokeWidth='1'
        />
      </g>
    </g>
    <g
      id='Ellisse_119'
      data-name='Ellisse 119'
      transform='translate(5.711 111.862)'
      fill='none'
      stroke='#e80000'
      strokeWidth='3'
    >
      <circle cx='23' cy='23' r='23' stroke='none' />
      <circle cx='23' cy='23' r='21.5' fill='none' />
    </g>
  </Icon>
);

export const FootWidthIcon = (props: IconProps) => (
  <Icon
    xmlns='http://www.w3.org/2000/svg'
    width='89.707'
    height='166.524'
    viewBox='0 0 89.707 166.524'
    {...props}
  >
    <defs>
      <clipPath id='clipPath'>
        <rect
          id='Rettangolo_1222'
          data-name='Rettangolo 1222'
          width='75.255'
          height='166.524'
          transform='translate(-4 -5)'
          fill='none'
          stroke='#00b1dc'
          strokeWidth='1'
        />
      </clipPath>
    </defs>
    <g
      id='Raggruppa_2097'
      data-name='Raggruppa 2097'
      transform='translate(-192.573 -345)'
    >
      <g
        id='Raggruppa_2093'
        data-name='Raggruppa 2093'
        transform='translate(204 350)'
      >
        <g
          id='Raggruppa_2091'
          data-name='Raggruppa 2091'
          clipPath='url(#clipPath)'
        >
          <path
            id='Tracciato_1386'
            data-name='Tracciato 1386'
            d='M.908,49.538a77.208,77.208,0,0,0,0-8.383C.618,38.83-1.042,30.447,7.922,27.542c0,0-1.992-9.463,4.316-11.288,0,0,4.4-1.328,7.138,2.075,0,0-.913-9.13,4.98-9.877,0,0,3.735-.581,5.644,1.743,0,0,2.324-4.731,6.723-4.316a6.884,6.884,0,0,1,6.059,5.894c.166,1.494,1.245,17.347,1.245,17.347l2.407,1.743,1.66-.083L45.024,15.423C44.045,8.492,44.442,2.447,51.415.649c4.618-.591,8.417.512,10.791,4.565,3.416,6.1,5.341,12.086,5.312,17.928A96.71,96.71,0,0,0,68.182,46.3c.828,7.306-2.346,14.833-7.968,22.494Q50.49,90.437,49.465,117.268l.623,18.676c-.225,10.506-3.876,18.3-13.571,21.29-9.275,1.7-16.292-2.908-21.415-12.824-3.454-8.306-5.63-18.581-7.222-29.756L2.153,88.632A208.8,208.8,0,0,1,.908,49.538Z'
            transform='translate(-0.279 -0.279)'
            fill='none'
            stroke='#00b1dc'
            strokeMiterlimit='10'
            strokeWidth='1'
          />
        </g>
      </g>
      <g id='Raggruppa_2096' data-name='Raggruppa 2096'>
        <path
          id='Tracciato_1390'
          data-name='Tracciato 1390'
          d='M2552.313,9354.679h82.537'
          transform='translate(-15.812 -9305.756) rotate(14)'
          fill='none'
          stroke='#e80000'
          strokeWidth='3'
        />
        <path
          id='Tracciato_1376'
          data-name='Tracciato 1376'
          d='M1753.187,9506.242l4.64,4.977,4.7-4.977'
          transform='matrix(-0.259, 0.966, -0.966, -0.259, 9838.28, 1152.591)'
          fill='none'
          stroke='#e80000'
          strokeWidth='3'
        />
        <path
          id='Tracciato_1391'
          data-name='Tracciato 1391'
          d='M1753.187,9506.242l4.64,4.977,4.7-4.977'
          transform='matrix(0.174, -0.985, 0.985, 0.174, -9394.85, 485.454)'
          fill='none'
          stroke='#e80000'
          strokeWidth='3'
        />
      </g>
    </g>
  </Icon>
);

export const FootLengthIcon = (props: IconProps) => (
  <Icon
    xmlns='http://www.w3.org/2000/svg'
    width='93.518'
    height='167.303'
    viewBox='0 0 93.518 167.303'
    {...props}
  >
    <defs>
      <clipPath id='clipPath'>
        <rect
          id='Rettangolo_1222'
          data-name='Rettangolo 1222'
          width='77.255'
          height='167.303'
          transform='translate(-4 -4.779)'
          fill='none'
          stroke='#00b1dc'
          strokeWidth='1'
        />
      </clipPath>
    </defs>
    <g
      id='Raggruppa_2092'
      data-name='Raggruppa 2092'
      transform='translate(20.263 4.779)'
    >
      <g
        id='Raggruppa_2091'
        data-name='Raggruppa 2091'
        clipPath='url(#clipPath)'
      >
        <path
          id='Tracciato_1386'
          data-name='Tracciato 1386'
          d='M.908,49.538a77.208,77.208,0,0,0,0-8.383C.618,38.83-1.042,30.447,7.922,27.542c0,0-1.992-9.463,4.316-11.288,0,0,4.4-1.328,7.138,2.075,0,0-.913-9.13,4.98-9.877,0,0,3.735-.581,5.644,1.743,0,0,2.324-4.731,6.723-4.316a6.884,6.884,0,0,1,6.059,5.894c.166,1.494,1.245,17.347,1.245,17.347l2.407,1.743,1.66-.083L45.024,15.423C44.045,8.492,44.442,2.447,51.415.649c4.618-.591,8.417.512,10.791,4.565,3.416,6.1,5.341,12.086,5.312,17.928A96.71,96.71,0,0,0,68.182,46.3c.828,7.306-2.346,14.833-7.968,22.494Q50.49,90.437,49.465,117.268l.623,18.676c-.225,10.506-3.876,18.3-13.571,21.29-9.275,1.7-16.292-2.908-21.415-12.824-3.454-8.306-5.63-18.581-7.222-29.756L2.153,88.632A208.8,208.8,0,0,1,.908,49.538Z'
          transform='translate(-0.279 -0.279)'
          fill='none'
          stroke='#00b1dc'
          strokeMiterlimit='10'
          strokeWidth='1'
        />
      </g>
    </g>
    <path
      id='Tracciato_1387'
      data-name='Tracciato 1387'
      d='M2561.849,9357.493v158.485'
      transform='translate(-2488.737 -9353.221)'
      fill='none'
      stroke='#e80000'
      strokeWidth='3'
    />
    <path
      id='Tracciato_1388'
      data-name='Tracciato 1388'
      d='M2552.313,9354.679h82.537'
      transform='translate(-2552.312 -9350.406)'
      fill='none'
      stroke='#e80000'
      strokeWidth='3'
    />
    <path
      id='Tracciato_1389'
      data-name='Tracciato 1389'
      d='M2552.313,9354.679h82.538'
      transform='translate(-2552.313 -9191.921)'
      fill='none'
      stroke='#e80000'
      strokeWidth='3'
    />
  </Icon>
);
