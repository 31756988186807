import { Flex, Text } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageContainer } from '../components/container';
import { FacebookLoginIcon, GoogleLoginIcon } from '../components/icons/misc';
import SimpleInputText from '../components/inputs/input-text';
import SocialButton from '../components/inputs/social-button';
import StyledButton from '../components/inputs/styled-button';
import { SectionTitle } from '../components/section-title';
import { BASE_PATH } from '../config';
import {
  addToken,
  getAuthApi,
  notifyServerError,
} from '../services/api/open-api';
import { useBookingStore, useNotification, useUserStore } from '../store';
import { LoginFormType } from '../types';
import shallow from 'zustand/shallow';

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormType>();
  const { login, setRegisterData, loginBackUrl, isLoggedIn } = useUserStore(
    ({ login, setRegisterData, loginBackUrl, token }) => ({
      login,
      setRegisterData,
      loginBackUrl,
      isLoggedIn: !!token,
    }),
    shallow
  );
  const { bookingInfo } = useBookingStore(
    ({ bookingInfo }) => ({ bookingInfo }),
    shallow
  );
  const navigate = useNavigate();
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);

  const isDateSelected =
    !!bookingInfo?.startDate && !!bookingInfo?.endDate && !!bookingInfo?.areaId;

  const query = new URLSearchParams(useLocation().search);

  const tokenLogin = async (token: string) => {
    const api = getAuthApi();
    const {
      data: { user },
    } = await api.authUser(addToken(token));
    if (user) {
      login(user, token);
    }
    navigate(loginBackUrl ?? '/');
  };

  useEffect(() => {
    if (isLoggedIn) {
      return navigate('/');
    }
    (async () => {
      if (query) {
        const sessionToken = query.get('sessionToken');
        let error = query.get('error');
        try {
          if (sessionToken) {
            await tokenLogin(sessionToken);
          }
        } catch (e: any) {
          error = e;
        }

        if (error) {
          return notifyServerError(error, notify);
        }
      }
    })();
  }, []);

  const onSubmit = useCallback(
    async (formData: LoginFormType) => {
      try {
        const api = getAuthApi();
        const {
          data: { sessionToken },
        } = await api.authLogin({
          identifier: formData.email,
          type: 'email',
          password: formData.password,
        });

        await tokenLogin(sessionToken);
      } catch (e: any) {
        if (e.response.status === 404) {
          return notify('error', {
            title: 'Errore',
            text: "L'email inserita è errata o non è stata verificata.",
          });
        }
        if (e.response.status === 401) {
          return notify('error', {
            title: 'Errore',
            text: 'La password inserita non è corretta.',
          });
        }
      }
    },
    [login]
  );

  return (
    <PageContainer>
      <SectionTitle hasBack text='Accedi' mt={{ base: '15px', md: '0px' }} />
      <Flex maxW='315px' w='100%' mx='auto' pt='30px'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex mb='20px'>
            <SimpleInputText
              {...register('email', { required: true })}
              label='Email'
              type='email'
              errors={errors.email}
              placeholder='paolog@gmail.com'
            />
          </Flex>
          <SimpleInputText
            {...register('password', { required: true })}
            label='Password'
            isPassword
            placeholder='*********'
            errors={errors.password}
          />

          <StyledButton mt='20px !important' type='submit'>
            Accedi
          </StyledButton>
          <Text
            fontSize='15px'
            lineHeight='1.2'
            mb='20px'
            mt='20px'
            w='100%'
            textAlign='center'
          >
            Oppure
          </Text>
          <SocialButton
            onClick={() => window.location.assign(`${BASE_PATH}/auth/google`)}
            leftIcon={
              <GoogleLoginIcon h='24px' w='24px' maxW='24px' maxH='24px' />
            }
          >
            Accedi con Google
          </SocialButton>
          <SocialButton
            mt='10px'
            onClick={() => window.location.assign(`${BASE_PATH}/auth/facebook`)}
            leftIcon={<FacebookLoginIcon h='24px' w='13px' maxH='24px' />}
          >
            Accedi con Facebook
          </SocialButton>
          {loginBackUrl === '/profiles' && isDateSelected && (
            <StyledButton
              mt='55px'
              bg='accent'
              color='black'
              boxShadow='0 18px 40px #E6FF0059'
              onClick={() => navigate('/profiles')}
            >
              Continua senza registrarmi
            </StyledButton>
          )}

          <Flex
            fontSize='15px'
            mt='30px'
            mb='10px'
            w='100%'
            align='center'
            justify='center'
          >
            <Text color='white' lineHeight='1.2' mr='4px'>
              Ancora non sei membro?
            </Text>
            <Text
              lineHeight='1.2'
              decoration='underline'
              color='accent'
              cursor='pointer'
              onClick={() => {
                setRegisterData({});
                navigate('/register');
              }}
            >
              Registrati
            </Text>
          </Flex>
          <Flex
            fontSize='15px'
            pb='43px'
            w='100%'
            align='center'
            justify='center'
          >
            <Text color='white' lineHeight='1.2' mr='4px'>
              Non ricordi la password?
            </Text>
            <Text
              lineHeight='1.2'
              decoration='underline'
              color='accent'
              cursor='pointer'
              onClick={() => {
                navigate('/forgot-password');
              }}
            >
              Recupera
            </Text>
          </Flex>
        </form>
      </Flex>
    </PageContainer>
  );
};
