import {
  BoxProps,
  Flex,
  FlexProps,
  forwardRef,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';
import { PrintServices } from '../../services/app/print-services';
import { PreviewDataType, UserProfile } from '../../types';
import { DiscountLine } from '../icons/misc';
import { StyledCheckbox } from '../inputs/checkbox';
import { useMediaQuery } from 'react-responsive';

export const EquipmentSelectionCheckbox = forwardRef<
  {
    type: string;
    kitInfo: string[];
    checkedCondition?: boolean;
    errors?: FieldError | undefined;
    prices: {
      board: { fullValue: number; value: number };
      ski: { fullValue: number; value: number };
    };
  } & FlexProps,
  'div'
>(({ type, kitInfo, checkedCondition, errors, prices, ...props }, ref) => {
  const LowRes = useMediaQuery({ query: '(max-width:368px) ' });
  return (
    <Flex
      maxW='283px'
      h={
        kitInfo.length > 2 && type === 'SKI'
          ? '93px'
          : kitInfo.length > 2
          ? '78px'
          : '68px'
      }
      w='100%'
      borderRadius='10px'
      borderWidth='1px'
      borderColor={errors ? 'warning' : 'brand.500'}
      bgColor={checkedCondition ? 'rgba(0,177,220,0.08)' : 'white'}
      cursor='pointer'
      boxShadow={
        checkedCondition
          ? 'none'
          : '0 18px 40px var(--chakra-colors-brandTransparent-350)'
      }
      {...props}
      ref={ref}
    >
      <StyledCheckbox
        w='24px'
        h='27px'
        m='15px 11.5px 0px 15px'
        checkedCondition={checkedCondition}
        isShadowed
        isError={false}
      />
      <HStack w='100%' justify='space-between' spacing={0} align='top'>
        <Flex
          flexDirection='column'
          gap={0}
          w='100%'
          maxW={{ sm: '136px' }}
          pt={kitInfo.length > 1 ? '15px' : '5px'}
        >
          <Text
            fontSize={{ base: '14px', sm: '15px' }}
            lineHeight={kitInfo.length > 1 ? '12px' : '17px'}
            whiteSpace={kitInfo.length > 1 ? 'nowrap' : undefined}
          >
            {kitInfo[0]}
          </Text>
          {kitInfo.map(
            (x, i) =>
              i !== 0 && (
                <Text
                  key={'info' + x + i}
                  fontSize={{ base: '10px', sm: '11px' }}
                  color='brand.500'
                  lineHeight='15px'
                  pt={i === 1 ? '2px' : '0px'}
                  whiteSpace='nowrap'
                >
                  {kitInfo[i]}
                </Text>
              )
          )}
        </Flex>
        <Flex
          flexDirection='column'
          w='100%'
          my='auto'
          position='relative'
          maxW={{ base: '75px', sm: '110px' }}
          top='0px'
          left='0px'
          pt={kitInfo.length > 2 ? '15px' : '5px'}
          pl='0px'
        >
          <DiscountLine
            position='absolute'
            h={{ base: '18px', sm: '30px' }}
            w={{ base: '65px', sm: '90%' }}
            left={{ base: '7px', sm: '5px' }}
          />
          <Text
            w='100%'
            fontSize={{ base: '15px', sm: '20px' }}
            lineHeight={{ base: '15px', sm: '20px' }}
            textAlign='right'
            pr={{ base: '5px', sm: '15px' }}
            whiteSpace='nowrap'
          >
            {kitInfo.length === 1 ? '+' : ''}
            {type === 'SKI'
              ? PrintServices.number(prices.ski.fullValue ?? 0)
              : PrintServices.number(prices.board.fullValue ?? 0)}
          </Text>

          <Text
            fontSize={{ base: '15px', sm: '20px' }}
            lineHeight={{ base: '15px', sm: '20px' }}
            textAlign='right'
            pr={{ base: '5px', sm: '15px' }}
            whiteSpace='nowrap'
          >
            {kitInfo.length === 1 ? '+' : ''}
            {type === 'SKI'
              ? PrintServices.number(prices.ski.value)
              : PrintServices.number(prices.board.value)}
          </Text>
          <Text
            fontSize={{ base: '8px', sm: '11px' }}
            color='brand.500'
            textAlign='right'
            lineHeight='15px'
            pr={{ base: '5px', sm: '15px' }}
            pt='1px'
          >
            al giorno
          </Text>
        </Flex>
      </HStack>
    </Flex>
  );
});

export const PreferencesSelectionCheckbox = forwardRef<
  {
    previewData: PreviewDataType;
    kitInfo: string[];
    checkedCondition?: boolean;
  } & BoxProps,
  'div'
>(({ previewData, kitInfo, checkedCondition, ...props }, ref) => {
  return (
    <HStack
      maxW='283px'
      h='50px'
      w='100%'
      borderRadius='10px'
      borderWidth='1px'
      borderColor='brand.500'
      bgColor='white'
      cursor='pointer'
      boxShadow='0 18px 40px var(--chakra-colors-brandTransparent-350)'
      pr='21px'
      justifyContent='space-between'
      spacing={0}
      {...props}
      ref={ref}
    >
      <Text pl='20px'>{kitInfo[0]}</Text>

      <StyledCheckbox
        checkedCondition={checkedCondition}
        variant='preference'
        isError={false}
      />
    </HStack>
  );
});

export const ProfileSelectionCheckbox = forwardRef<
  {
    checkedCondition?: boolean;
    profile: UserProfile;
    profileIndex: number;
    setUserValues: (ProfileIndex: number) => void;
    selectedProfileIds: (string | undefined)[] | undefined;
  } & BoxProps,
  'div'
>(
  (
    {
      checkedCondition,
      profile,
      profileIndex,
      setUserValues,
      selectedProfileIds,
      ...props
    },
    ref
  ) => {
    return (
      <HStack
        maxW='283px'
        h='76px'
        w='100%'
        borderRadius='10px'
        borderWidth='1px'
        borderColor='brand.500'
        bgColor='white'
        cursor='pointer'
        boxShadow='0 18px 40px var(--chakra-colors-brandTransparent-350)'
        pr='21px'
        justifyContent='space-between'
        spacing={0}
        {...props}
        ref={ref}
        onClick={() => {
          if (!selectedProfileIds?.includes(profile.id!)) {
            setUserValues(profileIndex);
          }
        }}
      >
        <VStack pl='20px' m='0px' spacing={0} alignItems='start'>
          <Text textAlign='left'>
            {profile.firstName + ' ' + profile.lastName}
          </Text>
        </VStack>
        <StyledCheckbox
          checkedCondition={selectedProfileIds?.includes(profile.id!)}
          variant='preference'
          isError={false}
        />
      </HStack>
    );
  }
);
