//import { SavedProfileForm } from "../components/forms/profile-form";
import { useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import AccountBookings from '../components/account/account.bookings';
import AccountData from '../components/account/account.data';
import AccountProfiles from '../components/account/account.profiles';
import { PageContainer } from '../components/container';
import { AccountTabButton, ButtonIndexedTabs } from '../components/forms/tabs';
import {
  EditAccountIcon,
  OrdersIcon,
  ProfilesIcon,
} from '../components/icons/navigation';
import { SectionTitle } from '../components/section-title';
import { useUserStore } from '../store';

export const Account = () => {
  const { user } = useUserStore(({ user }) => ({ user }), shallow);
  const urlParams = new URLSearchParams(useLocation().search);

  const userProfile = user?.profiles?.find((profile) => profile.main);

  const MIDDLE_MARGIN = { base: '4px', md: '8px' };
  const SIDE_MARGIN = { base: '0px', md: '22px' };

  const defaultIndex = () => {
    switch (urlParams.get('section')) {
      case 'info': {
        return 1;
      }
      case 'profiles': {
        return 2;
      }
      case 'bookings':
      default: {
        return 0;
      }
    }
  };

  return (
    <PageContainer>
      <SectionTitle
        mx='auto'
        mb='35px'
        hasBack
        backIsTop
        textAlign='left'
        text={`${userProfile?.firstName ?? 'Nome'} ${
          userProfile?.lastName ?? 'Cognome'
        }`}
      />
      <ButtonIndexedTabs
        zIndex={1}
        mb='19px'
        defaultIndex={defaultIndex()}
        childrens={[
          {
            value: 'Orders',
            button: (
              <AccountTabButton
                icon={<OrdersIcon w='30px' h='30px' color='brand.500' />}
                ml={SIDE_MARGIN}
                mr={MIDDLE_MARGIN}
                text='Ordini'
              />
            ),
            panel: () => <AccountBookings />,
          },
          {
            value: 'Account',
            button: (
              <AccountTabButton
                icon={<EditAccountIcon w='30px' h='30px' color='brand.500' />}
                mx={MIDDLE_MARGIN}
                text='Account'
              />
            ),
            panel: () => <AccountData />,
          },
          {
            value: 'Profiles',
            button: (
              <AccountTabButton
                icon={<ProfilesIcon w='30px' h='30px' color='brand.500' />}
                text='Altri profili'
                ml={MIDDLE_MARGIN}
                mr={SIDE_MARGIN}
              />
            ),
            panel: () => <AccountProfiles />,
          },
        ]}
      />
    </PageContainer>
  );
};
