import { Flex, Text, TextProps } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';
import BackLink from './backlink';

export const SectionTitle = ({
  text,
  hasBack,
  backIsTop,
  toPage,
  ...props
}: {
  text: string;
  hasBack?: boolean;
  toPage?: string;
  backIsTop?: boolean;
} & TextProps) => {
  return (
    <Flex
      w='100%'
      m='0 auto'
      flexDirection={{ base: 'column', md: 'row' }}
      alignItems={{ md: 'center' }}
      position='relative'
      mt={backIsTop ? '10px' : '0px'}
    >
      {hasBack && (
        <BackLink
          toPage={toPage ?? undefined}
          position={{ base: 'initial', md: 'absolute' }}
          left='0'
          top={backIsTop ? '-50px' : '0px'}
        />
      )}
      <Text
        w='100%'
        justifyContent='center'
        fontSize='25px'
        lineHeight='1.2'
        variant='bold'
        textAlign='center'
        mt={backIsTop ? '20px' : '0px'}
        {...props}
      >
        {text}
      </Text>
    </Flex>
  );
};
