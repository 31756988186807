import {
  AccordionButton,
  Text,
  AccordionIcon,
  ButtonProps,
  Flex,
  forwardRef,
  HStack,
  VStack,
  useMergeRefs,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { AccordionButtonProps } from '../../types';
import { StyledCheckbox } from '../inputs/checkbox';

export const ProfileFormAccordionButton = forwardRef<
  AccordionButtonProps & ButtonProps,
  'button'
>(
  (
    {
      btnType,
      details,
      detailsMax,
      borderBottomBool,
      proceedRef,
      previewData,
      label,
      profileId,
      isSummary,
      errors,
      equipmentRef,
      ...props
    },
    ref
  ) => {
    const accordionButtonRef = useRef<HTMLButtonElement | null>(null);
    const refs = useMergeRefs(proceedRef, accordionButtonRef, equipmentRef);

    const parsedDetails = Object.keys(details).reduce(
      (acc, next) =>
        acc +
        (Object.keys(details[next as keyof typeof details] || {}).length !== 0
          ? 1
          : 0),
      //fullName counts as 2 fields
      Object.keys(details)[0] === 'fullName' ? 1 : 0
    );

    return (
      <AccordionButton
        w='100%'
        h='60px'
        bgColor='white'
        borderBottomWidth={borderBottomBool ? '1px' : '0px'}
        borderBottomColor='brandTransparent.500'
        p='0px'
        ref={refs}
        onClick={() => {
          setTimeout(() => {
            accordionButtonRef.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          }, 400);
        }}
        _disabled={{ opacity: '100%', cursor: 'default' }}
        _hover={isSummary ? {} : { bgColor: '#00B1DC20' }}
      >
        {!isSummary && (
          <StyledCheckbox
            w='24px'
            h='27px'
            m='0px 12.5px 0px 20px'
            checkedCondition={detailsMax <= parsedDetails && parsedDetails > 0}
            isShadowed
            isError={
              Object.values(errors).filter((error) => !!error).length > 0
            }
            color='yellow'
          />
        )}
        <HStack
          w='100%'
          justifyContent='space-between'
          overflowX='scroll'
          className='no-scrollbar'
        >
          <VStack w='100%' spacing={0} pl={isSummary ? '20px' : ''}>
            <Text textAlign='left' fontSize='15px' w='100%' lineHeight='18px'>
              {label}
            </Text>

            {parsedDetails > 0 ? (
              <HStack
                justifyContent='left'
                w='100%'
                overflowX='scroll'
                className='no-scrollbar'
              >
                {Object.values(details).map(
                  (x, i) =>
                    !!x && (
                      <Text
                        key={'detailKey_' + profileId + '_' + i}
                        fontSize='10px'
                        borderRadius='6px'
                        bgColor='rgba(0,177,220,0.16)'
                        color='brand.500'
                        lineHeight='13px'
                        p='4px'
                        h='21px'
                        whiteSpace='nowrap'
                      >
                        {'' + x}
                      </Text>
                    )
                )}
              </HStack>
            ) : (
              <HStack w='100%'>
                <Text
                  textAlign='left'
                  color='brand.500'
                  fontSize='10px'
                  lineHeight='13px'
                  bgColor={isSummary ? 'rgba(0,177,220,0.16)' : ''}
                  borderRadius={isSummary ? '6px' : ''}
                  h='21px'
                  p={isSummary ? '4px' : '4px 0px 0px 2px'}
                >
                  {isSummary
                    ? 'Nessuna'
                    : !previewData?.boots &&
                      !previewData?.main &&
                      !previewData?.helmet &&
                      btnType === 'measures'
                    ? 'Selezionare prima attrezzatura'
                    : 'Seleziona'}
                </Text>
              </HStack>
            )}
          </VStack>
        </HStack>
        {!isSummary && (
          <Flex w='60px' h='60px' align='center'>
            <AccordionIcon
              color='brand.500'
              w='34px'
              h='34px'
              mr='12px'
              zIndex={1}
            />
          </Flex>
        )}
      </AccordionButton>
    );
  }
);
