import { Flex, FlexProps, Image } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

const BackLink = ({ toPage, ...props }: { toPage?: string } & FlexProps) => {
  const navigate = useNavigate();
  return (
    <Flex
      zIndex='1'
      w='48px'
      h='48px'
      borderRadius='24px'
      bgColor='rgba(0,177,220,0.11)'
      align='center'
      justifyContent='center'
      cursor='pointer'
      onClick={() => {
        if (!!toPage) {
          navigate(toPage);
          return;
        }
        navigate(-1);
      }}
      {...props}
    >
      <Image src='/backgrounds/back-arrow.svg' />
    </Flex>
  );
};

export default BackLink;
