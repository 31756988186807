import {
  Box,
  BoxProps,
  chakra,
  Flex,
  FlexProps,
  forwardRef,
  HStack,
  InputProps,
  Link,
  Text,
  useCheckbox,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useState } from 'react';
import {
  UseFormRegisterReturn,
  FieldError,
  UseFormGetValues,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { PrintServices } from '../../services/app/print-services';
import { useBookingStore, useUserStore } from '../../store';
import { RegisterFormType } from '../../types';
import {
  CheckboxError,
  CheckboxOff,
  CheckboxOn,
  LightBlueCheckboxOn,
  NoShadowCheckboxOn,
  YellowCheckboxOn,
} from '../icons/inputs';

export const StyledCheckbox = forwardRef<
  {
    isClickable?: boolean;
    checkedCondition?: boolean;
    variant?: string;
    isShadowed?: boolean;
    color?: string;
    isError: boolean;
  } & FlexProps,
  'div'
>(
  (
    {
      isClickable,
      checkedCondition,
      variant,
      isShadowed,
      color,
      isError,
      ...props
    },
    ref
  ) => {
    const [isChecked, setIsChecked] = useState(false);
    return (
      <Flex
        onClick={() => {
          if (isClickable) {
            setIsChecked(!isChecked);
          }
        }}
        position='relative'
        {...props}
        pt={variant === 'preference' ? '3.5px' : '0px'}
        ref={ref}
      >
        {!isError && (
          <>
            {(isClickable && isChecked) || checkedCondition ? (
              isShadowed ? (
                color === 'yellow' ? (
                  <YellowCheckboxOn w='24px' h='24px' />
                ) : (
                  <LightBlueCheckboxOn w='24px' h='24px' />
                )
              ) : (
                <NoShadowCheckboxOn w='24px' h='24px' />
              )
            ) : variant !== 'preference' ? (
              <>
                <Box
                  position='absolute'
                  w='21px'
                  h='21px'
                  ml='2px'
                  mr='2px'
                  borderWidth='2.5px'
                  borderColor='#00B1DC'
                  borderRadius='11px'
                  zIndex={10}
                />
                <Box
                  w='22px'
                  h='20px'
                  mt='4.5px'
                  mr='2px'
                  ml='1px'
                  //pr="2px"
                  bgColor='rgba(0,177,220,0.11)'
                  borderRadius='11px'
                  zIndex={0}
                />
              </>
            ) : (
              <Box
                w='20px'
                h='20.5px'
                mr='1.5px'
                ml='2.5px'
                mb='3.5px'
                borderWidth='2px'
                borderColor='#00B1DC'
                borderRadius='10.5px'
                zIndex={10}
              />
            )}
          </>
        )}
        {isError && <CheckboxError w='24px' h='24px' />}
      </Flex>
    );
  }
);

export const FinalizeCheckbox = forwardRef<
  {
    checked: boolean;
    label: string;
    isSummary?: boolean;
    type: 'storeShoe' | 'insurance';
  } & BoxProps,
  'div'
>(({ checked, label, type, isSummary, ...props }, ref) => {
  const { profilesCount, bookingInfo, pricesData } = useBookingStore(
    ({ profilesCount, bookingInfo, pricesData }) => ({
      profilesCount,
      bookingInfo,
      pricesData,
    }),
    shallow
  );

  const additionalCostCalc = () => {
    return (
      (type === 'storeShoe'
        ? pricesData?.STORESHOE?.fullValue ?? 0
        : pricesData?.INSURANCE?.fullValue ?? 0) *
      profilesCount *
      Math.ceil(
        (new Date(bookingInfo?.endDate!).getTime() +
          1 -
          new Date(bookingInfo?.startDate!).getTime()) /
          (1000 * 60 * 60 * 24)
      )
    );
  };

  return (
    <>
      <HStack
        w='100%'
        spacing={0}
        align='flex-start'
        maxW='312px'
        mx='auto'
        cursor={isSummary ? '' : 'pointer'}
        {...props}
        ref={ref}
      >
        <Box w='30px' h='30px' pt='6.5px'>
          {checked ? (
            <CheckboxOn w='30px' h='30px' />
          ) : (
            <CheckboxOff w='30px' h='30px' />
          )}
        </Box>
        <VStack w='100%' spacing={0} maxW='282px' pl='10px'>
          <HStack justifyContent='space-between' spacing={0} w='100%'>
            <Text
              w='100%'
              textAlign='left'
              textStyle='book'
              fontSize='10px'
              lineHeight='10px'
            >
              <strong>{label}</strong>
            </Text>
            {!isSummary && (
              <Text w='100%' textStyle='book' fontSize='20px' textAlign='right'>
                + {PrintServices.number(additionalCostCalc())}
              </Text>
            )}
          </HStack>
          <Text w='100%' fontSize='10px' lineHeight='13px' textAlign='left'>
            {type === 'storeShoe'
              ? 'Cos’è il deposito scarpe?'
              : 'Cos’è la polizza annullamento?'}
          </Text>
          <Text w='100%' fontSize='10px' textAlign='left' lineHeight='13px'>
            {type === 'storeShoe'
              ? 'Lascia le tue scarpe in custodia tutto il'
              : 'Puoi annullare la tua prenotazione entro '}
          </Text>
          <Text w='100%' fontSize='10px' textAlign='left' lineHeight='13px'>
            {type === 'storeShoe'
              ? 'tempo In cui sei impegnato in pista.'
              : '24 ore prima dell’inizio dell’esperienza. '}
          </Text>

          {!isSummary && (
            <Text w='100%' fontSize='10px' textAlign='left' pt='8px'>
              NB:{' '}
              {PrintServices.number(
                type === 'storeShoe'
                  ? pricesData?.STORESHOE?.fullValue!
                  : pricesData?.INSURANCE?.fullValue!
              )}{' '}
              a persona x numero di giorni
            </Text>
          )}
        </VStack>
      </HStack>
    </>
  );
});

export const TOSCheckboxItem = forwardRef<
  {
    registerProps?: UseFormRegisterReturn;
    errors: FieldError | undefined;
    option: { title: string };
    checked: boolean;
    formInfo?: UseFormGetValues<RegisterFormType>;
  } & InputProps,
  'div'
>(({ registerProps, errors, option, checked, formInfo, ...props }, ref) => {
  const { setRegisterData } = useUserStore(
    ({ setRegisterData }) => ({ setRegisterData }),
    shallow
  );
  const { state, getInputProps, getCheckboxProps, htmlProps } =
    useCheckbox(registerProps);
  const navigate = useNavigate();
  return (
    <chakra.label {...htmlProps} cursor='pointer'>
      <input {...getInputProps({ ref })} hidden />
      <Box {...getCheckboxProps()} justifyContent='center'>
        <Flex align='center' onClick={props.onClick}>
          <StyledCheckbox
            variant='preference'
            checkedCondition={checked}
            isError={false}
          />
          <VStack ml='10px'>
            <Text
              fontSize='15px'
              color={errors ? 'red' : 'brand.500'}
              lineHeight={1.2}
              w='100%'
            >
              {option.title}
            </Text>
            <Text
              fontSize='10px'
              mt='3px'
              color={errors ? 'red' : 'black'}
              lineHeight={1.3}
            >
              {'Puoi visualizzare termini e condizioni cliccando '}
              <Link
                onClick={() => {
                  setRegisterData(formInfo?.() ?? {});
                  setRegisterData(formInfo?.() ?? {});
                  navigate('/terms-of-service');
                }}
                color='brand.500'
              >
                QUI
              </Link>
            </Text>
          </VStack>
        </Flex>
      </Box>
    </chakra.label>
  );
});

export const CheckboxItem = React.forwardRef<
  HTMLInputElement,
  {
    registerProps?: UseFormRegisterReturn;
    errors: FieldError | undefined;
    option: { title: string; subtitle: string };
  } & FlexProps
>(({ registerProps, errors, option, ...props }, ref) => {
  const { state, getInputProps, getCheckboxProps, htmlProps } =
    useCheckbox(registerProps);

  return (
    <chakra.label {...htmlProps} w='100%' cursor='pointer'>
      <input {...getInputProps({ ref })} hidden />
      <Flex {...getCheckboxProps()} w='100%' {...props}>
        {state.isChecked ? (
          <CheckboxOn w='30px' h='30px' />
        ) : (
          <CheckboxOff w='30px' h='30px' />
        )}
        <Box>
          <Text fontSize='10px' ml='10px' lineHeight={1.3}>
            {option.title}
          </Text>

          <Text fontSize='10px' lineHeight={1.3} ml='10px' mt='2px'>
            {option.subtitle}
          </Text>
        </Box>
      </Flex>
    </chakra.label>
  );
});
