import { Flex, FlexProps } from '@chakra-ui/react';

export const Wrapper = ({ children, ...rest }: FlexProps) => (
  <Flex
    w='100%'
    p='30px 30px 0px 30px'
    flexDirection='column'
    mx='auto'
    {...rest}
  >
    {children}
  </Flex>
);

export const WhiteDomeWrapper = ({ children, ...rest }: FlexProps) => (
  <Flex
    borderTopRadius='37.5px'
    bg='white'
    w='100%'
    p='20px 34px 40px 34px'
    mx='auto'
    flexDirection='column'
    {...rest}
  >
    {children}
  </Flex>
);
