import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useBookingStore, useUserStore } from '../store';
import { HeaderContainer } from './container';
import { LogoIcon } from './icons/brand';
import {
  CrossIcon,
  FacebookIcon,
  InstagramIcon,
  MailIcon,
  MenuIcon,
} from './icons/misc';
import StyledButton from './inputs/styled-button';

export const Header = () => {
  const { logout, isLoggedIn, setLoginBackUrl } = useUserStore(
    (s) => ({
      logout: s.logout,
      isLoggedIn: !!s.token,
      setLoginBackUrl: s.setLoginBackUrl,
    }),
    shallow
  );

  const { setBookingInfo, setProfilesCount, setProfilesToSave } =
    useBookingStore(
      ({ setBookingInfo, setProfilesCount, setProfilesToSave }) => ({
        setBookingInfo,
        setProfilesCount,
        setProfilesToSave,
      }),
      shallow
    );
  const location = useLocation();
  const navigate = useNavigate();

  const menuProps = useDisclosure();

  const MenuElement: FC<{
    text: string;
    url: string;
    onClick?: () => void;
  }> = ({ text, url, onClick }) => {
    return (
      <Text
        cursor='pointer'
        onClick={() => {
          onClick?.();

          if (window.location.pathname === '/account') {
            navigate(url);
            window.location.reload();
            return;
          }
          navigate(url);
          menuProps.onClose();
        }}
        fontSize='25px'
        textAlign='center'
        mb='30px'
      >
        <strong>{text}</strong>
      </Text>
    );
  };

  return (
    <HeaderContainer>
      <Drawer size='full' {...menuProps}>
        <DrawerOverlay />
        <DrawerContent bg='brand.500'>
          <DrawerBody p='0px 0px 20px 0px'>
            <HStack justifyContent='right' mb={{ base: '75px', md: '143px' }}>
              <CrossIcon
                color='white'
                cursor='pointer'
                h='21px'
                w='21px'
                mr='30px'
                mt='36px'
                onClick={menuProps.onClose}
              />
            </HStack>

            <Flex color='white' direction='column'>
              <MenuElement text='Home' url='/' />
              <MenuElement text='Account' url='/account?section=info' />
              <MenuElement
                text='Prenotazioni'
                url='/account?section=bookings'
              />
              <MenuElement
                text='Log out'
                url='/'
                onClick={() => {
                  logout();
                  setBookingInfo({});
                  setProfilesToSave([]);
                  setProfilesCount(1);
                }}
              />
              <Flex mx='auto' gap='61px' mt={{ base: '75px', md: '123px' }}>
                <InstagramIcon h='27px' w='auto' />
                <FacebookIcon h='25px' w='auto' />
                <Link href='mailto:info@info.rent'>
                  <MailIcon h='21px' w='auto' />
                </Link>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex
        w='100%'
        minH={{ base: '70px', md: '120px' }}
        justify='space-between'
        bg='transparent'
      >
        <LogoIcon
          cursor='pointer'
          h='17px'
          mt='38.5px'
          w='auto'
          onClick={() => navigate('/')}
        />
        {isLoggedIn ? (
          <MenuIcon
            cursor='pointer'
            mt='38px'
            h='16px'
            w='auto'
            onClick={() => menuProps.onOpen()}
          />
        ) : (
          location.pathname !== '/login' &&
          location.pathname !== '/register' && (
            <StyledButton
              boxShadow='0 18px 40px  #E6FF0059'
              w={{ base: '108px', md: '181px' }}
              bg='accent'
              color='black'
              m='0'
              mt={{ md: '22px', base: '24px' }}
              h={{ base: '42px', md: '50px' }}
              onClick={() => {
                setLoginBackUrl(location.pathname);
                navigate('/login');
              }}
            >
              Accedi
            </StyledButton>
          )
        )}
      </Flex>
    </HeaderContainer>
  );
};
