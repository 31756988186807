import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { PageContainer } from '../components/container';
import SimpleInputText from '../components/inputs/input-text';
import StyledButton from '../components/inputs/styled-button';
import { getAuthApi, notifyServerError } from '../services/api/open-api';
import { useNotification } from '../store';

export const ResetPassword = () => {
  const [password, setPassword] = useState<string>();
  const [passwordRepeat, setPasswordRepeat] = useState<string>();
  const navigate = useNavigate();
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);
  const code = new URLSearchParams(useLocation().search).get('code');

  const resetPassword = async () => {
    try {
      if (!code) {
        return notify('error', { title: 'Errore', text: 'Codice non valido' });
      }
      if (!password) {
        return notify('error', {
          title: 'Errore',
          text: 'La password non è valida',
        });
      }

      if (password !== passwordRepeat) {
        return notify('error', {
          title: 'Errore',
          text: 'Le password non coincidono',
        });
      }
      await getAuthApi().authForgotPassword({ code, newPass: password });
      notify('success', {
        title: 'Password modificata',
        text: 'Password cambiata con successo. Torna indietro per effettuare il login.',
        onClose: () => navigate('/login'),
      });
    } catch (e: any) {
      if (e.response.status === 404) {
        return notify('error', {
          title: 'Errore',
          text: 'Codice non valido. Richiedere un nuovo codice.',
          onClose: () => navigate('/login'),
        });
      }
      notifyServerError(e, notify, () => navigate('/login'));
    }
  };

  return (
    <PageContainer>
      <Flex
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        height='100%'
        pb='40%'
      >
        <SimpleInputText
          onChange={(e) => setPassword(e.target.value)}
          isPassword
          label='Password'
          placeholder='*******'
          maxW='315'
        />
        <Flex w='100%' justify='center' mt='20px'>
          <SimpleInputText
            label='Ripeti password'
            isPassword
            onChange={(e) => setPasswordRepeat(e.target.value)}
            placeholder='*******'
            maxW='315'
          />
        </Flex>
        <StyledButton mt='30px' onClick={resetPassword}>
          Cambia password
        </StyledButton>
      </Flex>
    </PageContainer>
  );
};
