import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import { useNavigate } from 'react-router-dom';
import { CTAUnderline1, CTAUnderline2 } from '../components/icons/misc';

import { useEffect, useRef, useState } from 'react';
import 'react-date-range/dist/styles.css'; // date range main style file
import 'react-date-range/dist/theme/default.css'; // date range theme css file
import { useMediaQuery } from 'react-responsive';
import shallow from 'zustand/shallow';
import { PageContainer } from '../components/container';
import {
  DateRangeButton,
  DateRangeCalendar,
} from '../components/forms/date.selector';
import { ProfilesInput } from '../components/inputs/person-count';
import { CustomSelect } from '../components/inputs/select-input';
import StyledButton from '../components/inputs/styled-button';
import { WhiteDomeWrapper, Wrapper } from '../components/wrapper';
import { INFO_EMAIL } from '../config';
import { getAreaApi } from '../services/api/open-api';
import { useBookingStore, useNotification, useUserStore } from '../store';
import '../styles/custom-datepicker.scss'; // date range custom style file

const HomePage = () => {
  const { setLoginBackUrl, token } = useUserStore(
    ({ setLoginBackUrl, token }) => ({ setLoginBackUrl, token }),
    shallow
  );
  const {
    bookingInfo,
    setBookingInfo,
    setProfilesCount,
    setBookingCreationDate,
  } = useBookingStore(
    ({
      bookingInfo,
      setBookingInfo,
      setProfilesCount,
      setBookingCreationDate,
    }) => ({
      bookingInfo,
      setBookingInfo,
      setProfilesCount,
      setBookingCreationDate,
    }),
    shallow
  );

  const navigate = useNavigate();
  const calendarDisclosure = useDisclosure();
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);

  const calendarRef = useRef<HTMLDivElement | null>(null);

  const [startDate, setStartDate] = useState<Date | null>(
    bookingInfo?.startDate ? moment(bookingInfo.startDate).toDate() : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    bookingInfo?.endDate ? moment(bookingInfo.endDate).toDate() : null
  );

  const [selectedArea, setSelectedArea] = useState<{
    label: string;
    value: string;
  }>();

  const [areas, setAreas] = useState([{ label: 'Caricamento...', value: '' }]);

  const { data: areaData } = useQuery(['getArea'], async () => {
    const res = await getAreaApi().areaBrowse({});
    return res.data;
  });

  useEffect(() => {
    if (!areaData) {
      return;
    }
    setAreas(
      areaData.elements?.map((x) => ({
        label: '' + x.name + ', ' + x.province,
        value: x.id!,
      }))
    );
  }, [areaData]);

  useEffect(() => {
    if (!bookingInfo?.areaId || !areaData) {
      return;
    }

    for (const [key, value] of Object.entries(areaData.elements)) {
      if (value.id === bookingInfo.areaId) {
        setSelectedArea({
          label: '' + value.name + ', ' + value.province,
          value: value.id ?? '',
        });
      }
    }
  }, [areaData, bookingInfo]);

  const submit = () => {
    if (!selectedArea || !startDate) {
      return notify('error', {
        title: 'Errore',
        text: 'Scegliere località e periodo.',
      });
    }
    setBookingInfo({
      ...bookingInfo,
      startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: endDate
        ? moment(endDate).format('YYYY-MM-DDTHH:mm:ss')
        : moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
      areaId: selectedArea.value,
    });
    setBookingCreationDate();
    setLoginBackUrl('/profiles');
    return navigate(token ? '/profiles' : '/login');
  };
  const LowHeight = useMediaQuery({ query: '(min-height: 830px)' });

  return (
    <PageContainer>
      <Box position='relative'>
        <Box
          mt={{ base: '50px', md: '0px' }}
          h={{ base: '300px', md: '150px' }}
        >
          <CTAUnderline1
            position='absolute'
            left={{ base: '-13px', md: '-21px' }}
            top={{ base: '61px', md: '21px' }}
            h={{ base: '21px', md: '32px' }}
            w={{ base: '132px', md: '212px' }}
          />
          <CTAUnderline2
            position='absolute'
            left={{ base: '-3px', md: '-4px' }}
            h={{ base: '21px', md: '39px' }}
            top={{ base: '93px', md: '67px' }}
            w={{ base: '173px', md: '277px' }}
          />
          <Text
            zIndex='1'
            variant='medium'
            fontSize={{ base: '25px', md: '38px' }}
            lineHeight={{ base: '30px', md: '46px' }}
            width={{ base: '200px', md: '301px' }}
            position='absolute'
          >
            Noleggia la tua attrezzatura
            <br />
            in pochi click
          </Text>
        </Box>
      </Box>
      <Box mt='auto' mx={{ base: '-30px', md: '0px' }}>
        <Wrapper p='0' w='100%' position='relative'>
          <Image
            position='absolute'
            w={{ base: 'min(100vw, 375px)', md: '423px' }}
            h={{ base: 'auto', md: '462px' }}
            bottom={{
              base: '340px',
              md: LowHeight ? '250px' : 'calc(100vh - 580px)',
            }}
            left='50%'
            src='/backgrounds/yeki.svg'
            transform='translateX(-50%)'
            zIndex={0}
          />
          <WhiteDomeWrapper
            position='relative'
            zIndex={1}
            pb='38px'
            pt='32px'
            justifyContent='center'
          >
            {/*SELECT AREA*/}
            <CustomSelect
              label='Località'
              options={areas}
              placeholder='Seleziona località'
              customDefaultValue={selectedArea ?? undefined}
              customOnChange={(area: { label: string; value: string }) => {
                setSelectedArea(area);
                setStartDate(null);
                setEndDate(null);
              }}
              mb='16px'
            />

            {/* PEOPLE AND PERIOD */}
            <Grid
              templateColumns='repeat(2,2fr)'
              w='100%'
              gap={{ md: '17px' }}
              pt='16px'
            >
              <GridItem colSpan={{ base: 2, md: 1 }} w='100%'>
                <ProfilesInput setProfiles={setProfilesCount} />
              </GridItem>
              <GridItem colSpan={{ base: 2, md: 1 }} w='100%'>
                <DateRangeButton
                  startDate={startDate}
                  endDate={endDate}
                  selectedArea={selectedArea?.value ?? ''}
                  onClick={calendarDisclosure.onToggle}
                  scrollRef={calendarRef}
                />
              </GridItem>
              {calendarDisclosure.isOpen && (
                <GridItem colSpan={2} w='100%'>
                  <DateRangeCalendar
                    scrollRef={calendarRef}
                    startDate={startDate}
                    endDate={endDate}
                    selectedArea={selectedArea?.value ?? ''}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </GridItem>
              )}
            </Grid>

            <StyledButton
              onClick={() => {
                submit();
              }}
              maxW='100%'
              mt={{ base: '28px', md: '16px' }}
            >
              Prenota subito
            </StyledButton>
            <Flex justify='space-between' mb='-20px' mt='20px' w='100%'>
              <Link
                color='brand.500'
                onClick={() => {
                  setBookingInfo({
                    startDate:
                      startDate && endDate
                        ? moment(startDate).format('YYYY-MM-DDTHH:mm:ss')
                        : undefined,
                    endDate:
                      startDate && endDate
                        ? moment(endDate).format('YYYY-MM-DDTHH:mm:ss')
                        : undefined,
                    areaId: selectedArea?.value ?? '',
                  });
                  navigate('/terms-of-service');
                }}
              >
                Termini e condizioni
              </Link>
              <Link color='brand.500' href={`mailto:${INFO_EMAIL}`}>
                Contattaci!
              </Link>
            </Flex>
          </WhiteDomeWrapper>
        </Wrapper>
      </Box>
    </PageContainer>
  );
};
export default HomePage;
