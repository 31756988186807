import { BoxProps, Flex, FlexProps, Text, Box } from '@chakra-ui/react';
import { PrintServices } from '../../services/app/print-services';

export const OrderFinalizeBox = ({
  title,
  children,
  ...props
}: { title: string } & BoxProps) => (
  <Box
    borderColor='brandTransparent.500'
    borderRadius='10'
    bg='brandTransparent.120'
    w='100%'
    overflow='hidden'
    {...props}
  >
    <Flex direction='column' px='20px' pt='14px' pb='13px'>
      <Text mb='13px' fontSize='15px' lineHeight='18px' variant='medium'>
        {title}
      </Text>
      <Flex direction='column' gap='10px'>
        {children}
      </Flex>
    </Flex>
  </Box>
);

export const NameValueRow = ({
  name,
  value,
  ...props
}: { name: string; value: string | number | undefined } & FlexProps) => (
  <Flex {...props} w='100%'>
    <Text
      mr='13px'
      color='brand.500'
      fontSize='15px'
      lineHeight='18px'
      variant='medium'
    >
      {name}
    </Text>
    <Text fontSize='15px' lineHeight='18px'>
      {value ?? '-'}
    </Text>
  </Flex>
);
export const PriceRow = ({
  name,
  price,
  singleItem,
  ...props
}: {
  name: string;
  price: number | undefined;
  singleItem?: boolean;
} & FlexProps) => (
  <Flex justify='space-between' {...props} w='100%'>
    <Text
      fontSize={singleItem ? '10px' : '15px'}
      lineHeight={singleItem ? '15px' : '18px'}
    >
      {name}
    </Text>
    <Text
      fontSize={singleItem ? '10px' : '15px'}
      lineHeight={singleItem ? '15px' : '18px'}
      variant={singleItem ? '' : 'medium'}
    >
      {PrintServices.number(price)}
    </Text>
  </Flex>
);

export const PriceTotalRow = ({
  name,
  price,
  ...props
}: { name: string; price: number | undefined } & FlexProps) => (
  <Flex justify='space-between' {...props} w='100%'>
    <Text fontSize='25px' lineHeight='30px'>
      {name}
    </Text>
    <Text fontSize='25px' lineHeight='30px' variant='bold'>
      {PrintServices.number(price)}
    </Text>
  </Flex>
);

export const IconValueRow = ({
  icon,
  value,
  ...props
}: {
  icon: JSX.Element | undefined;
  value: string | number | undefined;
} & FlexProps) => (
  <Flex {...props} align='center' w='100%' py='5px'>
    {icon}
    <Text fontSize='15px' ml='13px' minW='120px'>
      {value ?? '-'}
    </Text>
  </Flex>
);
