import { Flex, FlexProps, FormControl } from '@chakra-ui/react';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDropzone } from 'react-dropzone';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

export const IdInput = React.forwardRef<
  HTMLInputElement,
  {
    registerProps?: UseFormRegisterReturn;
    errors: FieldError | undefined;
    isRequired?: boolean;
    setDocument: (data: File) => void;
  } & FlexProps
>(
  (
    {
      registerProps,
      errors,
      placeholder,
      isRequired,
      children,
      setDocument,
      ...props
    },
    ref
  ) => {
    const { getRootProps, getInputProps } = useDropzone({
      ...registerProps,
      accept: {
        'image/png': ['.png'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
        'image/bmp': ['.bmp'],
      },
      onDrop: (e) => {
        registerProps?.onChange({
          target: {
            name: registerProps.name,
            value: e?.[0],
          },
        } as any);
        setDocument(e?.[0]);
        /* notify('success', {
          title: 'Documento caricato',
          text: 'Documento caricato correttamente!',
        }); */
      },
    });

    // useEffect(() => {
    //   // Make sure to revoke the data uris to avoid memory leaks
    //   files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    // }, [files]);

    return (
      <FormControl id='documents'>
        <div {...getRootProps({})}>
          <input {...getInputProps({})} />
          <Flex
            w='188px'
            h='172px'
            borderWidth='1px'
            borderColor='rgba(0,177,220,0.5)'
            borderRadius='10px'
            bg='linear-gradient(rgba(0,177,220,0.3),#FFFFFF)'
            {...props}
          >
            {children}
          </Flex>
        </div>
      </FormControl>
    );
  }
);
