import {
  Box,
  Flex,
  FlexProps,
  forwardRef,
  HStack,
  Input,
  InputProps,
  Show,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import { RefObject, useState } from 'react';
import shallow from 'zustand/shallow';
import { useBookingStore } from '../../store';
import {
  CalendarIcon,
  IncrementPersonIcon,
  MinusIcon,
  PersonThickIcon,
  PlusIcon,
} from '../icons/inputs';

export const ProfilesWithDatesInput = forwardRef<
  {
    startDate: Date;
    endDate: Date;
    setProfiles: (value: number) => void;
    addRef?: RefObject<HTMLDivElement>;
  } & FlexProps,
  'div'
>(({ startDate, endDate, setProfiles, addRef, ...props }, ref) => {
  return (
    <>
      <Flex
        align='center'
        p='0'
        h='100%'
        whiteSpace='nowrap'
        ref={ref}
        {...props}
      >
        <CalendarIcon mr='10px' h='20px' w='auto' />
        <Text w='100%'>
          {`${moment(startDate).format('DD MMM')} - ${moment(
            endDate ?? startDate
          ).format('DD MMM')}`}
        </Text>
      </Flex>

      <ProfilesInput addRef={addRef} setProfiles={setProfiles} hasDates />
    </>
  );
});

export const ProfilesInput = forwardRef<
  {
    hasDates?: boolean;
    setProfiles: (value: number) => void;
    addRef?: RefObject<HTMLDivElement>;
  } & InputProps,
  'input'
>(({ hasDates, setProfiles, addRef, ...props }, ref) => {
  const { profilesCount } = useBookingStore(
    ({ profilesCount }) => ({ profilesCount }),
    shallow
  );
  const [inputValue, setInputValue] = useState<string | number>(profilesCount);

  return (
    <>
      <Show breakpoint={hasDates ? '(max-width: 569px)' : '(max-width: 0px)'}>
        <HStack w='100%' justifyContent='right'>
          <Box
            ref={addRef}
            onClick={() => {
              const value = parseInt('' + inputValue, 10);

              setInputValue(value + 1);
              setProfiles(value + 1);
            }}
          >
            <IncrementPersonIcon h='21px' w='24px' mr='10px' ml='auto' />
          </Box>
          <Input
            type='number'
            borderColor='brandTransparent.500'
            borderWidth='1px'
            borderRadius='10px'
            w='42px'
            px='0px'
            textAlign='center'
            h='27px'
            value={inputValue}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);

              setInputValue(value);
              if (!isNaN(value) && value > 0) {
                setProfiles(value);
              }
            }}
            onFocus={() => {
              setInputValue('');
            }}
            onBlur={() => {
              const value = parseInt('' + inputValue, 10);
              if (isNaN(value) || value === 0) {
                setInputValue(1);
                setProfiles(1);
              }
            }}
          />
        </HStack>
      </Show>
      <Show breakpoint={hasDates ? '(min-width: 570px)' : '(min-width: 0px)'}>
        <Flex
          flexDirection='column'
          justifyContent='space-between'
          w='100%'
          maxW={
            hasDates
              ? { base: '100%', md: '315px' }
              : { base: '100%', md: '281px' }
          }
        >
          {!hasDates && (
            <HStack justify='space-between'>
              {/* label */}
              <Text fontSize='15px' lineHeight={1.2} mb='10px'>
                Persone
              </Text>
            </HStack>
          )}
          <Flex
            h='48px'
            alignItems='center'
            borderColor='brandTransparent.500'
            borderWidth='1px'
            overflow='hidden'
            borderRadius='10px'
            bg='white'
            w='100%'
            justifyContent='space-between'
            minW={hasDates ? '315px' : '240px'}
          >
            <Flex alignItems='center'>
              <PersonThickIcon h='18px' w='auto' mr='6px' ml='20px' />
              <Text fontSize='15px' lineHeight={0} mr={'auto'}>
                Persone
              </Text>
            </Flex>
            <Flex alignItems='center'>
              <MinusIcon
                h='32px'
                w='32px'
                cursor='pointer'
                onClick={() => {
                  const value = parseInt('' + inputValue, 10);

                  if (value > 1) {
                    setInputValue(value - 1);
                    setProfiles(value - 1);
                  }
                }}
              />
              <Input
                w='48px'
                p='0'
                m='0'
                textAlign='center'
                fontSize='20px'
                type='number'
                {...props}
                ref={ref}
                value={inputValue}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);

                  setInputValue(value);
                  if (!isNaN(value) && value > 0) {
                    setProfiles(value);
                  }
                }}
                onFocus={() => {
                  setInputValue('');
                }}
                onBlur={() => {
                  const value = parseInt('' + inputValue, 10);
                  if (isNaN(value) || value === 0) {
                    setInputValue(1);
                    setProfiles(1);
                  }
                }}
              />

              <Box
                ref={addRef}
                onClick={() => {
                  const value = parseInt('' + inputValue, 10);

                  setInputValue(value + 1);
                  setProfiles(value + 1);
                }}
              >
                <PlusIcon h='32px' w='32px' mr='10px' cursor='pointer' />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Show>
    </>
  );
});
