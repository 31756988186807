import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
  Flex,
  HStack,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import shallow from 'zustand/shallow';
import { INFO_EMAIL } from '../../config';
import { useNotification, useUserStore } from '../../store';
import { YetiModalProps } from '../../types';
import { ProfileSelectionCheckbox } from '../forms/profile-form-selection-checkbox';
import {
  AnkleIcon,
  BootIcon,
  FootLengthIcon,
  FootWidthIcon,
  HeadIcon,
} from '../icons/measure';
import { CrossIcon } from '../icons/misc';
import StyledButton from '../inputs/styled-button';
import { WhiteDomeWrapper, Wrapper } from '../wrapper';

export const YetiModal: FC<YetiModalProps> = ({
  title,
  text,
  buttonText,
  buttonTwoText,
  type,
  onClose: propsClose,
  onConfirm,
  isOpen,
}) => {
  const { close } = useNotification(({ close }) => ({ close }), shallow);
  const src = useMemo(() => {
    switch (type) {
      case 'delete': {
        return '/modals/yeki-wait.png';
      }
      case 'email': {
        return '/modals/yeki-email.png';
      }
      case 'warning':
      case 'error':
      case 'availability': {
        return '/modals/yeki-wait.png';
      }
      case 'success': {
        return '/modals/yeki-ok.png';
      }
    }
  }, [type]);

  const onClose = () => {
    propsClose?.();
    close();
  };

  return (
    <Modal isCentered onClose={onClose} isOpen={!!isOpen}>
      <ModalOverlay />
      <ModalContent position='relative' mx='30px'>
        <CrossIcon
          position='absolute'
          h='21px'
          w='21px'
          right='30px'
          top='30px'
          cursor='pointer'
          onClick={onClose}
        />
        <ModalBody p='40px'>
          <Image
            src={src}
            alt='ok'
            mb='5px'
            px='10px'
            w='100%'
            objectFit='contain'
            h='275px'
          />
          <Text
            w='100%'
            color='brand.500'
            fontSize='25px'
            variant='bold'
            textAlign='center'
            mb='13px'
          >
            {title}
          </Text>

          <Text w='100%' fontSize='15px' textAlign='center' mb='15px'>
            {text}
          </Text>
          {type === 'availability' ? (
            <HStack>
              <Link
                textAlign='center'
                w='100%'
                pb='26px'
                href={`mailto:${INFO_EMAIL}`}
                color='brand.500'
              >
                Contattaci!
              </Link>
            </HStack>
          ) : (
            <></>
          )}
          <HStack maxW='240px' justifyContent='space-between' mx='auto'>
            {type !== 'warning' ? (
              /*SINGLE BUTTON*/
              <StyledButton
                onClick={() => {
                  onConfirm?.();
                  onClose();
                }}
              >
                {buttonText ?? 'Ok'}
              </StyledButton>
            ) : (
              /*YES NO BUTTONS*/
              <>
                <StyledButton
                  maxW='112px'
                  onClick={() => {
                    onConfirm?.();
                    onClose();
                  }}
                >
                  {buttonText ?? 'Sì'}
                </StyledButton>
                <StyledButton maxW='112px' onClick={onClose}>
                  {buttonTwoText ?? 'No'}
                </StyledButton>
              </>
            )}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const SizeGuideEntry: FC<{
  label: string;
  Icon: JSX.Element;
  text: string;
}> = ({ label, Icon, text }) => {
  return (
    <AccordionItem border='0' borderBottom='1px solid #00B1DC' w='100%'>
      {({ isExpanded }) => (
        <>
          <AccordionButton
            px='20px'
            py='12px'
            bg='white'
            h='60px'
            _hover={{ bgColor: '#00B1DC20' }}
          >
            <Flex w='100%' align='center'>
              <Box ml='12.5px' mr='auto'>
                <Text lineHeight={1} fontSize='15px'>
                  {label}
                </Text>
              </Box>
              <AccordionIcon color='brand.500' w='34px' h='34px' />
            </Flex>
          </AccordionButton>
          <Box
            bg='#F2FCFF'
            borderColor='brand.500'
            borderTopWidth='1px'
            maxH={isExpanded ? 'auto' : '0'}
            display={isExpanded ? 'block' : 'none'}
            px='20px'
            py='30px'
          >
            <Flex wrap='wrap' flexDirection='column'>
              <Text color='#00B1DC' fontSize='15px'>
                COME PRENDERE LE MISURE
              </Text>
              <Text color='#00B1DC' fontSize='12px' variant='book' mt='10px'>
                Segui le istruzioni sottostanti e compara le misure con la guida
                alle taglie
              </Text>
              <Flex my='20px' align='center' w='100%' justify='center'>
                {Icon}
              </Flex>
              <Text color='#00B1DC' fontSize='12px' variant='book'>
                {text}
              </Text>
            </Flex>
          </Box>
        </>
      )}
    </AccordionItem>
  );
};

export const MeasuresModal = ({
  defaultOpen,
  ...props
}: Omit<DrawerProps, 'children'> & {
  defaultOpen: 'ankle' | 'foot' | 'head' | 'boots' | false;
}) => (
  <Drawer size='100%' {...props}>
    <DrawerOverlay />
    <DrawerContent bgGradient='linear(to-b,#00B1DC50,white)'>
      <DrawerBody p='0px'>
        <HStack justifyContent='right' mb='143px'>
          <CrossIcon
            color='brand.500'
            h='21px'
            w='21px'
            mr='30px'
            mt='36px'
            cursor='pointer'
            onClick={props.onClose}
          />
        </HStack>

        <Wrapper
          w='100%'
          mb='0px'
          p='0px 30px 0px 30px'
          left='0px'
          flexDirection='column'
          overflow='auto'
          justifyContent='space-between'
          position='absolute'
          bottom='0px'
        >
          <WhiteDomeWrapper
            w='100%'
            h='100%'
            borderColor='brandTransparent.500'
            borderWidth='1px'
            overflow='hidden'
            p='0px'
          >
            <Accordion
              borderRadius='11px'
              overflow='hidden'
              allowToggle
              defaultIndex={
                defaultOpen === 'ankle'
                  ? 0
                  : defaultOpen === 'head'
                  ? 1
                  : defaultOpen === 'foot'
                  ? 2
                  : defaultOpen === 'boots'
                  ? 3
                  : undefined
              }
              minH='fit-content'
            >
              <SizeGuideEntry
                label='Circonferenza caviglia'
                Icon={<AnkleIcon />}
                text='Misurare la circonferenza della caviglia appena al di sopra dell’osso della caviglia. Misurare dalla base del collo sino all’altezza della linea della vita.'
              />
              <SizeGuideEntry
                label='Circonferenza testa'
                Icon={<HeadIcon />}
                text='Misurare la circonferenza della testa appena sopra le orecchie e sopracciglia. Il casco dovrebbe arrivare fino in basso e non dovrebbe stare inclinato.'
              />
              <SizeGuideEntry
                label='Misure piede'
                Icon={
                  <Flex flexDirection='row'>
                    <FootLengthIcon mr='15px' />
                    <FootWidthIcon ml='15px' />
                  </Flex>
                }
                text='La lunghezza del piede si misura dal tallone al dito più lungo, che può essere l’alluce o il secondo dito. la larghezza invece si misura da destra a sinistra nella zona del metatarso.'
              />
              <SizeGuideEntry
                label='Millimetri scarpone'
                Icon={<BootIcon />}
                text='Generalmente sul tuo scarpone da sci c’è un numero di 3 cifre sul tallone esterno o interno di uno o entrambi gli scarponi. '
              />
            </Accordion>
          </WhiteDomeWrapper>
        </Wrapper>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);

export const SelectProfilesModal = ({
  setUserValues,
  selectedProfileIds = [],
  ...props
}: {
  setUserValues: (ProfileIndex: number) => void;
  selectedProfileIds: (string | undefined)[] | undefined;
} & Omit<ModalProps, 'children'>) => {
  const { user } = useUserStore(({ user }) => ({ user }), shallow);
  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent position='relative' maxW={{ base: '315px', sm: '646px' }}>
        <CrossIcon
          position='absolute'
          h='21px'
          w='21px'
          right='30px'
          top='30px'
          onClick={props.onClose}
        />
        <ModalBody p='40px'>
          <Image
            src='/modals/select-profile.svg'
            alt='select'
            mb='5px'
            mx='auto'
          />
          <Text
            w='100%'
            color='brand.500'
            fontSize='25px'
            textStyle='bold'
            textAlign='center'
            mb='13px'
          >
            Seleziona profilo
          </Text>
          <Text w='100%' fontSize='15px' textAlign='center' mb='22px'>
            Aggiungi profilo esistente
          </Text>
          <VStack w='100%' spacing='10px'>
            {user?.profiles!.map((profile, i) => {
              return (
                <ProfileSelectionCheckbox
                  profile={profile}
                  key={'profile' + i}
                  selectedProfileIds={selectedProfileIds}
                  maxW='315px'
                  setUserValues={setUserValues}
                  profileIndex={i}
                />
              );
            })}

            <StyledButton mt='40px' w='100%' onClick={props.onClose}>
              Ok
            </StyledButton>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
