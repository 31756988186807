import { Button, ButtonProps, forwardRef, Text } from '@chakra-ui/react';

const StyledButton = forwardRef<
  { brandColor?: string } & ButtonProps,
  'button'
>(({ children, brandColor, ...rest }, ref) => {
  return (
    <Button
      boxShadow={
        rest.boxShadow ||
        '0 18px 40px var(--chakra-colors-brandTransparent-350)'
      }
      w='100%'
      maxW={rest.maxW ?? '315px'}
      mx='auto'
      borderRadius='10px'
      h='50px'
      textAlign='center'
      bg={brandColor ?? 'brand.500'}
      fontSize='15px'
      color='white'
      ref={ref}
      {...rest}
    >
      {children}
    </Button>
  );
});

export default StyledButton;
