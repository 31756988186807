import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { WhiteDomeWrapper } from '../wrapper';

const AccountWhiteWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Flex mx={{ base: '-30px', md: '0px' }} h='100%'>
      <WhiteDomeWrapper
        w='100%'
        h='100%'
        minH='max(580px,70vh)'
        pt='40px'
        px={{ base: '30px', sm: '50px' }}
        flexDirection='column'
      >
        {children}
      </WhiteDomeWrapper>
    </Flex>
  );
};

export default AccountWhiteWrapper;
