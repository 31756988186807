import { Link, Text } from '@chakra-ui/react';
import { PageContainer } from '../components/container';
import { SectionTitle } from '../components/section-title';
import { INFO_EMAIL } from '../config';

const TextHeader = ({ text }: { text: string }) => {
  return (
    <Text align='center' variant='medium' my='10px'>
      {text}
    </Text>
  );
};

export const TermsOfService = () => {
  return (
    <PageContainer>
      <SectionTitle hasBack text='Termini e condizioni' mt='10px' />
      <Text
        mt='30px'
        textAlign='justify'
      >{`Spettabile azienda ho preso visione e approvo il regolamento interno a tergo riportato da intendersi quale
    parte integrante del presente contratto con riferimento a servizi di cui posso fruire. Dichiaro, sottoscrivendo
    il presente contratto, che sono stato puntualmente informato e mi obbligo a versare la somma indicata e a
    rispettare quanto dettato dalle seguenti condizioni generali di noleggio e regolamento.`}</Text>
      <TextHeader
        text='CONDIZIONI GENERALI DI NOLEGGIO E REGOLAMENTO
        '
      ></TextHeader>
      <TextHeader text='1. Iscrizione al servizio' />
      <Text textAlign='justify'>{`
    L’iscrizione al servizio noleggio è soggetta ai termini e dalle condizioni del presente contratto. Accettando le
    presenti “condizioni generali di noleggio e regolamento” il cliente è obbligato espressamente a rispettarlo e
    prendendo atto che una sua mancata osservazione può comportare il diritto per la denominata “Smile service
    -società cooperativa”- di seguito denominata società - di recedere dal contratto dietro comunicazione scritta
    motivata e senza alcun onere di risarcimento o rimborso della quota pagata al momento della sottoscrizione
    del modulo di noleggio. La società si riserva il diritto di respingere, a proprio insindacabile giudizio, le richieste
    di noleggio non ritenute idonee.
    Il locatario si impegna a corrispondere per intero la quota di noleggio stabilita dal contratto secondo le
    modalità indicate, anche nel caso in cui dovesse assentarsi o interrompere momentaneamente l’utilizzo delle
    attrezzature noleggiate per qualsiasi motivo non dipendente dalla società, quest’ultima si riserva di non
    rimborsare la quota versata in caso di ritardo di consegna delle attrezzature ed anche in caso di eventuale
    ritardo di apertura del centro turistico scelto per l’espletazione dell’attività sportiva. Il noleggio ha inizio dalla
    sottoscrizione del presente contratto. Non è previsto rimborso per il mancato utilizzo del noleggio o in caso
    di ripensamento. Il noleggio delle attrezzature è valido esclusivamente nella sede in cui verranno le stesse
    date in consegna e queste dovranno allo stesso luogo, salvo diversi accordi da far pervenire per iscritto alla
    società, essere restituite al termine del noleggio. Non è possibile cedere l’attrezzatura noleggiata a terzi.
    `}</Text>
      <TextHeader text='2. Limitazioni di responsabilità' />
      <Text textAlign='justify'>{`
    La società inclusi i suoi dipendenti e collaboratori non è responsabile in caso di perdite, danni o furti che si
    verifichino sia all’interno dei locali di consegna delle attrezzature, sia all’esterno di essi, di qualsiasi bene
    appartenente al cliente o ai suoi ospiti. La società, inclusi i suoi dipendenti e collaboratori, non è responsabile
    per qualsiasi lesione personale o malattia del cliente o ancora dei seguenti ospiti. È il responsabilità del cliente
    utilizzare correttamente tutte le attrezzature messe a disposizione della società.`}</Text>
      <TextHeader
        text='
    3. Assicurazione
    '
      />
      <Text textAlign='justify'>{`
    La società è coperta da un'assicurazione per responsabilità civile. Tale assicurazione copre esclusivamente gli
    eventuali danni a cose o a persone causate direttamente per colpa della società. Tutti i danni alle strutture o
    ai beni della società anche se nell'esercizio di attività svolte con istruttori saranno a carico di chi li avrà
    cagionati.`}</Text>
      <TextHeader
        text='
    4. Condizioni fisiche del cliente
    '
      />
      <Text textAlign='justify'>{`
    L'utilizzo delle attrezzature messe a disposizione dalla società è avulso dall'idoneità fisica del cliente che
    semplicemente si consiglia di avere. a tal uopo Il relativo benestare rilasciato da qualunque medico, non
    implica assunzione di alcuna responsabilità da parte della società.
    Il cliente che comunque inizi o continui a svolgere attività fisica utilizzando le attrezzature noleggiate,
    esonera la società da qualsiasi responsabilità. Il cliente si impegna a non utilizzare alcuna attrezzatura della
    società durante il periodo in cui la sua condizione di salute possa comportare rischi per la sua salute e
    quella degli altri.`}</Text>
      <TextHeader
        text='
    5. Risoluzione del contratto di noleggio
    '
      />

      <Text textAlign='justify'>{`
    La direzione può porre In termine al contratto di noleggio senza preavviso e con effetto immediato: qualora
    la condotta del cliente possa essere dannosa all'immagine e o agli interessi della società, in caso di violazione
    dei termini delle condizioni del contratto, inclusi i regolamenti di volta in volta vigenti, nel caso in cui tutto o
    parte della quota di noleggio diventa esigibile. In tali casi il cliente perde tutti i diritti e privilegi inerenti il
    noleggio, senza possibilità di rimborso.`}</Text>
      <TextHeader
        text='
    6. Accesso
    '
      />
      <Text textAlign='justify'>{`
     Durante l'utilizzo delle attrezzature deve essere utilizzato un abbigliamento consono alla attività sportiva
    da eseguire oltre ad indossare tutte le protezioni previste onde garantire la propria igiene e quella altrui`}</Text>
      <TextHeader
        text='
    7. Condotta
    '
      />
      <Text textAlign='justify'>{`
    I clienti devono utilizzare le attrezzature in conformità con le indicazioni fornite dal personale e seguendo i
    seguenti Termini e Condizioni.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    a) Il cliente (locatario) è responsabile per gli oggetti noleggiati.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    b) Il materiale noleggiato non è assicurato. Il rischio è a carico del locatario al 100% per rottura e al
    100% per furto.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    c) In caso di perdita del materiale noleggiato e in caso di furto o danno totale e irreparabile dello
    stesso, il materiale in oggetto sarà addebitato al locatario al prezzo di acquisto del locatore, con
    eventuale riconsiderazione del suo valore corrente dovuto all’usura e al passare del tempo.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    d) In caso di furto del materiale noleggiato, il locatario è tenuto a sporgere immediatamente denuncia
    presso le forze dell’ordine competenti.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    e) In caso di danneggiamento del materiale noleggiato, a causa di uso non adeguato e/o trattamento
    vandalico e/o di danneggiamento parziale, i costi di riparazione verranno addebitati al locatario.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    f) Durante il periodo di noleggio è possibile effettuare la sostituzione del materiale noleggiato con
    materiale di categoria superiore, con il pagamento di un sovraprezzo. Non è previsto alcun
    rimborso nel caso di sostituzione con materiale di categoria inferiore.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    g) Il costo dell’attrezzatura noleggiata è calcolato fino al giorno di restituzione incluso e la consegna
    deve avvenire entro le ore 17:00 dell’ultimo giorno. Per le consegne dopo l’orario indicato verrà
    addebitato 1 ulteriore giorno di noleggio.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    h) Nel caso in cui il materiale noleggiato non dovesse, per qualsiasi motivo, ritornare al locatore entro
    la data stabilita, verrà automaticamente esposta denuncia di furto allo scadere del 7° giorno da tale
    data.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    i) Nel caso di risoluzione anticipata del contratto di noleggio con restituzione del materiale
    noleggiato, non verrà concesso alcun rimborso. Eventuali rimborsi verranno concessi
    esclusivamente nel caso di malattia e/o incidente, con presentazione di regolare documentazione
    medica.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    j) Con la Sua firma, il locatario si dichiara espressamente d’accordo con la regolazione professionale
    delle attrezzature in base ai propri dati personali.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    k) Con la Sua firma, il locatario dichiara di aver ritirato l’oggetto in affitto in perfette condizioni.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    l) Al momento della prenotazione/acquisto del servizio desiderato, è possibile acquistare anche una
    “polizza annullamento”
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    m) Tutti gli atti giuridici sottostanno alla legge nazionale, anche se il locatario ha la propria sede
    all’estero.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    n) Con la prenotazione online il contratto di noleggio è valido a tutti gli effetti alle condizioni indicate.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    o) Con la prenotazione online il contratto di noleggio è valido a tutti gli effetti alle condizioni indicate.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    p) Ogni danno o rottura delle attrezzature vi verrà addebitato secondo il listino consegnato
    unitamente alle attrezzature scelte.
    `}</Text>
      <Text textAlign='justify' mb='5px'>{`
    q) È obbligatorio il riconoscimento per l'accesso al servizio di noleggio delle attrezzature.
    `}</Text>
      <TextHeader text=' 8. Liberatoria di responsabilità ed utilizzo di immagini' />
      <Text textAlign='justify'>{`
    Il sottoscritto dichiara di liberare ed esonerare la SOCIETA’ , i collaboratori, i partner di sponsor,
    collettivamente denominati organizzazione, da qualunque responsabilità in relazione al danno della persona
    o a cose che può essere subito e/o provocato da me medesimo durante lo svolgimento delle attività.Sono
    altresì a conoscenza che durante le attività potranno essere effettuate riprese audio e video. Sono
    consapevole che potrei entrare in alcune riprese e acconsento all'utilizzo l'uso di tale materiale. La sessione
    di diritti è a titolo gratuito e non ha limiti di territorio di durata. Autorizzo la pubblicazione, la retribuzione e
    la messa in commercio delle riprese video, in ogni canale televisivo, on-line ed ogni supporto digitale presente
    e futuro. In nessun caso potrò rivalermi sulla SOCIETA’ per risarcimento di eventuali danni morali e materiali
    causati da soggetti terzi o derivati da utilizzi non previsti nella presente liberatoria.
    `}</Text>
      <TextHeader
        text='
    9. Emergenza covid 19
    '
      />
      <Text textAlign='justify'>{`
    In riferimento all'emergenza covid-19, la società si riserva la possibilità di modificare il regolamento, in
    ottemperanza alle disposizioni delle normative vigenti nonché alle disposizioni governative. L’ inosservanza
    del regolamento da parte del cliente potrà portare alla sua espulsione. Come da decreto. La modifica delle
    condizioni di accesso al noleggio delle attrezzature, in materia covid, non può essere imputata alla direzione,
    la quale non si fa carico di eventuali richieste o indennizzi.`}</Text>
      <TextHeader
        text='
    10. Varie
    '
      />
      <Text textAlign='justify'>{`
    La direzione si riserva il diritto di variare gli orari di consegna delle attrezzature, e ridurre le ore del noleggio
    delle stesse anche alla fine di effettuare la pulizia o le riparazioni necessarie ovvero in occasione di eventi
    speciali di alcune festività e del periodo di alta stagione. La direzione si adopererà al fine di dare ragionevole
    preavviso di qualsiasi estensione o riduzione di tali orari. Qualsiasi comunicazione al cliente sarà considerata
    come validamente effettuata anche attraverso l’utilizzo di piattaforme di messaggistica social o messaggi
    sms.`}</Text>
      <TextHeader
        text='
    INFORMATIVA IN MATERIA DI PROTEZIONE DEI DATI PERSONALI.
    '
      />
      <Text textAlign='justify'>{`
    Ai sensi dell'articolo 12 e seguenti regolamento ue numero 679/16 la l'azienda da sempre molto attenta agli aspetti
    della protezione dei dati personali e al rispetto dei principi della riservatezza e delle dignità delle persone. Ai sensi del
    nuovo regolamento ue 679 del 2016 Non segue al principio di responsabilizzazione, qualsiasi trattamento di dati
    personali deve essere lecito e corretto punto deve essere trasparente per le persone fisiche la modalità con cui è
    raccolto, consultato o altrimenti trattato il dato personale che lo riguarda, nonché la misura in cui lo stesso dato è
    oppure sarà trattato. Il principio di trasparenza impone che le informazioni e le comunicazioni relative al trattamento di
    tali dati siano facilmente accessibili e comprensibili e che sia utilizzato un linguaggio semplice e chiaro. In tale ottica la
    preghiamo di prendere visione della seguente informativa. La azienda, nella sua qualità di titolare del trattamento dei
    dati, ai sensi e per gli effetti del regolamento come sopra, con la presente in forma l'interessato i dati personali assunti
    che lo riguardano acquisiti dal titolare o che verranno richiesti in seguito e/o comunicate da terze parti, sono necessarie
    saranno utilizzati per le finalità di seguito indicate. Il titolare dei dati per qualunque chiarimento, domanda o esigenza
    legata alla tua privacy e al trattamento dei tuoi dati personali puoi contattarci in qualunque momento inviando una
    richiesta al nostro servizio clienti: `}</Text>
      <Link
        w='100%'
        textAlign='center'
        href={`mailto:${INFO_EMAIL}`}
        color='brand.500'
        mt='10px'
      >
        {INFO_EMAIL}
      </Link>
      <TextHeader
        text='
    TIPOLOGIE DI DATI
    '
      />
      <Text textAlign='justify'>{`
    Fra i dati personali raccolti da questa società, in modo autonomo o tramite terze parti, ci sono: email, dati comunicati
    durante l'utilizzo del servizio, nome, cognome, data di nascita, numero di telefono punto dettagli completi su ciascuna
    tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa privacy policy punto Se non diversamente
    specificato, tutti i dati richiesti Sono obbligatori. Se l'utente rifiuta di comunicarli, potrebbe essere un possibile per
    questa società fornire il servizio. Nei casi in cui la società indica alcuni dati come facoltativi viticola gli utenti sono liberi
    di astenersi dal comunicare tali dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del servizio o sulla sua
    operatività. Gli utenti che dovessero avere dubbi su quali dati siano obbligatori sono incoraggiati a contattare il titolare.
    L'utente si assume la responsabilità dei dati personali di terzi ottenuti, pubblicati o condivisi mediante questa
    applicazione e garantisce di avere diritto di comunicarli o diffonderli, liberando il titolare da qualsiasi responsabilità
    verso terzi.`}</Text>
      <TextHeader
        text='
    MODALITÀ E LUOGO DEL TRATTAMENTO DEI DATI
    '
      />
      <Text textAlign='justify'>{`
    Modalità del trattamento: il titolare adotta le opportune misure di sicurezza volte ad impedirne l'accesso, divulgazione,
    la modifica e la distruzione non autorizzata dei dati personali. Il trattamento viene effettuato mediante strumenti
    informatici e o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al
    titolare, in alcuni casi, potrebbero avere accesso ai dati altri soggetti coinvolti nella loro organizzazione del servizio (
        personale amministrativo, commerciale, marketing, legali, amministratori di sistema ) ovvero soggetti esterni (come
            fornitori di servizi tecnici terzi, corrieri postali, joystick provider, società informatiche, agenzie di comunicazione)
            nominati anche, se necessario, responsabili del trattamento da parte del titolare. L'elenco aggiornato dei responsabili
            sarà sempre potrà essere sempre chiesto al titolare del trattamento.`}</Text>
      <TextHeader
        text='
            BASE GIURIDICA DEL TRATTAMENTO
    '
      />
      <Text textAlign='justify'>{`
            Il titolare tratta dati personali relativi all'utente in caso sussista una delle seguenti condizioni:-l'utente ha prestato il
            consenso per uno o più finalità specifiche; nota: in alcuni ordinamenti il titolare può essere autorizzato a trattamenti
            dati personali senza che debba sostituire il consenso dell'utente o di un'altra delle basi giuridiche specificate di seguito,
            fino a quando l'utente non si opponga a tale trattamento. Ciò non è mai tuttavia applicabile qualora il trattamento di
            dati personali sia regolato dalla legislazione europea e materia di protezione di dati personali con duevirgola-il
            trattamento è necessario all'esecuzione di un contratto con l'utente e o all'esecuzione di misure preconta attuali punto
            e virgola-il diradamento è necessario per adempire ad un obbligo legale al quale è soggetto il titolare;-il trattamento è
            necessario per l'esecuzione di un compito di interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il
            titolare;-il trattamento è necessario per il proseguimento del legittimo interesse del titolare o di terzi punto e comunque
            possibile richiedere al titolare di chiarire la concreta base giuridica di alcun trattamento ed in particolare di specificare
            se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.`}</Text>
      <TextHeader
        text='
            LUOGO
    '
      />
      <Text textAlign='justify'>{`
            i dati sono trattati presso le sedi operativi del titolare ed ogni altro luogo in cui le parti coinvolte nel trattamento siano
            localizzate. per ulteriori informazioni, contatta il titolare. I dati personali dell'utente potrebbero essere trasferiti in un
            paese diverso da quello in cui l'utente si trova punto per ottenere ulteriori informazioni sul luogo del trattamento
            l’utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei dati personali. L'utente ha diritto a
            ottenere un'informazione In merito alla base giuridica del trasferimento di dati al di fuori dell'Unione Europea o ad
            un'organizzazione internazionale di diritto internazionale pubblico o costituita da due o più paesi come ad esempio
            l'ONU, nonché in merito alle misure di sicurezza adottate dal titolare per proteggere dati. Qualora abbia luogo uno dei
            trattamenti trasferimenti appena descritti, l'utente può fare riferimento alle rispettive sezioni di questo documento O
            chiedere informazioni al titolare contattandolo agli estremi riportati in apertura.
            `}</Text>
      <TextHeader
        text='
            PERIODO DI CONSERVAZIONE
    '
      />
      <Text textAlign='justify' pb='100px'>{`
            I dati sono trattati e conservati per il tempo richieste dal definirerità per le quali sono stati raccolti fino a cinque anni.
            Pertanto dati personali raccolti per scopi collegati all'esecuzione di un contratto tra il titolare e l'utente saranno
            trattenuti sino a quando sia completata l'esecuzione di tale contratto i dati personali raccolti per finalità riconducibili
            all'interesse legittimo del titolare saranno trattenuti sino al soddisfacimento di tale interesse. L'utente può ottenere
            ulteriori informazioni in merito all'interesse legittimo perseguito dal titolare e nelle relative sezioni di questo documento
            O contattando il titolare. Quando il trattamento è basato sul consenso dell'utente, il titolare può conservare i dati
            personali più a lungo sino a quando detto consenso non venga revocato. Inoltre il titolare potrebbe essere obbligato a
            conservare i dati personali per un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine di un'autorità.
            Al termine del periodo di conservazione dei dati personali saranno cancellati. Pertanto ad spiare di tale termine il diritto
            di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei dati non potranno più essere esercitati.`}</Text>
    </PageContainer>
  );
};
