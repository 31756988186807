import {
  Flex,
  Grid,
  HStack,
  IconProps,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { FC, Fragment, useMemo } from 'react';
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { INFO_EMAIL } from '../../config';
import { getAvailabilityApi, getBookingApi } from '../../services/api/open-api';
import { useBookingStore, useNotification, useUserStore } from '../../store';
import { BookingInfo, FinalizeFormType, FinalizePriceEnum } from '../../types';
import { PageContainer } from '../container';
import { ProfileForm } from '../forms/profile-form';
import {
  FinalizeAccomodationIcon,
  FinalizeCalendarIcon,
  FinalizePersonIcon,
  TrackIcon,
} from '../icons/inputs';
import { FinalizeClock } from '../icons/misc';
import { PointIcon } from '../icons/navigation';
import { FinalizeCheckbox, TOSCheckboxItem } from '../inputs/checkbox';
import SimpleInputText from '../inputs/input-text';
import StyledButton from '../inputs/styled-button';
import { SectionTitle } from '../section-title';
import {
  IconValueRow,
  NameValueRow,
  OrderFinalizeBox,
  PriceRow,
  PriceTotalRow,
} from './finalize.components';

export const BookingSummary = ({
  isFinalize,
  booking,
  onSubmit,
}: {
  isFinalize?: boolean;
  booking: BookingInfo;
  onSubmit: (booking: BookingInfo) => void;
}) => {
  const { user } = useUserStore(({ user }) => ({ user }), shallow);

  const { bookingInfo, setBookingInfo, profilesCount, setProfilesCount } =
    useBookingStore(
      ({ bookingInfo, setBookingInfo, profilesCount, setProfilesCount }) => ({
        bookingInfo,
        setBookingInfo,
        profilesCount,
        setProfilesCount,
      }),
      shallow
    );

  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);
  const parsedAccomodationAddress =
    booking.accomodationAddress && isFinalize
      ? JSON.parse(booking?.accomodationAddress).address +
        ', ' +
        JSON.parse(booking?.accomodationAddress).city
      : booking.accomodationAddress;

  const methods = useForm<FinalizeFormType>({
    defaultValues: {
      acceptedTerms: !!bookingInfo?.acceptedTerms,
      hasStoreShoe: bookingInfo?.hasStoreShoe ?? false,
      hasInsurance: bookingInfo?.hasInsurance ?? false,
      profiles: booking?.profiles,
    },
  });

  const {
    register,
    unregister,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
  } = methods;

  const { data: priceData, refetch: priceRefetch } = useQuery(
    ['getPriceData'],
    async () => {
      const res = await getBookingApi().bookingPrice({
        ...booking,
        profiles: isFinalize ? getValues('profiles') : booking.profiles,
        userEmail: user?.email ?? booking.userEmail,
        userPhone: user?.phone ?? booking.userPhone,
        acceptedTerms: false,
        hasStoreShoe: isFinalize
          ? getValues('hasStoreShoe')
          : booking.hasStoreShoe,
        hasInsurance: isFinalize
          ? getValues('hasInsurance')
          : booking.hasInsurance,
      });
      return res.data;
    },
    { enabled: !!isFinalize }
  );

  const { data: equipData } = useQuery(
    ['getEquipmentData'],
    async () => {
      const res = await getAvailabilityApi().availabilityMinimum({
        areaId: bookingInfo?.areaId!,
        startDate: moment(bookingInfo?.startDate ?? new Date()).format(
          'YYYY-MM-DD'
        ),
        endDate: moment(bookingInfo?.endDate ?? new Date()).format(
          'YYYY-MM-DD'
        ),
      });
      return res.data;
    },
    { enabled: !!isFinalize }
  );

  const onError: SubmitErrorHandler<FinalizeFormType> = (errors) => {
    if (errors.acceptedTerms) {
      return notify('error', {
        title: 'Errore',
        text: 'Accettare Termini e Condizioni.',
      });
    }
    if (isFinalize) {
      return notify('error', {
        title: 'Errore',
        text: 'Sono presenti errori nella compilazione dei profili.',
      });
    }
    return notify('error', {
      title: 'Errore',
      text: 'Inserisci una motivazione per cancellare la prenotazione.',
    });
  };

  const { AddressIcon, address } = useMemo<{
    AddressIcon: FC<IconProps>;
    address: string;
  }>(() => {
    switch (booking.delivery) {
      case 'ACCOMODATION': {
        return {
          AddressIcon: FinalizeAccomodationIcon,
          address: parsedAccomodationAddress ?? '',
        };
      }
      case 'POINT': {
        return {
          AddressIcon: PointIcon,
          address: booking.pointAddress ?? '',
        };
      }
      case 'TRACK': {
        return { AddressIcon: TrackIcon, address: booking.trackAddress ?? '' };
      }
      default: {
        return { AddressIcon: () => <></>, address: '' };
      }
    }
  }, [booking]);

  const deleteBookingProfile = (index: number) => {
    const newProfiles = bookingInfo?.profiles!.filter(
      (profile, i) => i !== index
    );
    setBookingInfo({
      ...bookingInfo,
      profiles: newProfiles,
    });
    setValue('profiles', newProfiles);
    unregister(
      `profiles.${Object.entries(getValues('profiles') ?? {}).length}`
    );
    setProfilesCount(profilesCount - 1);
    priceRefetch();
  };

  const { statusTitle, statusText } = useMemo(() => {
    let statusTitle = '';
    let statusText = '';
    switch (booking.status) {
      case 'ACCEPTED': {
        statusTitle = 'Accettato';
        statusText = 'Il tuo ordine è stato confermato. Ci vediamo in pista!';
        break;
      }
      case 'CANCELED': {
        statusTitle = 'Cancellata';
        statusText = `Il tuo ordine è stato cancellato. Motivo: ${booking.cancelReason}`;
        break;
      }
      case 'COMPLETED': {
        statusTitle = 'Completato';
        statusText =
          "Quest'ordine si è concluso con successo. Grazie per aver scelto Yeki!";
        break;
      }
      case 'PENDING': {
        statusTitle = 'In attesa di accettazione';
        statusText =
          'Il tuo ordine è stato registrato correttamente. Il gestore lo accetterà a breve.';
        break;
      }
      case 'REFUNDED': {
        statusTitle = 'Rimborsato';
        statusText =
          "Quest'ordine è stato rimborsato correttamente. Dovresti ricevere il tuo rimborso tra qualche giorno.";
        break;
      }
      case 'REJECTED': {
        statusTitle = 'Rifiutato';
        statusText = `Ci spiace ma il gestore ha rifiutato il tuo ordine. Motivo: ${booking.rejectReason}`;
        break;
      }
      case 'WAITING_PAYMENT': {
        statusTitle = 'In attesa di pagamento';
        statusText = 'Il tuo pagamento verrà registrato a breve, attendi...';
        break;
      }
    }
    return { statusText, statusTitle };
  }, [booking.status, booking.cancelReason, booking.rejectReason]);

  //enum to convert object into names
  const PriceNameEnum: Record<FinalizePriceEnum, string> = {
    HELMET: 'Casco',
    BOARD: 'Snowboard',
    SKI: 'Sci',
    SKIBOOTS: 'Scarponi sci',
    BOARDBOOTS: 'Scarponi snowboard',
    SKIBUNDLE: 'Kit sci',
    BOARDBUNDLE: 'Kit snowboard',
    SKIPREMIUM: 'Premium',
    BOARDPREMIUM: 'Premium',
    TRACK: 'Ritiro in pista',
    SHOES: 'Deposito scarpe',
    INSURANCE: 'Polizza annullamento',
  };

  const mainProfile = user?.profiles?.find((profile) => profile.main);

  return (
    <PageContainer>
      <FormProvider {...methods} key={'formid' + booking.profiles?.length}>
        <form
          onSubmit={handleSubmit(
            (data) =>
              onSubmit({
                ...booking,
                ...data,
                profiles: data.profiles?.map(({ ownedBoots, ...profile }) => {
                  return profile.bookedSkiBoots || profile.bookedBoardBoots
                    ? { ...profile, ownedBoots: undefined }
                    : { ...profile, ownedBoots };
                }),
                startDate:
                  booking.delivery === 'ACCOMODATION' &&
                  moment().isAfter(moment(booking.startDate))
                    ? moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
                    : booking.startDate,
              }),
            onError
          )}
        >
          {/*TODO: There shouldn't be a status for past bookings? */}
          {!isFinalize && (
            <>
              <SectionTitle
                hasBack
                toPage='/'
                text='Il tuo ordine'
                mt={{ base: '15px', md: '0px' }}
              />
              <OrderFinalizeBox
                mt={{ base: '15px', md: '70px' }}
                title={statusTitle}
                mb='15px'
              >
                <Text variant='book' fontSize='15px'>
                  {statusText}
                </Text>
              </OrderFinalizeBox>
            </>
          )}
          <SectionTitle
            hasBack={isFinalize}
            toPage='/document'
            mt='15px'
            text='Riepilogo ordine'
            mb={isFinalize ? '0px' : '15px'}
          />
          <OrderFinalizeBox
            mt={{ base: '16px', md: isFinalize ? '70px' : '16px' }}
            title='Dati acquirente'
            mb='15px'
          >
            <NameValueRow
              name='Nome'
              value={
                mainProfile?.firstName ?? booking?.profiles?.[0].firstName ?? ''
              }
            />
            <NameValueRow
              name='Cognome'
              value={
                mainProfile?.lastName ?? booking?.profiles?.[0].lastName ?? ''
              }
            />
            <NameValueRow name='Email' value={booking?.userEmail} />
            <NameValueRow name='Telefono' value={booking?.userPhone} />
          </OrderFinalizeBox>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            w='100%'
            rowGap='15px'
            columnGap='16px'
          >
            <OrderFinalizeBox title='Ritiro' flex={{ md: '1' }}>
              <Flex direction='row' w='100%' justifyContent='space-between'>
                <IconValueRow
                  icon={<FinalizeCalendarIcon h='20px' w='auto' />}
                  value={moment(booking?.startDate).format('DD MMMM')}
                />
                <IconValueRow
                  icon={<FinalizePersonIcon h='20px' w='auto' />}
                  value={booking?.profiles?.length}
                />
              </Flex>
              {(booking.delivery === 'POINT' ||
                booking.delivery === 'TRACK') && (
                <IconValueRow
                  icon={<FinalizeClock w='22px' h='22px' />}
                  value={
                    isFinalize
                      ? moment(booking?.startDate).format('HH:mm')
                      : moment(booking?.startDate).utc().format('HH:mm')
                  }
                />
              )}
              <IconValueRow
                icon={<AddressIcon h='20px' w='auto' />}
                value={address}
              />
            </OrderFinalizeBox>
            <OrderFinalizeBox title='Restituzione' flex={{ md: '1' }}>
              <IconValueRow
                icon={<FinalizeCalendarIcon h='20px' w='auto' />}
                value={moment(booking?.endDate).utc().format('DD MMMM')}
              />
              <IconValueRow
                icon={<FinalizeClock w='22px' h='22px' />}
                value={
                  isFinalize
                    ? moment(booking?.endDate).format('HH:mm')
                    : moment(booking?.endDate).utc().format('HH:mm')
                }
              />
              <IconValueRow
                icon={<TrackIcon h='20px' w='auto' />}
                value={booking?.pickupTrackAddress}
              />
            </OrderFinalizeBox>
          </Flex>
          {getValues('profiles')?.map((profile, i, array) => {
            return (
              <Fragment key={'profile' + i}>
                <HStack justifyContent='space-between'>
                  <Text color='brand.500' my='15px'>
                    Persona {i + 1}
                  </Text>
                  {array.length > 1 && isFinalize && (
                    <Text
                      color='warning'
                      my='15px'
                      cursor='pointer'
                      onClick={() => {
                        notify('warning', {
                          onConfirm: () => deleteBookingProfile(i),
                          title: 'Eliminazione definitiva',
                          text: 'Sei sicuro di voler eliminare il profilo?',
                        });
                      }}
                    >
                      Rimuovi persona
                    </Text>
                  )}
                </HStack>
                <ProfileForm
                  index={i}
                  setGuideToggled={() => {}}
                  proceed={() => {}}
                  page={isFinalize ? 'finalize' : 'summary'}
                  priceRefetch={priceRefetch}
                  equipData={equipData}
                />
              </Fragment>
            );
          })}
          {(isFinalize || booking.coupon) && (
            <Flex flexDirection='column' w='100%' mt='30px' alignItems='center'>
              <SectionTitle
                text={isFinalize ? 'Hai un coupon?' : 'Coupon utilizzato'}
                mb='30px'
              />
              <HStack w='100%' justifyContent='center' pl='10px'>
                <VStack spacing={0}>
                  <Text
                    textAlign='left'
                    textStyle='book'
                    fontSize='10px'
                    lineHeight='30px'
                  >
                    <strong>Aggiungi un coupon sconto</strong>
                  </Text>
                  <Text fontSize='10px' lineHeight='13px' textAlign='left'>
                    I coupon e le offerte non sono cumulabili
                  </Text>
                  <Text fontSize='10px' textAlign='left' lineHeight='13px'>
                    Verrà applicata la percentuale di sconto maggiore
                  </Text>
                </VStack>
              </HStack>
              <SimpleInputText
                {...register('coupon')}
                label=''
                maxW='300px'
                readOnly={!isFinalize}
                errors={errors.coupon}
              />
            </Flex>
          )}
          <Flex flexDirection='column' w='100%' mt='30px'>
            {(isFinalize || booking.hasStoreShoe || booking.hasInsurance) && (
              <SectionTitle text='Servizi extra' mb='30px' />
            )}
            <Grid
              templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
              gap={{ base: '38px', md: '16px' }}
              w='100%'
            >
              {(isFinalize || booking.hasStoreShoe) && (
                <FinalizeCheckbox
                  label='Deposito scarpe'
                  type='storeShoe'
                  isSummary={!isFinalize}
                  checked={
                    (isFinalize && bookingInfo?.hasStoreShoe) ||
                    (!isFinalize && booking.hasStoreShoe!)
                  }
                  onClick={() => {
                    if (isFinalize) {
                      setBookingInfo({
                        ...bookingInfo,
                        hasStoreShoe: !bookingInfo?.hasStoreShoe,
                      });
                      setValue('hasStoreShoe', !bookingInfo?.hasStoreShoe);
                      priceRefetch();
                    }
                  }}
                />
              )}

              {(isFinalize || booking.hasInsurance) && (
                <FinalizeCheckbox
                  label='Polizza annullamento'
                  type='insurance'
                  isSummary={!isFinalize}
                  checked={
                    (isFinalize && bookingInfo?.hasInsurance) ||
                    (!isFinalize && booking.hasInsurance!)
                  }
                  onClick={() => {
                    if (isFinalize) {
                      setBookingInfo({
                        ...bookingInfo,
                        hasInsurance: !bookingInfo?.hasInsurance,
                      });
                      setValue('hasInsurance', !bookingInfo?.hasInsurance);
                      priceRefetch();
                    }
                  }}
                />
              )}
            </Grid>
          </Flex>
          {isFinalize && (
            <HStack mt='30px' w='100%' justifyContent='center'>
              <TOSCheckboxItem
                {...register('acceptedTerms', {
                  required: true,
                  validate: (value) => !!value,
                })}
                checked={!!bookingInfo?.acceptedTerms}
                onClick={() => {
                  setBookingInfo({
                    ...bookingInfo,
                    acceptedTerms: !getValues('acceptedTerms'),
                  });
                  setValue('acceptedTerms', !getValues('acceptedTerms'), {
                    shouldValidate: true,
                  });
                }}
                errors={errors.acceptedTerms}
                option={{
                  title: 'Accetto termini & condizioni',
                }}
              />
            </HStack>
          )}
          {isFinalize ? (
            <>
              <Text w='100%' mt='45px' textAlign='center'>
                Evita la fila, chiedi disponibilità per lo skipass.{' '}
                <Link color='brand.500' href={`mailto:${INFO_EMAIL}`}>
                  Contattaci!
                </Link>
              </Text>
              <PriceRow
                mt='45px'
                mb='10px'
                name='Ordine'
                price={Object.values(priceData?.base ?? {}).reduce(
                  (acc, next) => acc + next,
                  0
                )}
              />

              {Object.entries(priceData?.base ?? {}).map(([key, price], i) => (
                <PriceRow
                  key={'item' + i + key}
                  name={'- ' + PriceNameEnum[key as keyof typeof PriceNameEnum]}
                  price={price}
                  singleItem
                />
              ))}
              <PriceRow
                mt='15px'
                mb='10px'
                name='Servizi Extra'
                price={Object.values(priceData?.extra ?? {}).reduce(
                  (acc, next) => acc + next,
                  0
                )}
              />
              {Object.entries(priceData?.extra ?? {}).map(([key, price], i) => (
                <PriceRow
                  key={'item' + i + key}
                  name={'- ' + PriceNameEnum[key as keyof typeof PriceNameEnum]}
                  price={price}
                  singleItem
                />
              ))}
            </>
          ) : null}
          <PriceRow
            mt='15px'
            mb='20px'
            name='IVA'
            price={isFinalize ? priceData?.tax : booking.taxPrice!}
          />
          {isFinalize && !!priceData?.discount && (
            <PriceRow mb='20px' name='Sconto' price={-priceData.discount} />
          )}
          <PriceTotalRow
            name='Totale'
            price={isFinalize ? priceData?.total : booking.fullPrice}
            mb='40px'
          />
          {isFinalize ? (
            <HStack>
              <StyledButton
                bg='accent'
                mx='auto'
                color='black'
                type='submit'
                mb='100px'
              >
                Paga
              </StyledButton>
            </HStack>
          ) : (
            (['ACCEPTED', 'PENDING'] as typeof booking.status[]).includes(
              booking.status
            ) &&
            moment(booking?.startDate).isAfter(
              moment().endOf('day').add(1, 'day'),
              'date'
            ) && (
              <>
                <Text fontSize='15px' lineHeight='18px' textAlign='center'>
                  Ci sono problemi?
                  <br />
                  Vuoi annullare la prenotazione?
                </Text>

                <Flex
                  justify='center'
                  align='center'
                  flexDirection='column'
                  my='30px'
                  w='100%'
                >
                  <SimpleInputText
                    {...register('cancelReason', {
                      required: true,
                    })}
                    maxW='315px'
                    label='Motivazione'
                    errors={errors.cancelReason}
                  />
                  <StyledButton
                    bg='white'
                    mt='30px'
                    color='black'
                    type='submit'
                  >
                    Annulla Prenotazione
                  </StyledButton>
                </Flex>
                {moment(booking?.startDate).isAfter(moment().add(1, 'day')) ? (
                  <Text
                    fontSize='15px'
                    lineHeight='18px'
                    textAlign='center'
                    mb='100px'
                  >
                    N.B: In caso di cancellazione, se non hai acquistato la
                    polizza
                    <br />
                    annullamento l’importo pagato non verrà restituito.
                  </Text>
                ) : (
                  <Text
                    fontSize='15px'
                    lineHeight='18px'
                    textAlign='center'
                    mb='100px'
                  >
                    N.B: In caso di cancellazione, l’importo pagato non verrà
                    restituito.
                  </Text>
                )}
              </>
            )
          )}
        </form>
      </FormProvider>
    </PageContainer>
  );
};
