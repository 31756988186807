import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = {
  sm: '370px',
  md: '650px',
  lg: '800px',
  xl: '80em',
  '2xl': '96em',
};

export const theme = extendTheme({
  breakpoints,
  fonts: {
    body: 'stolzl, sans-serif',
  },
  textStyles: {
    regular: {
      fontFamily: 'stolzl, sans-serif',
      fontWeight: '500',
      fontStyle: 'normal',
    },
    bold: {
      fontFamily: 'stolzl, sans-serif',
      fontWeight: '700',
      fontStyle: 'normal',
    },
    book: {
      fontFamily: 'stolzl, sans-serif',
      fontWeight: '300',
      fontStyle: 'normal',
    },
  },
  colors: {
    brand: { '500': '#00B1DC' },
    brandTransparent: {
      '500': '#00B1DC80',
      '350': '#00B1DC59',
      '200': '#00B1DC33',
      '120': '#00B1DC1F',
      '100': '#00B1DC1A',
    },
    brandGreen: '#005802',
    warning: '#E80000',
    gray: '#C6C6C6',
    black: '#1D1E1C',
    accent: '#E6FF00',
  },
  components: {
    Input: {
      variants: {
        solid: {
          field: {
            _focus: { boxShadow: 'none' },
          },
        },
      },
      defaultProps: {
        variant: 'solid',

        _placeholder: { color: '#1D1E1C80', textStyle: 'book' },
      },
    },
    Text: {
      baseStyle: {
        fontSize: '15px',
        fontFamily: 'stolzl, sans-serif',
        fontWeight: '300',
        fontStyle: 'normal',
      },
      variants: {
        medium: {
          fontWeight: '500',
        },
        bold: {
          fontWeight: '700',
        },
      },
      defaultProps: {},
    },
    Button: {
      baseStyle: {
        fontWeight: '400',
      },
      variants: {
        base: {},
      },
      defaultProps: {
        variant: 'base',
      },
    },
  },
});
