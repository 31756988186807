import {
  Button,
  Flex,
  TabList,
  TabListProps,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  TabsProps,
  Text,
  useTab,
} from '@chakra-ui/react';
import React, { Fragment } from 'react';

export const ShipmentTabButton = React.forwardRef<
  HTMLElement,
  TabProps & { icon: JSX.Element; text: string }
>(({ icon, text, ...props }, ref) => {
  // 1. Reuse the `useTab` hook
  const tabProps = useTab({ ...props, ref });

  return (
    <Button
      w='100px'
      h='100px'
      borderColor='brandTransparent.500'
      borderWidth='1px'
      borderRadius='10'
      bg='white'
      _selected={{ bgGradient: 'linear(to-b, #00B1DC40, white)' }}
      {...tabProps}
      p='0'
      disabled={props.disabled}
    >
      <Flex
        w='100%'
        h='100%'
        display='flex'
        direction='column'
        align='center'
        justify='flex-end'
      >
        {icon}
        <Text fontSize='15px' lineHeight='1.2' mt='13px' mb='9px'>
          {text}
        </Text>
      </Flex>
    </Button>
  );
});

export const AccountTabButton = React.forwardRef<
  HTMLElement,
  TabProps & { icon: JSX.Element; text: string }
>(({ icon, text, ...props }, ref) => {
  // 1. Reuse the `useTab` hook
  const tabProps = useTab({ ...props, ref });

  return (
    <Button
      w='100%'
      flex='1'
      h={{ base: '98px', md: '74px' }}
      borderColor='brandTransparent.500'
      borderWidth='1px'
      borderRadius='10'
      bg='white'
      _selected={{ bgGradient: 'linear(to-b, #00B1DC40, white)' }}
      {...tabProps}
    >
      <Flex
        w='100%'
        display='flex'
        direction={{ base: 'column', md: 'row' }}
        justify='center'
        align='center'
      >
        {icon}
        <Text
          mt={{ base: '10px', md: '0px' }}
          ml={{ base: '0px', md: '10px' }}
          variant='medium'
          fontSize={{ baes: '14px', md: '15px' }}
          lineHeight={{ md: '1' }}
        >
          {text}
        </Text>
      </Flex>
    </Button>
  );
});

export const DetailsTabButton = React.forwardRef<
  HTMLElement,
  { title: string } & TabProps
>(({ title, ...props }, ref) => {
  // 1. Reuse the `useTab` hook
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps['aria-selected'];

  return (
    <Button w='100%' bg='white' m='0' p='0' {...tabProps}>
      <Flex
        w='100%'
        h='100%'
        display='flex'
        direction='column'
        justify='center'
        align='center'
        borderColor={isSelected ? 'brand.500' : '#F2FCFF'}
        fontSize='15px'
        borderBottomWidth='4px'
        pb='10px'
      >
        <Text
          variant={isSelected ? 'bold' : 'regular'}
          opacity={isSelected ? '1' : '0.5'}
        >
          {title}
        </Text>
        {tabProps.children}
      </Flex>
    </Button>
  );
});

export const ButtonIndexedTabs = React.forwardRef<
  HTMLInputElement,
  {
    childrens: {
      value: string;
      button: JSX.Element;
      panel: () => JSX.Element;
    }[];
    tablistProps?: TabListProps;
  } & Omit<TabsProps, 'children'>
>(
  ({ childrens, tablistProps, mb, ...props }, ref) => {
    return (
      <Tabs variant='unstyled' w='100%' isLazy {...props}>
        <TabList display='flex' w='100%' mb={mb ?? '30px'} {...tablistProps}>
          {childrens.map((c, index) => (
            <Fragment key={'tabButton' + index}>{c.button}</Fragment>
          ))}
        </TabList>
        <TabPanels>
          {childrens.map((c, index) => (
            <TabPanel p='0' key={'tab' + index}>
              {c.panel()}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    );
  }
  /*   />
   ); 
}*/
);

export const TitleIndexedTabs = React.forwardRef<
  HTMLInputElement,
  {
    childrens: {
      button: JSX.Element;
      panel: () => JSX.Element;
    }[];
  } & Omit<TabsProps, 'children'>
>(({ childrens, ...props }) => {
  return (
    <Tabs variant='unstyled' w='100%' isLazy {...props}>
      <TabList display='flex' w='100%'>
        {childrens.map((c, index) => (
          <Fragment key={'tabButton' + index}>{c.button}</Fragment>
        ))}
      </TabList>
      <TabPanels h='100%' mt='30px' pr='5px'>
        {childrens.map((c, index) => (
          <TabPanel p='0' key={'tab' + index}>
            {c.panel()}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
});
