import { Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { FinalizeCalendarIcon } from '../../components/icons/inputs';
import { useUserStore } from '../../store';
import { BookingInfo } from '../../types';
import { BookingStatusEnum } from '../../typings';
import { DetailsTabButton, TitleIndexedTabs } from '../forms/tabs';
import { PersonIcon } from '../icons/inputs';
import StyledButton from '../inputs/styled-button';
import { SectionTitle } from '../section-title';
import AccountWhiteWrapper from './white.wrapper';

const BookingInfoCard: FC<{ booking: BookingInfo }> = ({
  booking: { startDate, endDate, id, profiles, fullPrice, taxPrice },
}) => {
  const navigate = useNavigate();
  return (
    <Flex
      w='100%'
      bg='rgba(0,177,220,0.11)'
      borderColor='brand.500'
      borderRadius='11px'
      borderWidth='1px'
      px='20px'
      py='15px'
      mb='15px'
      onClick={() => navigate(`/order-summary/${id}`)}
      cursor='pointer'
      align='center'
      justify='space-between'
    >
      <Flex direction='column'>
        <Flex align='center'>
          <FinalizeCalendarIcon h='20px' mr='10px' w='auto' />
          <Text fontSize='15px' variant='bold' lineHeight='1.2'>{`${moment(
            startDate
          ).format('DD MMM')} - ${moment(endDate).format('DD MMM')}`}</Text>
        </Flex>
        {!!profiles?.length && (
          <Flex align='center' mt='15px'>
            <PersonIcon h='20px' mr='10px' w='auto' />
            <Text fontSize='15px' lineHeight='1.2'>{`${profiles.length} ${
              profiles.length === 1 ? 'Persona' : 'Persone'
            }`}</Text>
          </Flex>
        )}
      </Flex>
      <Text
        lineHeight='30px'
        fontSize='25px'
        textAlign='center'
        variant='bold'
        color='brand.500'
      >{`${fullPrice}€`}</Text>
    </Flex>
  );
};

const ACTIVE_STATUSES: BookingStatusEnum[] = [
  'ACCEPTED',
  'PENDING',
  'WAITING_PAYMENT',
];
const OLD_STATUSES: BookingStatusEnum[] = ['COMPLETED', 'REJECTED'];
const CANCEL_STATUSES: BookingStatusEnum[] = ['CANCELED', 'REFUNDED'];

const AccountBookings: FC = () => {
  const { user } = useUserStore(({ user }) => ({ user }), shallow);
  const navigate = useNavigate();

  const activeBookings = user?.bookings?.filter(
    ({ status }) => status && ACTIVE_STATUSES.includes(status)
  );

  const oldBookings = user?.bookings?.filter(
    ({ status, endDate }) =>
      moment(endDate).isBefore(new Date(), 'day') ||
      (status && OLD_STATUSES.includes(status))
  );

  const deletedBookings = user?.bookings?.filter(
    ({ status }) => status && CANCEL_STATUSES.includes(status)
  );

  const [bookingsNumber, setBookingsNumber] = useState<number>(
    activeBookings?.length ?? 0
  );

  if (!user) {
    return <></>;
  }

  return (
    <AccountWhiteWrapper>
      <SectionTitle mb='23px' textAlign='left' text='Prenotazioni' />
      <TitleIndexedTabs
        mb='30px'
        childrens={[
          {
            button: (
              <DetailsTabButton
                w='100%'
                title='Attive'
                onClick={() => {
                  setBookingsNumber(activeBookings?.length ?? 0);
                }}
              />
            ),
            panel: () => (
              <Flex direction='column'>
                {activeBookings?.map((b) => (
                  <BookingInfoCard booking={b} key={b.id} />
                ))}
              </Flex>
            ),
          },
          {
            button: (
              <DetailsTabButton
                w='100%'
                title='Passate'
                onClick={() => {
                  setBookingsNumber(oldBookings?.length ?? 0);
                }}
              />
            ),
            panel: () => (
              <Flex direction='column'>
                {oldBookings?.map((b) => (
                  <BookingInfoCard booking={b} key={b.id} />
                ))}
              </Flex>
            ),
          },
          {
            button: (
              <DetailsTabButton
                w='100%'
                title='Cancellate'
                onClick={() => {
                  setBookingsNumber(deletedBookings?.length ?? 0);
                }}
              />
            ),
            panel: () => (
              <Flex direction='column'>
                {deletedBookings?.map((b) => (
                  <BookingInfoCard booking={b} key={b.id} />
                ))}
              </Flex>
            ),
          },
        ]}
      />
      {bookingsNumber === 0 && (
        <Text w='100%' textAlign='center' mb='30px'>
          {'Non sono presenti prenotazioni'}
        </Text>
      )}
      <StyledButton onClick={() => navigate('/')}>
        Nuova Prenotazione
      </StyledButton>
    </AccountWhiteWrapper>
  );
};

export default AccountBookings;
