import {
  Text,
  forwardRef,
  HStack,
  VStack,
  BoxProps,
  Grid,
  GridItem,
} from '@chakra-ui/react';

export const ProfilesEquipmentLabel = ({
  num,
  label,
}: {
  num: number;
  label: string;
}) => {
  return (
    <>
      <HStack pl='4px' spacing={0}>
        <Text
          bgColor='brand.500'
          w='22px'
          h='22px'
          textAlign='center'
          color='white'
          borderRadius='11px'
          textStyle='book'
          fontSize='15px'
          pr='1px'
        >
          {num}
        </Text>
        <Text textStyle='book' color='brand.500' pl='15px' fontSize='15px'>
          {label}
        </Text>
      </HStack>
    </>
  );
};

export const ProfilesPreferencesLabel = forwardRef<
  {
    label: string;
    secondLine?: string;
    disclaimer?: string;
    secondDisclaimer?: string;
  } & BoxProps,
  'div'
>(({ label, secondLine, disclaimer, secondDisclaimer, ...props }, ref) => {
  return (
    <VStack
      pl='4px'
      spacing={0}
      w='100%'
      //maxW="283px"
      {...props}
      ref={ref}
    >
      <Text
        textStyle='book'
        fontSize='15px'
        w='100%'
        color={props.color ?? 'black'}
        textAlign={props.textAlign ?? 'left'}
        lineHeight={props.lineHeight ?? '15px'}
      >
        {label}
      </Text>
      {secondLine && (
        <Text
          textStyle='book'
          fontSize='15px'
          w='100%'
          textAlign='left'
          lineHeight={props.lineHeight ?? '15px'}
        >
          {secondLine}
        </Text>
      )}
      {disclaimer && (
        <Grid
          templateColumns='repeat(2,1fr)'
          w='100%'
          justifyContent='space-between'
        >
          <GridItem
            colSpan={secondDisclaimer ? 1 : 2}
            maxW={secondDisclaimer ? '90px' : '100%'}
          >
            <Text
              fontSize='10px'
              color='brand.500'
              textAlign='left'
              lineHeight='15px'
              w='100%'
            >
              {disclaimer}
            </Text>
          </GridItem>

          {secondDisclaimer && (
            <GridItem colSpan={{ base: 2, sm: 1 }}>
              <Text
                fontSize='10px'
                color='brand.500'
                textAlign='left'
                lineHeight='15px'
                pr={{ base: '', sm: '5px' }}
                minW='180px'
              >
                {secondDisclaimer}
              </Text>
            </GridItem>
          )}
        </Grid>
      )}
    </VStack>
  );
});
