import { IconProps, Icon } from '@chakra-ui/react';

export const MenuIcon = (props: IconProps) => (
  <Icon viewBox='0 0 27 16' {...props}>
    <path
      id='Icon_ionic-md-menu'
      data-name='Icon ionic-md-menu'
      d='M4.5,25h27V22.333H4.5Zm0-6.667h27V15.667H4.5ZM4.5,9v2.667h27V9Z'
      transform='translate(-4.5 -9)'
    />
  </Icon>
);

export const FacebookIcon = (props: IconProps) => (
  <Icon viewBox='0 0 25.159 25.159' {...props}>
    <path
      id='Icon_awesome-facebook-square'
      data-name='Icon awesome-facebook-square'
      d='M22.464,2.25H2.7A2.7,2.7,0,0,0,0,4.946V24.714a2.7,2.7,0,0,0,2.7,2.7H10.4V18.856H6.865V14.83H10.4V11.761c0-3.49,2.078-5.418,5.26-5.418a21.434,21.434,0,0,1,3.118.272V10.04H17.026a2.013,2.013,0,0,0-2.27,2.175V14.83h3.863L18,18.856H14.756v8.554h7.708a2.7,2.7,0,0,0,2.7-2.7V4.946A2.7,2.7,0,0,0,22.464,2.25Z'
      transform='translate(0 -2.25)'
      fill='#fff'
    />
  </Icon>
);

export const InstagramIcon = (props: IconProps) => (
  <Icon viewBox='0 0 27 27' {...props}>
    <g
      id='Icon_ionic-logo-instagram'
      data-name='Icon ionic-logo-instagram'
      transform='translate(-4.5 -4.5)'
    >
      <path
        id='Tracciato_1307'
        data-name='Tracciato 1307'
        d='M23.625,6.75a5.642,5.642,0,0,1,5.625,5.625v11.25a5.642,5.642,0,0,1-5.625,5.625H12.375A5.642,5.642,0,0,1,6.75,23.625V12.375A5.642,5.642,0,0,1,12.375,6.75h11.25m0-2.25H12.375A7.9,7.9,0,0,0,4.5,12.375v11.25A7.9,7.9,0,0,0,12.375,31.5h11.25A7.9,7.9,0,0,0,31.5,23.625V12.375A7.9,7.9,0,0,0,23.625,4.5Z'
        fill='#fff'
      />
      <path
        id='Tracciato_1308'
        data-name='Tracciato 1308'
        d='M25.313,12.375A1.688,1.688,0,1,1,27,10.688,1.683,1.683,0,0,1,25.313,12.375Z'
        fill='#fff'
      />
      <path
        id='Tracciato_1309'
        data-name='Tracciato 1309'
        d='M18,13.5A4.5,4.5,0,1,1,13.5,18,4.5,4.5,0,0,1,18,13.5m0-2.25A6.75,6.75,0,1,0,24.75,18,6.752,6.752,0,0,0,18,11.25Z'
        fill='#fff'
      />
    </g>
  </Icon>
);

export const MailIcon = (props: IconProps) => (
  <Icon viewBox='0 0 29.934 23.719' {...props}>
    <g
      id='Icon_feather-mail'
      data-name='Icon feather-mail'
      transform='translate(-1.607 -5)'
    >
      <path
        id='Tracciato_1310'
        data-name='Tracciato 1310'
        d='M5.715,6H27.434a2.723,2.723,0,0,1,2.715,2.715V25a2.723,2.723,0,0,1-2.715,2.715H5.715A2.723,2.723,0,0,1,3,25V8.715A2.723,2.723,0,0,1,5.715,6Z'
        fill='none'
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        id='Tracciato_1311'
        data-name='Tracciato 1311'
        d='M30.148,9,16.574,18.5,3,9'
        transform='translate(0 -0.285)'
        fill='none'
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </g>
  </Icon>
);

export const GoogleLoginIcon = (props: IconProps) => (
  <Icon xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' {...props}>
    <path
      d='M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z'
      fill='#4285F4'
    />
    <path
      d='M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z'
      fill='#34A853'
    />
    <path
      d='M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z'
      fill='#FBBC05'
    />
    <path
      d='M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z'
      fill='#EA4335'
    />
    <path d='M1 1h22v22H1z' fill='none' />
  </Icon>
);

export const FacebookLoginIcon = (props: IconProps) => (
  <Icon width='12.997' height='24.268' viewBox='0 0 12.997 24.268' {...props}>
    <path
      id='Icon_awesome-facebook-f'
      data-name='Icon awesome-facebook-f'
      d='M13.755,13.65l.674-4.392H10.215V6.409a2.2,2.2,0,0,1,2.476-2.373h1.916V.3a23.363,23.363,0,0,0-3.4-.3C7.736,0,5.467,2.1,5.467,5.911V9.259H1.609V13.65H5.467V24.268h4.748V13.65Z'
      transform='translate(-1.609)'
      fill='#4267B2'
    />
  </Icon>
);

export const CTAUnderline1 = (props: IconProps) => (
  <Icon {...props} viewBox='0 0 212.785 33.083'>
    <rect
      id='Rettangolo_1189'
      data-name='Rettangolo 1189'
      width='230.017'
      height='25.699'
      transform='matrix(0.999, -0.035, 0.035, 0.999, 0, 7.399)'
      fill='#e6ff00'
    />
  </Icon>
);

export const CTAUnderline2 = (props: IconProps) => (
  <Icon {...props} viewBox='0 0 278.543 33.776'>
    <rect
      id='Rettangolo_1190'
      data-name='Rettangolo 1190'
      width='277.871px'
      height='24.093'
      transform='matrix(0.999, -0.035, 0.035, 0.999, 0, 9.698)'
      fill='#e6ff00'
    />
  </Icon>
);

export const DiscountLine = (props: IconProps) => (
  <Icon {...props} viewBox='0 0 212.785 33.083'>
    <rect
      id='Rettangolo_1189'
      data-name='Rettangolo 1189'
      width='230.017'
      height='5'
      transform='matrix(0.999, -0.035, 0.035, 0.999, 0, 7.399)'
      fill='red'
    />
  </Icon>
);

export const FinalizeClock = (props: IconProps) => (
  <Icon {...props} viewBox='0 0 22 22'>
    <g
      id='Icon_ionic-md-time'
      data-name='Icon ionic-md-time'
      transform='translate(22 22) rotate(180)'
    >
      <path
        id='Tracciato_1368'
        data-name='Tracciato 1368'
        d='M10.989,0A11,11,0,1,0,22,11,11,11,0,0,0,10.989,0ZM11,19.8A8.8,8.8,0,1,1,19.8,11,8.8,8.8,0,0,1,11,19.8Z'
        transform='translate(0)'
        fill='#00b1dc'
      />
      <path
        id='Tracciato_1369'
        data-name='Tracciato 1369'
        d='M1.65,0H0V6.6l5.775,3.464L6.6,8.71,1.65,5.775Z'
        transform='translate(9.9 5.5)'
        fill='#00b1dc'
      />
    </g>
  </Icon>
);

export const CrossIcon = (props: IconProps) => (
  <Icon viewBox='0 0 23.828 23.828' color='brand.500' {...props}>
    <path
      id='Icon_material-close'
      data-name='Icon material-close'
      d='M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z'
      transform='translate(-6.086 -6.086)'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='2'
    />
  </Icon>
);
