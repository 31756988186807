import { Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { PageContainer } from '../components/container';
import SimpleInputText from '../components/inputs/input-text';
import StyledButton from '../components/inputs/styled-button';
import { getAuthApi, notifyServerError } from '../services/api/open-api';
import { useNotification } from '../store';

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>(
    new URLSearchParams(useLocation().search).get('email') ?? ''
  );
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);
  const forgotPassword = async () => {
    if (!email) {
      notify('error', { title: 'Errore', text: 'Compila il campo e-mail' });
    }
    try {
      await getAuthApi().authSendCode({ type: 'password', email });
      notify('email', {
        title: 'E-mail inviata',
        text: 'Link di recupero inviato con successo',
      });
    } catch (e) {
      notifyServerError(e, notify);
    }
  };
  return (
    <PageContainer>
      <Flex
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        height='100%'
        maxH='300px'
      >
        <SimpleInputText
          maxW='315px'
          defaultValue={email}
          placeholder='E-mail'
          label='Reset Password'
          onChange={(e) => setEmail(e.target.value)}
          type='email'
        />
        <StyledButton mt='30px' onClick={forgotPassword}>
          Invia link di recupero
        </StyledButton>
      </Flex>
    </PageContainer>
  );
};
