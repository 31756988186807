import {
  AccordionItem,
  AccordionPanel,
  FlexProps,
  forwardRef,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import shallow from 'zustand/shallow';
import { useBookingStore, useUserStore } from '../../store';
import {
  BookedBoardBootsType,
  BookedBoardType,
  BookedHelmetType,
  BookedSkiBootsType,
  BookedSkiType,
  BookingInfo,
  OwnedBootsType,
  PreviewDataType,
  Profile,
} from '../../types';
import SimpleInputText from '../inputs/input-text';
import StyledButton from '../inputs/styled-button';
import { ProfileFormAccordionButton } from './profile-form-accordion-btn';
import { ProfilesPreferencesLabel } from './profile-form-labels';
import { PreferencesSelectionCheckbox } from './profile-form-selection-checkbox';

export const PreferencesForm = forwardRef<
  {
    details: {};
    page: 'finalize' | 'account' | 'profiles' | 'summary';
    errors: { [key in keyof Profile]?: unknown };
    savedProfileData: Profile | undefined;
    kitState: PreviewDataType;
    setKitState: (kitState: PreviewDataType) => void;
    index: number;
    profileId: string;
    handleBlur: (
      fieldValue?: string,
      fieldName?:
        | keyof Profile
        | `bookedHelmet.${keyof BookedHelmetType}`
        | `bookedSkiBoots.${keyof BookedSkiBootsType}`
        | `bookedBoardBoots.${keyof BookedBoardBootsType}`
        | `ownedBoots.${keyof OwnedBootsType}`
        | `bookedBoard.${keyof BookedBoardType}`
        | `bookedSki.${keyof BookedSkiType}`
    ) => void;
    setGuideToggled?: (
      bool: false | 'ankle' | 'head' | 'foot' | 'boots'
    ) => void;
    proceed?: () => void;
  } & FlexProps,
  'div'
>(
  (
    {
      details,
      page,
      kitState,
      setKitState,
      savedProfileData,
      index,
      profileId,
      handleBlur,
      errors,
      setGuideToggled,
      proceed,
      ...props
    },
    ref
  ) => {
    const { user } = useUserStore(({ user }) => ({ user }), shallow);
    const { profilesToSave, setProfilesToSave } = useBookingStore(
      ({ profilesToSave, setProfilesToSave }) => ({
        profilesToSave,
        setProfilesToSave,
      }),
      shallow
    );

    const generateName = (
      name:
        | keyof Profile
        | `bookedHelmet.${keyof BookedHelmetType}`
        | `bookedSkiBoots.${keyof BookedSkiBootsType}`
        | `bookedBoardBoots.${keyof BookedBoardBootsType}`
        | `ownedBoots.${keyof OwnedBootsType}`
        | `bookedBoard.${keyof BookedBoardType}`
        | `bookedSki.${keyof BookedSkiType}`
    ) => {
      return `profiles.${index}.${name}` as const;
    };

    const {
      register,
      unregister,
      setValue,
      getValues,
      watch,
      resetField,
      clearErrors,
      setError,
      formState,
    } = useFormContext<BookingInfo>();

    const preferenceBtnRef = React.useRef<HTMLButtonElement | null>(null);

    const handleClick = () => {
      preferenceBtnRef.current?.click();
      proceed?.();
    };
    const profileHasId = !!getValues(generateName('id'));

    return (
      <AccordionItem border='none' isDisabled={page === 'summary'}>
        <h2>
          <ProfileFormAccordionButton
            label='Preferenze'
            details={details}
            detailsMax={1}
            proceedRef={preferenceBtnRef}
            profileId={profileId}
            isSummary={page === 'summary'}
            errors={{}}
          />
        </h2>
        <AccordionPanel
          p='30px 16px 30px 16px'
          bgColor='rgba(242,252,255,1)'
          borderTopWidth='1px'
          borderTopColor='brandTransparent.500'
        >
          <VStack w='100%' maxW='283px' mx='auto' spacing={0}>
            {page !== 'summary' && (
              <>
                <ProfilesPreferencesLabel
                  label='N.B:  Il casco è obbligatorio per i minori di 18 anni. '
                  mb='14px'
                  textAlign='center'
                />

                <PreferencesSelectionCheckbox
                  previewData={kitState}
                  kitInfo={['Casco']}
                  checkedCondition={kitState.helmet}
                  onClick={() => {
                    setKitState({
                      ...kitState,
                      helmet: !kitState.helmet,
                    });

                    if (!getValues(generateName('headCircCM'))) {
                      setError(generateName('headCircCM'), {
                        type: 'required',
                      });
                    }
                    handleBlur();
                  }}
                  mt='14px'
                />
              </>
            )}

            <SimpleInputText
              {...register(generateName('notes'))}
              label='Note'
              maxW='283px'
              boxShadow='0 18px 40px var(--chakra-colors-brandTransparent-350)'
              h='111px'
              pt={'25px'}
              placeholder='Es. Rossi'
              textArea
              onBlur={(e) => {
                handleBlur(e.target.value, 'notes');
              }}
            />
            {user && page !== 'summary' && (
              <>
                <ProfilesPreferencesLabel
                  label={
                    'Vuoi ' +
                    (profileHasId ? 'aggiornare' : 'salvare') +
                    ' il profilo per '
                  }
                  secondLine='la prossima volta?'
                  pt='15.3px'
                  pb='13.7px'
                  lineHeight='18px'
                />

                <PreferencesSelectionCheckbox
                  previewData={kitState}
                  kitInfo={
                    profileHasId ? ['Aggiorna profilo'] : ['Salva profilo']
                  }
                  checkedCondition={
                    kitState.saveProfile ||
                    (page === 'finalize' && profilesToSave.includes(index))
                  }
                  onClick={() => {
                    setKitState({
                      ...kitState,
                      saveProfile: !kitState.saveProfile,
                      noPreference: false,
                    });
                    setProfilesToSave(
                      kitState.saveProfile
                        ? profilesToSave.filter((value) => value !== index)
                        : [...profilesToSave, index]
                    );
                    handleBlur();
                  }}
                />
              </>
            )}
            <Spacer pb='25px' />
            {page !== 'summary' && (
              <StyledButton onClick={handleClick}>Ok, Prosegui</StyledButton>
            )}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    );
  }
);
