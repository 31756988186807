/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { BookingInfo, PreviewDataType } from '../../types';
import { StarOff, StarOn } from '../icons/inputs';

export const VotingField = forwardRef<
  {
    previewData: PreviewDataType;
    setPreviewData: (previewData: PreviewDataType) => void;
    setValue: UseFormSetValue<BookingInfo>;
    profileIndex: number;
    isFinalize?: boolean;
    storeUpdate: () => void;
  } & FlexProps,
  'div'
>(
  (
    {
      previewData,
      setPreviewData,
      setValue,
      profileIndex,
      isFinalize,
      storeUpdate,
      ...props
    },
    ref
  ) => {
    const [hover, setHover] = useState<number>(0);

    const premiumUpdate = (bool: boolean) => {
      if (previewData.type === 'SKI') {
        setValue(`profiles.${profileIndex}.bookedSki.premium`, bool);
      }
      if (previewData.type === 'BOARD') {
        setValue(`profiles.${profileIndex}.bookedBoard.premium`, bool);
      }
    };

    return (
      <>
        <Flex
          align='center'
          justify='space-between'
          w='100%'
          {...props}
          ref={ref}
        >
          {Array.from<number>({ length: 5 })
            .fill(0)
            ?.map((star, index, array) => (
              <Box
                cursor='pointer'
                tabIndex={0}
                w='44px'
                h='100%'
                onClick={() => {
                  if (previewData.level === index + 1) {
                    setPreviewData({ ...previewData, level: 0 });
                    setValue(`profiles.${profileIndex}.level`, undefined);
                    if (previewData.main) {
                      premiumUpdate(false);
                    }
                  } else {
                    if (index < 4) {
                      setPreviewData({
                        ...previewData,
                        level: index + 1,
                        premium: false,
                      });
                      if (previewData.main) {
                        premiumUpdate(false);
                      }
                    }
                    if (index === 4) {
                      setPreviewData({
                        ...previewData,
                        level: index + 1,
                      });
                      if (previewData.premium && previewData.main) {
                        premiumUpdate(true);
                      }
                    }
                    const level =
                      index === 4 ? 'EXPERT' : index > 1 ? 'AVERAGE' : 'NOVICE';
                    setValue(`profiles.${profileIndex}.level`, level);
                  }
                  if (isFinalize) {
                    storeUpdate();
                  }
                }}
                onMouseEnter={() => {
                  setHover(index + 1);
                }}
                onMouseLeave={() => {
                  setHover(0);
                }}
                key={'star' + index}
              >
                {hover !== 0 && hover > index ? (
                  <StarOn h='100%' w='auto' />
                ) : previewData.level !== undefined &&
                  previewData.level !== 0 &&
                  previewData.level > index ? (
                  <StarOn h='100%' w='auto' />
                ) : (
                  <StarOff h='100%' w='auto' />
                )}
              </Box>
            ))}
        </Flex>
      </>
    );
  }
);
