/* tslint:disable */
/* eslint-disable */
/**
 * Test swagger
 * Testing the Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Area
 */
export interface Area {
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    'province': string;
    /**
     * 
     * @type {Array<ItemWareHouse>}
     * @memberof Area
     */
    'items'?: Array<ItemWareHouse>;
    /**
     * 
     * @type {Array<Point>}
     * @memberof Area
     */
    'points'?: Array<Point>;
    /**
     * 
     * @type {Array<Track>}
     * @memberof Area
     */
    'tracks'?: Array<Track>;
}
/**
 * 
 * @export
 * @interface AreaBrowse200Response
 */
export interface AreaBrowse200Response {
    /**
     * 
     * @type {Array<Area>}
     * @memberof AreaBrowse200Response
     */
    'elements': Array<Area>;
    /**
     * 
     * @type {number}
     * @memberof AreaBrowse200Response
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface AreaBrowseBody
 */
export interface AreaBrowseBody {
    /**
     * 
     * @type {UserBrowseBodyPagination}
     * @memberof AreaBrowseBody
     */
    'pagination'?: UserBrowseBodyPagination;
    /**
     * 
     * @type {UserBrowseBodySorting}
     * @memberof AreaBrowseBody
     */
    'sorting'?: UserBrowseBodySorting;
    /**
     * 
     * @type {AreaBrowseBodyFilter}
     * @memberof AreaBrowseBody
     */
    'filter'?: AreaBrowseBodyFilter;
}
/**
 * 
 * @export
 * @interface AreaBrowseBodyFilter
 */
export interface AreaBrowseBodyFilter {
    /**
     * 
     * @type {string}
     * @memberof AreaBrowseBodyFilter
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AreaRead200Response
 */
export interface AreaRead200Response {
    /**
     * 
     * @type {Area}
     * @memberof AreaRead200Response
     */
    'element'?: Area;
}
/**
 * 
 * @export
 * @interface Auth
 */
export interface Auth {
    /**
     * 
     * @type {string}
     * @memberof Auth
     */
    'sessionToken': string;
}
/**
 * 
 * @export
 * @interface AuthChangePasswordRequest
 */
export interface AuthChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthChangePasswordRequest
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof AuthChangePasswordRequest
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface AuthConfirmEmail200Response
 */
export interface AuthConfirmEmail200Response {
    /**
     * 
     * @type {string}
     * @memberof AuthConfirmEmail200Response
     */
    'sessionToken': string;
}
/**
 * 
 * @export
 * @interface AuthConfirmEmailRequest
 */
export interface AuthConfirmEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthConfirmEmailRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface AuthForgotPasswordRequest
 */
export interface AuthForgotPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthForgotPasswordRequest
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AuthForgotPasswordRequest
     */
    'newPass': string;
}
/**
 * 
 * @export
 * @interface AuthLoginRequest
 */
export interface AuthLoginRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthLoginRequest
     */
    'type': AuthLoginRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AuthLoginRequest
     */
    'identifier': string;
    /**
     * 
     * @type {string}
     * @memberof AuthLoginRequest
     */
    'password'?: string;
}

export const AuthLoginRequestTypeEnum = {
    Email: 'email',
    Facebook: 'facebook',
    Google: 'google'
} as const;

export type AuthLoginRequestTypeEnum = typeof AuthLoginRequestTypeEnum[keyof typeof AuthLoginRequestTypeEnum];

/**
 * 
 * @export
 * @interface AuthSendCodeRequest
 */
export interface AuthSendCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthSendCodeRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AuthSendCodeRequest
     */
    'type': AuthSendCodeRequestTypeEnum;
}

export const AuthSendCodeRequestTypeEnum = {
    Email: 'email',
    Password: 'password',
    Booking: 'booking'
} as const;

export type AuthSendCodeRequestTypeEnum = typeof AuthSendCodeRequestTypeEnum[keyof typeof AuthSendCodeRequestTypeEnum];

/**
 * 
 * @export
 * @interface AuthUser200Response
 */
export interface AuthUser200Response {
    /**
     * 
     * @type {User}
     * @memberof AuthUser200Response
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface AvailabilityBody
 */
export interface AvailabilityBody {
    /**
     * 
     * @type {string}
     * @memberof AvailabilityBody
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityBody
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityBody
     */
    'areaId': string;
}
/**
 * 
 * @export
 * @interface Booking
 */
export interface Booking {
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'accomodationAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'accomodationNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'trackAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'pointAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'pickupTrackAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'areaId'?: string;
    /**
     * 
     * @type {Area}
     * @memberof Booking
     */
    'area'?: Area;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'coupon'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'delivery'?: BookingDeliveryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'acceptedTerms': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'hasStoreShoe'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'hasInsurance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'documents'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'paymentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'userEmail': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'userPhone': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'cancelReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'rejectReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'status'?: BookingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'fullPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'taxPrice'?: number;
    /**
     * 
     * @type {Array<BookingProfile>}
     * @memberof Booking
     */
    'profiles'?: Array<BookingProfile>;
}

export const BookingDeliveryEnum = {
    Point: 'POINT',
    Track: 'TRACK',
    Accomodation: 'ACCOMODATION'
} as const;

export type BookingDeliveryEnum = typeof BookingDeliveryEnum[keyof typeof BookingDeliveryEnum];
export const BookingStatusEnum = {
    WaitingPayment: 'WAITING_PAYMENT',
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Canceled: 'CANCELED',
    Completed: 'COMPLETED',
    Refunded: 'REFUNDED'
} as const;

export type BookingStatusEnum = typeof BookingStatusEnum[keyof typeof BookingStatusEnum];

/**
 * 
 * @export
 * @interface Booking1
 */
export interface Booking1 {
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'accomodationAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'accomodationNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'trackAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'pointAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'pickupTrackAddress': string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'areaId'?: string;
    /**
     * 
     * @type {Area}
     * @memberof Booking1
     */
    'area'?: Area;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'coupon'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'delivery'?: Booking1DeliveryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Booking1
     */
    'acceptedTerms': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking1
     */
    'hasStoreShoe'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking1
     */
    'hasInsurance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'documents'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'paymentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'userEmail': string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'userPhone': string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'cancelReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'rejectReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'status'?: Booking1StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Booking1
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Booking1
     */
    'fullPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Booking1
     */
    'taxPrice'?: number;
    /**
     * 
     * @type {Array<BookingProfile>}
     * @memberof Booking1
     */
    'profiles': Array<BookingProfile>;
}

export const Booking1DeliveryEnum = {
    Point: 'POINT',
    Track: 'TRACK',
    Accomodation: 'ACCOMODATION'
} as const;

export type Booking1DeliveryEnum = typeof Booking1DeliveryEnum[keyof typeof Booking1DeliveryEnum];
export const Booking1StatusEnum = {
    WaitingPayment: 'WAITING_PAYMENT',
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Canceled: 'CANCELED',
    Completed: 'COMPLETED',
    Refunded: 'REFUNDED'
} as const;

export type Booking1StatusEnum = typeof Booking1StatusEnum[keyof typeof Booking1StatusEnum];

/**
 * 
 * @export
 * @interface BookingBrowse200Response
 */
export interface BookingBrowse200Response {
    /**
     * 
     * @type {Array<Booking>}
     * @memberof BookingBrowse200Response
     */
    'elements': Array<Booking>;
    /**
     * 
     * @type {number}
     * @memberof BookingBrowse200Response
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface BookingBrowseBody
 */
export interface BookingBrowseBody {
    /**
     * 
     * @type {UserBrowseBodyPagination}
     * @memberof BookingBrowseBody
     */
    'pagination'?: UserBrowseBodyPagination;
    /**
     * 
     * @type {UserBrowseBodySorting}
     * @memberof BookingBrowseBody
     */
    'sorting'?: UserBrowseBodySorting;
    /**
     * 
     * @type {BookingBrowseBodyFilter}
     * @memberof BookingBrowseBody
     */
    'filter'?: BookingBrowseBodyFilter;
}
/**
 * 
 * @export
 * @interface BookingBrowseBodyFilter
 */
export interface BookingBrowseBodyFilter {
    /**
     * 
     * @type {string}
     * @memberof BookingBrowseBodyFilter
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingBrowseBodyFilter
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingBrowseBodyFilter
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingBrowseBodyFilter
     */
    'userEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingBrowseBodyFilter
     */
    'userPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingBrowseBodyFilter
     */
    'areaId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookingBrowseBodyFilter
     */
    'status'?: Array<BookingBrowseBodyFilterStatusEnum>;
}

export const BookingBrowseBodyFilterStatusEnum = {
    WaitingPayment: 'WAITING_PAYMENT',
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Canceled: 'CANCELED',
    Completed: 'COMPLETED',
    Refunded: 'REFUNDED'
} as const;

export type BookingBrowseBodyFilterStatusEnum = typeof BookingBrowseBodyFilterStatusEnum[keyof typeof BookingBrowseBodyFilterStatusEnum];

/**
 * 
 * @export
 * @interface BookingChangeStatusRequest
 */
export interface BookingChangeStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof BookingChangeStatusRequest
     */
    'status': BookingChangeStatusRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BookingChangeStatusRequest
     */
    'reason'?: string;
}

export const BookingChangeStatusRequestStatusEnum = {
    WaitingPayment: 'WAITING_PAYMENT',
    Pending: 'PENDING',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Canceled: 'CANCELED',
    Completed: 'COMPLETED',
    Refunded: 'REFUNDED'
} as const;

export type BookingChangeStatusRequestStatusEnum = typeof BookingChangeStatusRequestStatusEnum[keyof typeof BookingChangeStatusRequestStatusEnum];

/**
 * 
 * @export
 * @interface BookingNew200Response
 */
export interface BookingNew200Response {
    /**
     * 
     * @type {string}
     * @memberof BookingNew200Response
     */
    'checkoutSession': string;
}
/**
 * 
 * @export
 * @interface BookingNewRequest
 */
export interface BookingNewRequest {
    /**
     * 
     * @type {string}
     * @memberof BookingNewRequest
     */
    'redirectUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BookingNewRequest
     */
    'coupon'?: string;
    /**
     * 
     * @type {Booking1}
     * @memberof BookingNewRequest
     */
    'booking'?: Booking1;
}
/**
 * 
 * @export
 * @interface BookingPrice
 */
export interface BookingPrice {
    /**
     * 
     * @type {BookingPriceBase}
     * @memberof BookingPrice
     */
    'base': BookingPriceBase;
    /**
     * 
     * @type {BookingPriceExtra}
     * @memberof BookingPrice
     */
    'extra': BookingPriceExtra;
    /**
     * 
     * @type {number}
     * @memberof BookingPrice
     */
    'appliedOffer'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingPrice
     */
    'tax': number;
    /**
     * 
     * @type {number}
     * @memberof BookingPrice
     */
    'discount': number;
    /**
     * 
     * @type {number}
     * @memberof BookingPrice
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface BookingPriceBase
 */
export interface BookingPriceBase {
    /**
     * 
     * @type {number}
     * @memberof BookingPriceBase
     */
    'SKI'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingPriceBase
     */
    'BOARD'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingPriceBase
     */
    'SKIBOOTS'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingPriceBase
     */
    'BOARDBOOTS'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingPriceBase
     */
    'HELMET'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingPriceBase
     */
    'SKIBUNDLE'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingPriceBase
     */
    'BOARDBUNDLE'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingPriceBase
     */
    'SKIPREMIUM'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingPriceBase
     */
    'BOARDPREMIUM'?: number;
}
/**
 * 
 * @export
 * @interface BookingPriceExtra
 */
export interface BookingPriceExtra {
    /**
     * 
     * @type {number}
     * @memberof BookingPriceExtra
     */
    'SHOES'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingPriceExtra
     */
    'INSURANCE'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingPriceExtra
     */
    'TRACK'?: number;
}
/**
 * 
 * @export
 * @interface BookingProfile
 */
export interface BookingProfile {
    /**
     * 
     * @type {string}
     * @memberof BookingProfile
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProfile
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof BookingProfile
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof BookingProfile
     */
    'gender'?: BookingProfileGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof BookingProfile
     */
    'heightCM'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingProfile
     */
    'weightKG'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingProfile
     */
    'ankleCM'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingProfile
     */
    'footLengthCM'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingProfile
     */
    'footWidthCM'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingProfile
     */
    'headCircCM'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingProfile
     */
    'shoeNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof BookingProfile
     */
    'stance'?: BookingProfileStanceEnum;
    /**
     * 
     * @type {string}
     * @memberof BookingProfile
     */
    'level'?: BookingProfileLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof BookingProfile
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingProfile
     */
    'notes'?: string;
    /**
     * 
     * @type {BookingProfileOwnedBoots}
     * @memberof BookingProfile
     */
    'ownedBoots'?: BookingProfileOwnedBoots;
    /**
     * 
     * @type {BookingProfileBookedBoard}
     * @memberof BookingProfile
     */
    'bookedBoard'?: BookingProfileBookedBoard;
    /**
     * 
     * @type {BookingProfileBookedSki}
     * @memberof BookingProfile
     */
    'bookedSki'?: BookingProfileBookedSki;
    /**
     * 
     * @type {BookingProfileBookedSkiBoots}
     * @memberof BookingProfile
     */
    'bookedSkiBoots'?: BookingProfileBookedSkiBoots;
    /**
     * 
     * @type {BookingProfileBookedSkiBoots}
     * @memberof BookingProfile
     */
    'bookedBoardBoots'?: BookingProfileBookedSkiBoots;
    /**
     * 
     * @type {BookingProfileBookedSkiBoots}
     * @memberof BookingProfile
     */
    'bookedHelmet'?: BookingProfileBookedSkiBoots;
}

export const BookingProfileGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type BookingProfileGenderEnum = typeof BookingProfileGenderEnum[keyof typeof BookingProfileGenderEnum];
export const BookingProfileStanceEnum = {
    Regular: 'REGULAR',
    Goofy: 'GOOFY'
} as const;

export type BookingProfileStanceEnum = typeof BookingProfileStanceEnum[keyof typeof BookingProfileStanceEnum];
export const BookingProfileLevelEnum = {
    Novice: 'NOVICE',
    Average: 'AVERAGE',
    Expert: 'EXPERT'
} as const;

export type BookingProfileLevelEnum = typeof BookingProfileLevelEnum[keyof typeof BookingProfileLevelEnum];

/**
 * 
 * @export
 * @interface BookingProfileBookedBoard
 */
export interface BookingProfileBookedBoard {
    /**
     * 
     * @type {boolean}
     * @memberof BookingProfileBookedBoard
     */
    'premium'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BookingProfileBookedBoard
     */
    'size'?: string;
}
/**
 * 
 * @export
 * @interface BookingProfileBookedSki
 */
export interface BookingProfileBookedSki {
    /**
     * 
     * @type {string}
     * @memberof BookingProfileBookedSki
     */
    'size'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BookingProfileBookedSki
     */
    'premium'?: boolean;
}
/**
 * 
 * @export
 * @interface BookingProfileBookedSkiBoots
 */
export interface BookingProfileBookedSkiBoots {
    /**
     * 
     * @type {string}
     * @memberof BookingProfileBookedSkiBoots
     */
    'size'?: string;
}
/**
 * 
 * @export
 * @interface BookingProfileOwnedBoots
 */
export interface BookingProfileOwnedBoots {
    /**
     * 
     * @type {string}
     * @memberof BookingProfileOwnedBoots
     */
    'size': string;
    /**
     * 
     * @type {number}
     * @memberof BookingProfileOwnedBoots
     */
    'bootsMM': number;
    /**
     * 
     * @type {string}
     * @memberof BookingProfileOwnedBoots
     */
    'brand': string;
}
/**
 * 
 * @export
 * @interface BookingRetrieve200Response
 */
export interface BookingRetrieve200Response {
    /**
     * 
     * @type {Booking}
     * @memberof BookingRetrieve200Response
     */
    'element'?: Booking;
}
/**
 * 
 * @export
 * @interface CalendarAvailability
 */
export interface CalendarAvailability {
    /**
     * 
     * @type {{ [key: string]: CalendarAvailabilityAvailabilityValue; }}
     * @memberof CalendarAvailability
     */
    'availability'?: { [key: string]: CalendarAvailabilityAvailabilityValue; };
}
/**
 * 
 * @export
 * @interface CalendarAvailabilityAvailabilityValue
 */
export interface CalendarAvailabilityAvailabilityValue {
    /**
     * 
     * @type {boolean}
     * @memberof CalendarAvailabilityAvailabilityValue
     */
    'available': boolean;
    /**
     * 
     * @type {CalendarAvailabilityAvailabilityValueOffer}
     * @memberof CalendarAvailabilityAvailabilityValue
     */
    'offer'?: CalendarAvailabilityAvailabilityValueOffer;
}
/**
 * 
 * @export
 * @interface CalendarAvailabilityAvailabilityValueOffer
 */
export interface CalendarAvailabilityAvailabilityValueOffer {
    /**
     * 
     * @type {number}
     * @memberof CalendarAvailabilityAvailabilityValueOffer
     */
    'percentage': number;
    /**
     * 
     * @type {number}
     * @memberof CalendarAvailabilityAvailabilityValueOffer
     */
    'minDays': number;
}
/**
 * 
 * @export
 * @interface Coupon
 */
export interface Coupon {
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    'percentage': number;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    'areaId'?: string;
}
/**
 * 
 * @export
 * @interface CouponBrowse200Response
 */
export interface CouponBrowse200Response {
    /**
     * 
     * @type {Array<Coupon>}
     * @memberof CouponBrowse200Response
     */
    'elements': Array<Coupon>;
    /**
     * 
     * @type {number}
     * @memberof CouponBrowse200Response
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface CouponBrowseBody
 */
export interface CouponBrowseBody {
    /**
     * 
     * @type {UserBrowseBodyPagination}
     * @memberof CouponBrowseBody
     */
    'pagination'?: UserBrowseBodyPagination;
    /**
     * 
     * @type {UserBrowseBodySorting}
     * @memberof CouponBrowseBody
     */
    'sorting'?: UserBrowseBodySorting;
}
/**
 * 
 * @export
 * @interface CouponRead200Response
 */
export interface CouponRead200Response {
    /**
     * 
     * @type {Coupon}
     * @memberof CouponRead200Response
     */
    'element'?: Coupon;
}
/**
 * 
 * @export
 * @interface DeleteBody
 */
export interface DeleteBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteBody
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ItemWareHouse
 */
export interface ItemWareHouse {
    /**
     * 
     * @type {string}
     * @memberof ItemWareHouse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemWareHouse
     */
    'type': ItemWareHouseTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ItemWareHouse
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof ItemWareHouse
     */
    'size': string;
    /**
     * 
     * @type {boolean}
     * @memberof ItemWareHouse
     */
    'premium'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ItemWareHouse
     */
    'rangeMin': number;
    /**
     * 
     * @type {number}
     * @memberof ItemWareHouse
     */
    'rangeMax': number;
}

export const ItemWareHouseTypeEnum = {
    Ski: 'SKI',
    Board: 'BOARD',
    Skiboots: 'SKIBOOTS',
    Boardboots: 'BOARDBOOTS',
    Helmet: 'HELMET'
} as const;

export type ItemWareHouseTypeEnum = typeof ItemWareHouseTypeEnum[keyof typeof ItemWareHouseTypeEnum];

/**
 * 
 * @export
 * @interface MinimumAvailability
 */
export interface MinimumAvailability {
    /**
     * 
     * @type {MinimumAvailabilityAvailability}
     * @memberof MinimumAvailability
     */
    'availability'?: MinimumAvailabilityAvailability;
}
/**
 * 
 * @export
 * @interface MinimumAvailabilityAvailability
 */
export interface MinimumAvailabilityAvailability {
    /**
     * 
     * @type {{ [key: string]: MinimumAvailabilityAvailabilitySKIValue; }}
     * @memberof MinimumAvailabilityAvailability
     */
    'SKI'?: { [key: string]: MinimumAvailabilityAvailabilitySKIValue; };
    /**
     * 
     * @type {{ [key: string]: MinimumAvailabilityAvailabilitySKIValue; }}
     * @memberof MinimumAvailabilityAvailability
     */
    'BOARD'?: { [key: string]: MinimumAvailabilityAvailabilitySKIValue; };
    /**
     * 
     * @type {{ [key: string]: MinimumAvailabilityAvailabilitySKIValue; }}
     * @memberof MinimumAvailabilityAvailability
     */
    'SKIBOOTS'?: { [key: string]: MinimumAvailabilityAvailabilitySKIValue; };
    /**
     * 
     * @type {{ [key: string]: MinimumAvailabilityAvailabilitySKIValue; }}
     * @memberof MinimumAvailabilityAvailability
     */
    'BOARDBOOTS'?: { [key: string]: MinimumAvailabilityAvailabilitySKIValue; };
    /**
     * 
     * @type {{ [key: string]: MinimumAvailabilityAvailabilitySKIValue; }}
     * @memberof MinimumAvailabilityAvailability
     */
    'HELMET'?: { [key: string]: MinimumAvailabilityAvailabilitySKIValue; };
}
/**
 * 
 * @export
 * @interface MinimumAvailabilityAvailabilitySKIValue
 */
export interface MinimumAvailabilityAvailabilitySKIValue {
    /**
     * 
     * @type {number}
     * @memberof MinimumAvailabilityAvailabilitySKIValue
     */
    'premium'?: number;
    /**
     * 
     * @type {number}
     * @memberof MinimumAvailabilityAvailabilitySKIValue
     */
    'regular'?: number;
}
/**
 * 
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    'percentage': number;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    'minDays': number;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'areaId': string;
}
/**
 * 
 * @export
 * @interface OfferBrowse200Response
 */
export interface OfferBrowse200Response {
    /**
     * 
     * @type {Array<Offer>}
     * @memberof OfferBrowse200Response
     */
    'elements': Array<Offer>;
    /**
     * 
     * @type {number}
     * @memberof OfferBrowse200Response
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface OfferBrowseBody
 */
export interface OfferBrowseBody {
    /**
     * 
     * @type {UserBrowseBodyPagination}
     * @memberof OfferBrowseBody
     */
    'pagination'?: UserBrowseBodyPagination;
    /**
     * 
     * @type {UserBrowseBodySorting}
     * @memberof OfferBrowseBody
     */
    'sorting'?: UserBrowseBodySorting;
    /**
     * 
     * @type {OfferBrowseBodyFilter}
     * @memberof OfferBrowseBody
     */
    'filter'?: OfferBrowseBodyFilter;
}
/**
 * 
 * @export
 * @interface OfferBrowseBodyFilter
 */
export interface OfferBrowseBodyFilter {
    /**
     * 
     * @type {string}
     * @memberof OfferBrowseBodyFilter
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferBrowseBodyFilter
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferBrowseBodyFilter
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferBrowseBodyFilter
     */
    'areaId'?: string;
}
/**
 * 
 * @export
 * @interface OfferRead200Response
 */
export interface OfferRead200Response {
    /**
     * 
     * @type {Offer}
     * @memberof OfferRead200Response
     */
    'element'?: Offer;
}
/**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    'province': string;
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    'deliveryHours': string;
    /**
     * 
     * @type {string}
     * @memberof Point
     */
    'areaId': string;
}
/**
 * 
 * @export
 * @interface PointBrowse200Response
 */
export interface PointBrowse200Response {
    /**
     * 
     * @type {Array<Point>}
     * @memberof PointBrowse200Response
     */
    'elements': Array<Point>;
    /**
     * 
     * @type {number}
     * @memberof PointBrowse200Response
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PointBrowseBody
 */
export interface PointBrowseBody {
    /**
     * 
     * @type {UserBrowseBodyPagination}
     * @memberof PointBrowseBody
     */
    'pagination'?: UserBrowseBodyPagination;
    /**
     * 
     * @type {UserBrowseBodySorting}
     * @memberof PointBrowseBody
     */
    'sorting'?: UserBrowseBodySorting;
    /**
     * 
     * @type {TrackBrowseBodyFilter}
     * @memberof PointBrowseBody
     */
    'filter'?: TrackBrowseBodyFilter;
}
/**
 * 
 * @export
 * @interface PointRead200Response
 */
export interface PointRead200Response {
    /**
     * 
     * @type {Point}
     * @memberof PointRead200Response
     */
    'element'?: Point;
}
/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    'name': PriceNameEnum;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'fullValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    'areaId': string;
}

export const PriceNameEnum = {
    Ski: 'SKI',
    Board: 'BOARD',
    Helmet: 'HELMET',
    Skiboots: 'SKIBOOTS',
    Boardboots: 'BOARDBOOTS',
    Skiandboots: 'SKIANDBOOTS',
    Boardandboots: 'BOARDANDBOOTS',
    Premium: 'PREMIUM',
    Storeshoe: 'STORESHOE',
    Insurance: 'INSURANCE',
    Trackdelivery: 'TRACKDELIVERY'
} as const;

export type PriceNameEnum = typeof PriceNameEnum[keyof typeof PriceNameEnum];

/**
 * 
 * @export
 * @interface PriceBrowse200Response
 */
export interface PriceBrowse200Response {
    /**
     * 
     * @type {Array<Price>}
     * @memberof PriceBrowse200Response
     */
    'elements': Array<Price>;
    /**
     * 
     * @type {number}
     * @memberof PriceBrowse200Response
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface PriceBrowseBody
 */
export interface PriceBrowseBody {
    /**
     * 
     * @type {UserBrowseBodyPagination}
     * @memberof PriceBrowseBody
     */
    'pagination'?: UserBrowseBodyPagination;
    /**
     * 
     * @type {UserBrowseBodySorting}
     * @memberof PriceBrowseBody
     */
    'sorting'?: UserBrowseBodySorting;
    /**
     * 
     * @type {TrackBrowseBodyFilter}
     * @memberof PriceBrowseBody
     */
    'filter'?: TrackBrowseBodyFilter;
}
/**
 * 
 * @export
 * @interface PriceRead200Response
 */
export interface PriceRead200Response {
    /**
     * 
     * @type {Price}
     * @memberof PriceRead200Response
     */
    'element'?: Price;
}
/**
 * 
 * @export
 * @interface Signup
 */
export interface Signup {
    /**
     * 
     * @type {SignupData}
     * @memberof Signup
     */
    'data'?: SignupData;
}
/**
 * 
 * @export
 * @interface SignupData
 */
export interface SignupData {
    /**
     * 
     * @type {string}
     * @memberof SignupData
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignupData
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof SignupData
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SignupData
     */
    'areaId'?: string;
    /**
     * 
     * @type {Array<SignupDataProfilesInner>}
     * @memberof SignupData
     */
    'profiles'?: Array<SignupDataProfilesInner>;
}
/**
 * 
 * @export
 * @interface SignupDataProfilesInner
 */
export interface SignupDataProfilesInner {
    /**
     * 
     * @type {string}
     * @memberof SignupDataProfilesInner
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SignupDataProfilesInner
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface Track
 */
export interface Track {
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    'province': string;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    'deliveryHours': string;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    'pickupHours': string;
    /**
     * 
     * @type {string}
     * @memberof Track
     */
    'areaId': string;
}
/**
 * 
 * @export
 * @interface TrackBrowse200Response
 */
export interface TrackBrowse200Response {
    /**
     * 
     * @type {Array<Track>}
     * @memberof TrackBrowse200Response
     */
    'elements': Array<Track>;
    /**
     * 
     * @type {number}
     * @memberof TrackBrowse200Response
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface TrackBrowseBody
 */
export interface TrackBrowseBody {
    /**
     * 
     * @type {UserBrowseBodyPagination}
     * @memberof TrackBrowseBody
     */
    'pagination'?: UserBrowseBodyPagination;
    /**
     * 
     * @type {UserBrowseBodySorting}
     * @memberof TrackBrowseBody
     */
    'sorting'?: UserBrowseBodySorting;
    /**
     * 
     * @type {TrackBrowseBodyFilter}
     * @memberof TrackBrowseBody
     */
    'filter'?: TrackBrowseBodyFilter;
}
/**
 * 
 * @export
 * @interface TrackBrowseBodyFilter
 */
export interface TrackBrowseBodyFilter {
    /**
     * 
     * @type {string}
     * @memberof TrackBrowseBodyFilter
     */
    'areaId'?: string;
}
/**
 * 
 * @export
 * @interface TrackRead200Response
 */
export interface TrackRead200Response {
    /**
     * 
     * @type {Track}
     * @memberof TrackRead200Response
     */
    'element'?: Track;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'documents'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'areaId'?: string;
    /**
     * 
     * @type {Area}
     * @memberof User
     */
    'area'?: Area;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isSocial'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'verifiedEmail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'role'?: UserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'customerId'?: string;
    /**
     * 
     * @type {Array<Booking>}
     * @memberof User
     */
    'bookings'?: Array<Booking>;
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof User
     */
    'profiles': Array<UserProfile>;
}

export const UserRoleEnum = {
    Superadmin: 'SUPERADMIN',
    Manager: 'MANAGER',
    Customer: 'CUSTOMER'
} as const;

export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];

/**
 * 
 * @export
 * @interface User1
 */
export interface User1 {
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'documents'?: string;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'areaId'?: string;
    /**
     * 
     * @type {Area}
     * @memberof User1
     */
    'area'?: Area;
    /**
     * 
     * @type {boolean}
     * @memberof User1
     */
    'isSocial'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User1
     */
    'notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User1
     */
    'verifiedEmail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'role'?: User1RoleEnum;
    /**
     * 
     * @type {string}
     * @memberof User1
     */
    'customerId'?: string;
    /**
     * 
     * @type {Array<Booking>}
     * @memberof User1
     */
    'bookings'?: Array<Booking>;
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof User1
     */
    'profiles'?: Array<UserProfile>;
}

export const User1RoleEnum = {
    Superadmin: 'SUPERADMIN',
    Manager: 'MANAGER',
    Customer: 'CUSTOMER'
} as const;

export type User1RoleEnum = typeof User1RoleEnum[keyof typeof User1RoleEnum];

/**
 * 
 * @export
 * @interface UserBrowse200Response
 */
export interface UserBrowse200Response {
    /**
     * 
     * @type {Array<User1>}
     * @memberof UserBrowse200Response
     */
    'elements': Array<User1>;
    /**
     * 
     * @type {number}
     * @memberof UserBrowse200Response
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface UserBrowseBody
 */
export interface UserBrowseBody {
    /**
     * 
     * @type {UserBrowseBodyPagination}
     * @memberof UserBrowseBody
     */
    'pagination'?: UserBrowseBodyPagination;
    /**
     * 
     * @type {UserBrowseBodySorting}
     * @memberof UserBrowseBody
     */
    'sorting'?: UserBrowseBodySorting;
    /**
     * 
     * @type {UserBrowseBodyFilter}
     * @memberof UserBrowseBody
     */
    'filter'?: UserBrowseBodyFilter;
}
/**
 * 
 * @export
 * @interface UserBrowseBodyFilter
 */
export interface UserBrowseBodyFilter {
    /**
     * 
     * @type {string}
     * @memberof UserBrowseBodyFilter
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBrowseBodyFilter
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBrowseBodyFilter
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBrowseBodyFilter
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBrowseBodyFilter
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBrowseBodyFilter
     */
    'role'?: UserBrowseBodyFilterRoleEnum;
}

export const UserBrowseBodyFilterRoleEnum = {
    Superadmin: 'SUPERADMIN',
    Manager: 'MANAGER',
    Customer: 'CUSTOMER'
} as const;

export type UserBrowseBodyFilterRoleEnum = typeof UserBrowseBodyFilterRoleEnum[keyof typeof UserBrowseBodyFilterRoleEnum];

/**
 * 
 * @export
 * @interface UserBrowseBodyPagination
 */
export interface UserBrowseBodyPagination {
    /**
     * 
     * @type {number}
     * @memberof UserBrowseBodyPagination
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserBrowseBodyPagination
     */
    'perPage'?: number;
}
/**
 * 
 * @export
 * @interface UserBrowseBodySorting
 */
export interface UserBrowseBodySorting {
    /**
     * 
     * @type {string}
     * @memberof UserBrowseBodySorting
     */
    'field'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBrowseBodySorting
     */
    'order'?: UserBrowseBodySortingOrderEnum;
}

export const UserBrowseBodySortingOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type UserBrowseBodySortingOrderEnum = typeof UserBrowseBodySortingOrderEnum[keyof typeof UserBrowseBodySortingOrderEnum];

/**
 * 
 * @export
 * @interface UserDelete200Response
 */
export interface UserDelete200Response {
    /**
     * 
     * @type {number}
     * @memberof UserDelete200Response
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface UserId
 */
export interface UserId {
    /**
     * 
     * @type {string}
     * @memberof UserId
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'gender'?: UserProfileGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    'heightCM'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    'weightKG'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    'ankleCM'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    'footLengthCM'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    'footWidthCM'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    'headCircCM'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    'shoeNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'stance'?: UserProfileStanceEnum;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'level'?: UserProfileLevelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    'main'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'bootsSize'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserProfile
     */
    'bootsMM'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    'bootsBrand'?: string;
}

export const UserProfileGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE'
} as const;

export type UserProfileGenderEnum = typeof UserProfileGenderEnum[keyof typeof UserProfileGenderEnum];
export const UserProfileStanceEnum = {
    Regular: 'REGULAR',
    Goofy: 'GOOFY'
} as const;

export type UserProfileStanceEnum = typeof UserProfileStanceEnum[keyof typeof UserProfileStanceEnum];
export const UserProfileLevelEnum = {
    Novice: 'NOVICE',
    Average: 'AVERAGE',
    Expert: 'EXPERT'
} as const;

export type UserProfileLevelEnum = typeof UserProfileLevelEnum[keyof typeof UserProfileLevelEnum];

/**
 * 
 * @export
 * @interface UserRead200Response
 */
export interface UserRead200Response {
    /**
     * 
     * @type {User1}
     * @memberof UserRead200Response
     */
    'element'?: User1;
}

/**
 * AreaApi - axios parameter creator
 * @export
 */
export const AreaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new area to the system
         * @param {Area} area 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaAdd: async (area: Area, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'area' is not null or undefined
            assertParamExists('areaAdd', 'area', area)
            const localVarPath = `/area/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(area, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Browse area from given filters
         * @param {AreaBrowseBody} [areaBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaBrowse: async (areaBrowseBody?: AreaBrowseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/area/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(areaBrowseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing area from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaDelete: async (deleteBody?: DeleteBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/area/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit existing area from given ID
         * @param {string} id 
         * @param {Area} area 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaEdit: async (id: string, area: Area, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('areaEdit', 'id', id)
            // verify required parameter 'area' is not null or undefined
            assertParamExists('areaEdit', 'area', area)
            const localVarPath = `/area/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(area, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read area from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('areaRead', 'id', id)
            const localVarPath = `/area/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AreaApi - functional programming interface
 * @export
 */
export const AreaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AreaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new area to the system
         * @param {Area} area 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaAdd(area: Area, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaAdd(area, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Browse area from given filters
         * @param {AreaBrowseBody} [areaBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaBrowse(areaBrowseBody?: AreaBrowseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaBrowse200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaBrowse(areaBrowseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete existing area from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaDelete(deleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit existing area from given ID
         * @param {string} id 
         * @param {Area} area 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaEdit(id: string, area: Area, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaEdit(id, area, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read area from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AreaApi - factory interface
 * @export
 */
export const AreaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AreaApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new area to the system
         * @param {Area} area 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaAdd(area: Area, options?: any): AxiosPromise<AreaRead200Response> {
            return localVarFp.areaAdd(area, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Browse area from given filters
         * @param {AreaBrowseBody} [areaBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaBrowse(areaBrowseBody?: AreaBrowseBody, options?: any): AxiosPromise<AreaBrowse200Response> {
            return localVarFp.areaBrowse(areaBrowseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing area from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaDelete(deleteBody?: DeleteBody, options?: any): AxiosPromise<UserDelete200Response> {
            return localVarFp.areaDelete(deleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit existing area from given ID
         * @param {string} id 
         * @param {Area} area 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaEdit(id: string, area: Area, options?: any): AxiosPromise<AreaRead200Response> {
            return localVarFp.areaEdit(id, area, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read area from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaRead(id: string, options?: any): AxiosPromise<AreaRead200Response> {
            return localVarFp.areaRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AreaApi - object-oriented interface
 * @export
 * @class AreaApi
 * @extends {BaseAPI}
 */
export class AreaApi extends BaseAPI {
    /**
     * 
     * @summary Add new area to the system
     * @param {Area} area 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaAdd(area: Area, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaAdd(area, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Browse area from given filters
     * @param {AreaBrowseBody} [areaBrowseBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaBrowse(areaBrowseBody?: AreaBrowseBody, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaBrowse(areaBrowseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing area from given ID
     * @param {DeleteBody} [deleteBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaDelete(deleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit existing area from given ID
     * @param {string} id 
     * @param {Area} area 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaEdit(id: string, area: Area, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaEdit(id, area, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read area from given ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public areaRead(id: string, options?: AxiosRequestConfig) {
        return AreaApiFp(this.configuration).areaRead(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change your password while logged in
         * @param {AuthChangePasswordRequest} authChangePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authChangePassword: async (authChangePasswordRequest: AuthChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authChangePasswordRequest' is not null or undefined
            assertParamExists('authChangePassword', 'authChangePasswordRequest', authChangePasswordRequest)
            const localVarPath = `/auth/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authChangePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm your customer e-mail
         * @param {AuthConfirmEmailRequest} authConfirmEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authConfirmEmail: async (authConfirmEmailRequest: AuthConfirmEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authConfirmEmailRequest' is not null or undefined
            assertParamExists('authConfirmEmail', 'authConfirmEmailRequest', authConfirmEmailRequest)
            const localVarPath = `/auth/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authConfirmEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redirect to facebook login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authFacebook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/facebook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Facebook callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authFacebookCallback: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/facebook/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change your password if given code matches
         * @param {AuthForgotPasswordRequest} authForgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authForgotPassword: async (authForgotPasswordRequest: AuthForgotPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authForgotPasswordRequest' is not null or undefined
            assertParamExists('authForgotPassword', 'authForgotPasswordRequest', authForgotPasswordRequest)
            const localVarPath = `/auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authForgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redirect to google login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGoogle: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Google callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGoogleCallback: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/google/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with your user credentials
         * @param {AuthLoginRequest} authLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin: async (authLoginRequest: AuthLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authLoginRequest' is not null or undefined
            assertParamExists('authLogin', 'authLoginRequest', authLoginRequest)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a code for different purposes
         * @param {AuthSendCodeRequest} authSendCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSendCode: async (authSendCodeRequest: AuthSendCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authSendCodeRequest' is not null or undefined
            assertParamExists('authSendCode', 'authSendCodeRequest', authSendCodeRequest)
            const localVarPath = `/auth/send-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authSendCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Signup as a customer user
         * @param {Signup} [signup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignup: async (signup?: Signup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user associated to the request token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change your password while logged in
         * @param {AuthChangePasswordRequest} authChangePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authChangePassword(authChangePasswordRequest: AuthChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authChangePassword(authChangePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm your customer e-mail
         * @param {AuthConfirmEmailRequest} authConfirmEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authConfirmEmail(authConfirmEmailRequest: AuthConfirmEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthConfirmEmail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authConfirmEmail(authConfirmEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Redirect to facebook login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authFacebook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authFacebook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Facebook callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authFacebookCallback(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authFacebookCallback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change your password if given code matches
         * @param {AuthForgotPasswordRequest} authForgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authForgotPassword(authForgotPasswordRequest: AuthForgotPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authForgotPassword(authForgotPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Redirect to google login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authGoogle(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authGoogle(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Google callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authGoogleCallback(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authGoogleCallback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login with your user credentials
         * @param {AuthLoginRequest} authLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogin(authLoginRequest: AuthLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Auth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLogin(authLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a code for different purposes
         * @param {AuthSendCodeRequest} authSendCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSendCode(authSendCodeRequest: AuthSendCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSendCode(authSendCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Signup as a customer user
         * @param {Signup} [signup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSignup(signup?: Signup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSignup(signup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user associated to the request token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Change your password while logged in
         * @param {AuthChangePasswordRequest} authChangePasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authChangePassword(authChangePasswordRequest: AuthChangePasswordRequest, options?: any): AxiosPromise<UserId> {
            return localVarFp.authChangePassword(authChangePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm your customer e-mail
         * @param {AuthConfirmEmailRequest} authConfirmEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authConfirmEmail(authConfirmEmailRequest: AuthConfirmEmailRequest, options?: any): AxiosPromise<AuthConfirmEmail200Response> {
            return localVarFp.authConfirmEmail(authConfirmEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Redirect to facebook login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authFacebook(options?: any): AxiosPromise<void> {
            return localVarFp.authFacebook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Facebook callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authFacebookCallback(options?: any): AxiosPromise<void> {
            return localVarFp.authFacebookCallback(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change your password if given code matches
         * @param {AuthForgotPasswordRequest} authForgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authForgotPassword(authForgotPasswordRequest: AuthForgotPasswordRequest, options?: any): AxiosPromise<UserId> {
            return localVarFp.authForgotPassword(authForgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Redirect to google login page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGoogle(options?: any): AxiosPromise<void> {
            return localVarFp.authGoogle(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Google callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGoogleCallback(options?: any): AxiosPromise<void> {
            return localVarFp.authGoogleCallback(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with your user credentials
         * @param {AuthLoginRequest} authLoginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(authLoginRequest: AuthLoginRequest, options?: any): AxiosPromise<Auth> {
            return localVarFp.authLogin(authLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a code for different purposes
         * @param {AuthSendCodeRequest} authSendCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSendCode(authSendCodeRequest: AuthSendCodeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.authSendCode(authSendCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Signup as a customer user
         * @param {Signup} [signup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignup(signup?: Signup, options?: any): AxiosPromise<void> {
            return localVarFp.authSignup(signup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user associated to the request token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUser(options?: any): AxiosPromise<AuthUser200Response> {
            return localVarFp.authUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Change your password while logged in
     * @param {AuthChangePasswordRequest} authChangePasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authChangePassword(authChangePasswordRequest: AuthChangePasswordRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authChangePassword(authChangePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm your customer e-mail
     * @param {AuthConfirmEmailRequest} authConfirmEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authConfirmEmail(authConfirmEmailRequest: AuthConfirmEmailRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authConfirmEmail(authConfirmEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Redirect to facebook login page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authFacebook(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authFacebook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Facebook callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authFacebookCallback(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authFacebookCallback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change your password if given code matches
     * @param {AuthForgotPasswordRequest} authForgotPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authForgotPassword(authForgotPasswordRequest: AuthForgotPasswordRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authForgotPassword(authForgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Redirect to google login page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authGoogle(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authGoogle(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Google callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authGoogleCallback(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authGoogleCallback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with your user credentials
     * @param {AuthLoginRequest} authLoginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogin(authLoginRequest: AuthLoginRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authLogin(authLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a code for different purposes
     * @param {AuthSendCodeRequest} authSendCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSendCode(authSendCodeRequest: AuthSendCodeRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authSendCode(authSendCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Signup as a customer user
     * @param {Signup} [signup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignup(signup?: Signup, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authSignup(signup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user associated to the request token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUser(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUser(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AvailabilityApi - axios parameter creator
 * @export
 */
export const AvailabilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get calendar availability for given period
         * @param {AvailabilityBody} availabilityBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilityCalendar: async (availabilityBody: AvailabilityBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'availabilityBody' is not null or undefined
            assertParamExists('availabilityCalendar', 'availabilityBody', availabilityBody)
            const localVarPath = `/availability/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(availabilityBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get minimum period availability for every item
         * @param {AvailabilityBody} availabilityBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilityMinimum: async (availabilityBody: AvailabilityBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'availabilityBody' is not null or undefined
            assertParamExists('availabilityMinimum', 'availabilityBody', availabilityBody)
            const localVarPath = `/availability/minimum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(availabilityBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvailabilityApi - functional programming interface
 * @export
 */
export const AvailabilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvailabilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get calendar availability for given period
         * @param {AvailabilityBody} availabilityBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availabilityCalendar(availabilityBody: AvailabilityBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.availabilityCalendar(availabilityBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get minimum period availability for every item
         * @param {AvailabilityBody} availabilityBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availabilityMinimum(availabilityBody: AvailabilityBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MinimumAvailability>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.availabilityMinimum(availabilityBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AvailabilityApi - factory interface
 * @export
 */
export const AvailabilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvailabilityApiFp(configuration)
    return {
        /**
         * 
         * @summary Get calendar availability for given period
         * @param {AvailabilityBody} availabilityBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilityCalendar(availabilityBody: AvailabilityBody, options?: any): AxiosPromise<CalendarAvailability> {
            return localVarFp.availabilityCalendar(availabilityBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get minimum period availability for every item
         * @param {AvailabilityBody} availabilityBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilityMinimum(availabilityBody: AvailabilityBody, options?: any): AxiosPromise<MinimumAvailability> {
            return localVarFp.availabilityMinimum(availabilityBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvailabilityApi - object-oriented interface
 * @export
 * @class AvailabilityApi
 * @extends {BaseAPI}
 */
export class AvailabilityApi extends BaseAPI {
    /**
     * 
     * @summary Get calendar availability for given period
     * @param {AvailabilityBody} availabilityBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilityApi
     */
    public availabilityCalendar(availabilityBody: AvailabilityBody, options?: AxiosRequestConfig) {
        return AvailabilityApiFp(this.configuration).availabilityCalendar(availabilityBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get minimum period availability for every item
     * @param {AvailabilityBody} availabilityBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilityApi
     */
    public availabilityMinimum(availabilityBody: AvailabilityBody, options?: AxiosRequestConfig) {
        return AvailabilityApiFp(this.configuration).availabilityMinimum(availabilityBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BookingApi - axios parameter creator
 * @export
 */
export const BookingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new booking to the system
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingAdd: async (booking: Booking, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'booking' is not null or undefined
            assertParamExists('bookingAdd', 'booking', booking)
            const localVarPath = `/booking/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(booking, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Browse booking from given filters
         * @param {BookingBrowseBody} [bookingBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingBrowse: async (bookingBrowseBody?: BookingBrowseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/booking/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingBrowseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a booking with given code
         * @param {AuthConfirmEmailRequest} authConfirmEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingCancel: async (authConfirmEmailRequest: AuthConfirmEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authConfirmEmailRequest' is not null or undefined
            assertParamExists('bookingCancel', 'authConfirmEmailRequest', authConfirmEmailRequest)
            const localVarPath = `/booking/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authConfirmEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the status of a given booking
         * @param {string} id 
         * @param {BookingChangeStatusRequest} bookingChangeStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingChangeStatus: async (id: string, bookingChangeStatusRequest: BookingChangeStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingChangeStatus', 'id', id)
            // verify required parameter 'bookingChangeStatusRequest' is not null or undefined
            assertParamExists('bookingChangeStatus', 'bookingChangeStatusRequest', bookingChangeStatusRequest)
            const localVarPath = `/booking/status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingChangeStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing booking from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDelete: async (deleteBody?: DeleteBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/booking/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit existing booking from given ID
         * @param {string} id 
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingEdit: async (id: string, booking: Booking, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingEdit', 'id', id)
            // verify required parameter 'booking' is not null or undefined
            assertParamExists('bookingEdit', 'booking', booking)
            const localVarPath = `/booking/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(booking, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ask for a new booking to be created
         * @param {BookingNewRequest} bookingNewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingNew: async (bookingNewRequest: BookingNewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingNewRequest' is not null or undefined
            assertParamExists('bookingNew', 'bookingNewRequest', bookingNewRequest)
            const localVarPath = `/booking/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingNewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate a booking price
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingPrice: async (booking: Booking, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'booking' is not null or undefined
            assertParamExists('bookingPrice', 'booking', booking)
            const localVarPath = `/booking/price`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(booking, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read booking from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingRead', 'id', id)
            const localVarPath = `/booking/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publicly read a booking
         * @param {string} id 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingRetrieve: async (id: string, email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingRetrieve', 'id', id)
            const localVarPath = `/booking/retrieve/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingApi - functional programming interface
 * @export
 */
export const BookingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new booking to the system
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingAdd(booking: Booking, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRetrieve200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingAdd(booking, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Browse booking from given filters
         * @param {BookingBrowseBody} [bookingBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingBrowse(bookingBrowseBody?: BookingBrowseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingBrowse200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingBrowse(bookingBrowseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel a booking with given code
         * @param {AuthConfirmEmailRequest} authConfirmEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingCancel(authConfirmEmailRequest: AuthConfirmEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRetrieve200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingCancel(authConfirmEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change the status of a given booking
         * @param {string} id 
         * @param {BookingChangeStatusRequest} bookingChangeStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingChangeStatus(id: string, bookingChangeStatusRequest: BookingChangeStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRetrieve200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingChangeStatus(id, bookingChangeStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete existing booking from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDelete(deleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit existing booking from given ID
         * @param {string} id 
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingEdit(id: string, booking: Booking, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRetrieve200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingEdit(id, booking, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ask for a new booking to be created
         * @param {BookingNewRequest} bookingNewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingNew(bookingNewRequest: BookingNewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingNew200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingNew(bookingNewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate a booking price
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingPrice(booking: Booking, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingPrice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingPrice(booking, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read booking from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRetrieve200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Publicly read a booking
         * @param {string} id 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingRetrieve(id: string, email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRetrieve200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingRetrieve(id, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingApi - factory interface
 * @export
 */
export const BookingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new booking to the system
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingAdd(booking: Booking, options?: any): AxiosPromise<BookingRetrieve200Response> {
            return localVarFp.bookingAdd(booking, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Browse booking from given filters
         * @param {BookingBrowseBody} [bookingBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingBrowse(bookingBrowseBody?: BookingBrowseBody, options?: any): AxiosPromise<BookingBrowse200Response> {
            return localVarFp.bookingBrowse(bookingBrowseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel a booking with given code
         * @param {AuthConfirmEmailRequest} authConfirmEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingCancel(authConfirmEmailRequest: AuthConfirmEmailRequest, options?: any): AxiosPromise<BookingRetrieve200Response> {
            return localVarFp.bookingCancel(authConfirmEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the status of a given booking
         * @param {string} id 
         * @param {BookingChangeStatusRequest} bookingChangeStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingChangeStatus(id: string, bookingChangeStatusRequest: BookingChangeStatusRequest, options?: any): AxiosPromise<BookingRetrieve200Response> {
            return localVarFp.bookingChangeStatus(id, bookingChangeStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing booking from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDelete(deleteBody?: DeleteBody, options?: any): AxiosPromise<UserDelete200Response> {
            return localVarFp.bookingDelete(deleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit existing booking from given ID
         * @param {string} id 
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingEdit(id: string, booking: Booking, options?: any): AxiosPromise<BookingRetrieve200Response> {
            return localVarFp.bookingEdit(id, booking, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ask for a new booking to be created
         * @param {BookingNewRequest} bookingNewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingNew(bookingNewRequest: BookingNewRequest, options?: any): AxiosPromise<BookingNew200Response> {
            return localVarFp.bookingNew(bookingNewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate a booking price
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingPrice(booking: Booking, options?: any): AxiosPromise<BookingPrice> {
            return localVarFp.bookingPrice(booking, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read booking from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingRead(id: string, options?: any): AxiosPromise<BookingRetrieve200Response> {
            return localVarFp.bookingRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Publicly read a booking
         * @param {string} id 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingRetrieve(id: string, email?: string, options?: any): AxiosPromise<BookingRetrieve200Response> {
            return localVarFp.bookingRetrieve(id, email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingApi - object-oriented interface
 * @export
 * @class BookingApi
 * @extends {BaseAPI}
 */
export class BookingApi extends BaseAPI {
    /**
     * 
     * @summary Add new booking to the system
     * @param {Booking} booking 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingAdd(booking: Booking, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingAdd(booking, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Browse booking from given filters
     * @param {BookingBrowseBody} [bookingBrowseBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingBrowse(bookingBrowseBody?: BookingBrowseBody, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingBrowse(bookingBrowseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel a booking with given code
     * @param {AuthConfirmEmailRequest} authConfirmEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingCancel(authConfirmEmailRequest: AuthConfirmEmailRequest, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingCancel(authConfirmEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the status of a given booking
     * @param {string} id 
     * @param {BookingChangeStatusRequest} bookingChangeStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingChangeStatus(id: string, bookingChangeStatusRequest: BookingChangeStatusRequest, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingChangeStatus(id, bookingChangeStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing booking from given ID
     * @param {DeleteBody} [deleteBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingDelete(deleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit existing booking from given ID
     * @param {string} id 
     * @param {Booking} booking 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingEdit(id: string, booking: Booking, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingEdit(id, booking, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ask for a new booking to be created
     * @param {BookingNewRequest} bookingNewRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingNew(bookingNewRequest: BookingNewRequest, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingNew(bookingNewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate a booking price
     * @param {Booking} booking 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingPrice(booking: Booking, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingPrice(booking, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read booking from given ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingRead(id: string, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Publicly read a booking
     * @param {string} id 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingRetrieve(id: string, email?: string, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingRetrieve(id, email, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CouponApi - axios parameter creator
 * @export
 */
export const CouponApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new coupon to the system
         * @param {Coupon} coupon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponAdd: async (coupon: Coupon, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'coupon' is not null or undefined
            assertParamExists('couponAdd', 'coupon', coupon)
            const localVarPath = `/coupon/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coupon, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Browse coupon from given filters
         * @param {CouponBrowseBody} [couponBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponBrowse: async (couponBrowseBody?: CouponBrowseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/coupon/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(couponBrowseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing coupon from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponDelete: async (deleteBody?: DeleteBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/coupon/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit existing coupon from given ID
         * @param {string} id 
         * @param {Coupon} coupon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponEdit: async (id: string, coupon: Coupon, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('couponEdit', 'id', id)
            // verify required parameter 'coupon' is not null or undefined
            assertParamExists('couponEdit', 'coupon', coupon)
            const localVarPath = `/coupon/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coupon, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read coupon from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('couponRead', 'id', id)
            const localVarPath = `/coupon/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponApi - functional programming interface
 * @export
 */
export const CouponApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CouponApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new coupon to the system
         * @param {Coupon} coupon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponAdd(coupon: Coupon, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponAdd(coupon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Browse coupon from given filters
         * @param {CouponBrowseBody} [couponBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponBrowse(couponBrowseBody?: CouponBrowseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponBrowse200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponBrowse(couponBrowseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete existing coupon from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponDelete(deleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit existing coupon from given ID
         * @param {string} id 
         * @param {Coupon} coupon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponEdit(id: string, coupon: Coupon, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponEdit(id, coupon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read coupon from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CouponApi - factory interface
 * @export
 */
export const CouponApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CouponApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new coupon to the system
         * @param {Coupon} coupon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponAdd(coupon: Coupon, options?: any): AxiosPromise<CouponRead200Response> {
            return localVarFp.couponAdd(coupon, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Browse coupon from given filters
         * @param {CouponBrowseBody} [couponBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponBrowse(couponBrowseBody?: CouponBrowseBody, options?: any): AxiosPromise<CouponBrowse200Response> {
            return localVarFp.couponBrowse(couponBrowseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing coupon from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponDelete(deleteBody?: DeleteBody, options?: any): AxiosPromise<UserDelete200Response> {
            return localVarFp.couponDelete(deleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit existing coupon from given ID
         * @param {string} id 
         * @param {Coupon} coupon 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponEdit(id: string, coupon: Coupon, options?: any): AxiosPromise<CouponRead200Response> {
            return localVarFp.couponEdit(id, coupon, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read coupon from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponRead(id: string, options?: any): AxiosPromise<CouponRead200Response> {
            return localVarFp.couponRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CouponApi - object-oriented interface
 * @export
 * @class CouponApi
 * @extends {BaseAPI}
 */
export class CouponApi extends BaseAPI {
    /**
     * 
     * @summary Add new coupon to the system
     * @param {Coupon} coupon 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponAdd(coupon: Coupon, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponAdd(coupon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Browse coupon from given filters
     * @param {CouponBrowseBody} [couponBrowseBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponBrowse(couponBrowseBody?: CouponBrowseBody, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponBrowse(couponBrowseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing coupon from given ID
     * @param {DeleteBody} [deleteBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponDelete(deleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit existing coupon from given ID
     * @param {string} id 
     * @param {Coupon} coupon 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponEdit(id: string, coupon: Coupon, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponEdit(id, coupon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read coupon from given ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponRead(id: string, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponRead(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary SendGrid callback when an email is sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSendGridCallback: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email/sendgrid/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary SendGrid callback when an email is sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailSendGridCallback(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailSendGridCallback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailApiFp(configuration)
    return {
        /**
         * 
         * @summary SendGrid callback when an email is sent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSendGridCallback(options?: any): AxiosPromise<void> {
            return localVarFp.emailSendGridCallback(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * 
     * @summary SendGrid callback when an email is sent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailSendGridCallback(options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailSendGridCallback(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferApi - axios parameter creator
 * @export
 */
export const OfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new offer to the system
         * @param {Offer} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerAdd: async (offer: Offer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offer' is not null or undefined
            assertParamExists('offerAdd', 'offer', offer)
            const localVarPath = `/offer/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Browse offer from given filters
         * @param {OfferBrowseBody} [offerBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerBrowse: async (offerBrowseBody?: OfferBrowseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offer/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offerBrowseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing offer from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDelete: async (deleteBody?: DeleteBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/offer/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit existing offer from given ID
         * @param {string} id 
         * @param {Offer} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerEdit: async (id: string, offer: Offer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerEdit', 'id', id)
            // verify required parameter 'offer' is not null or undefined
            assertParamExists('offerEdit', 'offer', offer)
            const localVarPath = `/offer/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read offer from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offerRead', 'id', id)
            const localVarPath = `/offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferApi - functional programming interface
 * @export
 */
export const OfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new offer to the system
         * @param {Offer} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerAdd(offer: Offer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerAdd(offer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Browse offer from given filters
         * @param {OfferBrowseBody} [offerBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerBrowse(offerBrowseBody?: OfferBrowseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferBrowse200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerBrowse(offerBrowseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete existing offer from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerDelete(deleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit existing offer from given ID
         * @param {string} id 
         * @param {Offer} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerEdit(id: string, offer: Offer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerEdit(id, offer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read offer from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offerRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offerRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferApi - factory interface
 * @export
 */
export const OfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new offer to the system
         * @param {Offer} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerAdd(offer: Offer, options?: any): AxiosPromise<OfferRead200Response> {
            return localVarFp.offerAdd(offer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Browse offer from given filters
         * @param {OfferBrowseBody} [offerBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerBrowse(offerBrowseBody?: OfferBrowseBody, options?: any): AxiosPromise<OfferBrowse200Response> {
            return localVarFp.offerBrowse(offerBrowseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing offer from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerDelete(deleteBody?: DeleteBody, options?: any): AxiosPromise<UserDelete200Response> {
            return localVarFp.offerDelete(deleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit existing offer from given ID
         * @param {string} id 
         * @param {Offer} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerEdit(id: string, offer: Offer, options?: any): AxiosPromise<OfferRead200Response> {
            return localVarFp.offerEdit(id, offer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read offer from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offerRead(id: string, options?: any): AxiosPromise<OfferRead200Response> {
            return localVarFp.offerRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferApi - object-oriented interface
 * @export
 * @class OfferApi
 * @extends {BaseAPI}
 */
export class OfferApi extends BaseAPI {
    /**
     * 
     * @summary Add new offer to the system
     * @param {Offer} offer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerAdd(offer: Offer, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerAdd(offer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Browse offer from given filters
     * @param {OfferBrowseBody} [offerBrowseBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerBrowse(offerBrowseBody?: OfferBrowseBody, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerBrowse(offerBrowseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing offer from given ID
     * @param {DeleteBody} [deleteBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerDelete(deleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit existing offer from given ID
     * @param {string} id 
     * @param {Offer} offer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerEdit(id: string, offer: Offer, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerEdit(id, offer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read offer from given ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferApi
     */
    public offerRead(id: string, options?: AxiosRequestConfig) {
        return OfferApiFp(this.configuration).offerRead(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Stripe callback when a payment is done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentStripeCallback: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment/stripe/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Stripe callback when a payment is done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentStripeCallback(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentStripeCallback(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary Stripe callback when a payment is done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentStripeCallback(options?: any): AxiosPromise<void> {
            return localVarFp.paymentStripeCallback(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary Stripe callback when a payment is done
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentStripeCallback(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).paymentStripeCallback(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PointApi - axios parameter creator
 * @export
 */
export const PointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new point to the system
         * @param {Point} point 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointAdd: async (point: Point, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'point' is not null or undefined
            assertParamExists('pointAdd', 'point', point)
            const localVarPath = `/point/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(point, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Browse point from given filters
         * @param {PointBrowseBody} [pointBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointBrowse: async (pointBrowseBody?: PointBrowseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/point/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointBrowseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing point from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointDelete: async (deleteBody?: DeleteBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/point/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit existing point from given ID
         * @param {string} id 
         * @param {Point} point 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointEdit: async (id: string, point: Point, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pointEdit', 'id', id)
            // verify required parameter 'point' is not null or undefined
            assertParamExists('pointEdit', 'point', point)
            const localVarPath = `/point/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(point, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read point from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pointRead', 'id', id)
            const localVarPath = `/point/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointApi - functional programming interface
 * @export
 */
export const PointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new point to the system
         * @param {Point} point 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointAdd(point: Point, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointAdd(point, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Browse point from given filters
         * @param {PointBrowseBody} [pointBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointBrowse(pointBrowseBody?: PointBrowseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointBrowse200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointBrowse(pointBrowseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete existing point from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointDelete(deleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit existing point from given ID
         * @param {string} id 
         * @param {Point} point 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointEdit(id: string, point: Point, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointEdit(id, point, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read point from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PointApi - factory interface
 * @export
 */
export const PointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new point to the system
         * @param {Point} point 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointAdd(point: Point, options?: any): AxiosPromise<PointRead200Response> {
            return localVarFp.pointAdd(point, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Browse point from given filters
         * @param {PointBrowseBody} [pointBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointBrowse(pointBrowseBody?: PointBrowseBody, options?: any): AxiosPromise<PointBrowse200Response> {
            return localVarFp.pointBrowse(pointBrowseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing point from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointDelete(deleteBody?: DeleteBody, options?: any): AxiosPromise<UserDelete200Response> {
            return localVarFp.pointDelete(deleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit existing point from given ID
         * @param {string} id 
         * @param {Point} point 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointEdit(id: string, point: Point, options?: any): AxiosPromise<PointRead200Response> {
            return localVarFp.pointEdit(id, point, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read point from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointRead(id: string, options?: any): AxiosPromise<PointRead200Response> {
            return localVarFp.pointRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointApi - object-oriented interface
 * @export
 * @class PointApi
 * @extends {BaseAPI}
 */
export class PointApi extends BaseAPI {
    /**
     * 
     * @summary Add new point to the system
     * @param {Point} point 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointAdd(point: Point, options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).pointAdd(point, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Browse point from given filters
     * @param {PointBrowseBody} [pointBrowseBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointBrowse(pointBrowseBody?: PointBrowseBody, options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).pointBrowse(pointBrowseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing point from given ID
     * @param {DeleteBody} [deleteBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).pointDelete(deleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit existing point from given ID
     * @param {string} id 
     * @param {Point} point 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointEdit(id: string, point: Point, options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).pointEdit(id, point, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read point from given ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointRead(id: string, options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).pointRead(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PriceApi - axios parameter creator
 * @export
 */
export const PriceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new price to the system
         * @param {Price} price 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceAdd: async (price: Price, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'price' is not null or undefined
            assertParamExists('priceAdd', 'price', price)
            const localVarPath = `/price/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(price, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Browse price from given filters
         * @param {PriceBrowseBody} [priceBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceBrowse: async (priceBrowseBody?: PriceBrowseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/price/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(priceBrowseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing price from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceDelete: async (deleteBody?: DeleteBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/price/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit existing price from given ID
         * @param {string} id 
         * @param {Price} price 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceEdit: async (id: string, price: Price, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('priceEdit', 'id', id)
            // verify required parameter 'price' is not null or undefined
            assertParamExists('priceEdit', 'price', price)
            const localVarPath = `/price/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(price, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read price from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('priceRead', 'id', id)
            const localVarPath = `/price/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceApi - functional programming interface
 * @export
 */
export const PriceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PriceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new price to the system
         * @param {Price} price 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceAdd(price: Price, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceAdd(price, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Browse price from given filters
         * @param {PriceBrowseBody} [priceBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceBrowse(priceBrowseBody?: PriceBrowseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceBrowse200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceBrowse(priceBrowseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete existing price from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceDelete(deleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit existing price from given ID
         * @param {string} id 
         * @param {Price} price 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceEdit(id: string, price: Price, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceEdit(id, price, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read price from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PriceApi - factory interface
 * @export
 */
export const PriceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PriceApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new price to the system
         * @param {Price} price 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceAdd(price: Price, options?: any): AxiosPromise<PriceRead200Response> {
            return localVarFp.priceAdd(price, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Browse price from given filters
         * @param {PriceBrowseBody} [priceBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceBrowse(priceBrowseBody?: PriceBrowseBody, options?: any): AxiosPromise<PriceBrowse200Response> {
            return localVarFp.priceBrowse(priceBrowseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing price from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceDelete(deleteBody?: DeleteBody, options?: any): AxiosPromise<UserDelete200Response> {
            return localVarFp.priceDelete(deleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit existing price from given ID
         * @param {string} id 
         * @param {Price} price 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceEdit(id: string, price: Price, options?: any): AxiosPromise<PriceRead200Response> {
            return localVarFp.priceEdit(id, price, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read price from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceRead(id: string, options?: any): AxiosPromise<PriceRead200Response> {
            return localVarFp.priceRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PriceApi - object-oriented interface
 * @export
 * @class PriceApi
 * @extends {BaseAPI}
 */
export class PriceApi extends BaseAPI {
    /**
     * 
     * @summary Add new price to the system
     * @param {Price} price 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public priceAdd(price: Price, options?: AxiosRequestConfig) {
        return PriceApiFp(this.configuration).priceAdd(price, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Browse price from given filters
     * @param {PriceBrowseBody} [priceBrowseBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public priceBrowse(priceBrowseBody?: PriceBrowseBody, options?: AxiosRequestConfig) {
        return PriceApiFp(this.configuration).priceBrowse(priceBrowseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing price from given ID
     * @param {DeleteBody} [deleteBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public priceDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig) {
        return PriceApiFp(this.configuration).priceDelete(deleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit existing price from given ID
     * @param {string} id 
     * @param {Price} price 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public priceEdit(id: string, price: Price, options?: AxiosRequestConfig) {
        return PriceApiFp(this.configuration).priceEdit(id, price, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read price from given ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public priceRead(id: string, options?: AxiosRequestConfig) {
        return PriceApiFp(this.configuration).priceRead(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrackApi - axios parameter creator
 * @export
 */
export const TrackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new track to the system
         * @param {Track} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackAdd: async (track: Track, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'track' is not null or undefined
            assertParamExists('trackAdd', 'track', track)
            const localVarPath = `/track/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(track, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Browse track from given filters
         * @param {TrackBrowseBody} [trackBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackBrowse: async (trackBrowseBody?: TrackBrowseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/track/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trackBrowseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing track from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackDelete: async (deleteBody?: DeleteBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/track/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit existing track from given ID
         * @param {string} id 
         * @param {Track} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackEdit: async (id: string, track: Track, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('trackEdit', 'id', id)
            // verify required parameter 'track' is not null or undefined
            assertParamExists('trackEdit', 'track', track)
            const localVarPath = `/track/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(track, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read track from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('trackRead', 'id', id)
            const localVarPath = `/track/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackApi - functional programming interface
 * @export
 */
export const TrackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new track to the system
         * @param {Track} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackAdd(track: Track, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackAdd(track, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Browse track from given filters
         * @param {TrackBrowseBody} [trackBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackBrowse(trackBrowseBody?: TrackBrowseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackBrowse200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackBrowse(trackBrowseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete existing track from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackDelete(deleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit existing track from given ID
         * @param {string} id 
         * @param {Track} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackEdit(id: string, track: Track, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackEdit(id, track, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read track from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrackApi - factory interface
 * @export
 */
export const TrackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrackApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new track to the system
         * @param {Track} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackAdd(track: Track, options?: any): AxiosPromise<TrackRead200Response> {
            return localVarFp.trackAdd(track, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Browse track from given filters
         * @param {TrackBrowseBody} [trackBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackBrowse(trackBrowseBody?: TrackBrowseBody, options?: any): AxiosPromise<TrackBrowse200Response> {
            return localVarFp.trackBrowse(trackBrowseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing track from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackDelete(deleteBody?: DeleteBody, options?: any): AxiosPromise<UserDelete200Response> {
            return localVarFp.trackDelete(deleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit existing track from given ID
         * @param {string} id 
         * @param {Track} track 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackEdit(id: string, track: Track, options?: any): AxiosPromise<TrackRead200Response> {
            return localVarFp.trackEdit(id, track, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read track from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackRead(id: string, options?: any): AxiosPromise<TrackRead200Response> {
            return localVarFp.trackRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrackApi - object-oriented interface
 * @export
 * @class TrackApi
 * @extends {BaseAPI}
 */
export class TrackApi extends BaseAPI {
    /**
     * 
     * @summary Add new track to the system
     * @param {Track} track 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackApi
     */
    public trackAdd(track: Track, options?: AxiosRequestConfig) {
        return TrackApiFp(this.configuration).trackAdd(track, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Browse track from given filters
     * @param {TrackBrowseBody} [trackBrowseBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackApi
     */
    public trackBrowse(trackBrowseBody?: TrackBrowseBody, options?: AxiosRequestConfig) {
        return TrackApiFp(this.configuration).trackBrowse(trackBrowseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing track from given ID
     * @param {DeleteBody} [deleteBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackApi
     */
    public trackDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig) {
        return TrackApiFp(this.configuration).trackDelete(deleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit existing track from given ID
     * @param {string} id 
     * @param {Track} track 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackApi
     */
    public trackEdit(id: string, track: Track, options?: AxiosRequestConfig) {
        return TrackApiFp(this.configuration).trackEdit(id, track, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read track from given ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackApi
     */
    public trackRead(id: string, options?: AxiosRequestConfig) {
        return TrackApiFp(this.configuration).trackRead(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new user to the system
         * @param {User1} [user1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAdd: async (user1?: User1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Browse user from given filters
         * @param {UserBrowseBody} [userBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userBrowse: async (userBrowseBody?: UserBrowseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/browse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userBrowseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing user from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDelete: async (deleteBody?: DeleteBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit existing user from given ID
         * @param {string} id 
         * @param {User1} [user1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userEdit: async (id: string, user1?: User1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userEdit', 'id', id)
            const localVarPath = `/user/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read user from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userRead', 'id', id)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new user to the system
         * @param {User1} [user1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAdd(user1?: User1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAdd(user1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Browse user from given filters
         * @param {UserBrowseBody} [userBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userBrowse(userBrowseBody?: UserBrowseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserBrowse200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userBrowse(userBrowseBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete existing user from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDelete(deleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit existing user from given ID
         * @param {string} id 
         * @param {User1} [user1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userEdit(id: string, user1?: User1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userEdit(id, user1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read user from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRead200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new user to the system
         * @param {User1} [user1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAdd(user1?: User1, options?: any): AxiosPromise<UserRead200Response> {
            return localVarFp.userAdd(user1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Browse user from given filters
         * @param {UserBrowseBody} [userBrowseBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userBrowse(userBrowseBody?: UserBrowseBody, options?: any): AxiosPromise<UserBrowse200Response> {
            return localVarFp.userBrowse(userBrowseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing user from given ID
         * @param {DeleteBody} [deleteBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDelete(deleteBody?: DeleteBody, options?: any): AxiosPromise<UserDelete200Response> {
            return localVarFp.userDelete(deleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit existing user from given ID
         * @param {string} id 
         * @param {User1} [user1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userEdit(id: string, user1?: User1, options?: any): AxiosPromise<UserRead200Response> {
            return localVarFp.userEdit(id, user1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read user from given ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRead(id: string, options?: any): AxiosPromise<UserRead200Response> {
            return localVarFp.userRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Add new user to the system
     * @param {User1} [user1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAdd(user1?: User1, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAdd(user1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Browse user from given filters
     * @param {UserBrowseBody} [userBrowseBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userBrowse(userBrowseBody?: UserBrowseBody, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userBrowse(userBrowseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing user from given ID
     * @param {DeleteBody} [deleteBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDelete(deleteBody?: DeleteBody, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userDelete(deleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit existing user from given ID
     * @param {string} id 
     * @param {User1} [user1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userEdit(id: string, user1?: User1, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userEdit(id, user1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read user from given ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRead(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userRead(id, options).then((request) => request(this.axios, this.basePath));
    }
}


