import moment from 'moment';
import create, { StateCreator } from 'zustand';

import { persist } from 'zustand/middleware';
import {
  BookingInfo,
  PriceEnum,
  RegisterFormType,
  TModalOpen,
  User,
  YetiModalProps,
} from './types';

export interface UserStoreType {
  user: User | undefined;
  setUser: (user: User) => void;
  token: string | undefined;
  login: (user: User, token: string) => void;
  logout: () => void;
  loginBackUrl?: string;
  setLoginBackUrl: (url: string) => void;
  registerData: Partial<RegisterFormType>;
  setRegisterData: (data: Partial<RegisterFormType>) => void;
}

export interface BookingStoreType {
  bookingInfo: Partial<BookingInfo> | undefined;
  setBookingInfo: (data: Partial<BookingInfo>) => void;
  profilesCount: number;
  setProfilesCount: (data: number) => void;
  pricesData: { [key in PriceEnum]?: { fullValue: number; value: number } };
  setPricesData: (data: {
    [key in PriceEnum]?: { fullValue: number; value: number };
  }) => void;
  profilesToSave: number[];
  setProfilesToSave: (indexes: number[]) => void;
  registerData: Partial<RegisterFormType>;
  setRegisterData: (data: Partial<RegisterFormType>) => void;
  bookingCreationDate: Date | undefined;
  setBookingCreationDate: () => void;
  reset: () => void;
}

export const useUserStore = create<UserStoreType>(
  persist(
    (set) => ({
      user: undefined,
      setUser: (user) => set({ user }),
      token: undefined,
      login: (user, token) => set({ user, token }),
      logout: () =>
        set({
          user: undefined,
          token: undefined,
          registerData: {},
        }),
      registerData: {},
      setRegisterData: (registerData) => set({ registerData }),
      loginBackUrl: undefined,
      setLoginBackUrl: (loginBackUrl) => set({ loginBackUrl }),
    }),
    { name: 'yeki-storage-user' }
  ) as StateCreator<UserStoreType, [], [], UserStoreType>
);

const bookingStoreInitialState = {
  bookingInfo: undefined,
  profilesToSave: [],
  profilesCount: 1,
  pricesData: {},
  registerData: {},
  bookingCreationDate: undefined,
};

export const useBookingStore = create<BookingStoreType>(
  persist(
    (set) => ({
      ...bookingStoreInitialState,
      setBookingInfo: (data) => set({ bookingInfo: data }),
      setProfilesToSave: (data) => set({ profilesToSave: data }),
      setProfilesCount: (data) => set({ profilesCount: data }),
      setPricesData: (indexes) => set({ pricesData: indexes }),
      setRegisterData: (data) => set({ registerData: data }),
      setBookingCreationDate: () =>
        set({ bookingCreationDate: moment().toDate() }),
      reset: () => set(bookingStoreInitialState),
    }),
    { name: 'yeki-storage-booking' }
  ) as StateCreator<BookingStoreType, [], [], BookingStoreType>
);

type TModalStore = {
  props: YetiModalProps | undefined;
  notify: TModalOpen;
  close: () => void;
};

export const useNotification = create<TModalStore>((set) => ({
  props: undefined,
  notify: (type, props) => set({ props: { ...props, type, isOpen: true } }),
  close: () => set({ props: undefined }),
}));
