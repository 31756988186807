import { ModalProps } from '@chakra-ui/react';
import axios, { AxiosResponse } from 'axios';
import { MutableRefObject } from 'react';
import {
  AvailabilityBody,
  Booking,
  BookingDeliveryEnum,
  BookingProfile,
  BookingProfileBookedBoard,
  BookingProfileBookedSkiBoots,
  BookingProfileBookedSki,
  BookingProfileGenderEnum,
  BookingProfileLevelEnum,
  BookingProfileOwnedBoots,
  CalendarAvailability as TempCalendarAvailability,
  Point,
  BookingNewRequest,
  PriceNameEnum,
  Track,
  TrackBrowse200Response,
  User1 as TempUser,
  UserProfile as TempUserProfile,
  BookingPrice,
  MinimumAvailabilityAvailabilitySKIValue,
  MinimumAvailability as TempMinimumAvailability,
  PriceRead200Response,
} from './typings';

export type Undefinible<T> = {
  [P in keyof T]: Required<T[P]> | undefined;
};

export type HomepageFormType = {
  startDate: string;
  endDate: string;
  profilesCount: number;
  areaId: string;
};

export type LoginFormType = {
  email: string;
  password: string;
};

export type RegisterFormType = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  password: string;
  password_confirm: string;
  TOS?: boolean;
};

export type ShipmentFormType = {
  pointAddress?: string;
  pickupTime?: string;
  deliveryTime?: string;
  trackAddress?: string;
  pickupTrackAddress: string;
  pointId?: string;
  accomodationNotes?: string;
  delivery?: DeliveryType;
  accomodation?: { address: string; city: string };
};

export type UnregisteredFormType = {
  email: string;
  phone: string;
};

export type DocumentFormType = {
  document: string;
  save: boolean;
};

export type FinalizeFormType = {
  hasStoreShoe: boolean;
  hasInsurance: boolean;
  acceptedTerms?: boolean;
  cancelReason?: string;
  profiles?: BookingInfo['profiles'];
  coupon?: string;
};

export type PreviewDataType = {
  type: string;
  main: boolean;
  helmet: boolean;
  boots: boolean;
  premium: boolean;
  level: number;
  skipass?: boolean;
  saveProfile?: boolean;
  noPreference?: boolean;
};

export type AccordionButtonProps = {
  label: string;
  borderBottomBool?: boolean;
  details: {};
  detailsMax: number;
  previewData?: PreviewDataType;
  proceedRef?: React.ForwardedRef<any>;
  profileId?: string;
  isSummary?: boolean;
  errors: {};
  equipmentRef?: MutableRefObject<HTMLButtonElement | null>;
  btnType?: 'measures';
};

export type TModalOpen = (
  type: YetiModalProps['type'],
  props: Omit<YetiModalProps, 'isOpen' | 'type'>
) => void;

export type YetiModalProps = {
  title: string;
  text: string;
  buttonText?: string;
  buttonTwoText?: string;
  type: 'email' | 'success' | 'warning' | 'delete' | 'error' | 'availability';
  onConfirm?: (index?: number) => void;
} & Partial<Pick<ModalProps, 'isOpen' | 'onClose'>>;

export type BookingInfo = Booking;
export type CreateBookingRequest = BookingNewRequest;
export type User = TempUser;
export type Profile = BookingProfile;
export type UserProfile = TempUserProfile;
export type ProfilesType = BookingProfile[];
export type DeliveryType = BookingDeliveryEnum;
export type EquipmentAvailability = AvailabilityBody;
export type CalendarAvailability = TempCalendarAvailability;
export type Tracks = TrackBrowse200Response;
export type TrackType = Track;
export type PointType = Point;
export type BookedSkiType = BookingProfileBookedSki;
export type BookedBoardType = BookingProfileBookedBoard;
export type BookedHelmetType = BookingProfileBookedSkiBoots;
export type BookedSkiBootsType = BookingProfileBookedSkiBoots;
export type BookedBoardBootsType = BookingProfileBookedSkiBoots;
export type OwnedBootsType = BookingProfileOwnedBoots;
export type GenderEnumType = BookingProfileGenderEnum;
export type LevelEnum = BookingProfileLevelEnum;
export type PriceEnum = PriceNameEnum;
export type FinalizePriceEnum =
  | keyof BookingPrice['base']
  | keyof BookingPrice['extra'];
export type ItemPrices = BookingPrice;
export type MinimumAvailabilitySKI = MinimumAvailabilityAvailabilitySKIValue;
export type MinimumAvailability = TempMinimumAvailability;

export const on401 = (callback: (response: AxiosResponse) => void) =>
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;
      if (response?.status === 401) {
        callback(response);
      }
      return Promise.reject(error);
    }
  );
