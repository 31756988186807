import {
  AccordionItem,
  AccordionPanel,
  FlexProps,
  forwardRef,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { MutableRefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  BookedBoardBootsType,
  BookedBoardType,
  BookedHelmetType,
  BookedSkiBootsType,
  BookedSkiType,
  BookingInfo,
  MinimumAvailability,
  MinimumAvailabilitySKI,
  OwnedBootsType,
  PreviewDataType,
  Profile,
} from '../../types';
import { StanceEnum } from '../../utils/profile-utils';
import SimpleInputText from '../inputs/input-text';
import { CustomSelect } from '../inputs/select-input';
import { ProfileFormAccordionButton } from './profile-form-accordion-btn';

export const MeasuresForm = forwardRef<
  {
    details: {};
    page: 'finalize' | 'account' | 'profiles' | 'summary';
    errors: { [key in keyof Profile]?: unknown };
    savedProfileData: Profile | undefined;
    kitState: PreviewDataType;
    setKitState: (kitState: PreviewDataType) => void;
    index: number;
    profileId: string;
    handleBlur: (
      fieldValue?: string,
      fieldName?:
        | keyof Profile
        | `bookedHelmet.${keyof BookedHelmetType}`
        | `bookedSkiBoots.${keyof BookedSkiBootsType}`
        | `bookedBoardBoots.${keyof BookedBoardBootsType}`
        | `ownedBoots.${keyof OwnedBootsType}`
        | `bookedBoard.${keyof BookedBoardType}`
        | `bookedSki.${keyof BookedSkiType}`
    ) => void;
    setGuideToggled?: (
      bool: false | 'ankle' | 'head' | 'foot' | 'boots'
    ) => void;
    availabilitySearch: (
      inputLabel: 'heightCM' | 'shoeNumber' | 'headCircCM',

      inputValue: number,
      availability: { [key: string]: MinimumAvailabilitySKI },
      shouldSearch: boolean
    ) => void;
    equipData?: MinimumAvailability;
    equipmentRef?: MutableRefObject<HTMLButtonElement | null>;
  } & FlexProps,
  'div'
>(
  (
    {
      details,
      page,
      kitState,
      setKitState,
      savedProfileData,
      index,
      profileId,
      handleBlur,
      errors,
      setGuideToggled,
      availabilitySearch,
      equipData,
      equipmentRef,
    },
    ref
  ) => {
    const { register, setValue, getValues, watch, formState } =
      useFormContext<BookingInfo>();

    const StanceValue = watch(`profiles.${index}.stance`);

    const generateName = (
      name:
        | keyof Profile
        | `bookedHelmet.${keyof BookedHelmetType}`
        | `bookedSkiBoots.${keyof BookedSkiBootsType}`
        | `bookedBoardBoots.${keyof BookedBoardBootsType}`
        | `ownedBoots.${keyof OwnedBootsType}`
        | `bookedBoard.${keyof BookedBoardType}`
        | `bookedSki.${keyof BookedSkiType}`
    ) => {
      return `profiles.${index}.${name}` as const;
    };

    return (
      <AccordionItem
        border='none'
        isDisabled={
          page === 'summary' ||
          (!kitState.main &&
            !kitState.boots &&
            !kitState.helmet &&
            page !== 'account')
        }
        onClick={() => {
          equipmentRef?.current?.click();
        }}
        ref={ref}
      >
        <h2>
          <ProfileFormAccordionButton
            label='Misure'
            btnType='measures'
            details={details}
            detailsMax={
              (kitState.main ? 2 : 0) +
              (kitState.main && !kitState.boots ? 3 : 0) +
              (kitState.boots ? 1 : 0) +
              (kitState.helmet ? 1 : 0) +
              (getValues(generateName('footLengthCM')) && kitState.boots
                ? 1
                : 0) +
              (getValues(generateName('weightKG')) &&
              kitState.type === 'BOARD' &&
              kitState.main
                ? 1
                : 0) +
              (getValues(generateName('footWidthCM')) && kitState.boots
                ? 1
                : 0) +
              (getValues(generateName('ankleCM')) && kitState.boots ? 1 : 0)
            }
            previewData={kitState}
            profileId={profileId}
            isSummary={page === 'summary'}
            errors={errors}
            borderBottomBool={page !== 'account'}
          />
        </h2>
        <AccordionPanel
          p='30px 16px 30px 16px'
          bgColor='rgba(242,252,255,1)'
          borderTop={page === 'account' ? '1px' : ''}
          borderBottomWidth={page !== 'account' ? '1px' : ''}
          borderColor='brandTransparent.500'
        >
          <Grid
            templateColumns={{ base: 'repeat(1,1fr)', md: 'repeat(2, 1fr)' }}
            columnGap='16px'
            rowGap='15px'
          >
            {(kitState.main || page === 'account') && (
              <>
                {/* HEIGHT */}
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <SimpleInputText
                    {...register(generateName('heightCM'), {
                      required: kitState.main,
                      valueAsNumber: true,
                      validate: (value) => {
                        if (page === 'account') {
                          return true;
                        }
                        const v = parseFloat(value?.toString() ?? '0');
                        return !isNaN(v) && v > 60 && v < 250;
                      },
                    })}
                    label='Altezza'
                    type='number'
                    secondaryLabelType='required'
                    additionalInfo='Cm'
                    errors={formState.errors?.profiles?.[index]?.heightCM}
                    onBlur={(e) => {
                      handleBlur(e.target.value, 'heightCM');
                      if (page !== 'account' && e.target.value !== '') {
                        availabilitySearch(
                          'heightCM',
                          parseInt(e.target.value),
                          kitState.type === 'SKI'
                            ? equipData?.availability?.SKI ?? {}
                            : equipData?.availability?.BOARD ?? {},
                          parseInt(e.target.value) > 60 &&
                            parseInt(e.target.value) < 250
                        );
                      }
                    }}
                  />
                </GridItem>
                {/* WEIGHT */}
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <SimpleInputText
                    {...register(generateName('weightKG'), {
                      required: kitState.main && kitState.type === 'SKI',
                      valueAsNumber: true,
                      validate: (value) =>
                        (kitState.main && kitState.type === 'SKI'
                          ? parseFloat(value?.toString() ?? '0') > 0
                          : true) || page === 'account',
                    })}
                    label='Peso'
                    type='number'
                    secondaryLabelType={
                      kitState.type === 'SKI' ? 'required' : undefined
                    }
                    additionalInfo='Kg'
                    errors={formState.errors?.profiles?.[index]?.weightKG}
                    onBlur={(e) => handleBlur(e.target.value, 'weightKG')}
                  />
                </GridItem>
              </>
            )}
            {/* STANCE SNOWBOARD */}
            {((kitState.type === 'BOARD' && kitState.main) ||
              page === 'account') && (
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <CustomSelect
                  {...register(generateName('stance'), {})}
                  label='Stile'
                  customDefaultValue={
                    !StanceValue
                      ? { label: 'Non lo so', value: '' }
                      : {
                          label: StanceEnum[StanceValue!].toString(),
                          value: StanceValue?.toString()!,
                        }
                  }
                  options={[
                    { label: 'Regular', value: 'REGULAR' },
                    { label: 'Goofy', value: 'GOOFY' },
                    { label: 'Non lo so', value: '' },
                  ]}
                  customOnChange={(stance: {
                    label: string;
                    value: string;
                  }) => {
                    setValue(
                      generateName('stance'),
                      stance.value !== '' ? stance.value : undefined,
                      {
                        shouldValidate: true,
                      }
                    );
                    handleBlur();
                  }}
                  errors={formState.errors?.profiles?.[index]?.stance}
                  secondaryLabelType='required'
                />
              </GridItem>
            )}
            {/* HEAD CIRCUMFERENCE */}
            {(kitState.helmet || page === 'account') && (
              <GridItem colSpan={{ base: 2, md: 1 }}>
                <SimpleInputText
                  {...register(generateName('bookedHelmet.size'))}
                  {...register(generateName('headCircCM'), {
                    required: page !== 'account',
                    valueAsNumber: true,
                    validate: (value) => {
                      if (page === 'account') {
                        return true;
                      }
                      const v = parseFloat(value?.toString() ?? '0');
                      return !isNaN(v) && v > 30 && v < 70;
                    },
                  })}
                  label='Circonferenza testa'
                  additionalInfo='Cm'
                  type='number'
                  secondaryLabelType='size'
                  setGuideToggled={(value) =>
                    setGuideToggled?.(value ? 'head' : false)
                  }
                  errors={formState.errors?.profiles?.[index]?.headCircCM}
                  onBlur={(e) => {
                    handleBlur(e.target.value, 'headCircCM');

                    if (page !== 'account' && e.target.value !== '') {
                      availabilitySearch(
                        'headCircCM',
                        parseInt(e.target.value),
                        equipData?.availability?.HELMET ?? {},

                        parseInt(e.target.value) > 30 &&
                          parseInt(e.target.value) < 70
                      );
                    }
                  }}
                />
              </GridItem>
            )}

            {kitState.main && !kitState.boots && (
              <GridItem colSpan={2}>
                <Text
                  color='brand.500'
                  w='100%'
                  variant='bold'
                  textAlign='center'
                >
                  Facci sapere che scarponi indosserai
                </Text>
              </GridItem>
            )}

            {((!kitState.boots && kitState.main) || page === 'account') && (
              <>
                {/*OWNED BOOTS SIZE*/}
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <SimpleInputText
                    {...register(generateName('ownedBoots.size'), {
                      required: page !== 'account',
                      validate: (value) =>
                        parseFloat(value?.toString() ?? '0') > 0 ||
                        page === 'account',
                    })}
                    label={'Taglia scarponi'}
                    secondaryLabelType='required'
                    additionalInfo='EU'
                    type='number'
                    errors={
                      formState.errors?.profiles?.[index]?.ownedBoots?.size
                    }
                    onBlur={(e) => {
                      handleBlur(e.target.value, 'ownedBoots.size');
                    }}
                  />
                </GridItem>
                {/* OWNED BOOTS BRAND */}
                {!kitState.boots && (
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <SimpleInputText
                      {...register(generateName('ownedBoots.brand'), {
                        required: !kitState.boots && page !== 'account',
                      })}
                      label='Marca scarponi'
                      errors={
                        formState.errors?.profiles?.[index]?.ownedBoots?.brand
                      }
                      onBlur={(e) => {
                        handleBlur(e.target.value, 'ownedBoots.brand');
                      }}
                      secondaryLabelType='required'
                    />
                  </GridItem>
                )}

                {/* BOOTS MM */}
                {!kitState.boots && (
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <SimpleInputText
                      {...register(generateName('ownedBoots.bootsMM'), {
                        required: !kitState.boots,
                        validate: (value) =>
                          parseFloat(value?.toString() ?? '0') > 0 ||
                          page === 'account',
                      })}
                      label='Mm scarpone'
                      type='number'
                      secondaryLabelType='size'
                      setGuideToggled={(value) =>
                        setGuideToggled?.(value ? 'boots' : false)
                      }
                      additionalInfo='Mm'
                      errors={
                        formState.errors?.profiles?.[index]?.ownedBoots?.bootsMM
                      }
                      onBlur={(e) => {
                        handleBlur(e.target.value, 'ownedBoots.bootsMM');
                      }}
                    />
                  </GridItem>
                )}
              </>
            )}

            {(kitState.boots || page === 'account') && (
              <>
                {/*BOOKED BOOTS SIZE*/}
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <SimpleInputText
                    {...(kitState.boots &&
                      kitState.type === 'SKI' &&
                      register(generateName('bookedSkiBoots')))}
                    {...(kitState.boots &&
                      kitState.type === 'BOARD' &&
                      register(generateName('bookedBoardBoots')))}
                    {...register(generateName('shoeNumber'), {
                      required: page !== 'account',
                      valueAsNumber: true,
                      validate: (value) => {
                        if (page === 'account') {
                          return true;
                        }
                        const v = parseFloat(value?.toString() ?? '0');
                        return !isNaN(v) && v > 30 && v < 60;
                      },
                    })}
                    label={'Numero scarpa'}
                    secondaryLabelType='required'
                    additionalInfo='EU'
                    type='number'
                    errors={formState.errors?.profiles?.[index]?.shoeNumber}
                    onBlur={(e) => {
                      handleBlur(e.target.value, 'shoeNumber');
                      if (
                        page !== 'account' &&
                        kitState.boots &&
                        e.target.value !== ''
                      ) {
                        availabilitySearch(
                          'shoeNumber',
                          parseInt(e.target.value),
                          (kitState.type === 'SKI'
                            ? equipData?.availability?.SKIBOOTS
                            : equipData?.availability?.BOARDBOOTS) ?? {},
                          parseInt(e.target.value) > 30 &&
                            parseInt(e.target.value) < 60
                        );
                      }
                    }}
                  />
                </GridItem>
                {/* ANKLE CM */}
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <SimpleInputText
                    {...register(generateName('ankleCM'), {
                      valueAsNumber: true,
                    })}
                    label='Circonferenza caviglia'
                    type='number'
                    secondaryLabelType='size'
                    setGuideToggled={(value) =>
                      setGuideToggled?.(value ? 'ankle' : false)
                    }
                    additionalInfo='Cm'
                    errors={formState.errors?.profiles?.[index]?.ankleCM}
                    onBlur={(e) => {
                      handleBlur(e.target.value, 'ankleCM');
                    }}
                  />
                </GridItem>
                {page !== 'account' && (
                  <GridItem colSpan={2}>
                    <Text
                      color='brand.500'
                      w='100%'
                      variant='bold'
                      textAlign='center'
                    >
                      Aiutaci ad essere più precisi
                    </Text>
                  </GridItem>
                )}
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <SimpleInputText
                    {...register(generateName('footWidthCM'), {
                      valueAsNumber: true,
                    })}
                    label='Larghezza piede'
                    type='number'
                    secondaryLabelType='size'
                    setGuideToggled={(value) =>
                      setGuideToggled?.(value ? 'foot' : false)
                    }
                    additionalInfo='Cm'
                    errors={formState.errors?.profiles?.[index]?.footWidthCM}
                    onBlur={(e) => {
                      handleBlur(e.target.value, 'footWidthCM');
                    }}
                  />
                </GridItem>
                {/* FOOT LENGTH */}
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <SimpleInputText
                    {...register(generateName('footLengthCM'), {
                      valueAsNumber: true,
                    })}
                    label='Lunghezza piede'
                    type='number'
                    secondaryLabelType='size'
                    setGuideToggled={(value) =>
                      setGuideToggled?.(value ? 'foot' : false)
                    }
                    additionalInfo='Cm'
                    errors={formState.errors?.profiles?.[index]?.footLengthCM}
                    onBlur={(e) => {
                      handleBlur(e.target.value, 'footLengthCM');
                    }}
                  />
                </GridItem>
              </>
            )}
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    );
  }
);
