import { Icon, IconProps } from '@chakra-ui/react';

export const SciIcon = (props: IconProps) => (
  <Icon viewBox='0 0 35.998 35.993' {...props}>
    <path
      id='Icon_awesome-skiing'
      data-name='Icon awesome-skiing'
      d='M30.375,6.75A3.375,3.375,0,1,0,27,3.375,3.376,3.376,0,0,0,30.375,6.75Zm5.133,25.038a1.681,1.681,0,0,0-2.384,0,2.861,2.861,0,0,1-3.171.612L20.4,27.464l3.459-5.189a3.375,3.375,0,0,0-.422-4.261L20.6,15.166l-7.523-3.734a4.486,4.486,0,0,0,1.245,3.825l5.28,5.288-3.227,4.837L2.461,18.19a1.686,1.686,0,1,0-1.547,3L28.47,35.423a6.192,6.192,0,0,0,2.609.57,6.26,6.26,0,0,0,4.43-1.828,1.685,1.685,0,0,0,0-2.377ZM8.438,6.441,7.629,8.023a2.753,2.753,0,0,0,3.009-.338L13.957,9.33c-.007.007-.007.014-.014.021l8.051,3.994,2.278-.914.45,1.343a3.347,3.347,0,0,0,1.688,1.948l4.085,2.039a2.248,2.248,0,1,0,2.011-4.022L28.842,11.9l-1.2-3.6A4.518,4.518,0,0,0,21.7,5.548L15.989,7.833l-4.395-2.18a2.726,2.726,0,0,0-1.47-2.5l-.78,1.526H9.33L6.912,4.184a.359.359,0,0,0-.352.12.368.368,0,0,0,.035.52L8.438,6.441Z'
      transform='translate(0)'
      fill='currentColor'
    />
  </Icon>
);
export const SnowboardIcon = (props: IconProps) => (
  <Icon viewBox='0 0 35.991 35.993' {...props}>
    <g
      id='Icon_map-snowboarding'
      data-name='Icon map-snowboarding'
      transform='translate(-1.442 -1.439)'
    >
      <path
        id='Tracciato_1361'
        data-name='Tracciato 1361'
        d='M30.983,5.675a3.006,3.006,0,1,1-3.006-2.958,2.983,2.983,0,0,1,3.006,2.958Z'
        transform='translate(2.04 0.111)'
        fill='currentColor'
      />
      <path
        id='Tracciato_1362'
        data-name='Tracciato 1362'
        d='M36.944,16.92,28.474,9.258a3.324,3.324,0,0,0-.976-.646L20.9,6.3l-3-4.277a1.486,1.486,0,0,0-1-.575,1.445,1.445,0,0,0-1.6,1.248,1.391,1.391,0,0,0,.213.907l3.058,4.354a1.389,1.389,0,0,0,.532.419l3.548,1.379-5.835,2.932a3.145,3.145,0,0,0-1.552,2.2l.035,5.245L9.919,22.522a1.827,1.827,0,0,0-1.074,1.587,1.8,1.8,0,0,0,.339,1.123L6.278,24.144a1.934,1.934,0,0,1-1.156-1.195,1.9,1.9,0,0,0-2.4-1.169,1.854,1.854,0,0,0-1.189,2.357,5.68,5.68,0,0,0,3.4,3.48l25.233,9.439a5.754,5.754,0,0,0,4.878-.369,1.845,1.845,0,0,0,.7-2.535,1.909,1.909,0,0,0-2.58-.686,1.956,1.956,0,0,1-1.66.12l-6.256-2.342a1.835,1.835,0,0,0,1.238-1.48l.985-7.845.008-.106a1.773,1.773,0,0,0-.577-1.4l-4.238-3.7,6.385-3.1,5.932,5.374A1.473,1.473,0,0,0,36,19.4a1.427,1.427,0,0,0,1.429-1.431,1.393,1.393,0,0,0-.489-1.047Zm-13.312,5.5-.843,6.886-.008.12a1.783,1.783,0,0,0,.244.98L11.137,25.961a1.889,1.889,0,0,0,.387-.135l6.6-2.933a1.847,1.847,0,0,0,.94-1.229L19.048,18.4l4.584,4.022Z'
        transform='translate(0)'
        fill='currentColor'
      />
    </g>
  </Icon>
);
