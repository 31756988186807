import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { BookingSummary } from '../components/finalize/booking-summary';
import {
  addToken,
  getBookingApi,
  getUserApi,
  notifyServerError,
} from '../services/api/open-api';
import { useBookingStore, useNotification, useUserStore } from '../store';
import { UserProfile } from '../types';

export const OrderSummary = () => {
  const { user, setUser, token } = useUserStore(
    ({ user, setUser, token }) => ({ user, setUser, token }),
    shallow
  );
  const { bookingInfo, setBookingInfo, profilesToSave, setProfilesToSave } =
    useBookingStore(
      ({ bookingInfo, setBookingInfo, profilesToSave, setProfilesToSave }) => ({
        bookingInfo,
        setBookingInfo,
        profilesToSave,
        setProfilesToSave,
      }),
      shallow
    );
  const { orderId } = useParams();
  const { search } = useLocation();
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);
  const navigate = useNavigate();
  const [timer, setTimer] = useState<NodeJS.Timer>();

  const params = new URLSearchParams(search);
  const code = params.get('code');

  const userEmail =
    user?.email ?? params.get('email') ?? bookingInfo?.userEmail;

  const { data: orderData, refetch: orderRefetch } = useQuery(
    ['getOrderData'],
    async () => {
      const res = await getBookingApi().bookingRetrieve(orderId!, userEmail!);
      const booking = res.data.element;
      if (timer && booking?.status !== 'WAITING_PAYMENT') {
        clearInterval(timer);
        setTimer(undefined);
      }
      return booking;
    },
    {
      enabled: !!(userEmail && orderId),
      onError: (e) => {
        navigate('/');
        if (e) {
          clearInterval(timer);
          setTimer(undefined);
          notify('error', {
            text: 'Prenotazione non trovata.',
            title: 'Errore',
          });
        }
      },
    }
  );

  const defaultUrl =
    orderData?.id && `/order-summary/${orderData?.id}?email=${userEmail}`;

  const onBookingCancel = async (reason?: string) => {
    const bookingId = orderData?.id;
    if (!bookingId) {
      return notify('error', {
        text: 'Prenotazione non trovata.',
        title: 'Errore',
      });
    }
    try {
      const { status } = await getBookingApi().bookingChangeStatus(
        bookingId,
        { status: 'CANCELED', reason },
        addToken(token)
      );
      if (status === 204) {
        // user not logged in, e-mail sent
        notify('email', {
          title: 'Cancellazione richiesta',
          text: 'Controlla la tua casella di posta per confermare la cancellazione',
        });
      } else {
        orderRefetch();
      }
    } catch (e) {
      notifyServerError(e, notify);
    }
  };

  const cancelBooking = async (code: string) => {
    const bookingId = orderData?.id;
    if (!bookingId) {
      return notify('error', {
        text: 'Prenotazione mancante',
        title: 'Errore',
      });
    }
    try {
      await getBookingApi().bookingCancel({ code });
      notify('success', {
        title: 'Ordine cancellato',
        text: 'Prenotazione cancellata con successo.',
        onClose: () => {
          orderRefetch();
          if (defaultUrl) {
            navigate(defaultUrl);
          }
        },
      });
    } catch (e: any) {
      if (e.response.status === 404) {
        return notify('error', {
          title: 'Errore',
          text: 'Codice non valido.',
        });
      }
      notifyServerError(e, notify);
    }
  };

  useEffect(() => {
    if (params.get('error')) {
      notify('error', {
        title: 'Pagamento non riuscito!',
        text: "C'è stato un errore durante il pagamento, verrai redirezionato al riepilogo",
        buttonText: 'Vai al riepilogo',
        onClose: () => navigate('/finalize?fromSummary=true'),
      });
    } else if (params.get('success')) {
      //async function to save/update selected profiles on booking creation success
      (async () => {
        if (!user?.id || Object.entries(profilesToSave).length === 0) {
          return;
        }

        const profilesToSaveOrModify =
          bookingInfo?.profiles
            ?.filter((profile, index) => profilesToSave.includes(index))
            .map<UserProfile>(
              ({
                birthDate,
                bookedSki,
                bookedBoard,
                bookedSkiBoots,
                bookedBoardBoots,
                bookedHelmet,
                notes,
                ownedBoots,
                footLengthCM,
                footWidthCM,
                ...profile
              }) => {
                return {
                  ...profile,
                  birthDate,
                  footLengthCM,
                  footWidthCM,
                  bootsBrand: ownedBoots?.brand,
                  bootsMM: ownedBoots?.bootsMM,
                  bootsSize: ownedBoots?.size,
                };
              }
            ) ?? [];

        const idsToModify = profilesToSaveOrModify.map((profile) => profile.id);
        const unchangedProfiles = user?.profiles?.filter(
          (profile) => !idsToModify.includes(profile.id)
        );

        const res = await getUserApi().userEdit(
          user.id,
          {
            profiles: [...(unchangedProfiles ?? []), ...profilesToSaveOrModify],
          },
          addToken(token)
        );
        if (res.data.element) {
          setUser(res.data.element);
        }
      })();
      setBookingInfo({});
      setProfilesToSave([]);
      notify('success', {
        title: 'Ordine Completato!',
        text: 'Pagamento avvenuto con successo',
        buttonText: 'Vai al mio ordine',
        onClose: () => defaultUrl && navigate(defaultUrl),
      });
    }
  }, []);

  useEffect(() => {
    if (orderData?.id && code) {
      cancelBooking(code);
    }
  }, [orderData]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [timer]);

  useEffect(() => {
    if (orderData?.status === 'WAITING_PAYMENT' && !params.get('error')) {
      if (timer) {
        clearInterval(timer);
      }
      setTimer(setInterval(orderRefetch, 5000));
    }
  }, [orderData, params]);

  if (!orderData) {
    return <></>;
  }

  return (
    <BookingSummary
      /* onSubmit here refers to "Cancel booking" */
      onSubmit={({ cancelReason }) => {
        notify('warning', {
          onConfirm: () => onBookingCancel(cancelReason),
          title: 'Sei sicuro di voler cancellare?',
          text: user
            ? "L'annullamento non è reversibile"
            : "Se annulli ti contatteremo per email per confermarti l'annullamento",
        });
      }}
      booking={orderData}
    />
  );
};
