import { IconProps, Icon, useId } from '@chakra-ui/react';

export const YellowCheckboxOn = (props: IconProps) => (
  <Icon viewBox='0 0 23.022 25.975' {...props}>
    <circle
      id='Ellisse_38'
      data-name='Ellisse 38'
      cx='11'
      cy='11'
      r='11'
      transform='translate(0 3.975)'
      fill='#e6ff00'
    />
    <g
      id='Icon_feather-check-circle'
      data-name='Icon feather-check-circle'
      transform='translate(-0.977 -1.979)'
    >
      <path
        id='Tracciato_1218'
        data-name='Tracciato 1218'
        d='M23,12.076V13a10,10,0,1,1-5.93-9.139'
        transform='translate(0 0)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        id='Tracciato_1219'
        data-name='Tracciato 1219'
        d='M27.775,6,16.794,16.991,13.5,13.7'
        transform='translate(-4.776 -0.806)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </g>
  </Icon>
);

export const LightBlueCheckboxOn = (props: IconProps) => (
  <Icon viewBox='0 0 23.022 25.975' {...props}>
    <circle
      id='Ellisse_38'
      data-name='Ellisse 38'
      cx='11'
      cy='11'
      r='11'
      transform='translate(0 3.975)'
      fill='rgba(0,177,220,0.11)'
    />
    <g
      id='Icon_feather-check-circle'
      data-name='Icon feather-check-circle'
      transform='translate(-0.977 -1.979)'
    >
      <path
        id='Tracciato_1218'
        data-name='Tracciato 1218'
        d='M23,12.076V13a10,10,0,1,1-5.93-9.139'
        transform='translate(0 0)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        id='Tracciato_1219'
        data-name='Tracciato 1219'
        d='M27.775,6,16.794,16.991,13.5,13.7'
        transform='translate(-4.776 -0.806)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </g>
  </Icon>
);

export const NoShadowCheckboxOn = (props: IconProps) => (
  <Icon viewBox='0 0 23.022 25.975' {...props}>
    <g
      id='Icon_feather-check-circle'
      data-name='Icon feather-check-circle'
      transform='translate(-0.977 -1.979)'
    >
      <path
        id='Tracciato_1218'
        data-name='Tracciato 1218'
        d='M23,12.076V13a10,10,0,1,1-5.93-9.139'
        transform='translate(0 0)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        id='Tracciato_1219'
        data-name='Tracciato 1219'
        d='M27.775,6,16.794,16.991,13.5,13.7'
        transform='translate(-4.776 -0.806)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </g>
  </Icon>
);

export const CheckboxOn = (props: IconProps) => (
  <Icon viewBox='0 0 30 30' {...props}>
    <g
      id='Rectangle_576'
      data-name='Rectangle 576'
      fill='#fff'
      stroke='#00b1dc'
      strokeWidth='1'
    >
      <rect width='30' height='30' rx='10' stroke='none' />
      <rect x='0.5' y='0.5' width='29' height='29' rx='9.5' fill='none' />
    </g>
    <g id='Group_77' data-name='Group 77' transform='translate(-919 -340)'>
      <rect
        id='Rectangle_460'
        data-name='Rectangle 460'
        width='16'
        height='16'
        transform='translate(926 347)'
        fill='none'
      />
      <path
        id='Checkbox'
        d='M6.345,11.034,0,4.69,1.287,3.4,6.345,8.368,14.713,0,16,1.287Z'
        transform='translate(926 349)'
        fill='#00b1dc'
      />
    </g>
  </Icon>
);

export const CheckboxOff = (props: IconProps) => (
  <Icon viewBox='0 0 30 30' {...props}>
    <g
      id='Rectangle_576'
      data-name='Rectangle 576'
      fill='#fff'
      stroke='#00b1dc'
      strokeWidth='1'
    >
      <rect width='30' height='30' rx='10' stroke='none' />
      <rect x='0.5' y='0.5' width='29' height='29' rx='9.5' fill='none' />
    </g>
  </Icon>
);

export const CheckboxError = (props: IconProps) => (
  <Icon viewBox='0 0 30 30' {...props}>
    <g
      id='Raggruppa_1893'
      data-name='Raggruppa 1893'
      transform='translate(-70.488 -602)'
    >
      <circle
        id='Ellisse_40'
        data-name='Ellisse 40'
        cx='11'
        cy='11'
        r='11'
        transform='translate(70.488 606)'
        fill='#ffc440'
      />
      <path
        id='Icon_material-error-outline'
        data-name='Icon material-error-outline'
        d='M13.35,17.95h2.3v2.3h-2.3Zm0-9.2h2.3v6.9h-2.3ZM14.488,3A11.5,11.5,0,1,0,26,14.5,11.494,11.494,0,0,0,14.488,3ZM14.5,23.7a9.2,9.2,0,1,1,9.2-9.2A9.2,9.2,0,0,1,14.5,23.7Z'
        transform='translate(69.512 599)'
        fill='#e80000'
      />
    </g>
  </Icon>
);

export const CalendarIcon = (props: IconProps) => (
  <Icon viewBox='0 0 20.947 20.946' {...props}>
    <path
      id='Icon_ionic-md-calendar'
      data-name='Icon ionic-md-calendar'
      d='M21.083,15.846H15.846v5.237h5.237ZM19.337,4.5V6.246H10.609V4.5H7.991V6.246H6.682A2.188,2.188,0,0,0,4.5,8.427V23.264a2.188,2.188,0,0,0,2.182,2.182H23.264a2.188,2.188,0,0,0,2.182-2.182V8.427a2.188,2.188,0,0,0-2.182-2.182H21.955V4.5Zm3.927,18.764H6.682V11.7H23.264Z'
      transform='translate(-4.5 -4.5)'
      fill='#00b1dc'
    />
  </Icon>
);

export const RadioOn = (props: IconProps) => (
  <Icon viewBox='0 0 23.022 25.975' {...props}>
    <circle
      id='Ellisse_38'
      data-name='Ellisse 38'
      cx='11'
      cy='11'
      r='11'
      transform='translate(0 3.975)'
      fill='#e6ff00'
    />
    <g
      id='Icon_feather-check-circle'
      data-name='Icon feather-check-circle'
      transform='translate(-0.977 -1.979)'
    >
      <path
        id='Tracciato_1218'
        data-name='Tracciato 1218'
        d='M23,12.076V13a10,10,0,1,1-5.93-9.139'
        transform='translate(0 0)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        id='Tracciato_1219'
        data-name='Tracciato 1219'
        d='M27.775,6,16.794,16.991,13.5,13.7'
        transform='translate(-4.776 -0.806)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </g>
  </Icon>
);

export const RadioOff = (props: IconProps) => (
  <Icon viewBox='0 0 23.523 26.463' {...props}>
    <g
      id='Raggruppa_1992'
      data-name='Raggruppa 1992'
      transform='translate(-61 -437)'
    >
      <circle
        id='Ellisse_99'
        data-name='Ellisse 99'
        cx='11'
        cy='11'
        r='11'
        transform='translate(61 441.463)'
        fill='rgba(0,177,220,0.11)'
      />
      <path
        id='Icon_material-radio-button-unchecked'
        data-name='Icon material-radio-button-unchecked'
        d='M14.5,3A11.5,11.5,0,1,0,26,14.5,11.5,11.5,0,0,0,14.5,3Zm0,20.7a9.2,9.2,0,1,1,9.2-9.2A9.2,9.2,0,0,1,14.5,23.7Z'
        transform='translate(58.523 434)'
        fill='#00b1dc'
      />
    </g>
  </Icon>
);

export const StarOff = (props: IconProps) => {
  const idOne = useId();
  const idTwo = useId();
  const idThree = useId();

  return (
    <Icon {...props} viewBox='60 42 44.53 43.32'>
      <defs>
        <linearGradient
          id={idOne}
          x1='0.296'
          y1='0.23'
          x2='1.101'
          y2='1.591'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#00b1dc' stopOpacity='0.133' />
          <stop offset='1' stopColor='#00596e' stopOpacity='0.729' />
        </linearGradient>
        <filter
          id={idTwo}
          x='0'
          y='0'
          width='164.534'
          height='163.336'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='18' />
          <feGaussianBlur stdDeviation='20' result={idThree} />
          <feFlood floodColor='#00b1dc' floodOpacity='0' />
          <feComposite operator='in' in2={idThree} />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g transform='matrix(1, 0, 0, 1, 0, 0)'>
        <path
          d='M21.319,1.507,15.884,12.712l-12.161,1.8a2.722,2.722,0,0,0-1.473,4.621l8.8,8.717L8.966,40.166a2.672,2.672,0,0,0,3.862,2.852L23.708,37.2l10.88,5.814a2.674,2.674,0,0,0,3.862-2.852L36.369,27.852l8.8-8.717a2.722,2.722,0,0,0-1.473-4.621l-12.161-1.8L26.1,1.507a2.647,2.647,0,0,0-4.778,0Z'
          transform='translate(58.56 42)'
          fill={`url(#${idOne})`}
        />
      </g>
    </Icon>
  );
};

export const StarOn = (props: IconProps) => {
  const idOne = useId();
  const idTwo = useId();
  const idThree = useId();
  return (
    <Icon {...props} viewBox='60 42 44.53 43.32'>
      <defs>
        <linearGradient
          id={idOne}
          x1='0.296'
          y1='0.23'
          x2='1.101'
          y2='1.591'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#00b1dc' />
          <stop offset='1' stopColor='#01242c' />
        </linearGradient>
        <filter
          id={idTwo}
          x='0'
          y='0'
          width='164.534'
          height='163.336'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy='18' />
          <feGaussianBlur stdDeviation='20' result='c' />
          <feFlood floodColor='#00b1dc' floodOpacity='0' />
          <feComposite operator='in' in2={idThree} />
          <feComposite in='SourceGraphic' />
        </filter>
      </defs>
      <g transform='matrix(1, 0, 0, 1, 0, 0)'>
        <path
          d='M21.319,1.507,15.884,12.712l-12.161,1.8a2.722,2.722,0,0,0-1.473,4.621l8.8,8.717L8.966,40.166a2.672,2.672,0,0,0,3.862,2.852L23.708,37.2l10.88,5.814a2.674,2.674,0,0,0,3.862-2.852L36.369,27.852l8.8-8.717a2.722,2.722,0,0,0-1.473-4.621l-12.161-1.8L26.1,1.507a2.647,2.647,0,0,0-4.778,0Z'
          transform='translate(58.56 42)'
          fill={`url(#${idOne})`}
        />
      </g>
    </Icon>
  );
};

export const PersonIcon = (props: IconProps) => (
  <Icon viewBox='0 0 18.667 20' {...props}>
    <g
      id='Icon_feather-user-plus'
      data-name='Icon feather-user-plus'
      transform='translate(1 1)'
    >
      <path
        id='Tracciato_1204'
        data-name='Tracciato 1204'
        d='M18.167,30.707V27.971c0-3.022-1.99-5.471-4.444-5.471H5.944C3.49,22.5,1.5,24.95,1.5,27.971v2.736Z'
        transform='translate(-1.5 -12.707)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        id='Tracciato_1205'
        data-name='Tracciato 1205'
        d='M15.639,8.944A4.444,4.444,0,1,1,11.194,4.5a4.444,4.444,0,0,1,4.444,4.444Z'
        transform='translate(-2.92 -4.5)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </g>
  </Icon>
);

export const PersonThickIcon = (props: IconProps) => (
  <Icon {...props} viewBox='0 0 18.667 20'>
    <g
      id='Icon_feather-user-plus'
      data-name='Icon feather-user-plus'
      transform='translate(1 1)'
    >
      <path
        id='Tracciato_1204'
        data-name='Tracciato 1204'
        d='M18.167,30.707V27.971c0-3.022-1.99-5.471-4.444-5.471H5.944C3.49,22.5,1.5,24.95,1.5,27.971v2.736Z'
        transform='translate(-1.5 -12.707)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        id='Tracciato_1205'
        data-name='Tracciato 1205'
        d='M15.639,8.944A4.444,4.444,0,1,1,11.194,4.5a4.444,4.444,0,0,1,4.444,4.444Z'
        transform='translate(-2.92 -4.5)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </g>
  </Icon>
);

export const IncrementPersonIcon = (props: IconProps) => (
  <Icon viewBox='0 0 24.007 21.513' {...props}>
    <g
      id='Raggruppa_1901'
      data-name='Raggruppa 1901'
      transform='translate(-248.993 -141)'
    >
      <g
        id='Icon_feather-user-plus'
        data-name='Icon feather-user-plus'
        transform='translate(250 142)'
      >
        <path
          id='Tracciato_1204'
          data-name='Tracciato 1204'
          d='M17.333,28.833V26.722A4.222,4.222,0,0,0,13.111,22.5H5.722A4.222,4.222,0,0,0,1.5,26.722v2.111'
          transform='translate(-1.5 -9.833)'
          fill='none'
          stroke='#00b1dc'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Tracciato_1205'
          data-name='Tracciato 1205'
          d='M15.194,8.722A4.222,4.222,0,1,1,10.972,4.5a4.222,4.222,0,0,1,4.222,4.222Z'
          transform='translate(-3.111 -4.5)'
          fill='none'
          stroke='#00b1dc'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Tracciato_1206'
          data-name='Tracciato 1206'
          d='M30,12v6.333'
          transform='translate(-10.167 -6.722)'
          fill='none'
          stroke='#00b1dc'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Tracciato_1207'
          data-name='Tracciato 1207'
          d='M31.833,16.5H25.5'
          transform='translate(-8.833 -8.056)'
          fill='none'
          stroke='#00b1dc'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
      <path
        id='Tracciato_1332'
        data-name='Tracciato 1332'
        d='M-2867.817,10722.513h17.825'
        transform='translate(3116.81 -10561)'
        fill='none'
        stroke='#00b1dc'
        strokeWidth='2'
      />
    </g>
  </Icon>
);

export const DropzoneIcon = (props: IconProps) => (
  <Icon viewBox='0 0 38.789 48.358' {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='38.789'
      height='48.358'
      viewBox='0 0 38.789 48.358'
    >
      <g
        id='Icon_feather-share'
        data-name='Icon feather-share'
        transform='translate(1.5 2.121)'
      >
        <path
          id='Tracciato_1298'
          data-name='Tracciato 1298'
          d='M6,18V35.895a4.474,4.474,0,0,0,4.474,4.474H37.315a4.474,4.474,0,0,0,4.474-4.474V18'
          transform='translate(-6 4.368)'
          fill='none'
          stroke='#00b1dc'
          strokeWidth='3'
        />
        <path
          id='Tracciato_1299'
          data-name='Tracciato 1299'
          d='M29.895,11.947,20.947,3,12,11.947'
          transform='translate(-3.053 -3)'
          fill='none'
          stroke='#00b1dc'
          strokeWidth='3'
        />
        <path
          id='Tracciato_1300'
          data-name='Tracciato 1300'
          d='M18,3V32.079'
          transform='translate(-0.106 -3)'
          fill='none'
          stroke='#00b1dc'
          strokeLinejoin='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  </Icon>
);

export const MinusIcon = (props: IconProps) => (
  <Icon {...props} viewBox='60 42 32 32'>
    <defs>
      <filter
        id='Rectangle_132'
        x='0'
        y='0'
        width='152'
        height='152'
        filterUnits='userSpaceOnUse'
      >
        <feOffset dy='18' />
        <feGaussianBlur stdDeviation='20' result='blur' />
        <feFlood floodColor='#00b1dc' floodOpacity='0.349' />
        <feComposite operator='in' in2='blur' />
        <feComposite in='SourceGraphic' />
      </filter>
    </defs>
    <g
      id='Raggruppa_2023'
      data-name='Raggruppa 2023'
      transform='translate(59.624 42)'
    >
      <g
        transform='matrix(1, 0, 0, 1, -59.62, -42)'
        filter='url(#Rectangle_132)'
      >
        <g
          id='Rectangle_132-2'
          data-name='Rectangle 132'
          transform='translate(60 42)'
          fill='none'
          stroke='#00b1dc'
          strokeWidth='1'
        >
          <rect width='32' height='32' rx='5' stroke='none' />
          <rect x='0.5' y='0.5' width='31' height='31' rx='4.5' fill='none' />
        </g>
      </g>
      <g
        id='Rettangolo_1176'
        data-name='Rettangolo 1176'
        transform='translate(10.376 15)'
        fill='none'
        stroke='#00b1dc'
        strokeWidth='1'
      >
        <rect width='13' height='2' stroke='none' />
        <rect x='0.5' y='0.5' width='12' height='1' fill='none' />
      </g>
    </g>
  </Icon>
);

export const PlusIcon = (props: IconProps) => (
  <Icon {...props} viewBox='60 42 30 31'>
    <defs>
      <filter
        id='Rectangle_132'
        x='0'
        y='0'
        width='150'
        height='151'
        filterUnits='userSpaceOnUse'
      >
        <feOffset dy='18' />
        <feGaussianBlur stdDeviation='20' result='blur' />
        <feFlood floodColor='#00b1dc' floodOpacity='0.349' />
        <feComposite operator='in' in2='blur' />
        <feComposite in='SourceGraphic' />
      </filter>
    </defs>
    <g
      id='Raggruppa_2077'
      data-name='Raggruppa 2077'
      transform='translate(-244 -433)'
    >
      <g
        id='Raggruppa_2024'
        data-name='Raggruppa 2024'
        transform='translate(304 475)'
      >
        <g
          transform='matrix(1, 0, 0, 1, -60, -42)'
          filter='url(#Rectangle_132)'
        >
          <g
            id='Rectangle_132-2'
            data-name='Rectangle 132'
            transform='translate(60 42)'
            fill='none'
            stroke='#00b1dc'
            strokeWidth='1'
          >
            <rect width='30' height='31' rx='5' stroke='none' />
            <rect x='0.5' y='0.5' width='29' height='30' rx='4.5' fill='none' />
          </g>
        </g>
      </g>
      <g
        id='Rettangolo_1177'
        data-name='Rettangolo 1177'
        transform='translate(313 489)'
        fill='none'
        stroke='#00b1dc'
        strokeWidth='1'
      >
        <rect width='13' height='2' stroke='none' />
        <rect x='0.5' y='0.5' width='12' height='1' fill='none' />
      </g>
      <g
        id='Rettangolo_1178'
        data-name='Rettangolo 1178'
        transform='translate(320.5 483.5) rotate(90)'
        fill='none'
        stroke='#00b1dc'
        strokeWidth='1'
      >
        <rect width='13' height='2' stroke='none' />
        <rect x='0.5' y='0.5' width='12' height='1' fill='none' />
      </g>
    </g>
  </Icon>
);

export const ArrowIcon = (props: IconProps) => (
  <Icon {...props} viewBox='0 0 17.143 10'>
    <path
      id='Icon_material-keyboard-arrow-down'
      data-name='Icon material-keyboard-arrow-down'
      d='M11.014,11.76l6.557,6.181,6.557-6.181,2.014,1.9-8.571,8.1L9,13.663Z'
      transform='translate(-9 -11.76)'
      fill='#00b1dc'
    />
  </Icon>
);

export const TrackIcon = (props: IconProps) => (
  <Icon {...props} viewBox='0 0 23.561 19.375'>
    <g
      id='Raggruppa_2015'
      data-name='Raggruppa 2015'
      transform='translate(1.782 1.921)'
    >
      <path
        id='Tracciato_1318'
        data-name='Tracciato 1318'
        d='M2110,10402.143l-10.008-16.365-9.992,16.447Z'
        transform='translate(-2090 -10385.777)'
        fill='none'
        stroke='#00b1dc'
        strokeWidth='2'
      />
      <path
        id='Tracciato_1319'
        data-name='Tracciato 1319'
        d='M2169.555,10394.184s7.732,1.643,6.308,4.182-2.047,3.191,1.652,4.785a38.911,38.911,0,0,0,4.121,1.666'
        transform='translate(-2162.763 -10388.761)'
        fill='none'
        stroke='#00b1dc'
        strokeWidth='2'
      />
    </g>
  </Icon>
);

export const FinalizeCalendarIcon = (props: IconProps) => (
  <Icon {...props} viewBox='0 0 20 21'>
    <g
      id='Icon_feather-calendar'
      data-name='Icon feather-calendar'
      transform='translate(1)'
    >
      <path
        id='Tracciato_1118'
        data-name='Tracciato 1118'
        d='M6.5,6h14a1.947,1.947,0,0,1,2,1.889V21.111A1.947,1.947,0,0,1,20.5,23H6.5a1.947,1.947,0,0,1-2-1.889V7.889A1.947,1.947,0,0,1,6.5,6Z'
        transform='translate(-4.5 -3)'
        fill='none'
        stroke='#00b1dc'
        strokeWidth='2'
      />
      <path
        id='Tracciato_1119'
        data-name='Tracciato 1119'
        d='M24,3V9'
        transform='translate(-11 -3)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        id='Tracciato_1120'
        data-name='Tracciato 1120'
        d='M12,3V9'
        transform='translate(-7 -3)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
      <path
        id='Tracciato_1121'
        data-name='Tracciato 1121'
        d='M4.5,15h18'
        transform='translate(-4.5 -7)'
        fill='none'
        stroke='#00b1dc'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </g>
  </Icon>
);

export const FinalizePersonIcon = (props: IconProps) => (
  <Icon {...props} viewBox='0 0 18.667 22'>
    <g
      id='Raggruppa_1838'
      data-name='Raggruppa 1838'
      transform='translate(0.008 1)'
    >
      <g
        id='Icon_feather-user-plus'
        data-name='Icon feather-user-plus'
        transform='translate(0.992)'
      >
        <path
          id='Tracciato_1204'
          data-name='Tracciato 1204'
          d='M18.167,29.167V26.944A4.444,4.444,0,0,0,13.722,22.5H5.944A4.444,4.444,0,0,0,1.5,26.944v2.222'
          transform='translate(-1.5 -9.167)'
          fill='none'
          stroke='#00b1dc'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Tracciato_1205'
          data-name='Tracciato 1205'
          d='M15.639,8.944A4.444,4.444,0,1,1,11.194,4.5a4.444,4.444,0,0,1,4.444,4.444Z'
          transform='translate(-2.92 -4.5)'
          fill='none'
          stroke='#00b1dc'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
      <path
        id='Tracciato_1312'
        data-name='Tracciato 1312'
        d='M15369.508,10392h18.651'
        transform='translate(-15369.508 -10372)'
        fill='none'
        stroke='#00b1dc'
        strokeWidth='2'
      />
    </g>
  </Icon>
);

export const FinalizeAccomodationIcon = (props: IconProps) => (
  <Icon {...props} viewBox='0 0 20 22.267'>
    <g
      id='Icon_feather-home'
      data-name='Icon feather-home'
      transform='translate(1 1.267)'
    >
      <path
        id='Tracciato_1292'
        data-name='Tracciato 1292'
        d='M4.5,10l9-7,9,7V21a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2Z'
        transform='translate(-4.5 -3)'
        fill='none'
        stroke='#00b1dc'
        strokeWidth='2'
      />
      <path
        id='Tracciato_1293'
        data-name='Tracciato 1293'
        d='M13.5,28V18h6V28'
        transform='translate(-7.5 -8)'
        fill='none'
        stroke='#00b1dc'
        strokeWidth='2'
      />
    </g>
  </Icon>
);
