import { Flex, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { PageContainer } from '../components/container';
import { ButtonIndexedTabs, ShipmentTabButton } from '../components/forms/tabs';
import { HomeIcon, PointIcon, TrackIcon } from '../components/icons/navigation';
import SimpleInputText from '../components/inputs/input-text';
import { CustomSelect } from '../components/inputs/select-input';
import StyledButton from '../components/inputs/styled-button';
import { SectionTitle } from '../components/section-title';
import { Wrapper } from '../components/wrapper';
import { getPointApi, getTrackApi } from '../services/api/open-api';
import { PrintServices } from '../services/app/print-services';
import { useBookingStore, useNotification, useUserStore } from '../store';
import { PointType, ShipmentFormType, TrackType } from '../types';

export const Shipment = () => {
  const navigate = useNavigate();
  const { notify } = useNotification(({ notify }) => ({ notify }), shallow);
  const { user } = useUserStore(({ user }) => ({ user }), shallow);
  const { bookingInfo, setBookingInfo, pricesData } = useBookingStore(
    ({ bookingInfo, setBookingInfo, pricesData }) => ({
      bookingInfo,
      setBookingInfo,
      pricesData,
    }),
    shallow
  );
  const [tracks, setTracks] = useState([
    { label: 'Caricamento...', value: '' },
  ]);
  const [points, setPoints] = useState([
    { label: 'Caricamento...', value: '' },
  ]);

  const [selectedDeliveryTrack, setSelectedDeliveryTrack] =
    useState<TrackType>();
  const [selectedPickupTrack, setSelectedPickupTrack] = useState<TrackType>();
  const [selectedPoint, setSelectedPoint] = useState<PointType>();

  const stringToTimeValue = (value: string, index: number) => {
    return value.replaceAll(':', '').split('-')[index];
  };

  const startDateIsToday = moment(bookingInfo?.startDate).isSame(
    moment(),
    'date'
  );
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<ShipmentFormType>({
    defaultValues: {
      delivery:
        bookingInfo?.delivery ?? (startDateIsToday ? 'POINT' : 'ACCOMODATION'),
    },
  });
  const deliveryType = watch('delivery');

  const { data: trackData } = useQuery(['getTracksByArea'], async () => {
    const res = await getTrackApi().trackBrowse({
      filter: { areaId: bookingInfo?.areaId! },
    });
    setTracks(
      res.data.elements?.map((x, i) => ({
        label: '' + x.address + ', ' + x.province,
        value: i.toString(),
      }))
    );
    return res.data.elements;
  });

  const { data: pointData } = useQuery(['getPointsBArea'], async () => {
    const res = await getPointApi().pointBrowse({
      filter: { areaId: bookingInfo?.areaId! },
    });
    setPoints(
      res.data.elements?.map((x, i) => ({
        label: '' + x.address + ', ' + x.province,
        value: i.toString(),
      }))
    );
    return res.data.elements;
  });

  useEffect(() => {
    if (!bookingInfo?.profiles) {
      return navigate('/');
    }
    if (!trackData || !pointData) {
      return;
    }
    register('delivery', { required: true });
    const {
      pointAddress,
      trackAddress,
      accomodationAddress,
      accomodationNotes,
      pickupTrackAddress,
      startDate,
      endDate,
    } = bookingInfo;
    if (accomodationAddress) {
      const { address, city } = JSON.parse(accomodationAddress);
      setValue('delivery', 'ACCOMODATION');
      setValue('accomodation.address', address);
      setValue('accomodation.city', city);
      setValue('accomodationNotes', accomodationNotes);
    }
    if (trackAddress) {
      setValue('delivery', 'TRACK');

      setValue('trackAddress', trackAddress);
      setValue('deliveryTime', moment(startDate).format('HH:mm'));
      for (const value of Object.values(trackData ?? {})) {
        if (value.address === trackAddress) {
          setSelectedDeliveryTrack(value);
        }
      }
    }
    if (pointAddress) {
      setValue('delivery', 'POINT');

      setValue('pointAddress', pointAddress);
      setValue('deliveryTime', moment(startDate).format('HH:mm'));
      for (const value of Object.values(pointData ?? {})) {
        if (value.address === pointAddress) {
          setSelectedPoint(value);
        }
      }
    }
    if (pickupTrackAddress) {
      setValue('pickupTrackAddress', pickupTrackAddress);
      setValue('pickupTime', moment(endDate).format('HH:mm'));
      for (const [key, value] of Object.entries(trackData!)) {
        if (value.address === pickupTrackAddress) {
          setSelectedPickupTrack(value);
        }
      }
    }
  }, [trackData, bookingInfo]);

  const updatedDeliveryHours =
    (moment(bookingInfo?.startDate).format('YYYY-MM-DD') ===
    moment().format('YYYY-MM-DD')
      ? moment().add(15, 'minutes').format('HH:mm')
      : ((deliveryType === 'TRACK' &&
          selectedDeliveryTrack?.deliveryHours!.split('-')[0]) ||
          '') +
        ((deliveryType === 'POINT' &&
          selectedPoint?.deliveryHours!.split('-')[0]) ||
          '')) +
    ' -' +
    ((deliveryType === 'TRACK' &&
      selectedDeliveryTrack?.deliveryHours!.split('-')[1]) ||
      '') +
    ((deliveryType === 'POINT' &&
      selectedPoint?.deliveryHours!.split('-')[1]) ||
      '');

  const deliveryHours = (value: string) => {
    return (
      +stringToTimeValue(value, 0) >=
        +stringToTimeValue(updatedDeliveryHours!, 0) &&
      +stringToTimeValue(value, 0) <=
        +stringToTimeValue(
          deliveryType === 'TRACK'
            ? selectedDeliveryTrack?.deliveryHours!
            : selectedPoint?.deliveryHours!,
          1
        )
    );
  };

  const updatedPickupHours =
    (moment(bookingInfo?.endDate).format('YYYY-MM-DD') ===
    moment().format('YYYY-MM-DD')
      ? moment().add(30, 'minutes').format('HH:mm')
      : selectedPickupTrack?.pickupHours!.split('-')[0] || '') +
      '-' +
      selectedPickupTrack?.pickupHours!.split('-')[1] || '';

  const pickupTrackHours = (value: string) => {
    return (
      +stringToTimeValue(value, 0) >=
        +stringToTimeValue(updatedPickupHours, 0) &&
      +stringToTimeValue(value, 0) <=
        +stringToTimeValue(selectedPickupTrack?.pickupHours!, 1)
    );
  };

  const onSubmit: SubmitHandler<ShipmentFormType> = (data) => {
    const completeStartDate = new Date(bookingInfo?.startDate!);
    if (data.deliveryTime) {
      completeStartDate.setHours(+data.deliveryTime!.split(':')[0]!);
      completeStartDate.setMinutes(+data.deliveryTime!.split(':')[1]!);
    }
    const completeEndDate = new Date(bookingInfo?.endDate!);
    completeEndDate.setHours(+data.pickupTime!.split(':')[0]!);
    completeEndDate.setMinutes(+data.pickupTime!.split(':')[1]!);
    setBookingInfo({
      ...bookingInfo!,
      userEmail: user ? user.email : bookingInfo?.userEmail ?? '',
      userPhone: user ? user.phone : bookingInfo?.userPhone ?? '',
      trackAddress: data.trackAddress,
      pickupTrackAddress: data.pickupTrackAddress,
      pointAddress: data.pointAddress,
      accomodationAddress: !!data.accomodation?.address
        ? JSON.stringify(data.accomodation)
        : undefined,
      accomodationNotes: data.accomodationNotes,
      delivery: data.delivery,
      startDate: moment(completeStartDate).format('YYYY-MM-DDTHH:mm:ss'),

      endDate: moment(completeEndDate).format('YYYY-MM-DDTHH:mm:ss'),
    });
    navigate(
      (user?.email && user?.phone) ||
        (bookingInfo?.userEmail && bookingInfo?.userPhone)
        ? '/document'
        : '/contact-info'
    );
  };

  const onError: SubmitErrorHandler<ShipmentFormType> = (data) => {
    if (data.pointAddress?.type === 'required') {
      return notify('error', {
        title: 'Errore',
        text: 'Selezionare un punto Yeki per il ritiro.',
      });
    }
    notify('error', {
      title: 'Errore',
      text: 'Errori nei dati di ritiro e restituzione.',
    });
  };

  if (!bookingInfo) return <Wrapper />;

  const TAB_MARGIN = '4px';

  return (
    <PageContainer>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <SectionTitle
          hasBack
          toPage='/profiles'
          text='Ritiro'
          mt={{ base: '15px', md: '0px' }}
        />
        <Text
          fontSize='15px'
          lineHeight={1.2}
          maxW='320px'
          w='100%'
          mt={{ base: '30px', md: '70px' }}
          mb='30px'
          mx='auto'
        >
          Seleziona il punto di ritiro
        </Text>

        <ButtonIndexedTabs
          tablistProps={{
            maxW: '350px',
            mx: 'auto',
            justifyContent: 'center',
            mb: '0px',
          }}
          defaultIndex={
            bookingInfo.pointAddress || startDateIsToday
              ? 2
              : bookingInfo.trackAddress
              ? 1
              : 0
          }
          childrens={[
            {
              value: 'Home',
              button: (
                <ShipmentTabButton
                  icon={<HomeIcon w='auto' h='30px' color='brand.500' />}
                  mr={TAB_MARGIN}
                  text='Alloggio'
                  disabled={startDateIsToday}
                  onClick={() => {
                    setValue('delivery', 'ACCOMODATION');
                    unregister('pointAddress');
                    unregister('trackAddress');
                    setSelectedPoint(undefined);
                    setSelectedDeliveryTrack(undefined);
                  }}
                />
              ),
              panel: () => (
                <Flex direction='column' mt='30px'>
                  <SectionTitle text='Seleziona ritiro' mt='7px' mb='30px' />
                  <Text
                    fontSize='15px'
                    lineHeight={1.25}
                    color='brand.500'
                    textAlign={'center'}
                    maxW='370px'
                    mx='auto'
                  >
                    N.B In caso di distanze superiori a 20 km la prenotazione
                    potrebbe essere annullata.
                    <br />
                    <br />
                    La consegna dell'attrezzatura all'alloggio verrà effettuata
                    preferibilmente il giorno prima.
                  </Text>
                  <Grid
                    templateColumns={{
                      base: 'repeat(1, 1fr)',
                      md: 'repeat(2, 1fr)',
                    }}
                    columnGap='16px'
                    rowGap='14px'
                    w='100%'
                    mt='20px'
                  >
                    <SimpleInputText
                      label='Indirizzo / Hotel'
                      {...register('accomodation.address', {
                        required: deliveryType === 'ACCOMODATION',
                      })}
                      errors={errors.accomodation?.address}
                      placeholder='Es. Via Valentino guaercio, 90'
                    />
                    <SimpleInputText
                      label='Città'
                      {...register('accomodation.city', {
                        required: deliveryType === 'ACCOMODATION',
                      })}
                      errors={errors.accomodation?.city}
                      placeholder='Es. Salerno'
                    />

                    <SimpleInputText
                      label='Note'
                      {...register('accomodationNotes')}
                      errors={errors.accomodationNotes}
                    />
                  </Grid>
                </Flex>
              ),
            },
            {
              value: 'TRACK',
              button: (
                <ShipmentTabButton
                  icon={<TrackIcon w='42px' h='34px' color='brand.500' />}
                  text='Pista'
                  mx={TAB_MARGIN}
                  disabled={startDateIsToday}
                  onClick={() => {
                    setValue('delivery', 'TRACK');
                    unregister('pointAddress');
                    unregister('accomodation');
                    setSelectedPoint(undefined);
                  }}
                />
              ),
              panel: () => (
                <Flex direction='column'>
                  <SectionTitle text='Seleziona ritiro' mt='37px' mb='30px' />
                  <Text
                    fontSize='15px'
                    lineHeight={1.25}
                    color='brand.500'
                    textAlign='center'
                    maxW='390px'
                    mx='auto'
                  >
                    {!selectedDeliveryTrack
                      ? 'N.B: La fascia oraria per il ritiro varia in base alla pista scelta'
                      : 'N.B: Fascia oraria della pista scelta: ' +
                        updatedDeliveryHours}
                  </Text>
                  <Text
                    fontSize='15px'
                    lineHeight={1.25}
                    color='brand.500'
                    textAlign='center'
                    maxW='390px'
                    mx='auto'
                    mt='5px'
                  >
                    {'Costo del ritiro in pista: '}
                  </Text>
                  <Text
                    fontSize='15px'
                    lineHeight={1.25}
                    color='brand.500'
                    textAlign='center'
                    maxW='390px'
                    mx='auto'
                  >
                    {PrintServices.number(
                      pricesData['TRACKDELIVERY']?.fullValue
                    )}
                  </Text>
                  <Grid
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      md: 'repeat(3, 1fr)',
                    }}
                    columnGap={{ base: '0px', md: '20px' }}
                    rowGap={{ base: '14px', md: '0px' }}
                    w='100%'
                    maxW={{ md: '500px' }}
                    mx='auto'
                    mt='30px'
                  >
                    <GridItem w='100%' mx='auto' colSpan={2}>
                      <CustomSelect
                        maxW={{ md: '320px' }}
                        w='100%'
                        mx='auto'
                        mt='35px'
                        label='Seleziona piste disponibili'
                        {...register('trackAddress', {
                          required: deliveryType === 'TRACK',
                        })}
                        customDefaultValue={
                          !getValues('trackAddress')
                            ? undefined
                            : {
                                label: getValues('trackAddress')!,
                                value: getValues('trackAddress')!,
                              }
                        }
                        customOnChange={(track: {
                          label: string;
                          value: string;
                        }) => {
                          setValue(
                            'trackAddress',
                            trackData![+track.value].address
                          );
                          setSelectedDeliveryTrack(trackData![+track.value]);
                        }}
                        options={tracks}
                        errors={errors.trackAddress}
                      />
                    </GridItem>
                    <GridItem w='100%' mx='auto'>
                      <SimpleInputText
                        label='Orario'
                        {...register('deliveryTime', {
                          required: deliveryType === 'TRACK',
                          validate: (value) =>
                            !(deliveryType === 'TRACK') ||
                            deliveryHours(value!),
                        })}
                        maxW={{ md: '138px' }}
                        w='100%'
                        type='time'
                        secondaryLabelType='time'
                        timeRange={updatedDeliveryHours}
                        errors={errors.deliveryTime}
                      />
                    </GridItem>
                  </Grid>
                </Flex>
              ),
            },
            {
              value: 'Punto Yeki',
              button: (
                <ShipmentTabButton
                  icon={<PointIcon w='34px' h='30px' color='brand.500' />}
                  text='Punto yeki'
                  ml={TAB_MARGIN}
                  onClick={() => {
                    setValue('delivery', 'POINT');
                    unregister('trackAddress');
                    unregister('accomodation');
                    unregister('deliveryTime');
                    setSelectedDeliveryTrack(undefined);
                  }}
                />
              ),
              panel: () => (
                <>
                  <Flex direction='column'>
                    <SectionTitle text='Seleziona ritiro' mt='37px' mb='30px' />
                    <Text
                      fontSize='15px'
                      lineHeight={1.25}
                      color='brand.500'
                      textAlign='center'
                      maxW='390px'
                      w='100%'
                      mx='auto'
                    >
                      {!selectedPoint
                        ? 'N.B: La fascia oraria per il ritiro varia in base al punto Yeki scelto'
                        : 'N.B: Fascia oraria del punto scelto: ' +
                          updatedDeliveryHours}
                    </Text>
                    <Grid
                      templateColumns={{
                        base: 'repeat(1,1fr)',
                        md: 'repeat(3, 1fr)',
                      }}
                      columnGap={{ base: '0px', md: '20px' }}
                      rowGap={{ base: '14px', md: '0px' }}
                      w='100%'
                      maxW={{ md: '500px' }}
                      mx='auto'
                      mt='30px'
                    >
                      <GridItem w='100%' mx='auto' colSpan={2}>
                        <CustomSelect
                          maxW={{ md: '320px' }}
                          w='100%'
                          mx='auto'
                          mt='35px'
                          label='Seleziona negozio'
                          {...register('pointAddress', {
                            required: deliveryType === 'POINT',
                          })}
                          customDefaultValue={
                            !getValues('pointAddress')
                              ? undefined
                              : {
                                  label: getValues('pointAddress')!,
                                  value: getValues('pointAddress')!,
                                }
                          }
                          customOnChange={(point: {
                            label: string;
                            value: string;
                          }) => {
                            setValue(
                              'pointAddress',
                              pointData![+point.value].address
                            );
                            setSelectedPoint(pointData![+point.value]);
                          }}
                          options={points}
                          errors={errors.pointAddress}
                        />
                      </GridItem>
                      <GridItem w='100%' mx='auto'>
                        <SimpleInputText
                          label='Orario'
                          {...register('deliveryTime', {
                            required: deliveryType === 'POINT',
                            validate: (value) =>
                              !(deliveryType === 'POINT') ||
                              deliveryHours(value!),
                          })}
                          maxW={{ md: '138px' }}
                          w='100%'
                          type='time'
                          secondaryLabelType='time'
                          timeRange={updatedDeliveryHours}
                          errors={errors.deliveryTime}
                        />
                      </GridItem>
                    </Grid>
                  </Flex>
                </>
              ),
            },
          ]}
        />

        <SectionTitle text='Seleziona restituzione' mt='37px' mb='30px' />
        <Text
          fontSize='15px'
          lineHeight={1.25}
          color='brand.500'
          textAlign='center'
          maxW='370px'
          mx='auto'
          w='100%'
        >
          {!selectedPickupTrack
            ? 'N.B: La fascia oraria per la restituzione varia in base alla pista scelta'
            : 'Fascia oraria della pista scelta: ' + updatedPickupHours}
        </Text>
        <Grid
          templateColumns={{ base: 'repeat(1,1fr)', md: 'repeat(3, 1fr)' }}
          columnGap={{ base: '0px', md: '20px' }}
          rowGap={{ base: '14px', md: '0px' }}
          w='100%'
          maxW={{ md: '500px' }}
          mx='auto'
          mt='30px'
        >
          <GridItem w='100%' mx='auto' colSpan={2}>
            <CustomSelect
              maxW={{ md: '320px' }}
              w='100%'
              mt='35px'
              label='Seleziona pista per la restituzione'
              {...register('pickupTrackAddress', {
                required: true,
              })}
              customDefaultValue={
                !getValues('pickupTrackAddress')
                  ? undefined
                  : {
                      label: getValues('pickupTrackAddress'),
                      value: getValues('pickupTrackAddress'),
                    }
              }
              customOnChange={(track: { label: string; value: string }) => {
                setValue(
                  'pickupTrackAddress',
                  trackData![+track.value].address
                );
                setSelectedPickupTrack(trackData![+track.value]);
              }}
              options={tracks}
              errors={errors.pickupTrackAddress}
            />
          </GridItem>
          <GridItem w='100%' mx='auto'>
            <SimpleInputText
              label='Orario'
              {...register('pickupTime', {
                required: true,
                validate: (value) => pickupTrackHours(value!),
              })}
              maxW={{ md: '138px' }}
              w='100%'
              type='time'
              secondaryLabelType='time'
              timeRange={updatedPickupHours}
              errors={errors.pickupTime}
            />
          </GridItem>
        </Grid>

        <HStack>
          <StyledButton mt='35px !important' mb='50px' type='submit'>
            Procedi
          </StyledButton>
        </HStack>
      </form>
    </PageContainer>
  );
};
